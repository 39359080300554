import { observable, action, makeObservable } from 'mobx';
import queryString from 'query-string';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import Agents from '../agents/agents';
import TimeUtil from '../utils/timeUtil';

// The default report ID
const reportId = 'content-type';

class Report {
  userReports = [
    {
      heading: 'Courses',
      description: 'View course activity for your team members, including a number of courses enrolled and lessons completed',
      href: `/enterprise/{orgId}/reporting/report/course`,
      reportId: 'course',
    },
    {
      heading: 'Course Completion',
      description: 'View your team members and their completed courses',
      href: `/enterprise/{orgId}/reporting/report/course-completion`,
      reportId: 'course-completion',
    },
    {
      heading: 'Learning Hours',
      description: 'View learning hours completed for your team members',
      href: `/enterprise/{orgId}/reporting/report/learning-hours`,
      reportId: 'learning-hours',
    },
    {
      heading: 'Learning Activities',
      description: 'View learning activity interactions for your team members, including start date, progress, and score',
      href: `/enterprise/{orgId}/reporting/report/learning-activities`,
      reportId: 'learning-activities',
    },
    {
      heading: 'Member Activity',
      description: 'View member activity interactions for your team, including which members are active, passive, or inactive',
      href: `/enterprise/{orgId}/reporting/report/status-per-member`,
      reportId: 'status-per-member',
    },
    {
      heading: 'CEU',
      description: 'View CEUs completed for your team members',
      href: `/enterprise/{orgId}/reporting/report/ceu`,
      reportId: 'ceu',
    },
    {
      heading: 'Master Performance Report',
      description: 'Download a complete list of all Team Member activities',
      href: `/enterprise/{orgId}/reporting/report/team-performance`,
      reportId: 'team-performance',
      permission: 'teamReports',
      beta: true,
    },
  ];

  contentReports = [
    {
      heading: 'Paths Report',
      description: 'View a list of Paths containing team member activity',
      href: `/enterprise/{orgId}/reporting/report/curricula-overview`,
      reportId: 'curricula-overview',
    },
    {
      heading: 'Courses',
      description: 'View a list of courses containing team member activity',
      href: `/enterprise/{orgId}/reporting/report/content-type/?contentTypeIds[]=1&contentType=Course Activity`,
      reportId,
    },
    {
      heading: 'CybrScore Labs',
      description: 'View a list of CybrScore Labs containing team member activity',
      href: `/enterprise/{orgId}/reporting/report/content-type/?contentTypeIds[]=12&contentType=CybrScore Lab Activity`,
      reportId,
    },
    {
      heading: 'Practice Labs',
      description: 'View a list of Practice Labs containing team member activity',
      href: `/enterprise/{orgId}/reporting/report/content-type/?contentTypeIds[]=11&contentType=Practice Lab Activity`,
      reportId,
    },
    {
      heading: 'Rangeforce Labs',
      description: 'View Rangeforce Labs containing team member activity',
      href: `/enterprise/{orgId}/reporting/report/content-type/?contentTypeIds[]=16&contentType=Rangeforce Lab Activity`,
      reportId,
    },
    {
      heading: 'Rangeforce Assessments',
      description: 'View Rangeforce Assessments containing team member activity',
      href: `/enterprise/{orgId}/reporting/report/content-type/?contentTypeIds[]=32&contentType=Rangeforce Assessment Activity`,
      reportId,
    },
    {
      heading: 'CyberVista Practice Tests',
      description: 'View a list of CyberVista Practice Tests containing team member activity',
      href: `/enterprise/{orgId}/reporting/report/content-type/?contentTypeIds[]=14&contentType=CyberVista Practice Test Activity`,
      reportId,
    },
    {
      heading: 'Cydefe Assessments',
      description: 'View a list of Cydefe Assessments containing team member activity',
      href: `/enterprise/{orgId}/reporting/report/content-type/?contentTypeIds[]=15&contentType=Cydefe Assessment Activity`,
      reportId,
    },
    {
      heading: 'Microcourse Assessments',
      description: 'View a list of Microcourse Assessments containing team member activity',
      href: `/enterprise/{orgId}/reporting/report/content-type/?contentTypeIds[]=13&contentType=Micro Course Assessment Activity`,
      reportId,
    },
    {
      heading: 'iMocha Assessments',
      description: 'View a list of iMocha Assessments containing team member activity',
      href: `/enterprise/{orgId}/reporting/report/content-type/?contentTypeIds[]=21&contentType=iMocha Assessment Activity`,
      reportId,
    },
    {
      heading: 'Practice Labs Exams',
      description: 'View a list of Practice Labs Exams containing team member activity',
      href: `/enterprise/{orgId}/reporting/report/content-type/?contentTypeIds[]=18&contentType=Practice Labs Exams`,
      reportId,
    },
    {
      heading: 'Skillable Virtual Labs',
      description: 'View a list of Skillable Labs containing team member activity',
      href: `/enterprise/{orgId}/reporting/report/content-type/?contentTypeIds[]=22&contentType=Skillable Virtual Labs`,
      reportId,
    },
    {
      heading: 'Skillable Assessments',
      description: 'View a list of Skillable Assessments containing team member activity',
      href: `/enterprise/{orgId}/reporting/report/content-type/?contentTypeIds[]=33&contentType=Skillable Assessments`,
      reportId,
    },
    {
      heading: 'Next Tech Virtual Labs',
      description: 'View a list of Next Tech labs containing team member activity',
      href:
        `/enterprise/{orgId}/reporting/report/content-type/?contentTypeIds[]=25` +
        `&contentTypeIds[]=26&contentTypeIds[]=27&contentTypeIds[]=28&contentTypeIds[]=29&contentTypeIds[]=30&contentTypeIds[]=31&contentType=Next Tech Virtual Labs`,
      reportId,
    },
    {
      heading: 'Infosec Learning Virtual Labs',
      description: 'View a list of Infosec Learning labs containing team member activity',
      href: `/enterprise/{orgId}/reporting/report/content-type/?contentTypeIds[]=34&contentType=Infosec Learning Virtual Labs`,
      reportId,
    },
  ];

  avataoReports = [
    {
      heading: 'Avatao Virtual Labs',
      description: 'View a list of Avatao labs containing team member activity',
      href: `/enterprise/{orgId}/reporting/report/content-type/?contentTypeIds[]=35&contentTypeIds[]=36&contentTypeIds[]=37&contentType=Avatao Virtual Labs`,
      reportId,
      premiumContent: 'Avatao',
    },
  ];

  reportTools = [
    {
      heading: 'Scheduled Reports',
      text: 'Manage scheduled reports',
      href: `/enterprise/{orgId}/reporting/scheduled-reports`,
    },
  ];

  /* Replace the placeholder orgId with the actual orgId provided */
  formatReportHrefs = (reportsArr, teamId) => {
    if (!reportsArr || !reportsArr.length || !teamId) {
      return [];
    }
    return reportsArr.map((item) => {
      const itemCopy = { ...item };
      itemCopy.href = itemCopy.href.replace('{orgId}', teamId);
      return itemCopy;
    });
  };

  filterReports = (reports, permissions) => {
    const reportsCopy = [...reports];
    return reportsCopy.filter((report) => {
      return !report.permission || (report.permission === 'teamReports' && !!permissions && permissions.canViewReports === 'all');
    });
  };

  /* Get the reports for the view specified */
  getReportLinks = (view, team) => {
    if (view === 'user') {
      const reports = this.filterReports(this.userReports, team.permissions);
      return this.formatReportHrefs(reports, team.id);
    }
    if (view === 'content') {
      const contentReports = [...this.contentReports];
      if (team && team.package_types && team.package_types.indexOf('avatao') > -1) {
        contentReports.push(...this.avataoReports);
      }
      const reports = this.filterReports(contentReports, team.permissions);
      return this.formatReportHrefs(reports, team.id);
    }
    if (view === 'tools') {
      const reports = this.filterReports(this.reportTools, team.permissions);
      return this.formatReportHrefs(reports, team.id);
    }
    return [];
  };

  getReportType = (id) => {
    // Check if this report type is in the content reports array
    const contentReportMatches = this.contentReports.filter((item) => item.reportId === id);
    return contentReportMatches.length ? 'Content' : 'User';
  };

  reportItem = {
    graph: {
      loading: true,
      error: false,
      data: null,
      interval: 'month',
      tableParams: {
        sortCol: 'period',
        sortDirection: 'asc',
      },
    },
    table: {
      loading: true,
      error: false,
      data: {
        tableHeadings: null,
        omittedHeadings: [],
        tableData: null,
        recordsCount: null,
      },
    },
    filters: {
      loading: true,
      error: false,
      data: null,
    },
    queryParams: {
      filterAud: 'all',
      filterAudId: null,
      userId: null,
      sortCol: 'real_name',
      sortDirection: 'asc',
      resultsPerPg: 100,
      resultsPg: 1,
      startDate: moment().subtract(30, 'd').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    },
    filterChips: {},
    startDate: moment().subtract(30, 'd'), // These are needed as temporary dates prior to user hitting the 'Apply Filters' button
    endDate: moment(),
    userFilter: {
      userId: null,
      name: '',
    },
    canClear: false, // These flags dictate when you can clear and submit filters -- each of which result in service calls and need to be managed individually
    canFilter: false,
  };

  constructor() {
    makeObservable(this, {
      reportItem: observable,
      setDefaultReportItem: action,
      setViewChangeParamDefaults: action,
      setFilterChips: action,
      clearFilterChips: action,
      removeChip: action,
      setGraphLoading: action,
      setGraphError: action,
      setGraphData: action,
      setTableLoading: action,
      setTableError: action,
      setTableData: action,
      setFilterLoading: action,
      setFilterError: action,
      setFilterData: action,
      setActionFlag: action,
      setQueryParams: action,
      deleteQueryParam: action,
      setDates: action,
      setUserFilter: action,
      omitHeading: action,
      setDefaultQueryParams: action,
      downloadLoading: observable,
      downloadReport: action,
      loadGraphData: action,
      loadTableData: action,
      loadFilterData: action,
      scheduledReportsTableData: observable,
      setDefaultScheduledReportsTableData: action,
      setScheduledReportsTableLoading: action,
      setScheduledReportsTableError: action,
      scheduledReportData: observable,
      setDefaultScheduledReportData: action,
      setScheduledReportLoading: action,
      setScheduledReportError: action,
      getScheduledReportData: action,
      setScheduledReportData: action,
      setScheduledReportParams: action,
      userListData: observable,
      setDefaultUserListData: action,
      setUserListLoading: action,
      setUserListError: action,
      postScheduledReport: action,
      deleteScheduledReport: action,
      getUserListData: action,
    });
  }

  setDefaultReportItem() {
    this.reportItem = {
      graph: {
        loading: true,
        error: false,
        data: null,
        interval: 'month',
        tableParams: {
          sortCol: 'period',
          sortDirection: 'asc',
        },
      },
      table: {
        loading: true,
        error: false,
        data: {
          tableHeadings: null,
          omittedHeadings: [],
          tableData: null,
          recordsCount: null,
        },
      },
      filters: {
        loading: true,
        error: false,
        data: null,
      },
      queryParams: {
        filterAud: 'all',
        filterAudId: null,
        userId: null,
        groupName: null,
        sortCol: 'real_name',
        sortDirection: 'asc',
        resultsPerPg: 100,
        resultsPg: 1,
        startDate: moment().subtract(30, 'd').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
      filterChips: {},
      startDate: moment().subtract(30, 'd'), // These are needed as temporary dates prior to user hitting the 'search' button
      endDate: moment(),
      userFilter: {
        userId: null,
        name: '',
      },
      canClear: false, // These flags dictate when you can clear and submit filters -- each of which result in service calls and need to be managed individually
      canFilter: false,
    };
  }

  setViewChangeParamDefaults(queryParams) {
    this.reportItem.graph = {
      loading: true,
      error: false,
      data: null,
    };

    this.reportItem.table = {
      loading: true,
      error: false,
      data: {
        tableHeadings: null,
        omittedHeadings: [],
        tableData: null,
        recordsCount: null,
      },
    };

    this.reportItem.filters = {
      loading: true,
      error: false,
      data: null,
    };

    this.reportItem.queryParams = {
      ...this.reportItem.queryParams,
      resultsPerPg: 100,
      resultsPg: 1,
    };
    const queryParamKeys = Object.keys(queryParams);
    if (queryParamKeys.indexOf('contentName') < 0) {
      delete this.reportItem.queryParams.contentName;
      delete this.reportItem.queryParams.contentDescriptionId;
    }
    if (queryParamKeys.indexOf('assignmentName') < 0) {
      delete this.reportItem.queryParams.assignmentName;
      delete this.reportItem.queryParams.assignment;
    }
  }

  // Adds timestamps to the start/end dates - Fixes bug with some report data being omitted
  formatDateQueryParams = () => {
    this.reportItem.queryParams.startDate = moment(this.reportItem.queryParams.startDate).format('YYYY-MM-DD 00:00:00');
    this.reportItem.queryParams.endDate = moment(this.reportItem.queryParams.endDate).format('YYYY-MM-DD 23:59:59');
  };

  setFilterChips = (id) => {
    this.reportItem.filterChips = {};
    Object.keys(this.reportItem.queryParams).forEach((param) => {
      if (
        // Not all query params should show as chips (group ID's, user ID's, etc)
        !!this.reportItem.queryParams[param] &&
        (param === 'groupName' ||
          param === 'dateRange' ||
          param === 'userId' ||
          param === 'goalName' ||
          // Some reports should not allow you to remove their content ID filtering (there isn't a blanket report for all content ID's)
          (param === 'contentName' && id !== 'content-type' && id !== 'content' && id !== 'assignment' && id !== 'assignment-details' && id !== 'curricula-content') ||
          param === 'assignmentName')
      ) {
        let chipText = this.reportItem.queryParams[param];
        if (param === 'userId' && this.reportItem.userFilter.name && !!this.reportItem.userFilter.userId) {
          // If we have a userId chip, but also have a name and userId from filter, use the user name instead
          chipText = this.reportItem.userFilter.name;
        }
        this.reportItem.filterChips[param] = chipText;
      }
    });
  };

  clearFilterChips = (canViewReports) => {
    const currChips = { ...this.reportItem.filterChips };
    if (canViewReports === 'group') {
      this.reportItem.filterChips = {
        groupName: currChips.groupName,
      };
    }
  };

  removeChip = (item) => {
    this.setQueryParams(item, null);
    delete this.reportItem.filterChips[item];
  };

  setGraphLoading(loading) {
    this.reportItem.graph.loading = loading;
  }

  setGraphError(error) {
    this.reportItem.graph.error = error;
  }

  setGraphData(data) {
    this.reportItem.graph.data = data;
  }

  setTableLoading(loading) {
    this.reportItem.table.loading = loading;
  }

  setTableError(error) {
    this.reportItem.table.error = error;
  }

  setTableData(data) {
    this.reportItem.table.data = data;
  }

  setFilterLoading(loading) {
    this.reportItem.filters.loading = loading;
  }

  setFilterError(error) {
    this.reportItem.filters.error = error;
  }

  setFilterData(data) {
    this.reportItem.filters.data = data;
  }

  setActionFlag(flag, status) {
    this.reportItem[flag] = status;
  }

  setQueryParams = (objKey, queryData, isGraphTable) => {
    if (isGraphTable) {
      this.reportItem.graph.tableParams[objKey] = queryData;
    } else {
      this.reportItem.queryParams[objKey] = queryData;
    }
  };

  deleteQueryParam = (param) => {
    delete this.reportItem.queryParams[param];
  };

  setDates = (objKey, date) => {
    this.reportItem[objKey] = date;
  };

  setUserFilter = (userId, name) => {
    this.reportItem.userFilter = {
      name,
      userId,
    };
  };

  omitHeading = (headingIdx) => {
    this.reportItem.table.data.omittedHeadings.push(headingIdx);
  };

  setDefaultQueryParams = (canViewReports) => {
    const currQueryParams = {
      ...this.reportItem.queryParams,
      userId: null,
      sortCol: 'name',
      sortDirection: 'asc',
      resultsPerPg: 100,
      resultsPg: 1,
      startDate: moment().subtract(30, 'd').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    };
    // Don't want to remove the group filter if the user can't see all reports
    if (canViewReports === 'all') {
      currQueryParams.groupName = null;
      currQueryParams.filterAud = 'all';
      currQueryParams.filterAudId = null;
    }
    delete currQueryParams.dateRange;
    this.reportItem.queryParams = currQueryParams;
    this.reportItem.startDate = moment().subtract(30, 'd');
    this.reportItem.endDate = moment();
  };

  // Break apart/format the contentTypeIds[] param
  getContentTypeIdsParam = (param) => {
    let contentTypeIdParams = '';
    // If contentTypeIds isn't an array, make it one (so it can all run through same forEach below to make the param string)
    const contentTypeIds = Array.isArray(param.slice()) ? param.slice() : [param];
    const showScoreContentTypes = [14, 15, 16, 18, 21];
    contentTypeIds.forEach((typeId) => {
      contentTypeIdParams += `&contentTypeIds[]=${typeId}`;
      // Certain content type reports also need a showScore param. Include that param if of type
      if (showScoreContentTypes.indexOf(1 * typeId) > -1) {
        contentTypeIdParams += `&showScore=1`;
      }
    });
    return contentTypeIdParams;
  };

  // compose the query params for the report retrieval. Handles breaking apart contentTypeIds[] is present
  getReportQueryParams = (includeGraphTableParams, includeDateConstraints) => {
    // contentTypeIds[] can be an array of items but need to be sent individually to backend. Need to break them out
    const { startDate, endDate, ...params } = this.reportItem.queryParams;

    let reportQueryParams = { ...params };

    if (includeDateConstraints) {
      reportQueryParams = {
        ...reportQueryParams,
        startDate,
        endDate,
      };
    }

    if (includeGraphTableParams) {
      reportQueryParams = { ...reportQueryParams, ...this.reportItem.graph.tableParams };
    }
    const contentTypeIds = reportQueryParams['contentTypeIds[]'];
    delete reportQueryParams['contentTypeIds[]'];

    let query = `?${queryString.stringify(reportQueryParams)}`;
    if (contentTypeIds) {
      query += this.getContentTypeIdsParam(contentTypeIds);
    }
    return query;
  };

  downloadLoading = false;

  downloadReport = (id, teamId, reportTitle, format, includeDateConstraints) => {
    this.downloadLoading = true;
    let updatedReportId = id;

    if (includeDateConstraints) {
      this.formatDateQueryParams();
    }

    let query = '';

    // In some reports, we need to add a param that omits empty results
    if (updatedReportId === 'assignment') {
      query += '/csv/signed';
      if (this.reportItem.queryParams.contentDescriptionId) {
        this.reportItem.queryParams.includeEmpty = 0;
      }
    } else if (updatedReportId === 'assignment-details') {
      updatedReportId = 'assignment';
      query += this.reportItem.queryParams.assignment ? `/${this.reportItem.queryParams.assignment}` : '';
      if (this.reportItem.queryParams.userId) {
        query += `/user/${this.reportItem.queryParams.userId}/csv/signed`;
        this.reportItem.queryParams.includeEmpty = 0;
      }
    } else if (updatedReportId === 'content-type' || updatedReportId === 'content' || updatedReportId === 'curricula-overview') {
      query += '/csv/signed';
      this.reportItem.queryParams.includeEmpty = 0;
    } else {
      query += '/csv/signed';
    }

    let teamIdForQuery = teamId;
    if (this.reportItem.queryParams.filterAud === 'group' && !!this.reportItem.queryParams.filterAudId) {
      // If filtering by a specific group, pass that groupId rather than team ID
      teamIdForQuery = this.reportItem.queryParams.filterAudId;
    }

    query += this.getReportQueryParams(false, includeDateConstraints);
    query += reportTitle ? `&reportTitle=${reportTitle}` : '';
    query += format ? `&format=${format}` : '';

    Agents.reports
      .getReportData(teamIdForQuery, updatedReportId, query)
      .then(
        action('fetchSuccess', (response) => {
          window.open(response, '_blank');
          this.downloadLoading = false;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.downloadLoading = false;
        })
      );
  };

  downloadScheduledReport = (orgId, downloadId) => {
    return Agents.reports.downloadScheduledReport(orgId, downloadId);
  };

  // /**
  //  * Get graph data for component and sets data in store
  //  */
  loadGraphData(id, teamId, tableFormat) {
    const { startDate, endDate } = this.reportItem.queryParams;
    this.formatDateQueryParams();

    const query = this.getReportQueryParams(!!tableFormat);
    let teamIdForQuery = teamId;

    const interval = TimeUtil.getDateRangeInterval(startDate, endDate);
    this.reportItem.graph.interval = interval;

    if (this.reportItem.queryParams.filterAud === 'group' && !!this.reportItem.queryParams.filterAudId) {
      // If filtering by a specific group, pass that groupId rather than team ID
      teamIdForQuery = this.reportItem.queryParams.filterAudId;
    }
    this.setGraphLoading(true);
    this.setGraphError(false);

    let queryParams = query;
    queryParams += `${query.length ? '&' : '?'}interval=${interval}`;

    if (id === 'content-type-interval') {
      queryParams += `&contentTypeIds[]=1`;
    }
    if (tableFormat) {
      queryParams += `&format=table`;
    }

    Agents.reports
      .getReportData(teamIdForQuery, id, queryParams)
      .then(
        action('fetchSuccess', (response) => {
          this.reportItem.graph.data = response;
          this.setGraphLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setGraphError(error.response || error);
          this.setGraphLoading(false);
        })
      );
  }

  // /**
  //  * Get table data for component and sets data in store
  //  */
  loadTableData(id, teamId) {
    let updatedReportId = id;
    this.formatDateQueryParams();
    let query = '';
    if (updatedReportId === 'assignment' && !!this.reportItem.queryParams.contentDescriptionId) {
      this.reportItem.queryParams.includeEmpty = 0;
    } else if (updatedReportId === 'assignment-details') {
      updatedReportId = 'assignment';
      query += this.reportItem.queryParams.assignment ? `/${this.reportItem.queryParams.assignment}` : '';
      if (this.reportItem.queryParams.userId) {
        query += `/user/${this.reportItem.queryParams.userId}`;
        this.reportItem.queryParams.includeEmpty = 0;
      }
    } else if (updatedReportId === 'content-type') {
      if (this.reportItem.queryParams['contentTypeIds[]']) {
        this.reportItem.queryParams.includeEmpty = 0;
      }
    } else if (updatedReportId === 'content') {
      if (this.reportItem.queryParams.contentDescriptionId) {
        this.reportItem.queryParams.includeEmpty = 0;
      }
    } else if (updatedReportId === 'curricula-overview') {
      // When searching for a specific user in this report, we do not want it to return curricula not assigned
      this.reportItem.queryParams.includeEmpty = 0;
    }

    query += this.getReportQueryParams();
    let teamIdForQuery = teamId;
    if (this.reportItem.queryParams.filterAud === 'group' && !!this.reportItem.queryParams.filterAudId) {
      // If filtering by a specific group, pass that groupId rather than team ID
      teamIdForQuery = this.reportItem.queryParams.filterAudId;
    }
    this.setTableLoading(true);
    this.setTableError(false);
    Agents.reports
      .getReportData(teamIdForQuery, updatedReportId, `${query}&format=table`)
      .then(
        action('fetchSuccess', (response) => {
          this.reportItem.table.data.tableData = response.tableData;
          this.reportItem.table.data.tableHeadings = response.columns;
          this.reportItem.table.data.recordsCount = response.totalRecords;
          this.setTableLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setTableError(error.response || error);
          this.setTableLoading(false);
        })
      );
  }

  // /**
  //  * Format filter options for select list autocomplete
  //  * @param data
  //  * @param aud
  //  * @returns obj
  //  */
  formatFilterData = (data) => {
    const returnData = [];
    data.forEach((item) => {
      returnData.push({
        key: item.value,
        value: item.value,
        text: item.text,
      });
    });
    return returnData;
  };

  // /**
  //  * Get filter data for component and sets data in store
  //  */
  loadFilterData(teamId, aud) {
    this.setFilterLoading(true);
    this.setFilterError(false);
    Agents.reports
      .getFilterData(teamId, aud)
      .then(
        action('fetchSuccess', (response) => {
          const formattedData = this.formatFilterData(response.data);
          this.setFilterData(formattedData.slice());
          this.setFilterLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setFilterError(error.response || error);
          this.setFilterLoading(false);
        })
      );
  }

  // Report date range obj -- Dropdown options as well as moment filtered dates
  dateRangeData = {
    today: {
      text: 'Today',
      startDate: moment(),
      endDate: moment(),
    },
    '7days': {
      text: 'Last 7 Days',
      startDate: moment().subtract(7, 'd'),
      endDate: moment(),
    },
    '30days': {
      text: 'Last 30 Days',
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
    },
    '3months': {
      text: 'Last 3 Months',
      startDate: moment().subtract(3, 'months'),
      endDate: moment(),
    },
    '6months': {
      text: 'Last 6 Months',
      startDate: moment().subtract(6, 'months'),
      endDate: moment(),
    },
    year: {
      text: 'Last Year',
      startDate: moment().subtract(1, 'y'),
      endDate: moment(), // from same day
    },
    monthToDate: {
      text: 'Month to Date',
      startDate: moment().startOf('month'),
      endDate: moment(),
    },
    yearToDate: {
      text: 'Year to Date',
      startDate: moment().startOf('year'),
      endDate: moment(),
    },
  };

  // Returns array of date range dropdown options
  getDateRangeOptions() {
    return Object.keys(this.dateRangeData).map((option) => {
      return {
        key: option,
        text: this.dateRangeData[option].text,
        value: option,
      };
    });
  }

  /* Scheduled Reports */

  scheduledReportsTableData = {
    loading: true,
    error: false,
    data: {},
    queryParams: {
      activePg: 1,
      recordsPerPage: 20,
      sortCol: 'report_name',
      sortDirection: 'asc',
    },
    tempEndpoint: '',
    totalRecords: '',
  };

  setDefaultScheduledReportsTableData() {
    this.scheduledReportsTableData = {
      loading: true,
      error: false,
      data: {},
      queryParams: {
        activePg: 1,
        recordsPerPage: 20,
        sortCol: 'report_name',
        sortDirection: 'asc',
      },
      tempEndpoint: '',
      totalRecords: '',
    };
  }

  setScheduledReportsTableLoading(loading) {
    this.scheduledReportsTableData.loading = loading;
  }

  setScheduledReportsTableError(error) {
    this.scheduledReportsTableData.error = error;
  }

  /**
   * Gets a table with the current team members on the team
   */
  getScheduledReportsTableData = (orgId) => {
    this.setScheduledReportsTableLoading(true);
    this.setScheduledReportsTableError(false);
    const query = `?${queryString.stringify(this.scheduledReportsTableData.queryParams)}`;
    if (this.scheduledReportsTableData.tempEndpoint !== query) {
      // Prevent un-needed calls to loadTableData
      this.scheduledReportsTableData.tempEndpoint = query;
    }
    return Agents.reports
      .getScheduledReports(orgId, query)
      .then(
        action('fetchSuccess', (response) => {
          this.scheduledReportsTableData.data.tableData = response.tableData;
          this.scheduledReportsTableData.data.tableHeadings = response.columns;
          this.scheduledReportsTableData.data.recordsCount = response.totalRecords;
          this.scheduledReportsTableData.data.pagPagesCount = Math.ceil(
            this.scheduledReportsTableData.data.recordsCount / this.scheduledReportsTableData.queryParams.recordsPerPage
          );
          this.setScheduledReportsTableLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setScheduledReportsTableError(error.response || error);
          this.setScheduledReportsTableLoading(false);
        })
      );
  };

  scheduledReportData = {
    loading: true,
    error: false,
    data: {
      report_parameters: {},
    },
  };

  setDefaultScheduledReportData() {
    this.scheduledReportData = {
      loading: true,
      error: false,
      data: {
        report_parameters: {},
      },
    };
  }

  setScheduledReportLoading(loading) {
    this.scheduledReportData.loading = loading;
  }

  setScheduledReportError(error) {
    this.scheduledReportData.error = error;
  }

  getScheduledReportData(orgId, scheduleId) {
    return Agents.reports
      .getScheduledReport(orgId, scheduleId)
      .then(
        action('fetchSuccess', (response) => {
          this.scheduledReportData.data = response;
          this.setScheduledReportLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setScheduledReportError(error.response || error);
          this.setScheduledReportLoading(false);
        })
      );
  }

  setScheduledReportData(data) {
    this.setScheduledReportLoading(false);
    this.scheduledReportData.data = data;
  }

  setScheduledReportParams(data) {
    this.setScheduledReportLoading(false);
    if (data) {
      const reportParams = { ...data };
      // If we have contentTypeIds[], which is valid for the GET queryParams, we need to rename the key for this POST
      if (reportParams['contentTypeIds[]']) {
        reportParams.contentTypeIds = reportParams['contentTypeIds[]'];
        delete reportParams['contentTypeIds[]'];
      }
      this.scheduledReportData.data.report_parameters = reportParams;
    }
  }

  userListData = {
    loading: true,
    error: false,
    data: null,
  };

  setDefaultUserListData() {
    this.userListData = {
      loading: true,
      error: false,
      data: null,
    };
  }

  setUserListLoading(loading) {
    this.userListData.loading = loading;
  }

  setUserListError(error) {
    this.userListData.error = error;
  }

  postScheduledReport(orgId, data) {
    const scheduledReportData = data;
    scheduledReportData.start_date = moment(scheduledReportData.start_date).format('YYYY-MM-DD 00:00:00');
    scheduledReportData.end_date = moment(scheduledReportData.end_date).format('YYYY-MM-DD 23:59:59');
    if (scheduledReportData.interval === 'MTD') {
      scheduledReportData.startOf = 'Month';
    } else if (scheduledReportData.interval === 'YTD') {
      scheduledReportData.startOf = 'Year';
    }
    scheduledReportData.report_parameters = this.scheduledReportData.data.report_parameters;
    const { name } = this.scheduledReportData.data.report_parameters;
    scheduledReportData.report_name = name;

    // If id exists with current schedule data, we are editing an existing record. Include that ID
    if (this.scheduledReportData.data.id) {
      scheduledReportData.id = this.scheduledReportData.data.id;
    }
    return Agents.reports.addEditScheduledReport(orgId, scheduledReportData);
  }

  deleteScheduledReport = (orgId, id) => {
    return Agents.reports.deleteScheduledReport(orgId, id);
  };

  /**
   * Retrieve team specific member data for listing and set data in store
   */
  getUserListData(orgId) {
    this.setUserListLoading(true);
    this.setUserListError(false);
    return Agents.enterprise
      .getMembersList(orgId)
      .then(
        action('fetchSuccess', (response) => {
          this.userListData.data = response.data;
          this.setUserListLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setUserListError(error.response || error);
          this.setUserListLoading(false);
        })
      );
  }
}

export default new Report();
