import React, { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import Loading from './Loading';
import Icon from '../Icon/Icon';
import SearchUtil from '../../utils/searchUtil';

function FactIcon(icon) {
  if (!icon) {
    return null;
  }
  return (
    <div className="self-center mr-2">
      <Icon className="font-gray-700" name={icon} />
    </div>
  );
}

function LoadingFacts() {
  const [loadingFact, setLoadingFact] = useState(null);

  const getRandomLoadingFact = (loadingFacts) => {
    if (!loadingFacts) {
      return null;
    }
    return loadingFacts[Math.floor(Math.random() * loadingFacts.length)];
  };

  const getLoadingFacts = async () => {
    const adIndex = process.env.REACT_APP_INSTANTSEARCH_ADS_INDEX;
    const options = {
      hitsPerPage: 1,
      filters: SearchUtil.buildFilters('section', ['loading']),
    };
    try {
      const factsData = await SearchUtil.fetchFromAlgolia(adIndex, options);
      const facts = (factsData && factsData.hits && factsData.hits.length && factsData.hits[0].facts) || null;
      setLoadingFact(getRandomLoadingFact(facts));
    } catch (e) {
      Bugsnag.notify(e);
    }
  };

  useEffect(() => {
    getLoadingFacts();
  }, []);

  return (
    <div className="pt-12">
      <Loading />
      <div className="pt-8">
        <div className={`mx-auto max-w-lg transition-all duration-1000 ${loadingFact ? 'opacity-100' : 'opacity-0 -mt-16'}`}>
          {loadingFact && (
            <>
              <FactIcon icon={loadingFact.icon} />
              <div className="px-4">
                {loadingFact.title && <p className="mb-0 text-lg font-semibold">{loadingFact.title}</p>}
                <p>{loadingFact.description}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoadingFacts;
