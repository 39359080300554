import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import { useOnboardingContext } from '../../../providers/OnboardingProvider';
import { USERPILOT_EVENTS } from '../../../constants';

function NavigateStep() {
  const { navigationLocation = '/' } = useOnboardingContext();
  const [isCurrentPage, setIsCurrentPage] = useState('');

  useEffect(() => {
    // will output something like https://www.cybrary.com/?refreshUser=1... /?refreshUser=1 comes from navigationLocation
    const url = new URL(`${process.env.REACT_APP_BASE_URL}${navigationLocation}`);
    const { pathname } = url;
    const currentPage = window.location.pathname === pathname;
    // prevents infinite looping with Navigate component
    setIsCurrentPage(currentPage);
    // Track Userpilot event for finishing onboarding
    Userpilot.track(USERPILOT_EVENTS.FINISHED_ONBOARDING);
  }, []);

  if (!isCurrentPage) {
    // refresh user store and navigate to new page
    const refreshAndNavigateUrl = `${navigationLocation}?refreshUser=1&finishedOnboarding=1`;
    return <Navigate to={refreshAndNavigateUrl} />;
  }
  return null;
}
export default NavigateStep;
