import React from 'react';
import { find, has } from 'lodash';
import nav from '../../navigation/nav';
import NavIconSwitch from './NavIconSwitch';
import AddLink from '../AddLink/AddLink';

// show child labels
function AdditionalLabels({ labels, userStore }) {
  return labels.map((item) => {
    const { display, id } = item;
    const Icon = <NavIconSwitch key={id} id="-profile-menu" name={item.icon} active classes="fill-current text-gray-500 h-10 w-10 lg:h-8 lg:w-8 mt-1" />;

    switch (item.type) {
      case 'upgrade':
        return null;

      case 'icon':
        return Icon;

      case 'default':
        if (display && !display(userStore)) {
          return null;
        }
        return (
          !item.hide && (
            <span key={id} className="flex pr-2 text-xs font-bold text-gray-600 uppercase lg:text-2xs">
              {item.label}
            </span>
          )
        );

      default:
        return null;
    }
  });
}

export default function ProfileMenu({ onClick, mobile, userStore }) {
  const { primaryDesktopRight } = nav;
  const profileDropdown = find(primaryDesktopRight.items, (item) => {
    return item.label === 'Profile';
  });

  function handleClick() {
    if (onClick) {
      onClick();
    }
  }

  if (!has(profileDropdown, 'children')) {
    return null;
  }

  return (
    <ul>
      {profileDropdown.children.map((item) => {
        const { display } = item;

        if (display && !display?.(userStore)) {
          return null;
        }

        if (mobile && (item.label === 'Logout' || item.hideOnMobile)) {
          return null;
        }

        if (item.heading) {
          return (
            <li key={item.id}>
              <h2 className="pr-4 pb-2 pl-6 text-xs font-bold uppercase lg:px-8 lg:pb-0 lg:text-2xs">{item.label}</h2>
            </li>
          );
        }
        if (item.divider) {
          return (
            <li key={item.id}>
              <div className={`my-3 ${item.noFollowingHeader ? 'mb-3' : 'mb-5'} w-full border-b`} style={{ height: '1px' }} />
            </li>
          );
        }

        const linkClass = `${
          item.additionalLabels ? 'w-auto' : 'w-full'
        } lg:w-auto flex items-center text-gray-600 lg:text-gray-500 hover:text-black lg:hover:text-gray-500 text-lg lg:text-sm pl-6 lg:pl-8 pr-2 py-3 lg:py-2 cursor-pointer`;

        return (
          <li key={item.id} className="flex items-center mb-2">
            <AddLink onClick={() => handleClick()} to={item.href} className={linkClass}>
              <span className="pr-2 text-gray-600">{item.label}</span>
            </AddLink>
            {item.additionalLabels && <AdditionalLabels onClick={() => handleClick()} labels={item.additionalLabels} userStore={userStore} />}
          </li>
        );
      })}
    </ul>
  );
}
