import React from 'react';
import { inject, observer } from 'mobx-react';
import Bugsnag from '@bugsnag/js';
import { PATH_OMITTED_CONTENT_TYPES } from '../../constants';
import MultiSelect from '../Select/MultiSelect';
import SearchUtil from '../../utils/searchUtil';
import FormatUtil from '../../utils/formatUtil';
import CurriculumBuildForm from '../../forms/CurriculumBuildForm';
import CurriculumBuildContent from './CurriculumBuildContent';
import Loading from '../Loading/Loading';
import Segment from '../Segment/Segment';
import Divider from '../Divider/Divider';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import StyledError from '../Error/StyledError';
import Agents from '../../agents/agents';
import Container from '../Container/Container';

const CurriculumBuilder = inject(
  'userStore',
  'enterpriseStore',
  'commonStore',
  'authStore',
  'notificationsStore'
)(
  observer(
    class CurriculumBuilder extends React.Component {
      state = {
        submitting: false,
        serverErrors: null,
        isModalOpen: false,
        duplicates: [],
        selectedDuplicates: [],
        data: null,
        hasSelectedAll: false,
        team: null,
      };

      componentDidMount() {
        const { team: storeTeam } = this.props.userStore;
        const { curriculumId, copyId, scopedTeam } = this.props;
        const team = scopedTeam || storeTeam;
        // Initialize our courseCache
        this.courseCache = {};
        const newState = {
          ...this.state,
          team,
        };
        this.setState(newState);
        if (curriculumId) {
          // Edit curricula
          this.props.enterpriseStore.setEditMode(true);
          this.props.enterpriseStore.setCreateMode(false);
          this.props.enterpriseStore.setCopyMode(false);
          this.loadCurriculum(team.id, curriculumId);
        } else if (copyId) {
          // Copy curricula
          this.props.enterpriseStore.setEditMode(false);
          this.props.enterpriseStore.setCreateMode(false);
          this.props.enterpriseStore.setCopyMode(true);
          this.loadCurriculum(team.id, copyId);
        } else {
          // Create curricula
          this.props.enterpriseStore.setCreateMode(true);
          this.props.enterpriseStore.setEditMode(false);
          this.props.enterpriseStore.setCopyMode(false);
          this.props.enterpriseStore.setCurriculumLoading(false);
        }
      }

      componentWillUnmount() {
        if (this.props.commonStore.confirmState.confirmDisplay) {
          this.props.commonStore.resetConfirmState();
        }
        this.props.enterpriseStore.reset();
      }

      loadCurriculum = (teamId, curriculumId) => {
        this.props.enterpriseStore.loadCurriculum(teamId, curriculumId).then(() => {
          if (this.props.enterpriseStore.curriculum && this.props.enterpriseStore.curriculum.content_replaced_at) {
            this.props.notificationsStore
              .getReplacedContent(curriculumId)
              .then(() => {
                this.props.enterpriseStore.setCurriculumLoading(false);
              })
              .catch(() => {
                this.props.enterpriseStore.setCurriculumLoading(false);
              });
          } else {
            this.props.enterpriseStore.setCurriculumLoading(false);
          }
        });
      };

      resetConfirmState = () => {
        const newState = {
          ...this.state,
          isModalOpen: false,
        };
        this.setState(newState);
      };

      handleCancel = () => {
        this.props.commonStore.resetConfirmState();
        this.props.enterpriseStore.reset();
        this.props.navigate(`/enterprise/${this.state.team.id}/paths`);
      };

      triggerSuccess = (action) => {
        this.props.commonStore.triggerToast('success', {
          content: `The path was successfully ${action}.`,
        });
        this.props.commonStore.resetConfirmState();
        const newState = {
          ...this.state,
          submitting: false,
          serverErrors: null,
        };
        this.setState(newState);
      };

      clearErrorForField = (field) => {
        if (this.state.serverErrors && this.state.serverErrors.errors && this.state.serverErrors.errors[field]) {
          const newServerErrors = {
            ...this.state.serverErrors.errors,
            [field]: undefined,
          };
          const newState = {
            ...this.state,
            serverErrors: {
              ...this.state.serverErrors,
              errors: newServerErrors,
            },
          };
          this.setState(newState);
        }
      };

      /**
       * Handle curriculum archive
       * @param id curriculum id
       */
      handleCurriclumArchive = (id) => {
        this.props.commonStore.triggerConfirm({
          content: 'Are you sure you want to delete this path?\nDeleting this path will delete the assignment for current members.',
          confirmBtn: 'Delete Path',
          cancelBtn: 'Cancel',
          cancel: () => this.props.commonStore.resetConfirmState(),
          continue: () => {
            this.props.enterpriseStore
              .deleteCurriculum(this.state.team.curriculaTeamGroupId, id)
              .then(() => {
                this.triggerSuccess('deleted');
              })
              .then(() => {
                this.props.commonStore.resetConfirmState();
                return this.handleCancel();
              })
              .catch((error) => {
                this.props.commonStore.resetConfirmState();
                this.props.commonStore.triggerToast('error', {
                  content: 'There was a problem deleting this path. Please try again.',
                });
                const newState = {
                  ...this.state,
                  submitting: false,
                  serverErrors: !!error && !!error.response ? error.response.data : null,
                };
                this.setState(newState);
              });
          },
        });
      };

      postCurriculumThumb = (id, thumb) => {
        this.props.enterpriseStore.addCurriculaImg(this.state.team.curriculaTeamGroupId, id, thumb).then((response) => {
          if (response) {
            this.triggerSuccess('saved');
          } else {
            this.props.commonStore.triggerToast('error', {
              header: 'Thumbnail not Saved',
              content: 'Your path was saved, however there was an error saving the thumbnail. Please check the image requirements and try again by editing this path',
            });
          }
          this.handlePostSuccess(response);
        });
      };

      handlePostSuccess = (data) => {
        if (this.props.onSubmit) {
          this.props.onSubmit(data);
        }
      };

      // Split our content into courses and other
      splitContentByType = (data) => {
        const ret = {
          courses: [],
          other: [],
        };

        data.forEach((item) => {
          if (item.type === 'Course' || item.type === 'MicroCourse') {
            ret.courses.push(item);
          } else {
            ret.other.push(item);
          }
        });

        return ret;
      };

      getFlattenedCourse = (content) => {
        const flattenedCourse = {
          title: content.title,
          id: content.id,
          type: content.content_type.nice_name,
          lessons: [],
          lessonIds: [],
        };
        content.content_item.learning_modules.forEach((module) => {
          if (module.activities && module.activities.length) {
            module.activities.forEach((lesson) => {
              if (lesson.type !== 'Video Activity' && lesson.type !== 'Article Activity') {
                flattenedCourse.lessons.push(lesson);
                flattenedCourse.lessonIds.push(lesson.id);
              }
            });
          }
        });

        return flattenedCourse;
      };

      // Fetch course details
      fetchDetailsForCourse = async (course) => {
        return new Promise((resolve) => {
          if (this.courseCache[course.id]) {
            resolve(this.courseCache[course.id]);
          } else {
            Agents.catalog.getContentDescriptionById(course.id).then((response) => {
              if (response.content_item && response.content_item.learning_modules && response.content_item.learning_modules.length) {
                const flattenedCourse = this.getFlattenedCourse(response);
                this.courseCache[course.id] = flattenedCourse;
                resolve(flattenedCourse);
              } else {
                resolve(null);
              }
            });
          }
        });
      };

      allCourses = async (courses) => {
        const coursePromises = [];
        for (let i = 0; i < courses.length; i++) {
          coursePromises.push(this.fetchDetailsForCourse(courses[i]));
        }
        return Promise.all(coursePromises);
      };

      /**
       * handle the input change of duplicate select
       */
      handleInputChange = (e, data) => {
        // Manage LOCAL state of the radio group, while calling the parents onChange function.
        const existingIndex = this.state.selectedDuplicates.indexOf(data.id);
        const newState = { ...this.state };
        if (existingIndex === -1) {
          // We are checking! Add the value to the array
          newState.selectedDuplicates.push(data.id);
        } else {
          // We are UN-checking! Remove the value from the array
          newState.selectedDuplicates.splice(existingIndex, 1);
          if (this.state.selectedDuplicates && this.state.selectedDuplicates.length <= 0 && this.state.hasSelectedAll === true) {
            newState.hasSelectedAll = false;
          }
        }
        this.setState(newState);
      };

      /**
       * handle input change of select all for duplicates
       */
      handleSelectAll = (e, duplicates) => {
        if (duplicates && duplicates.length) {
          const newState = { ...this.state, hasSelectedAll: !this.state.hasSelectedAll };
          this.setState(newState, () => {
            duplicates.forEach((duplicate) => {
              duplicate.activities.forEach((activity) => {
                this.handleInputChange(e, activity);
              });
            });
          });
        }
      };

      checkForArchivedItems = (items) => {
        let hasArchivedItem = false;
        if (items.length) {
          for (let i = 0; i < items.length; i++) {
            if (items[i].isArchived) {
              hasArchivedItem = true;
              break;
            }
          }
        }
        return hasArchivedItem;
      };

      /**
       * Handle submit of either edit or create
       * @param data
       */
      handleSubmit = async (data) => {
        if (!data) {
          return null;
        }
        // Let the UI know we are submitting
        const newState = { ...this.state, submitting: true };
        this.setState(newState);
        // Check if any of the items are archived. If so, user must replace/remove them
        const hasArchivedContent = this.checkForArchivedItems(data.contents);
        if (hasArchivedContent) {
          // Prevent submit and show message saying why
          const currState = { ...this.state };
          currState.submitting = false;
          this.props.commonStore.triggerToast(
            'error',
            {
              content: `Unable to save this path. Please replace or remove archived content.`,
            },
            5000
          );
          this.setState(currState);
          return null;
        }
        // Examine the data for DUPLICATE CONTENT!

        // Look at each item in the array, separate them in to two arrays - courses/microcourses, everything else
        const { courses, other } = this.splitContentByType(data.contents);
        // For each course/microcourse, fetch the full content description (unless we already have it, in which case resolve to cached copy)
        const loadedCourses = await this.allCourses(courses);
        // Once each course is loaded in memory, loop over all OTHER content
        const duplicates = [];
        if (loadedCourses.length && other.length) {
          // For each piece of content, check each course to see if it contains that content
          loadedCourses.forEach((flatCourse) => {
            const course = {
              activities: [],
            };
            other.forEach((item) => {
              if (flatCourse) {
                const dupeIndex = flatCourse.lessonIds.indexOf(item.id);
                if (dupeIndex !== -1) {
                  course.title = flatCourse.title;
                  course.type = flatCourse.type;
                  // We found a duplicate!
                  course.activities.push(
                    item
                    // `${item.title} is duplicated, as it is found in [${flatCourse.type}] ${flatCourse.title}`
                  );
                }
              }
            });
            // If we encounter content that exists inside a course/microcourse, add a message to the array of messages in the format
            if (course.activities.length) {
              duplicates.push(course);
            }
          });
          // Before we submit...
          // Check to see if we have any messages in the array
          if (duplicates.length) {
            // If we do, display a confirm (trigger confirm), alerting the user to the duplicates (showing each message)
            // Only continue submission IF the user hits continue at that point!
            const newerState = { ...this.state, data, duplicates, isModalOpen: true };
            this.setState(newerState);
          } else {
            const newerState = { ...this.state, data };
            this.setState(newerState);
            this.finishSubmit(data);
          }
        } else {
          this.finishSubmit(data);
        }
        return false;
      };

      /**
       * handle removing duplicated activities in curriculum
       */
      removeDuplicates = () => {
        const newState = { ...this.state };
        const { selectedDuplicates, data } = newState;

        // iterating backwards to index 0 to remove selected duplicate items before submitting
        for (let i = data.contents.length - 1; i >= 0; --i) {
          const obj = data.contents[i];
          if (selectedDuplicates.indexOf(obj.id) !== -1) {
            data.contents.splice(i, 1);
          }
        }
        newState.data = data;
        this.setState(newState, () => this.finishSubmit(data));
      };

      finishSubmitSuccess = (data, response) => {
        if (data.thumbnail) {
          this.postCurriculumThumb(response.id, data.thumbnail);
        } else {
          this.triggerSuccess('saved');
          this.handlePostSuccess(response);
        }
      };

      finishSubmitError = (error) => {
        this.props.commonStore.triggerToast('error', {
          content: 'There was an error saving the path. Please review the form for more details.',
        });
        const newState = {
          ...this.state,
          submitting: false,
          serverErrors: !!error && !!error.response ? error.response.data : null,
        };
        this.setState(newState);
      };

      finishSubmit = (data) => {
        // convert the data to the expected format for the endpoint
        const formattedData = {
          name: data.name,
          description: data.description,
          contents: [],
        };
        data.contents.forEach((item) => {
          formattedData.contents.push(item.id);
        });

        if (this.props.enterpriseStore.editMode) {
          const { curriculum } = this.props.enterpriseStore;
          this.props.enterpriseStore
            .updateCurriculum(this.state.team.curriculaTeamGroupId, curriculum.id, formattedData)
            .then((response) => {
              this.finishSubmitSuccess(data, response);
            })
            .catch((error) => {
              this.finishSubmitError(error);
            });
        } else {
          this.props.enterpriseStore
            .createCurriculum(this.state.team.curriculaTeamGroupId, formattedData)
            .then((response) => {
              this.props.authStore.fireAttributionEvent('path:created');
              this.finishSubmitSuccess(data, response);
            })
            .catch((error) => {
              this.finishSubmitError(error);
            });
        }
      };

      getFormContentsProvider = (existingItems, successCallback) => {
        const loadContent = (query) => {
          let filters = SearchUtil.buildFilters('display_in_catalog', ['true']);

          // ensure no 'Coming Soon' courses show up in drop down and can't be added
          filters += ` AND ${SearchUtil.buildFilters('status', ['Active'])}`;

          // Can not have collections in paths (Career Paths, Assessment Paths & Threat Actor Paths)
          PATH_OMITTED_CONTENT_TYPES.forEach((type) => {
            filters += ` AND (NOT ${SearchUtil.buildFilters('content_type.nice_name', [`'${type}'`])})`;
          });
          const curriculumIndex = process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX;
          const queryOptions = {
            hitsPerPage: 30,
            filters,
          };

          return SearchUtil.fetchFromAlgolia(curriculumIndex, queryOptions, query, { teamPackages: this?.state?.team?.package_types || [] })
            .then((response) => {
              const options = [];
              const idsArray = existingItems.map((item) => item.id);
              response.hits.forEach((hit) => {
                let duration = null;
                let durationSeconds = null;
                if (idsArray.indexOf(hit.id) === -1) {
                  let label = `[${hit.content_type.nice_name}] ${hit.title}`;

                  const contentTypeTitle = `[${hit.content_type.nice_name}] ${hit.title}`;

                  if (hit.duration_seconds) {
                    label += ` ( ${FormatUtil.formatTime(hit.duration_seconds, 'hm')})`;
                    duration = FormatUtil.formatTime(hit.duration_seconds, 'hm');
                    durationSeconds = hit.duration_seconds ? 1 * hit.duration_seconds : 0;
                  }
                  options.push({
                    label,
                    title: hit.title,
                    value: hit.id,
                    id: hit.id,
                    tags: hit.tags_info,
                    contentTypeTitle,
                    type: hit.content_type.nice_name,
                    duration,
                    durationSeconds,
                  });
                }
              });
              return options;
            })
            .catch((error) => {
              Bugsnag.notify(error);
            });
        };

        const handleDone = (data) => {
          const formattedData = [];
          if (data && data.length) {
            data.forEach((val) => {
              formattedData.push(val);
            });
          }
          successCallback(formattedData);
        };

        return (
          <MultiSelect
            handleDone={handleDone}
            content={loadContent}
            existingItems={existingItems}
            placeholder="Type to search..."
            label="Select items from the catalog"
            inputId="search-catalog"
          />
        );
      };

      formatCurriculumItem = (item) => {
        const label = `[${item.type}] ${item.title}`;
        return {
          value: item.id,
          label,
          title: item.title,
          type: item.type,
          id: item.id,
          duration_seconds: item.duration_seconds,
          isArchived: !!item.archived_at,
          archive_at: item.archive_at,
          tags: item.tags,
        };
      };

      getCurriculumBuildForm = () => {
        const form = { ...CurriculumBuildForm };
        const { curriculum, editMode, copyMode } = this.props.enterpriseStore;
        const { defaultContent } = this.props;

        form.fields.name.defaultValue = '';
        form.fields.description.defaultValue = '';
        form.fields.contents.defaultValue = '';
        form.fields.contents.addProvider = this.getFormContentsProvider;
        form.fields.submit.loading = this.state.submitting;

        // If any default content was passed in, let's add that as dafault
        if (defaultContent && defaultContent.length) {
          const content = defaultContent.map((item) => {
            return this.formatCurriculumItem(item);
          });
          form.fields.contents.defaultValue = content;
        }

        if ((!!editMode || !!copyMode) && this.props.enterpriseStore.curriculum) {
          // Format default values for form
          const defaultItems = [];
          if (curriculum.contents && curriculum.contents.length) {
            curriculum.contents.forEach((item) => {
              defaultItems.push(this.formatCurriculumItem(item));
            });
          }

          form.fields.name.defaultValue = copyMode ? `Copy of ${curriculum.name}` : curriculum.name;
          form.fields.description.defaultValue = curriculum.description;
          form.fields.contents.defaultValue = defaultItems;
        }
        return form;
      };

      handleCancelDupsConfirm = () => {
        const newState = { ...this.state, submitting: false, isModalOpen: false };
        this.setState(newState);
      };

      handleConfirmDups = () => {
        const { selectedDuplicates, data } = this.state;
        this.resetConfirmState();
        if (selectedDuplicates && selectedDuplicates.length) {
          this.removeDuplicates();
        } else {
          this.finishSubmit(data);
        }
      };

      render() {
        const store = this.props.enterpriseStore;
        if (store.curriculumLoading || !this.state.team) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        if (store.curriculumError) {
          return (
            <Container>
              <StyledError error={store.error} />
            </Container>
          );
        }

        const { curriculum, curriculumItems, editMode, reset } = this.props.enterpriseStore;
        const { duplicates, isModalOpen, selectedDuplicates } = this.state;
        const form = this.getCurriculumBuildForm();
        const errors = this.state.serverErrors && this.state.serverErrors.errors ? this.state.serverErrors.errors : null;
        const mode = editMode ? 'Edit' : 'Create';
        return (
          <>
            <Segment className="px-0 border-none">
              <CurriculumBuildContent
                enterpriseStore={this.props.enterpriseStore}
                curriculum={curriculum}
                curriculumItems={curriculumItems}
                onSortEnd={this.onSortEnd}
                orgId={this.state.team.id}
                form={form}
                mode={mode}
                reset={reset}
                handleSubmit={this.handleSubmit}
                serverErrors={errors}
                clearErrorForField={this.clearErrorForField}
                handleCurriclumArchive={this.handleCurriclumArchive}
                breadcrumbs={this.props.breadcrumbs}
              />
            </Segment>
            <Modal open={isModalOpen} size="md" toggle={this.handleCancelDupsConfirm} paddingBottom="pb-0">
              <Segment className="border-none">
                <div className="block justify-center sm:grid" key="duplicates-warning-modal-content">
                  <div className="block text-center" key="duplicates-header-row">
                    <p key="title-one">We noticed some duplicated content in this path.</p>
                    <p key="title-two">
                      <strong>Please select the ones you would like to remove.</strong>
                    </p>
                    <div className="w-full" key="duplicates-header-three">
                      <div className="text-right">
                        <span className="mr-2">Select All</span>
                        <input
                          type="checkbox"
                          key="select-all"
                          name="select-all"
                          value="select-all"
                          checked={this.state.hasSelectedAll}
                          onChange={(e) => this.handleSelectAll(e, duplicates)}
                        />
                      </div>
                    </div>
                  </div>
                  {duplicates && duplicates.length
                    ? duplicates.map((duplicate, i) => (
                        <React.Fragment key={duplicate.title}>
                          {i >= 0 ? <Divider marginTop="mt-2" marginBottom="mb-2" className="w-full" /> : null}
                          <div className="w-full">
                            <p className="mb-4">
                              The {duplicate.type.toLowerCase()} <u>{duplicate.title}</u> already includes:
                            </p>
                          </div>
                          {!!duplicate && duplicate.activities.length
                            ? duplicate.activities.map((activity) => {
                                return (
                                  <div className="flex mt-2" key={activity.id}>
                                    <div className="w-3/4 md:w-5/6">
                                      <p className="pl-4">{activity.title || activity.contentTypeTitle}</p>
                                    </div>
                                    <div className="w-1/4 text-right md:w-1/6">
                                      <input
                                        type="checkbox"
                                        name={activity.title}
                                        value={activity.id}
                                        checked={this.state.selectedDuplicates.indexOf(activity.id) !== -1}
                                        onChange={(e) => this.handleInputChange(e, activity)}
                                      />
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </React.Fragment>
                      ))
                    : null}
                </div>
                <Divider marginTop="mt-6" marginBottom="mb-4" className="w-full" />
                <div className="flex gap-x-4 justify-end">
                  <Button color="gray" onClick={this.handleCancelDupsConfirm}>
                    Cancel Save
                  </Button>
                  <Button onClick={this.handleConfirmDups}>{selectedDuplicates.length ? 'Remove Selected and Save' : 'Save Anyway'}</Button>
                </div>
              </Segment>
            </Modal>
          </>
        );
      }
    }
  )
);

export default CurriculumBuilder;
