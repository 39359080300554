import React, { useEffect, useState, useRef, useCallback } from 'react';
import Cookies from 'js-cookie';

import { inject, observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import { useAuth } from '../../contexts/UseAuth';
import { PUBLIC_PAGES, DEFAULT_COOKIE_SETTINGS, USERPILOT_EVENTS } from '../../constants';
import Modal from '../Modal/Modal';
import PermalinkUtil from '../../utils/permalinkUtil';
import { useOnboardingContext } from '../../providers/OnboardingProvider';

import InitialStep from './OnboardingSteps/InitialStep';

import CIPGoalsStep from './OnboardingSteps/CIPGoalsStep';
import CIPFormStep from './OnboardingSteps/CIPFormStep';
import CIPExperienceStep from './OnboardingSteps/CIPExperienceStep';
import CIPYearsOfExperienceStep from './OnboardingSteps/CIPYearsOfExperienceStep';
import SelectPackageStep from './OnboardingSteps/SelectPackageStep';
import RecommendationsStep from './OnboardingSteps/RecommendationsStep';
import DemoFormStep from './OnboardingSteps/DemoFormStep';
import TeamFormStep from './OnboardingSteps/TeamFormStep';
import NavigateStep from './OnboardingSteps/NavigateStep';
import { firePendoTrackEvent } from '../../utils/pendoUtil';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import Container from '../Container/Container';
import useQueryParams from '../../hooks/useQueryParams';

const OnboardingModal = inject('userStore')(
  observer(({ userStore, inline = false }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const { step, setUserId } = useOnboardingContext();
    const lastStep = useRef(step); // useRef to avoid state updates when values change
    const stepHistory = useRef([]); // track the users stepHistory to recreate the user journeys through onboarding
    const auth = useAuth();
    const { profileData, user, fetchBookmarks, isEnterprise, isCip } = userStore;
    const { finishedOnboarding, language } = useQueryParams();
    // Allow language to be passed as a query param for testing purposes
    const languageCode = language || navigator.language;

    // Set a cookie to hide the username modal for 36 hours if it is this user's first time here
    useEffect(() => {
      if (!profileData) {
        Cookies.set('hide_username_modal', true, {
          ...DEFAULT_COOKIE_SETTINGS,
          path: '/',
          // expires in 36 hours
          expires: 1.5,
        });
      }
    }, [profileData]);

    // Handle tracking of onboarding steps
    useEffect(() => {
      const envFlag = process.env.NODE_ENV;
      if (step) {
        stepHistory.current.push(step);

        // Track the last step that was completed
        if (step !== lastStep.current) {
          trackSnowplowEvent({ category: 'OnboardingStep', action: 'completed', label: lastStep.current });
          firePendoTrackEvent(`OnboardingStep|${envFlag}|${lastStep.current}|Completed`, { step: lastStep.current, stepHistory });
        }

        if (step === 'navigate') return;
        // Track the step that was shown
        trackSnowplowEvent({ category: 'OnboardingStep', action: 'shown', label: step });
        firePendoTrackEvent(`OnboardingStep|${envFlag}|${step}|Shown`, { step, stepHistory });
        // Update the last step for next event fire
        lastStep.current = step;
      }
    }, [step]);

    useEffect(() => {
      // Get list of important pages that should be omitted from forcing this modal onto user
      const whiteListedPages = [
        ...PUBLIC_PAGES,
        '/logout',
        '/onboarding/terms-of-service',
        '/onboarding/recovery-email',
        '/onboarding/international',
        '/upgrade/teams-checkout',
        '/upgrade/checkout',
        '/google-marketplace-onboarding',
      ];
      const isTeamInvitePage = /^\/team-invite*/.test(location.pathname);
      const cleanPathName = PermalinkUtil.removeTrailingSlash(location.pathname);
      /*
        Only open if you have user data,
        but no profile data,
        and not on a whitelisted page or the team-invite page
      */
      const shouldEnterOnboarding = user && !profileData && !whiteListedPages.includes(cleanPathName) && !isTeamInvitePage && !finishedOnboarding;
      // Non-english users should be redirected to the embedded onboarding flow to allow for translation
      if (languageCode.startsWith('en')) {
        setOpen(shouldEnterOnboarding);
      } else if (shouldEnterOnboarding) {
        navigate('/onboarding/international');
      }
    }, [location, user, profileData, navigate]);

    useEffect(() => {
      // only care about setting this if the modal is open...
      if (open) {
        setUserId(user.id);
      }
    }, [open]);

    const handleCloseModal = () => {
      auth.refreshUser();
      setOpen(false);
      // Track when a user closes the onboarding modal. label is the step the modal was closed on.
      trackSnowplowEvent({ category: 'OnboardingStep', action: 'closedModal', label: step });
      Userpilot.track(USERPILOT_EVENTS.FINISHED_ONBOARDING);
    };

    const OnboardingSteps = useCallback(
      <>
        {(!step || step === 'initial') && <InitialStep />}
        {step === 'cipGoals' && <CIPGoalsStep />}

        {step === 'cipExperience' && <CIPExperienceStep />}
        {step === 'cipYearsOfExperience' && <CIPYearsOfExperienceStep />}

        {step === 'cipForm' && <CIPFormStep isEnterprise={isEnterprise} isCip={isCip} />}
        {step === 'teamForm' && <TeamFormStep isEnterprise={isEnterprise} isCip={isCip} />}

        {/** Only shown for free users */}
        {step === 'selectPackage' && <SelectPackageStep fetchBookmarks={fetchBookmarks} />}

        {step === 'demoForm' && <DemoFormStep />}
        {step === 'contentRecommendations' && <RecommendationsStep fetchBookmarks={fetchBookmarks} />}

        {step === 'navigate' && <NavigateStep />}
      </>,
      [step, isEnterprise, isCip, fetchBookmarks]
    );

    if (inline) {
      return (
        <Container size="dashboard" className="flex-col w-[100vw] h-[100vh]">
          {OnboardingSteps}
        </Container>
      );
    }

    return (
      <Modal
        /** hide close x for all but last step of cip flow */
        omitCloseX={step !== 'contentRecommendations'}
        sizeClasses="md:max-w-[90%] w-4/5 mx-auto"
        className="bg-gray-200"
        paddingBottom="pb-0"
        open={open}
        /**  make modal closeable on last step */
        toggle={step === 'contentRecommendations' ? handleCloseModal : () => {}}
        position="center"
        omitMargin
      >
        {OnboardingSteps}
      </Modal>
    );
  })
);

export default OnboardingModal;
