import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Bugsnag from '@bugsnag/js';
import { Fade } from 'react-awesome-reveal';
import Carousel from '../Carousel/Carousel';
import ContentCard from '../Card/ContentCard';
import AddLink from '../AddLink/AddLink';
import ContentTransformer from '../../transformers/contentTransformer';
import Loading from '../Loading/Loading';

const NewReleasesWidget = inject(
  'searchStore',
  'userStore'
)(
  observer(({ searchStore, userStore }) => {
    const { searchItems } = searchStore;
    useEffect(() => {
      const getSearchItems = async () => {
        try {
          await searchStore.loadSearchItems({ categories: ['new'] });
        } catch (err) {
          Bugsnag.notify(err);
        }
      };
      getSearchItems();
    }, []);

    const { data, loading: isLoading } = searchItems;
    const { hits: newlyReleasedCourses = [] } = data?.new || {};
    const items = newlyReleasedCourses?.map((newCourse) => {
      const { id } = newCourse;
      const prepped = ContentTransformer.formatDataSources(newCourse);

      return (
        <div key={id} className="flex h-full bg-white">
          <ContentCard key={id} userStore={userStore} data={prepped} className="w-80 md:w-60" cardProps={{ menuAbove: true }} />
        </div>
      );
    });

    return isLoading ? (
      <Loading message="Loading New Releases... " />
    ) : (
      <Fade triggerOnce>
        <Carousel
          id="new-releases-carousel"
          title="New Releases"
          items={items}
          actions={
            <AddLink className="inline-block text-sm text-cyb-pink-500 hover:text-black underline whitespace-nowrap cursor-pointer" to="/browse/refined?new=true&resultsView=grid">
              View all
            </AddLink>
          }
        />
      </Fade>
    );
  })
);
export default NewReleasesWidget;
