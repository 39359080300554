import React, { useState, useEffect } from 'react';
import SearchInput from '../Search/SearchInput';
import CheckmarkCircle from '../../Icons/CheckmarkCircle';
import Check from '../../Icons/Check';
import Loading from '../Loading/Loading';
import Tooltip from '../Tooltip/Tooltip';

function SelectBox({ option, isSelected, labelsLeft, metaLabels, handleClick, disabled, id }) {
  return (
    <button
      id={id}
      className={`py-2 px-2 border-xs items-center justify-start ${isSelected ? 'border-cyb-pink-500 text-black' : 'border-gray-400'} rounded-sm ${
        !disabled ? 'cursor-pointer' : 'text-gray-600 cursor-not-allowed'
      } flex justify-between w-full`}
      onClick={() => (disabled ? null : handleClick(option.value))}
    >
      <div className={`text-sm flex items-center justify-start text-left ${isSelected ? 'text-black' : ''}`}>
        {labelsLeft && metaLabels}
        {option.text}
        {!labelsLeft && metaLabels}
      </div>
      <div className="shrink-0 ml-2">{!!isSelected && <CheckmarkCircle active classes="w-6 h-6 stroke-current text-green-500" />}</div>
    </button>
  );
}

function SelectBoxOptions({ options, multiSelect, selections, labelsLeft, disabled, handleClick, nested, emptyMessage }) {
  if (!options || !options.length) {
    return <p className="text-sm">{emptyMessage || 'No options to display'}</p>;
  }

  return options.map((item) => {
    const isSelected = multiSelect ? selections.indexOf(item.value) > -1 : selections === item.value;
    const metaLabelClasses = `rounded-sm whitespace-nowrap border border-gray-600 px-2 ${labelsLeft ? 'mr-2' : 'ml-2'}`;
    const metaLabels =
      item.metaLabels &&
      item.metaLabels.length &&
      item.metaLabels.map((meta) => {
        return (
          <div className={metaLabelClasses} style={{ fontSize: '0.625rem' }} key={meta}>
            {meta}
          </div>
        );
      });

    const selectBoxProps = {
      disabled: disabled || item.disabled,
      key: item.value,
      isSelected,
      metaLabels,
      option: item,
      labelsLeft,
      handleClick: (val) => handleClick(val),
      id: item.id,
    };

    return (
      <div key={item.value} className={nested ? 'ml-8 mt-2' : ''}>
        <div>
          {item.tooltip ? (
            <Tooltip position="bottom-start" maxWidth={400} omitTabIndex triggerContent={<SelectBox {...selectBoxProps} />} content={item.tooltip} />
          ) : (
            <SelectBox {...selectBoxProps} />
          )}
        </div>
        {
          // Display nested child options. If this parent is selected, the children are disabled since the overarching option (assumed to include all nested) is selected
          !!item.childOptions && !!item.childOptions.length && (
            <SelectBoxOptions
              nested
              options={item.childOptions}
              multiSelect={multiSelect}
              selections={selections}
              labelsLeft={labelsLeft}
              disabled={disabled || isSelected}
              handleClick={handleClick}
            />
          )
        }
      </div>
    );
  });
}

// Find the selected option within the array of all options. Searches nested options as well
function findSelection(selectOptions, val) {
  if (!selectOptions || !selectOptions.length) {
    return null;
  }
  let selection = null;
  // If no selection found yet (in event that there's a lot of nested options)
  selectOptions.forEach((option) => {
    if (!selection) {
      if (option.value === val) {
        selection = option;
      } else if (option.childOptions && option.childOptions.length) {
        selection = findSelection(option.childOptions, val);
      }
    }
  });

  return selection;
}

// Handle click on an individual option
function handleOptionClick(val, multiSelect, selections, setSelectAll, handleSelect, returnFullSelections, options) {
  let currSelections = multiSelect ? [...selections] : selections;
  setSelectAll(false);
  if (!multiSelect) {
    currSelections = val;
  } else if (currSelections.indexOf(val) > -1) {
    currSelections.splice(currSelections.indexOf(val), 1);
  } else {
    currSelections.push(val);
    // If this option has children, remove the children selections (children also disabled - Assumes parent includes all children)
    const fullSelection = findSelection(options, val);
    if (fullSelection && fullSelection.childOptions) {
      fullSelection.childOptions.forEach((child) => {
        if (currSelections.indexOf(child.value) > -1) {
          currSelections.splice(currSelections.indexOf(child.value), 1);
        }
      });
    }
  }

  // Get full selections
  let fullSelections = null;
  // If the prop to returnFullSelections is included, returns an additional array with selections full objects (name, value, anything else included)
  if (returnFullSelections) {
    const selectsArr = multiSelect ? [...currSelections] : [currSelections];
    fullSelections = [];
    selectsArr.forEach((item) => {
      fullSelections.push(findSelection(options, item));
    });
  }
  handleSelect(currSelections, fullSelections);
}

// Set all options as selected
function handleSelectAll(disabled, returnFullSelections, selectAll, options, handleSelect, setSelectAll) {
  if (!disabled) {
    let allSelections = [];
    let fullSelections = returnFullSelections ? [] : null;
    // We are clicking to select all
    if (!selectAll) {
      allSelections = options.map((item) => {
        return item.value;
      });
      fullSelections = [...options];
    }
    handleSelect(allSelections, fullSelections);
    setSelectAll(!selectAll);
  }
}

function SelectBoxes({
  handleSelect,
  selections,
  emptyMessage,
  options,
  multiSelect,
  returnFullSelections,
  containerClasses,
  containerStyles,
  labelsLeft,
  showSelectAll,
  handleSearch,
  searchVal,
  disabled,
  loading,
  tabs,
  activeTab,
  tabClick,
}) {
  // Reset search filter before unmounting
  useEffect(() => {
    return () => handleSearch && handleSearch('');
  }, []);

  const [selectAll, setSelectAll] = useState(false);
  const [hasSearchQuery, setHasSearchQuery] = useState(false);
  const showSearch = !!handleSearch;
  const showSelectAllOption = !!showSelectAll && !!multiSelect;
  const showTabs = !!tabs && !!tabClick;

  const applySearch = (query) => {
    if (!disabled) {
      setSelectAll(false);
      handleSearch(query);
      // Hides "Select All" when a query exists
      if (query.length) {
        setHasSearchQuery(true);
      } else {
        setHasSearchQuery(false);
      }
    }
  };

  const handleClick = (val) => {
    if (!disabled) {
      handleOptionClick(val, multiSelect, selections, setSelectAll, handleSelect, returnFullSelections, options);
    }
  };

  if (loading) {
    return <Loading message="Loading..." />;
  }

  return (
    <div>
      <div className="flex justify-between">
        {showSearch && (
          <div className={`mb-6 ${showSelectAllOption || showTabs ? 'w-3/5' : 'w-full'}`}>
            <SearchInput disabled={disabled} placeholder="Type to search..." onChange={(e) => applySearch(e.target.value)} value={searchVal} />
          </div>
        )}
        {showSelectAllOption && !hasSearchQuery && (
          <div className="flex justify-end items-center mb-6 w-2/6 lg:mr-4">
            Select All
            <button
              onClick={() => handleSelectAll(disabled, returnFullSelections, selectAll, options, handleSelect, setSelectAll)}
              className="inline-block ml-4 rounded-sm border border-black"
            >
              <Check classes={`w-4 h-4 ${!selectAll ? 'invisible' : ''}`} />
            </button>
          </div>
        )}
        {showTabs && (
          <div className="flex justify-end items-center mb-6 w-2/6">
            {tabs.map((tab) => {
              return (
                <button key={tab} className={`cursor-pointer mr-4 ${activeTab === tab ? 'font-bold' : 'text-gray-600'}`} onClick={() => tabClick(tab)}>
                  {tab}
                </button>
              );
            })}
          </div>
        )}
      </div>
      <div className={`flex flex-col gap-y-4 ${containerClasses || ''}`} style={containerStyles}>
        <SelectBoxOptions
          options={options}
          multiSelect={!!multiSelect}
          selections={selections}
          labelsLeft={labelsLeft}
          disabled={disabled}
          handleClick={handleClick}
          emptyMessage={emptyMessage}
        />
      </div>
    </div>
  );
}

export default SelectBoxes;
