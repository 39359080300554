import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Icon from '../../components/Icon/Icon';
import withRouter from '../../components/Router/withRouter';
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import AddLink from '../../components/AddLink/AddLink';
import Loading from '../../components/Loading/Loading';
import Segment from '../../components/Segment/Segment';
import StyledError from '../../components/Error/StyledError';
import GroupSelect from '../../components/Enterprise/GroupSelect';
import DropdownFilter from '../../components/Dropdown/DropdownFilter';
import OptionsMenu from '../../components/OptionsMenu/OptionsMenu';
import Container from '../../components/Container/Container';
import ResultsSummary from '../../components/AdminTable/ResultsSummary';
import Modal from '../../components/Modal/Modal';
import './assignments.css';

function AssigneeDetails({ orgId, loading, error, groupName, users }) {
  if (loading) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }
  if (error) {
    return (
      <Container>
        <StyledError error={error} />
      </Container>
    );
  }
  return (
    <div>
      <p>
        Users assigned to <strong>{groupName}</strong>
      </p>
      <div className="my-8 responsive-table-container">
        <Table className="responsive-table">
          <Table.Body>
            {users.map((user) => {
              const profileUrl = `/enterprise/${orgId}/organization/member/${user.id}/dashboard`;
              return (
                <Table.Row key={user.name}>
                  <Table.Cell width="10">{user.name}</Table.Cell>
                  <Table.Cell width="6" textAlign="right">
                    <AddLink className="text-cyb-pink-500 hover:text-black underline" to={profileUrl}>
                      View Profile
                    </AddLink>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}

function AssignmentFilters({ team, applyGroupFilter, applyUserFilter, groupFilter, userFilter, userListData }) {
  const userOptions = userListData.data ? [{ text: 'All Users', value: null }, ...userListData.data] : [];
  return (
    <Segment className="justify-end items-center border-none sm:flex">
      <p className="mr-2 mb-0">Filters:</p>
      <div className="w-full sm:w-80">
        <GroupSelect
          team={team}
          handleGroupSelect={applyGroupFilter}
          selectedGroup={groupFilter}
          groupFilter={(group) => group.permissions.manage === 'edit'}
          placeholder="All Groups"
          defaultOption={
            team.permissions && team.permissions.canManageAssignments === 'all'
              ? {
                  text: 'All Groups',
                  value: null,
                }
              : null
          }
        />
      </div>
      <div className="mt-2 w-full sm:mt-0 sm:ml-2 sm:w-80">
        <DropdownFilter
          name="member-filter"
          loading={userListData.loading}
          options={userOptions}
          value={userFilter}
          ariaLabel="Select a member for filter"
          onChange={applyUserFilter}
          placeholder="All Members"
          isSearchable
        />
      </div>
    </Segment>
  );
}

const EnterpriseAssignment = inject(
  'commonStore',
  'enterpriseStore',
  'userStore',
  'authStore'
)(
  observer(
    class EnterpriseAssignment extends React.Component {
      constructor(props) {
        super(props);
        this.active = React.createRef();
        this.past = React.createRef();
      }

      state = {
        orgId: null,
        view: this.props.match.params.section || 'active',
        groupFilter: null,
        userFilter: null,
        modalOpen: false,
      };

      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        this.setNewPageTitle(this.state.view);
        this.props.userStore.setPreferredTeamInit('', this.props.match.params.orgId, this.init);
      }

      componentDidUpdate(prevProps) {
        this.props.userStore.setPreferredTeamInit(prevProps.match.params.orgId, this.props.match.params.orgId, this.init);
        this.checkUpdateView();
      }

      componentWillUnmount() {
        this.props.enterpriseStore.setDefaultTeamAssignmentData();
        this.props.enterpriseStore.setDefaultAssignmentUsersData();
        this.props.enterpriseStore.setDefaultGroupList();
        this.props.enterpriseStore.setDefaultUserListData();
      }

      init = (orgId) => {
        const { team } = this.props.userStore;
        const groupSelection = team.permissions && team.permissions.canManageAssignments === 'group' ? team.assignmentsTeamGroupId : null;
        const newState = {
          ...this.state,
          orgId,
          groupFilter: groupSelection,
        };
        this.setState(newState, () => {
          this.checkUpdateView();
          this.getAssignmentData();
        });

        this.props.enterpriseStore.getUserListData(team.assignmentsTeamGroupId);
      };

      checkUpdateView = () => {
        const { section } = this.props.match.params;
        const newState = {
          ...this.state,
          view: section || 'active',
        };
        if (this.state.view !== newState.view) {
          this.setNewPageTitle(newState.view);
          this.setState(newState);
        }
      };

      setNewPageTitle = (section) => {
        // section will be active or past or undefined/null

        const pageSection = section && section === 'past' ? 'Completed' : 'In Progress';

        return this.props.commonStore.setPageTitle(`${pageSection} Assignments | Cybrary`);
      };

      getAssignmentData = () => {
        const { team } = this.props.userStore;
        const query = this.state.userFilter ? `?userId=${this.state.userFilter}` : '';
        this.props.enterpriseStore.getTeamAssignmentData(this.state.groupFilter || team.assignmentsTeamGroupId, query);
        this.props.enterpriseStore.getTeamPastAssignmentData(this.state.groupFilter || team.assignmentsTeamGroupId, query);
      };

      getUsersForAssignment = (assignId) => {
        this.props.enterpriseStore.getAssignmentUsersData(assignId, this.props.userStore.team.assignmentsTeamGroupId);
        const newState = {
          ...this.state,
          modalOpen: true,
        };
        this.setState(newState);
      };

      formatAssignedTo = (assign) => {
        const { assignee_name, assignee_type, assignee_id } = assign;
        let groupAssigneeName = assignee_name;
        if (!groupAssigneeName && assignee_type === 'group') {
          groupAssigneeName = 'Deleted Group';
        }
        if (groupAssigneeName === this.props.userStore.team.name) {
          groupAssigneeName = 'All Members';
        }

        if (assignee_type === 'user') {
          return (
            <AddLink className="text-cyb-pink-500 hover:text-black underline" to={`/enterprise/${this.state.orgId}/organization/member/${assignee_id}/dashboard`}>
              {assignee_name || 'Deleted User'}
            </AddLink>
          );
        }
        return (
          <Modal
            size="md"
            position="center"
            paddingBottom="pb-0"
            ariaLabel="Who assignment is assigned to modal"
            trigger={
              <button onClick={() => this.getUsersForAssignment(assign.id)} className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm">
                {groupAssigneeName}
              </button>
            }
            open={this.state.modalOpen}
            toggle={() => {
              const newState = {
                ...this.state,
                modalOpen: false,
              };
              this.setState(newState);
            }}
          >
            <div className="pt-8 pb-4 mx-8">
              <AssigneeDetails
                orgId={this.state.orgId}
                loading={this.props.enterpriseStore.assignmentUsersData.loading}
                error={this.props.enterpriseStore.assignmentUsersData.error}
                groupName={assignee_name}
                users={this.props.enterpriseStore.assignmentUsersData.data}
              />
            </div>
          </Modal>
        );
      };

      showChangeDateConfirm = (data, date) => {
        this.props.commonStore.triggerConfirm({
          content: "Are you sure you want to change this assignment's date?",
          cancel: () => this.props.commonStore.resetConfirmState(),
          continue: () => {
            this.props.commonStore.resetConfirmState();
            this.changeDueDate(data, date);
          },
        });
      };

      showDeleteConfirm = (id) => {
        this.props.commonStore.triggerConfirm({
          content: 'Are you sure you want to delete this assignment?',
          cancel: () => this.props.commonStore.resetConfirmState(),
          continue: () => {
            this.props.commonStore.resetConfirmState();
            this.deleteAssignment(id);
          },
        });
      };

      deleteAssignment = (id) => {
        const { team } = this.props.userStore;
        this.props.enterpriseStore
          .deleteAssignment(team.assignmentsTeamGroupId, id)
          .then(() => {
            this.props.enterpriseStore.rerenderAssignData(this.state.groupFilter || team.assignmentsTeamGroupId);
            this.props.commonStore.triggerToast('success', {
              content: 'Assignment has been removed',
            });
          })
          .catch((e) => {
            this.props.commonStore.triggerToast('error', {
              errorCode: e.response && e.response.status ? e.response && e.response.status : null,
            });
          });
      };

      addTableRows = (data) => {
        return data.map((row) => {
          return (
            <Table.Row key={row.id}>
              <Table.Cell>{row.name}</Table.Cell>
              <Table.Cell singleLine>{row.due_date ? moment(row.due_date).format('M/D/YYYY') : ''}</Table.Cell>
              <Table.Cell>
                <AddLink
                  className="hover:font-semibold text-black hover:text-black underline hover:underline"
                  to={`/enterprise/${this.state.orgId}/path/show/${row.curriculum_id}`}
                >
                  {row.curriculum_name}
                </AddLink>
              </Table.Cell>
              <Table.Cell>{this.formatAssignedTo(row)}</Table.Cell>
              <Table.Cell>{`${row.total - row.completed} / ${row.total}`}</Table.Cell>
              <Table.Cell>{`${row.completed} / ${row.total}`}</Table.Cell>
              <Table.Cell className="text-right assignment-options">
                <OptionsMenu
                  menuClasses="right-12 bottom-0"
                  optionClasses="sm:py-1"
                  options={[
                    {
                      text: 'Dashboard',
                      action: () => this.props.navigate(`/enterprise/${this.state.orgId}/organization/assignment/${row.id}/dashboard`),
                      icon: 'pie graph',
                    },
                    {
                      text: 'Edit Assignment',
                      action: () => this.props.navigate(`/enterprise/${this.state.orgId}/assignment/edit/${row.id}`),
                      icon: 'edit',
                    },
                    {
                      text: 'Delete Assignment',
                      action: () => this.showDeleteConfirm(row.id),
                      icon: 'times',
                    },
                  ]}
                />
              </Table.Cell>
            </Table.Row>
          );
        });
      };

      showAssignments = (data) => {
        const { team } = this.props.userStore;
        if (data.loading) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        if (data.error) {
          if (data.error.status === 403 && team.permissions && team.permissions.canManageAssignments !== 'all') {
            return (
              <Container>
                <Segment basic textAlign="center">
                  <Icon name="face-frown" className="mx-auto mb-4 w-20 h-20 text-gray-600" />
                  <p>Sorry, only Group Admins can view assignments. To see the assignments for another group, please adjust the filter</p>
                </Segment>
              </Container>
            );
          }
          return (
            <Container>
              <StyledError error={data.error} />
            </Container>
          );
        }
        return (
          <>
            <ResultsSummary numOfResults={data.data.length} srOnly />
            <div className="responsive-table-container">
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell scope="col">Assignment Name</Table.HeaderCell>
                    <Table.HeaderCell scope="col">Due Date</Table.HeaderCell>
                    <Table.HeaderCell scope="col">Paths</Table.HeaderCell>
                    <Table.HeaderCell scope="col">Assigned To</Table.HeaderCell>
                    <Table.HeaderCell scope="col">In Progress</Table.HeaderCell>
                    <Table.HeaderCell scope="col">Complete</Table.HeaderCell>
                    <Table.HeaderCell scope="col">
                      <span className="sr-only">Actions</span>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{this.addTableRows(data.data)}</Table.Body>
              </Table>
            </div>
          </>
        );
      };

      applyGroupFilter = (groupId) => {
        const { team } = this.props.userStore;
        const newState = {
          ...this.state,
          groupFilter: groupId,
          userFilter: null,
        };
        this.setState(newState, () => {
          // If this is a group admin and the group filter was changed, set that to the preferred group for the user
          if (team && team.permissions && !team.permissions.canManageTeam && team.permissions.canManageAssignments !== 'all') {
            this.props.userStore.setPreferredGroup(this.state.orgId, groupId);
          }
          this.getAssignmentData();
          // Get the users on this group
          this.props.enterpriseStore.getUserListData(groupId || team.assignmentsTeamGroupId);
        });
      };

      applyUserFilter = ({ value }) => {
        const newState = {
          ...this.state,
          userFilter: value,
        };
        this.setState(newState, () => {
          this.getAssignmentData();
        });
      };

      render() {
        const { team } = this.props.userStore;
        const { groupFilter, userFilter } = this.state;
        const { userListData } = this.props.enterpriseStore;
        if (!team) {
          return null;
        }
        const data = this.state.view === 'active' ? this.props.enterpriseStore.teamAssignmentData : this.props.enterpriseStore.teamPastAssignmentData;
        return (
          <Container className="enterprise-assignment">
            <div className="pt-16 mb-4 border-b border-gray-400 sm:pb-12">
              <div className="items-center sm:flex">
                <Title title="Assignments" omitPadding />
              </div>
            </div>
            <AssignmentFilters
              team={team}
              applyGroupFilter={this.applyGroupFilter}
              applyUserFilter={this.applyUserFilter}
              groupFilter={groupFilter}
              userFilter={userFilter}
              userListData={userListData}
            />
            {this.showAssignments(data)}
          </Container>
        );
      }
    }
  )
);

export default withRouter(EnterpriseAssignment);
