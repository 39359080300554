import React, { useState, useMemo, useCallback } from 'react';

const ACTIVE_CLASSES = 'border-cyb-pink-500 font-semibold text-black';
const INACTIVE_CLASSES = 'border-slate-200 cursor-pointer';

function BasicTabs({ tabs }) {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const content = useMemo(() => tabs[activeTab - 1].content, [activeTab]);

  const onClickTab = useCallback((id) => setActiveTab(id), []);

  return (
    <div className="flex flex-col items-start w-full">
      <div className="relative self-start mb-4 w-full lg:flex">
        <nav className="flex z-10 w-full">
          {tabs.map(({ id, title }) => (
            <button
              key={id}
              aria-pressed={activeTab === id}
              aria-label={`View ${title} content`}
              className={`inline-block text-sm px-4 pt-2 pb-5 border-b-2 ${activeTab === id ? ACTIVE_CLASSES : INACTIVE_CLASSES}`}
              onClick={() => onClickTab(id)}
            >
              {title}
            </button>
          ))}
        </nav>
        <div className="absolute bottom-0 w-full h-[2px] bg-slate-200 z-9" />
      </div>
      {content}
    </div>
  );
}

export default BasicTabs;
