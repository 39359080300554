import React from 'react';
import { useOnboardingContext } from '../../../providers/OnboardingProvider';

/** Step shown at the start of the onboarding flow */
function SwitchToManager() {
  const { switchToManager } = useOnboardingContext();
  return (
    <p className="text-lg font-bold text-right">
      Are you a manager?
      <button onClick={switchToManager} className="text-cyb-pink-500 cursor-pointer" title="Learn more about Cybrary Teams">
        &nbsp; Learn more about Cybrary Teams
      </button>
    </p>
  );
}

export default SwitchToManager;
