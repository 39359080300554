import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import Bugsnag from '@bugsnag/js';
import { onPlay, onPause, onEnd, onTextTrackChange, onTimeUpdate } from './VideoTracking';
import Agents from '../../../agents/agents';

/**
 * Anembedded video component for lx2 videos (includes time tracking)
 */
class Lx2Video extends React.Component {
  state = {
    lastSecond: 0,
    furthestSecond: 0,
    totalSecondsWatched: 0,
    playing: false,
    trueDuration: null,
    initialized: false,
  };

  intervalRef = null;

  componentDidMount() {
    const { activity } = this.props;
    if (activity) {
      const newState = {
        ...this.state,
        initialized: true,
      };
      this.setState(newState);
      this.intervalRef = setInterval(this.syncSessionData, 30000);
    }
  }

  componentWillUnmount() {
    if (this.intervalRef) {
      window.clearInterval(this.intervalRef);
      this.intervalRef = null;
      // Sync the data one last time
      this.syncSessionData();
    }
  }

  syncSessionData = () => {
    // Update session service with watched information
    const { activity, videoId } = this.props;
    const { session_id: sessionId } = activity;
    const { lastSecond, furthestSecond, totalSecondsWatched } = this.state;
    // No point in sending back all zeros
    if (lastSecond || furthestSecond || totalSecondsWatched) {
      const data = {
        last_second: lastSecond,
        furthest_second: furthestSecond,
        total_seconds: totalSecondsWatched,
        vimeo_id: videoId,
      };
      Agents.enrollments.saveSessionVideoData(sessionId, data).catch((err) => Bugsnag.notify(err));
    }
  };

  // Callback function to update state whenever video events fire.
  updateCallback = (newState) => {
    this.setState({ ...newState });
  };

  render() {
    const { initialized } = this.state;
    if (!initialized) {
      return null;
    }
    const { videoId } = this.props;
    return (
      <Vimeo
        responsive
        speed
        video={videoId}
        onPlay={(event) => onPlay(event, this.props, this.state, this.updateCallback)}
        onEnd={(event) => onEnd(event, this.props, this.state, this.updateCallback)}
        onTimeUpdate={(event) => onTimeUpdate(event, this.props, this.state, this.updateCallback)}
        onTextTrackChange={(event) => onTextTrackChange(event, this.props, this.state, this.updateCallback)}
        onPause={(event) => onPause(event, this.props, this.state, this.updateCallback)}
      />
    );
  }
}

export default Lx2Video;
