const MetricsSettings = {
  leaderboards: {
    course: {
      heading: 'Top Courses',
      href: '/reporting/report/leaderboard-course',
      filters: [
        {
          key: 'learning_hours',
          text: 'Learning Hours',
          value: 'learning_hours',
        },
        {
          key: 'enrollments',
          text: 'Enrollments',
          value: 'enrollments',
        },
      ],
    },
    member: {
      heading: 'Top Members',
      href: '/reporting/report/leaderboard-member',
      filters: [
        {
          key: 'learning_hours',
          text: 'Learning Hours',
          value: 'learning_hours',
        },
        {
          key: 'total_xp',
          text: 'Total XP',
          value: 'total_xp',
        },
        {
          key: 'total_enrollments',
          text: 'Enrollments',
          value: 'total_enrollments',
        },
      ],
    },
    /** New tables */
    'virtual-lab': {
      heading: 'Top Virtual Labs',
      href: '/reporting/report/leaderboard-virtual-lab',
      filters: [
        {
          key: 'learning_hours',
          text: 'Learning Hours',
          value: 'learning_hours',
        },
        {
          key: 'enrollments',
          text: 'Enrollments',
          value: 'enrollments',
        },
      ],
    },
    assessment: {
      heading: 'Top Assessments',
      href: '/reporting/report/leaderboard-assessment',
      filters: [
        {
          key: 'average_score',
          text: 'Average Score',
          value: 'average_score',
        },
        {
          key: 'learning_hours',
          text: 'Learning Hours',
          value: 'learning_hours',
        },
        {
          key: 'enrollments',
          text: 'Enrollments',
          value: 'enrollments',
        },
      ],
    },
  },
  competencyLevels: {
    Novice: {
      color: 'rgba(87, 218, 255, 0.25)',
      range: '0% - 25%',
    },
    Intermediate: {
      color: 'rgba(87, 218, 255, 0.5)',
      range: '26% - 50%',
    },
    Advanced: {
      color: 'rgba(87, 218, 255, .75)',
      range: '51% - 75%',
    },
    Proficient: {
      color: 'rgba(87, 218, 255, 1)',
      range: '76% - 100%',
    },
    'Not Assessed': {
      color: '#eaeaea',
    },
  },
};

export default MetricsSettings;
