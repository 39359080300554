import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import Bugsnag from '@bugsnag/js';
import { onPlay, onPause, onEnd, onTextTrackChange, onTimeUpdate } from './VideoTracking';
import Agents from '../../../agents/agents';

/**
 * A embedded video component for legacy videos (includes time tracking)
 */
class LegacyVideo extends React.Component {
  state = {
    lastSecond: 0,
    furthestSecond: 0,
    totalSecondsWatched: 0,
    playing: false,
    isComplete: false,
    trueDuration: null,
    initialized: false,
  };

  intervalRef = null;

  // Track the running of the maybe complete callback
  runningMaybeComplete = false;

  componentDidMount() {
    const { activity, isComplete } = this.props;
    this.props.setIncludeA11yPause(true);
    if (activity) {
      const { videoProgress } = activity;
      // Initialize our progress values
      const newState = {
        ...this.state,
        lastSecond: videoProgress.last_second,
        furthestSecond: videoProgress.furthest_second,
        totalSecondsWatched: videoProgress.total_seconds,
        isComplete,
        initialized: true,
      };
      this.setState(newState);
      this.intervalRef = setInterval(this.syncSessionData, 30000);
    }
  }

  componentWillUnmount() {
    this.props.setIncludeA11yPause(false);
    this.props.setA11yPaused(false);
    if (this.intervalRef) {
      window.clearInterval(this.intervalRef);
      this.intervalRef = null;
      // Sync the data one last time
      this.syncSessionData();
    }
  }

  // Logic to determine if a video activity has recently been completed
  determineIfComplete = () => {
    // Our threshold for completeness
    const completionThreshold = 0.9;
    const { activity } = this.props;
    const { furthestSecond, trueDuration } = this.state;
    const { duration_seconds: duration } = activity;
    const durationToUse = trueDuration || duration;
    if (durationToUse) {
      const percentDone = furthestSecond / durationToUse;
      return percentDone >= completionThreshold;
    }
    Bugsnag.notify(new Error('No duration found for video activity, unable to complete lesson.'));
    return false;
  };

  maybeCompleteSession = () => {
    // Ensure we only attempt this once at a time, in case this callback is triggered in rapid succession
    if (this.runningMaybeComplete) {
      return;
    }
    this.runningMaybeComplete = true;
    const { isComplete: wasComplete, completeActivity } = this.props;
    const { isComplete } = this.state;
    // Check to see if the video is newly completed
    if (!wasComplete && !isComplete) {
      // The video wasn't complete to start, is it complete now?
      const justCompleted = this.determineIfComplete();
      if (justCompleted) {
        (async () => {
          try {
            await completeActivity();
            const newState = {
              ...this.state,
              isComplete: true,
            };
            this.setState(newState);
          } catch (err) {
            Bugsnag.notify(err);
          }
        })();
      }
    }
    this.runningMaybeComplete = false;
  };

  syncSessionData = () => {
    // Update session service with watched information
    const { activity, videoId } = this.props;
    const { session_id: sessionId } = activity;
    const { lastSecond, furthestSecond, totalSecondsWatched } = this.state;
    // No point in sending back all zeros
    if (lastSecond || furthestSecond || totalSecondsWatched) {
      const data = {
        last_second: lastSecond,
        furthest_second: furthestSecond,
        total_seconds: totalSecondsWatched,
        vimeo_id: videoId,
      };
      Agents.enrollments.saveSessionVideoData(sessionId, data).catch((err) => Bugsnag.notify(err));
      this.maybeCompleteSession();
    }
  };

  // Callback function to update state whenever video events fire.
  updateCallback = (newState) => {
    this.setState({ ...newState }, () => this.maybeCompleteSession());
  };

  render() {
    const { initialized, lastSecond, isComplete } = this.state;
    if (!initialized) {
      return null;
    }
    const { videoId, isNew } = this.props;
    return (
      <div className={`${isNew ? '' : 'md:aspect-w-16 md:h-full'}`}>
        <Vimeo
          id="video-player-wrapper"
          autoplay
          responsive
          speed
          video={videoId}
          onPlay={(event) => onPlay(event, this.props, this.state, this.updateCallback)}
          onEnd={(event) => onEnd(event, this.props, this.state, this.updateCallback)}
          onTimeUpdate={(event) => onTimeUpdate(event, this.props, this.state, this.updateCallback)}
          onTextTrackChange={(event) => onTextTrackChange(event, this.props, this.state, this.updateCallback)}
          onPause={(event) => onPause(event, this.props, this.state, this.updateCallback)}
          start={isComplete ? 0 : lastSecond}
          paused={this.props.a11yPaused}
        />
      </div>
    );
  }
}

export default LegacyVideo;
