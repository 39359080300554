import React from 'react';
import GoalsUtil from '../../../utils/goalsUtil';
import Icon from '../../Icon/Icon';
import Button from '../../Button/Button';

function NoGoalsBanner({ userGoal, toggleOverlay }) {
  const userGoalOutcomeMap = GoalsUtil.getUserGoalOutcomeMap();
  const goalVal = userGoalOutcomeMap?.[userGoal];
  const headingStyles = 'uppercase mb-2 text-gray-600 text-2xs font-semibold bg-white';
  return (
    <div className="py-2 mx-auto w-full">
      <div
        className="bg-center 
      bg-[url(https://images.ctfassets.net/kvf8rpi09wgk/5ckB5s6ZsrbjL8y0wFUEGs/2bf4b0c863ec55131849cb9dc69c8135/no-goals-image.png?h=250)] 
      bg-no-repeat bg-cover border border-gray-400"
      >
        <div className="py-6 px-4 w-full bg-white/70 bg-center">
          <div className="mb-6 xl-1664:mb-0">
            <h2 className="mb-4 text-2xl font-black">You&apos;re almost done setting your goal</h2>
            {!!goalVal && (
              <>
                <p className={headingStyles}>Goal</p>
                <p className="text-lg font-bold">
                  {userGoal}
                  <button aria-label="Add Goal" id="add-learner-goal-icon" onClick={() => toggleOverlay('add', null, null, false)}>
                    <Icon className="inline-block mb-1 ml-3 w-4 h-4 hover:text-cyb-pink-500" name="edit" />
                  </button>
                </p>
              </>
            )}
            <p className={headingStyles}>How to get started</p>
            <p className="text-sm bg-white">
              Need help getting started? Finish setting your goal to get personalized learning recommendations. Set a deadline to hold yourself accountable and to measure your
              progress. Reach your goal faster with our guidance and support.
            </p>
          </div>
          <div className="xl-1664:text-right">
            <Button id="add-learner-goal-banner" color="gray" onClick={() => toggleOverlay('add', null, null, goalVal)}>
              Finish Setting Your Goal
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoGoalsBanner;
