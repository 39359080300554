import React, { useState } from 'react';
import { find } from 'lodash';
import AddLink from '../../AddLink/AddLink';
import Chevron from '../Icons/Chevron';
import HelpIcon from '../../../Icons/HelpIcon';
import BellIcon from '../Icons/BellIcon';
import NavIconSwitch from '../NavIconSwitch';
import nav from '../../../navigation/nav';
import UserIcon from '../../../Icons/UserIcon';
import Footer from './Footer';
import SecondarySidebar from './SecondarySidebar';
import PlatformFeedback from '../PlatformFeedback';
import InviteReferralButton from '../InviteReferralButton';
import { HELP_DESK_LINKS } from '../../../constants';

function hasChildren(item) {
  return item && item.children && item.children.length;
}

function handleClick(item, primary, toggleMobileSidebar, setActiveSidebar) {
  if (hasChildren(item) && item.label) {
    const secondarySideMenu = find(primary.items, (primaryItem) => {
      return primaryItem.label === item.label;
    });
    setActiveSidebar(secondarySideMenu);
  } else {
    toggleMobileSidebar(false);
    setActiveSidebar(null);
  }
}

function PrimarySideBar({ mobileSidebar, toggleMobileSidebar, userStore, commonStore }) {
  const [activeSidebar, setActiveSidebar] = useState(null);
  const { primary } = nav;

  const isDefaultUserAvatar = !userStore.avatarUrl || (userStore.avatarUrl && userStore.avatarUrl === 'https://assets.cybrary.it/uploads/user-avatars/profile.jpg');

  return (
    <div className={`fixed top-0 left-0 w-full h-full lg:hidden transition-all ease-in duration-400 ${mobileSidebar ? 'visible' : 'invisible'}`} style={{ zIndex: '1500' }}>
      <button onClick={() => toggleMobileSidebar(!mobileSidebar)} aria-label="Toggle Sidebar Overlay" className="w-full h-full">
        <div id="sidebar-overlay" style={{ zIndex: '1600' }} className="w-full h-full bg-black/50" />
      </button>
      <div className={`overflow-hidden absolute top-0 left-0 z-220 bg-white w-4/5 h-full transition-all ease-in-out duration-500 ${mobileSidebar ? 'ml-0' : '-ml-152'}`}>
        <div className="overflow-y-scroll pb-10 h-full second-container" style={{ width: '103%' }}>
          {/* primary side nav */}
          {!activeSidebar && (
            <>
              <div className="flex items-center py-5 px-6 mx-auto mb-6 border-b border-gray-300">
                <AddLink onClick={() => handleClick(null, primary, toggleMobileSidebar, setActiveSidebar)} to="/" aria-label="Home" className="cursor-pointer">
                  <img
                    src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg"
                    alt="Cybrary"
                    width="120"
                    height="31"
                  />
                </AddLink>
              </div>
              <ul className="mb-10">
                <li>
                  <p className="pb-2 pl-6 text-xs font-semibold uppercase">Navigation</p>
                </li>
                {primary.items.map((item) => {
                  const { hrefReplace, display } = item;
                  // Check to see if we should even display this item
                  if (display && !display(userStore)) {
                    return null;
                  }
                  let actualHref = item.href;
                  // Some URLs require dynamic replacement values, send along the href and the user store to the provided function
                  if (hrefReplace) {
                    actualHref = hrefReplace(item.href, userStore);
                  }
                  return (
                    <li key={item.label}>
                      <AddLink
                        onClick={() => handleClick(item, primary, toggleMobileSidebar, setActiveSidebar)}
                        to={!hasChildren(item) ? actualHref : ''}
                        className="group flex items-center py-4 px-6 cursor-pointer"
                      >
                        <NavIconSwitch id="-primary-sidebar" name={item.icon} classes="h-8 w-8 mr-3 stroke-current text-gray-600 group-hover:text-black" />
                        <p className="text-lg text-gray-600 group-hover:text-black" style={{ marginBottom: '0px' }}>
                          {item.label}
                        </p>
                        {hasChildren(item) && <Chevron direction="right" classes="h-5 w-5 ml-auto text-gray-600 group-hover:text-black" />}
                      </AddLink>
                    </li>
                  );
                })}
                <li>
                  <div className="mt-4 mb-8 w-full h-px border-b border-gray-300" />
                </li>
                <li>
                  <p className="pb-2 pl-6 text-xs font-bold uppercase">Feedback</p>
                </li>
                <li>
                  <PlatformFeedback iconClasses="scale-125 translate-y-3px" buttonClasses="px-6" />
                </li>
                <li>
                  <div className="mt-4 mb-8 w-full h-px border-b border-gray-300" />
                </li>
                <li>
                  <p className="pb-2 pl-6 text-xs font-bold uppercase">Invite</p>
                </li>
                <li>
                  <InviteReferralButton buttonClasses="px-6" />
                </li>
                <li>
                  <div className="mt-4 mb-8 w-full h-px border-b border-gray-300" />
                </li>
                <li>
                  <p className="pb-2 pl-6 text-xs font-bold uppercase">Account</p>
                </li>
                <li>
                  <button
                    onClick={() => setActiveSidebar('profile')}
                    className="group flex items-center py-4 px-6 cursor-pointer"
                    aria-label={`${activeSidebar ? 'Close User Menu' : 'Open User Menu'}`}
                  >
                    {isDefaultUserAvatar ? (
                      <UserIcon classes="h-8 w-8 mr-4 text-gray-600 group-hover:text-black" id="-mobile-primary" />
                    ) : (
                      <div className="mr-3 w-8 h-8 cursor-pointer">
                        <img src={userStore.avatarUrl} className="w-full rounded-full" alt="Avatar" />
                      </div>
                    )}
                    <p className="text-lg text-gray-600 group-hover:text-black" style={{ marginBottom: '0px' }}>
                      {userStore?.user?.real_name || userStore?.user?.name}
                    </p>
                    <Chevron direction="right" classes="h-5 w-5 ml-auto text-gray-600 group-hover:text-black" />
                  </button>
                </li>
                <li>
                  <AddLink onClick={() => toggleMobileSidebar(false)} to="/notification-center" className="group flex items-center py-4 px-6 cursor-pointer">
                    <BellIcon classes="h-8 w-8 mr-3 text-gray-600 group-hover:text-black" id="-primary-sidebar" />
                    <p className="text-lg text-gray-600 group-hover:text-black" style={{ marginBottom: '0px' }}>
                      Notifications
                    </p>
                  </AddLink>
                </li>
                <li>
                  <AddLink to={HELP_DESK_LINKS.HOMEPAGE} className="group flex items-center py-4 px-6 cursor-pointer">
                    <HelpIcon classes="h-8 w-8 mr-3 text-gray-600 group-hover:text-black" id="-primary-sidebar" />
                    <p className="text-lg text-gray-600 group-hover:text-black" style={{ marginBottom: '0px' }}>
                      Help Center
                    </p>
                  </AddLink>
                </li>
              </ul>
              <Footer commonStore={commonStore} userStore={userStore} />
            </>
          )}
          <SecondarySidebar
            activeSidebar={activeSidebar}
            setActiveSidebar={setActiveSidebar}
            toggleMobileSidebar={toggleMobileSidebar}
            isDefaultUserAvatar={isDefaultUserAvatar}
            userStore={userStore}
            commonStore={commonStore}
          />
        </div>
      </div>
    </div>
  );
}

export default PrimarySideBar;
