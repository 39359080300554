import React from 'react';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import FormatUtil from '../../utils/formatUtil';
import SelectionChip from './SelectionChip';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';

function TileOptions({ data, handleClick, emptyMessage, displayLength, omitAddIcon }) {
  if (!data || !data.length) {
    return emptyMessage ? <div className="mb-4 text-bold">{emptyMessage}</div> : null;
  }

  return (
    <div className="flex flex-wrap -mx-1 mb-3 text-sm space">
      {data.map((tile) => {
        return (
          <div className="p-1 w-full sm:w-1/2 xl:w-1/3" key={tile.value}>
            <button
              className={`w-full rounded-sm bg-gray-200 p-3 cursor-pointer my-1 flex items-center gap-x-2 align-center ${omitAddIcon && 'justify-between'}`}
              onClick={() => handleClick(tile)}
            >
              {!omitAddIcon ? <Icon name="plus-circle" active className="w-5 h-5" /> : null}
              {
                // If there's a display length and the tile display is more than that, truncate and show full name in popup
                // Only does so in tablet/desktop since mobile tiles are full width and have enough space
                displayLength && tile.display.length > displayLength && window.innerWidth > 768 ? (
                  <Tooltip content={tile.display} triggerContent={<span className="tile-display">{FormatUtil.formatLongText(tile.display, displayLength)}</span>} omitTabIndex />
                ) : (
                  <span className="tile-display">{tile.display}</span>
                )
              }
              {omitAddIcon ? <Icon name="chevron-right" className="mb-1 w-4 h-4" /> : null}
            </button>
          </div>
        );
      })}
    </div>
  );
}

function SelectedItems({ selectedItems, handleClick }) {
  if (!selectedItems || !selectedItems.length) {
    return null;
  }
  return (
    <div className="flex flex-wrap gap-2">
      {selectedItems.map((item) => {
        return <SelectionChip key={item.value} handleClose={() => handleClick(item)} text={item.display} id={item.value} />;
      })}
    </div>
  );
}

function SelectTiles({ handleSelection, data, selectedOptions, removeSelection, loading, error, emptyMessage, displayLength, omitAddIcon }) {
  if (loading) {
    return (
      <div>
        <Loading message="Loading..." />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <StyledError error={error} />
      </div>
    );
  }

  return (
    <div className="select-tiles-wrapper">
      <TileOptions data={data} handleClick={handleSelection} emptyMessage={emptyMessage} displayLength={displayLength} omitAddIcon={omitAddIcon} />
      <SelectedItems selectedItems={selectedOptions} handleClick={removeSelection} />
    </div>
  );
}

export default SelectTiles;
