/* eslint-disable max-classes-per-file */
import { observable, action, makeObservable } from 'mobx';
import Cookies from 'js-cookie';
import Bugsnag from '@bugsnag/js';
import ContentfulUtil from '../utils/contentfulUtil';
import GaUtil from '../utils/gaUtil';

class CommonStore {
  constructor() {
    makeObservable(this, {
      pageTitle: observable,
      primaryNavDisplay: observable,
      getUpgradeLink: action,
      additionalClass: observable,
      addCybRouterClass: action,
      removeCybRouterClass: action,
      hidePrimaryNav: action,
      showPrimaryNav: action,
      setPageTitle: action,
      toastState: observable,
      triggerToast: action,
      resetToastState: action,
      shareState: observable,
      triggerShareComponent: action,
      confirmState: observable,
      resetConfirmState: action,
      triggerConfirm: action,
      promotionBanner: observable,
      setPromotionBanner: action,
      isInImmersive: observable,
      setIsInImmersive: action,
      hideFooter: observable,
      setHideFooter: action,
      promoBannerHeight: observable,
      setPromoBannerHeight: action,
      isReferralModalOpen: observable,
      setIsReferralModalOpen: action,
    });
  }

  isReferralModalOpen = false;

  setIsReferralModalOpen = (isOpen) => {
    this.isReferralModalOpen = isOpen;
  };

  /* Share modal management */

  shareState = {
    openShare: false,
    permalink: null,
    prefix: null,
    title: null,
    type: null,
  };

  triggerShareComponent = (item) => {
    if (!item) {
      this.shareState.openShare = { openShare: false };
    }
    this.shareState = { openShare: !this.shareState.openShare, title: item.title, permalink: item.permalink, prefix: item.prefix, type: item.type, username: item.username };
  };

  resetShareComponent = () => {
    this.shareState = {
      openShare: false,
      permalink: null,
      title: null,
      prefix: null,
      type: null,
      username: null,
    };
  };

  /* Toast Component management */

  toastState = {
    toastDisplay: false,
    color: null,
    icon: null,
    header: null,
    content: null,
  };

  triggerToast = (status, obj, time) => {
    const toastObj = { toastDisplay: true };
    const delay = time || 6000;
    toastObj.color = this.getToasterColor(obj, status);
    toastObj.header = this.getToasterHeader(obj, status);
    toastObj.content = this.getToasterContent(obj, status);
    toastObj.icon = this.getToasterIcon(obj, status);
    this.toastState = toastObj;
    // Delay to ensure it's rendered in, then set focus on toast
    setTimeout(() => {
      const toastContainer = document.getElementById('toast-container');
      if (toastContainer) {
        toastContainer.focus();
      }
    }, 300);
    setTimeout(() => {
      this.resetToastState();
    }, delay);
  };

  getToasterColor = (obj, status) => {
    let color = '';
    if (!!obj && !!obj.color) {
      color = obj.color;
    } else {
      color = status === 'error' ? 'red' : 'green';
    }
    return color;
  };

  getToasterHeader = (obj, status) => {
    let header = '';
    if (!!obj && !!obj.header) {
      header = obj.header;
    } else if (status === 'error' && !!obj && !!obj.errorCode && obj.errorCode === 403) {
      header = 'Unauthorized';
    } else {
      header = status === 'error' ? 'Error' : 'Success!';
    }
    return header;
  };

  getToasterContent = (obj, status) => {
    let content = '';
    if (!!obj && !!obj.content) {
      content = obj.content;
    } else if (status === 'error' && !!obj && !!obj.errorCode && obj.errorCode === 403) {
      content = 'Sorry, you are unauthorized to perform this action.';
    } else {
      content = status === 'error' ? 'Something went wrong. Please try again.' : 'Action complete.';
    }
    return content;
  };

  getToasterIcon = (obj, status) => {
    let icon = '';
    if (!!obj && !!obj.icon) {
      icon = obj.icon;
    } else if (status === 'error' && !!obj && !!obj.errorCode && obj.errorCode === 403) {
      icon = 'x-circle';
    } else {
      icon = status === 'error' ? 'thumbs-down' : 'thumbs-up';
    }
    return icon;
  };

  resetToastState = () => {
    this.toastState = {
      toastDisplay: false,
      color: null,
      icon: null,
      header: null,
      content: null,
    };
  };

  /* Confirm Modal */

  confirmState = {
    confirmDisplay: false,
    content: null,
    cancel: null,
    continue: null,
    cancelBtn: null,
    confirmBtn: null,
    doNotShowAgainId: null,
    doNotShowAgainText: '',
    focusLockDelay: 0,
    isLoading: false,
  };

  setLoadingConfirmState = (value) => {
    this.confirmState = {
      ...this.confirmState,
      isLoading: value,
    };
  };

  resetConfirmState = () => {
    this.confirmState = {
      confirmDisplay: false,
      content: null,
      cancel: null,
      continue: null,
      cancelBtn: null,
      confirmBtn: null,
      doNotShowAgainId: null,
      doNotShowAgainText: '',
      focusLockDelay: 0,
      isLoading: false,
    };
  };

  triggerConfirm = (obj) => {
    let bypassConfirm = false;
    // Check if this confirm has an ID to track 'Do not show this again'
    if (obj.doNotShowAgainId) {
      // Check to see if they have a cookie tracking 'Do not shows'
      const dismissedConfirmsCookie = Cookies.get('dismissedConfirms');
      const dismissedConfirms = dismissedConfirmsCookie ? dismissedConfirmsCookie.split(',') : [];
      // Check if they've seen this already. If so, just proceed without showing confirm (act as if user clicked continue)
      if (dismissedConfirms.indexOf(obj.doNotShowAgainId) > -1) {
        bypassConfirm = true;
      }
    }

    if (bypassConfirm) {
      obj.continue();
    } else {
      this.confirmState = {
        ...obj,
        confirmDisplay: true,
      };
    }
  };

  /* Promotion Banner management */

  promotionBanner = {
    which: '',
    banner: {},
    displayed: false,
  };

  setPromotionBanner(banner) {
    this.promotionBanner = banner;
    // Delay to allow banner render
    setTimeout(() => {
      this.setPromoBannerHeight();
    }, 1000);
  }

  fetchPromotionBanners = (isUserWithinWeekOfRegistration, isUserEnrolledInFreeMonthlyCourseLastWeek, isEnterprise) => {
    return ContentfulUtil.getPromotionBanners()
      .then((response) => {
        if (!!response && !!response.items && response.items.length) {
          // Determine which banner to show for this user
          const promoEligibility = {
            'end-of-trial': isUserWithinWeekOfRegistration,
            'monthly-free-course': isUserEnrolledInFreeMonthlyCourseLastWeek && !isEnterprise,
            custom: true,
            promotions: true,
          };
          // Transform response to map by permalink for ease
          const promosMap = {};
          response.items.forEach((promo) => {
            promosMap[promo.fields.permalink] = { ...promo.fields };
          });
          // Loop through eligible promos and if we have data for that promotion, evaluate the priority num assigned. Lower number is higher priority
          const eligibilityKeys = Object.keys(promoEligibility);
          let promoToDisplay = null;
          eligibilityKeys.forEach((promoKey) => {
            const promo = promosMap[promoKey];
            if (promoEligibility[promoKey] && promo) {
              if (!promoToDisplay || promoToDisplay.priority > promo.priority) {
                promoToDisplay = promo;
              }
            }
          });
          if (promoToDisplay) {
            this.setPromotionBanner({ which: promoToDisplay.permalink, banner: { ...promoToDisplay }, displayed: promoToDisplay.isInAppSiteWide });
          }
        }
      })
      .catch((error) => {
        Bugsnag.notify(error);
      });
  };

  promoBannerHeight = 0;

  setPromoBannerHeight = () => {
    const promoBanner = document.getElementById('promo-banner');
    this.promoBannerHeight = promoBanner ? promoBanner.offsetHeight : 0;
  };

  /* Upgrade Links handling */

  getUpgradeLink(queryParam, upgradeUrl) {
    let formattedParams = queryParam || '';
    const { which, banner } = this.promotionBanner;
    if (banner && (which === 'end-of-trial' || which === 'monthly-free-course')) {
      const { annualCouponCode, monthlyCouponCode } = banner;
      if (annualCouponCode) {
        formattedParams = formattedParams.concat(`&annually=${annualCouponCode}`);
      }
      if (monthlyCouponCode) {
        formattedParams = formattedParams.concat(`&monthly=${monthlyCouponCode}`);
      }
    }

    const upgradePath = upgradeUrl || `upgrade`;

    return `${process.env.REACT_APP_V2_SITE_URL}/${upgradePath}/${formattedParams || ''}`;
  }

  getRequestLicenseLink(teamId) {
    if (!teamId) {
      return '';
    }
    return `/enterprise/${teamId}/request-license`;
  }

  getRequestAddOnLicenseLink(teamId) {
    if (!teamId) {
      return '';
    }
    return `/enterprise/${teamId}/request-cybrary-select`;
  }

  /* App Teamplate config */

  primaryNavDisplay = true; // whether or not we are hiding the primary nav

  hidePrimaryNav() {
    this.primaryNavDisplay = false;
  }

  showPrimaryNav() {
    this.primaryNavDisplay = true;
  }

  pageTitle = 'Cybrary';

  setPageTitle(pageTitle) {
    if (pageTitle) {
      this.pageTitle = pageTitle;
      GaUtil.fireGaPageView(pageTitle);
    }
  }

  additionalClass = '';

  addCybRouterClass(className = '') {
    if (className.length && this.additionalClass.indexOf(className) === -1) {
      this.additionalClass += ` ${className}`;
    }
  }

  removeCybRouterClass(className = '') {
    this.additionalClass = this.additionalClass.replace(className, '');
  }

  isInImmersive = false;

  setIsInImmersive(isInImmersive) {
    this.isInImmersive = isInImmersive;
  }

  hideFooter = false;

  setHideFooter(bool) {
    this.hideFooter = bool;
  }
}

export default new CommonStore();
