import React, { useMemo, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import BaselineTeamsOverview from '../../components/Baseline/BaselineTeamsOverview';
import If from '../../components/If/If';
import { isPaidAdmin } from '../../utils/navUtil';
import UserAccessDeniedPrompt from '../../components/Measure/Baseline/shared/UserAccessDeniedPrompt';

const BaselineTeams = inject(
  'userStore',
  'commonStore',
  'authStore'
)(
  observer(({ userStore, commonStore, authStore }) => {
    // Block user if they are unpaid AND not an enterprise admin
    // This allows enterprise admins to access the page without a license
    const userAccessDenied = useMemo(() => !isPaidAdmin(userStore), [userStore?.user]);

    useEffect(() => {
      commonStore.setPageTitle('Measure - Assessments  Results | Cybrary');
      authStore.fireAttributionEvent();
    }, []);

    return (
      <Container className="pt-0 sm-576:px-4" md={!userAccessDenied} fluid={userAccessDenied} omitPadding>
        <If condition={userAccessDenied}>
          <UserAccessDeniedPrompt userId={userStore?.user?.id} />
        </If>
        <If condition={!userAccessDenied}>
          <>
            <Title title="Company Overview" omitPadding wrapperClasses="pt-8 md:pt-12 mb-4" classes="text-2xl lg:text-3xl" />
            <BaselineTeamsOverview />
          </>
        </If>
      </Container>
    );
  })
);

export default BaselineTeams;
