import React from 'react';
import { useContinueLearningContext } from '../../../providers/ContinueLearningProvider';
import ContinueLearningContentRow from '../ContinueLearningContentRow';
import ContinueLearningFooterRow from '../ContinueLearningFooterRow';
import ContinueLearningNoContent from '../ContinueLearningNoContent';
/**
 * Tab for showing the first five in progress courses a user is
 * currently enrolled in within the continue learning block on the dashboard
 * @returns <ContinueLearningTabInProgress />
 */
export default function ContinueLearningTabInProgress() {
  const { enrollments, isLoading, MAX_IN_PROGRESS, initEnrollmentsError } = useContinueLearningContext();
  // If there is no current enrollment, show the empty state
  if (!isLoading && initEnrollmentsError) {
    return <ContinueLearningNoContent title="Uh-oh, something went wrong!" body={initEnrollmentsError?.message} />;
  }
  if (!isLoading && !enrollments?.length) {
    return <ContinueLearningNoContent />;
  }
  return (
    <div className="overflow-auto w-full max-h-80 lg:max-h-44">
      {enrollments
        ?.slice(1, MAX_IN_PROGRESS)
        ?.filter((enrollment) => !enrollment.error)
        ?.map((enrollment) => (
          <ContinueLearningContentRow key={enrollment.id} enrollment={enrollment} />
        ))}
      <ContinueLearningFooterRow title="Want to see all your in progress content?" to="/my-learning" cta="Visit My Learning" />
    </div>
  );
}
