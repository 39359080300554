import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import withRouter from '../../components/Router/withRouter';
import BlankTemplate from '../../components/BlankTemplate/BlankTemplate';
import { FooterBottom } from '../../components/Footer/Footer';
import RecoveryEmailForm from '../../components/TeamsSecondEmailModal/RecoveryEmailForm';

const RecoveryEmail = inject(
  'commonStore',
  'userStore',
  'authStore'
)(
  observer(
    class RecoveryEmail extends Component {
      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        this.props.commonStore.setPageTitle('Recovery Email | Cybrary');
      }

      redirectUser = () => {
        const { team } = this.props.userStore;
        const newSubscription = this.props.userStore.fetchSubscriptionStatus();
        const redirectUrl = team && newSubscription ? `/enterprise/${team.id}/organization/members?newSubscription=1` : `/?refreshUser=1`;
        window.location.href = redirectUrl;
      };

      render() {
        return (
          <div className="relative">
            <div className="hidden absolute top-0 left-0 w-5/12 bg-gray-200 md:block md:bottom-0" />
            <div className="hidden absolute top-0 right-0 w-7/12 bg-white md:block md:bottom-0" />
            <BlankTemplate />
            <div className="flex flex-col w-screen h-screen md:flex-row">
              <div className="md:z-10 md:mt-12 md:w-5/12">
                <div className="grid grid-cols-6 px-4">
                  <div className="col-span-full md:col-span-3 md:col-start-2">
                    <img
                      src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg?w=120&h=16"
                      alt="Cybrary text logo"
                      width="120"
                      height="16"
                      className="mx-auto mt-6 md:mx-0 md:mt-0"
                    />
                    <h1 className="my-6 text-lg font-black leading-10 text-center md:mt-16 md:mb-10 md:text-5xl md:text-left">Recovery Email</h1>
                    <div className="mb-4 text-gray-600">If you lose access to your primary email, you will lose access to your Cybrary account.</div>
                    <div className="mb-12 text-gray-600">This email will be used for recovery purposes only.</div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col h-full md:z-10 md:mt-10 md:w-7/12 md:h-auto">
                <div className="grid flex-1 grid-cols-6 px-4 md:px-8">
                  <div className="col-span-full md:col-span-4 md:col-start-2">
                    <p className="mt-6 mb-12 md:mt-24">Add another email to make sure you&apos;ll never lose access to Cybrary.</p>
                    <div className="md:mt-12">
                      <RecoveryEmailForm
                        successCallback={() => {
                          setTimeout(() => {
                            this.redirectUser();
                          }, 6500);
                        }}
                        dismissCallback={() => this.redirectUser()}
                      />
                    </div>
                  </div>
                </div>
                <div className="m-4">
                  <FooterBottom />
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  )
);

export default withRouter(RecoveryEmail);
