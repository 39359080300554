import React, { useMemo } from 'react';
import Loading from '../../Loading/Loading';
import TeamsNoGoalsBanner from './TeamsNoGoalsBanner';
import NoGoalsBanner from './NoGoalsBanner';
import GoalTile from '../GoalTile';
import Button from '../../Button/Button';
import If from '../../If/If';
import Header from '../../Header/Header';

const TABS = [
  {
    name: 'In Progress',
    key: 'in_progress',
  },
  {
    name: 'Completed',
    key: 'completed',
  },
  {
    name: 'Expired',
    key: 'expired',
  },
];

const ACTIVE_CLASSES = ' border-cyb-pink-500 font-semibold text-black';
const INACTIVE_CLASSES = ' border-slate-200 cursor-pointer font-normal';

/**
 * Custom header for the goals carousel
 * @param {function} setView - function to call when a tab is clicked
 * @param {string} view - the current view, used to determine which tab is active
 * @param {boolean} loading - whether the goals are loading
 * @returns {JSX.Element} - the header
 */
function DashboardGoalsHeading({ setView, view, loading }) {
  return (
    <div className="flex grow justify-between items-center mt-4 w-full lg:mt-0">
      <div className="block w-full">
        <div className="flex justify-between mx-2 mb-2">
          <Header as="h2" className="mt-1 mb-0">
            My Goals
          </Header>

          <nav className="flex grow pl-2 lg:max-w-sm">
            {TABS.map(({ key, name }) => (
              <button
                key={key}
                aria-pressed={view === key}
                aria-label={`View ${name} goals`}
                className={`inline-block px-1 pb-2 w-full text-sm border-b-2 ${view === key ? ACTIVE_CLASSES : INACTIVE_CLASSES}`}
                onClick={() => {
                  setView(key);
                }}
                disabled={loading || view === key}
              >
                {name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

/**
 * Carousel for the goals dashboard
 * @param {Object} data - the goals data
 * @param {boolean} loading - whether the goals are loading
 * @param {Object} userTeams - the user's teams
 * @param {function} getMenuOptions - function to get the menu options for a goal
 * @param {string} view - the current view, used to determine which tab is active
 * @param {function} toggleOverlay - function to toggle the overlay for a specific goal
 * @param {function} continueLearning - function to continue learning for a specific goal
 * @param {Object} trackingGoal - the goal currently being tracked, if any
 * @param {Object} userGoal - ???
 * @param {function} setView - function to set the current view
 * @param {boolean} isEnterprise - whether the user is an enterprise user
 * @returns {JSX.Element} - the goals carousel
 */
function GoalsCarousel({ data, loading, userTeams, getMenuOptions, view, toggleOverlay, continueLearning, trackingGoal, userGoal, setView, isEnterprise }) {
  // Limit to 465 px to accommodate assessments on the dashboard
  const containerClasses = 'p-3 lg:max-w-[465px]';
  const Heading = <DashboardGoalsHeading view={view} setView={setView} toggleOverlay={toggleOverlay} isEnterprise={isEnterprise} loading={loading} />;

  // Carousel items
  const items =
    data?.map((goal) => {
      const trackedGoal = trackingGoal?.id && trackingGoal.id === goal.id;
      const canTrackGoal = !trackedGoal && goal.status === 'in_progress';
      const menuOptions = getMenuOptions(goal, canTrackGoal);
      // 27.1rem width is roughly the width of the goals container
      return (
        <GoalTile
          key={goal.id}
          goal={goal}
          teamData={goal.team_id ? userTeams[goal.team_id] : null}
          menuOptions={menuOptions}
          view={view}
          viewDetails={() => toggleOverlay('view', goal.id)}
          continueLearning={continueLearning}
          preventAnimation
        />
      );
    }) || [];

  // CIP/Free have a "Add New Goal" button below the scroll container while Enterprise does not. Let the height of the scroll container be different for each.
  const scrollContainerHeight = useMemo(() => (isEnterprise ? 'h-[260px]' : 'h-[220px]'), [isEnterprise]);
  return (
    <>
      {/** Loading State */}
      <If condition={loading}>
        <div className={containerClasses}>
          {Heading}
          <Loading message="Loading goals..." wrapperClassName="py-6 h-64" />
        </div>
      </If>
      {/** Empty State */}
      <If condition={!loading && !data?.length}>
        <div className={containerClasses}>
          {Heading}
          {isEnterprise ? <TeamsNoGoalsBanner view={view} hasGoalsInProgress={!!data?.length} /> : <NoGoalsBanner userGoal={userGoal} toggleOverlay={toggleOverlay} />}
        </div>
      </If>
      {/** Default State */}
      <If condition={!loading && data?.length}>
        <div className={containerClasses}>{Heading}</div>
        <div className={`overflow-y-auto ${scrollContainerHeight}`}>{items}</div>
        <If condition={!isEnterprise}>
          <div className="flex justify-end items-center pr-4 mt-2 mb-4 w-full">
            <Button id="add-learner-goal" onClick={() => toggleOverlay('add')} color="gray">
              Add New Goal
            </Button>
          </div>
        </If>
      </If>
    </>
  );
}
export default GoalsCarousel;
