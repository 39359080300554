import { observable, action, computed, set, makeObservable } from 'mobx';
import queryString from 'query-string';
import axios from 'axios';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import Agents from '../agents/agents';
import FormatUtil from '../utils/formatUtil';

class AdminStore {
  hasAccess = false;

  loading = true;

  constructor() {
    makeObservable(this, {
      hasAccess: observable,
      loading: observable,
      setHasAccess: action,
      setLoading: action,
      denyAccess: computed,
      checkAccess: action,
      setCheckboxState: action,
      resetCheckboxState: action,
      setAllSelectedRows: action,
      setQueryParams: action,
      roleSelected: observable,
      setRoleSelected: action,
      resetHeaderCheckbox: action,
      manageTeamData: action,
      adminTeamTableData: observable,
      setDefaultAdminTeamTableData: action,
      setAdminTeamTableLoading: action,
      setAdminTeamTableError: action,
      getAdminTeamTableData: action,
      teamDetailsData: observable,
      setDefaultTeamDetailsData: action,
      setTeamDetailsDataLoading: action,
      setTeamDetailsDataError: action,
      setTeamLogo: action,
      getTeamDetailsData: action,
      teamMemberTableData: observable,
      setDefaultTeamMemberTableData: action,
      setTeamMemberTableLoading: action,
      setTeamMemberTableError: action,
      loadTeamMemberTableData: action,
      memberSearchTableData: observable,
      setDefaultMemberSearchTableData: action,
      setMemberSearchTableParams: action,
      setMemberSearchTableLoading: action,
      setMemberSearchTableError: action,
      userLookupTableData: observable,
      setDefaultUserLookupTableData: action,
      setUserLookupTableLoading: action,
      setUserLookupTableError: action,
      vendorResultsTableData: observable,
      setDefaultVendorResultsTableData: action,
      setVendorResultsTableLoading: action,
      setVendorResultsTableError: action,
      getVendorResultsTableData: action,
      searchMembers: action,
      getMemberSearchTableData: action,
      getUserLookupTableData: action,
      setDashboardQueryParams: action,
      adminMemberDashboardData: observable,
      setDefaultAdminMemberDashboardData: action,
      setAdminMemberDashboardLoading: action,
      setAdminMemberDashboardError: action,
      getAllScores: action,
      getWorkroleData: action,
      getUserData: action,
      getAdminMemberDashboardAssignmentDetails: action,
      getUserAssignmentData: action,
      getUserGoalData: action,
      getUserLearningData: action,
      getUserEnrollmentData: action,
      getUserCurrentEnrollmentData: action,
      getUserCertificates: action,
      larping: observable,
      setDefaultLarp: action,
      checkStorageLarping: action,
      setUserLarping: action,
      setLarping: action,
      toggleReleaseTag: observable,
      setToggleReleaseTag: action,
    });
  }

  setHasAccess(access) {
    this.hasAccess = access;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  get denyAccess() {
    // We deny access if we are NOT loading and we do not have access
    if (!this.loading && !this.hasAccess) {
      return true;
    }
    return false;
  }

  checkAccess(authStore) {
    const hasAdminAccess = authStore.hasAdminAccess();
    this.setHasAccess(hasAdminAccess);
    this.setLoading(false);
  }

  setCheckboxState = (obj, idx, bool) => {
    if (bool) {
      obj.selectedRows.push(idx);
    } else {
      obj.selectedRows.splice(obj.selectedRows.indexOf(idx), 1);
    }
    set(obj.ckBoxesState, [idx], bool);
  };

  resetCheckboxState = (obj, data) => {
    this[obj].ckBoxesState = {};
    data.forEach((row, idx) => {
      this[obj].ckBoxesState[idx] = false;
    });

    this[obj].selectedRows = [];
    this[obj].headerCheckbox = false;
  };

  setAllSelectedRows = (obj, checked) => {
    const checkboxObj = obj;
    checkboxObj.selectedRows = [];
    checkboxObj.headerCheckBox = checked;
    for (let i = 0; i < Object.keys(checkboxObj.ckBoxesState).length; i++) {
      this.setCheckboxState(checkboxObj, i, checked);
    }
  };

  setQueryParams = (obj, key, val) => {
    this[obj].queryParams[key] = val;
  };

  numberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  roleSelected = '';

  setRoleSelected = (role) => {
    this.roleSelected = role;
  };

  deleteTeam = (teamId) => {
    if (teamId) {
      return Agents.admin.deleteTeam(teamId);
    }
    return null;
  };

  removeMembers = (teamId, users) => {
    const userIds = [];
    users.forEach((row) => {
      userIds.push(this.teamMemberTableData.tableData[row][0].value);
    });
    return Agents.admin.removeMembers(teamId, {
      users: userIds,
    });
  };

  changeUserRoles = (users, role, teamId) => {
    const userIds = [];

    users.forEach((row) => {
      userIds.push(this.teamMemberTableData.tableData[row][0].value);
    });

    const data = {
      users: userIds,
      role,
    };

    return Agents.admin.changeRole(teamId, data);
  };

  resetHeaderCheckbox = (obj) => {
    this[obj].headerCheckBox = false;
  };

  addMemberToTeam = (team, role, memberId) => {
    const data = {
      users: [memberId],
      role,
    };
    return Agents.admin.addMembers(team, data);
  };

  // Adds a new team, or edits an existing
  manageTeamData = (data, team) => {
    const submitData = data;
    if (submitData.package_types[0] === 'none') {
      submitData.package_types = [];
    }
    if (!team) {
      return Agents.admin.createTeam(submitData);
    }
    return Agents.admin.editTeam(submitData, team);
  };

  adminTeamTableData = {
    loading: true,
    error: false,
    queryParams: {
      activePg: 1,
      resultsPerPage: 25,
      sortCol: 'name',
      sortDirection: 'asc',
      searchQuery: '',
    },
    tempEndpoint: '',
    numResults: '',
    pagPagesCount: '',
  };

  setDefaultAdminTeamTableData() {
    this.adminTeamTableData = {
      loading: true,
      error: false,
      queryParams: {
        activePg: 1,
        resultsPerPage: 25,
        sortCol: 'name',
        sortDirection: 'asc',
        searchQuery: '',
      },
      tempEndpoint: '',
      numResults: '',
      pagPagesCount: '',
    };
  }

  setAdminTeamTableLoading(loading) {
    this.adminTeamTableData.loading = loading;
  }

  setAdminTeamTableError(error) {
    this.adminTeamTableData.error = error;
  }

  // /**
  //  * Gets table of existing teams
  //  */
  getAdminTeamTableData = () => {
    const query = `?${queryString.stringify(this.adminTeamTableData.queryParams)}`;
    if (this.adminTeamTableData.tempEndpoint !== query) {
      this.adminTeamTableData.tempEndpoint = query;
      this.setAdminTeamTableLoading(true);
      return Agents.admin
        .getTeamListing(query)
        .then(
          action('fetchSuccess', (response) => {
            this.adminTeamTableData.tableData = response.tableData;
            this.adminTeamTableData.tableHeadings = response.columns;
            this.adminTeamTableData.recordsCount = this.numberWithCommas(response.totalRecords);
            this.adminTeamTableData.pagPagesCount = Math.ceil(response.totalRecords / this.adminTeamTableData.queryParams.resultsPerPage);
            this.setAdminTeamTableLoading(false);
          })
        )
        .catch(
          action('fetchError', (error) => {
            Bugsnag.notify(error);
            this.adminTeamTableData.error = error.response || error;
            this.setAdminTeamTableLoading(false);
          })
        );
    }
    return null;
  };

  teamDetailsData = {
    loading: true,
    error: false,
    data: null,
  };

  setDefaultTeamDetailsData() {
    this.teamDetailsData = {
      loading: true,
      error: false,
      data: null,
    };
  }

  setTeamDetailsDataLoading(loading) {
    this.teamDetailsData.loading = loading;
  }

  setTeamDetailsDataError(error) {
    this.teamDetailsData.error = error;
  }

  setTeamLogo(url) {
    this.teamDetailsData.data.logo_url = url;
  }

  // /**
  //  * Gets team data to display in team view, or set default values in edit form
  //  */
  getTeamDetailsData(teamId) {
    this.setTeamDetailsDataLoading(true);
    return Agents.admin
      .getTeamData(teamId)
      .then(
        action('fetchSuccess', (response) => {
          this.teamDetailsData.data = response;
          this.setTeamDetailsDataLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.teamDetailsData.error = error.response || error;
          this.setTeamDetailsDataLoading(false);
        })
      );
  }

  teamMemberTableData = {
    loading: true,
    error: false,
    queryParams: {
      activePg: 1,
      resultsPerPage: 25,
      sortCol: 'role',
      sortDirection: 'asc',
      searchQuery: '',
    },
    tempEndpoint: '',
    ckBoxesState: {},
    selectedRows: [],
    selectedRole: null,
    headerCheckBox: false,
    totalRecords: '',
  };

  setDefaultTeamMemberTableData() {
    this.teamMemberTableData = {
      loading: true,
      error: false,
      queryParams: {
        activePg: 1,
        resultsPerPage: 25,
        sortCol: 'role',
        sortDirection: 'asc',
        searchQuery: '',
      },
      tempEndpoint: '',
      ckBoxesState: {},
      selectedRows: [],
      selectedRole: null,
      headerCheckBox: false,
      totalRecords: '',
    };
  }

  setTeamMemberTableLoading(loading) {
    this.teamMemberTableData.loading = loading;
  }

  setTeamMemberTableError(error) {
    this.teamMemberTableData.error = error;
  }

  exportMemberData = (orgId) => {
    const queryParams = { ...this.teamMemberTableData.queryParams };
    delete queryParams.activePg;
    delete queryParams.resultsPerPage;
    const query = `?format=csv&${queryString.stringify(queryParams)}`;
    Agents.admin
      .getTeamMembersData(orgId, query)
      .then(
        action('fetchSuccess', (response) => {
          const currentDate = moment().format('YYYY-MM-DD');
          FormatUtil.downloadCSV(response, `member-list-${currentDate}`);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
        })
      );
  };

  // /**
  //  * Gets a table with the current team members on the team
  //  */
  getTeamMemberTableData = (cId, resetQueryParams = false, reloadAfterAction = false) => {
    if (resetQueryParams) {
      this.teamMemberTableData.queryParams.activePg = 1;
    }
    const query = `?format=table&${queryString.stringify(this.teamMemberTableData.queryParams)}`;
    if (this.teamMemberTableData.tempEndpoint !== query || reloadAfterAction) {
      // Prevent un-needed calls to loadTableData
      this.teamMemberTableData.tempEndpoint = query;
      this.loadTeamMemberTableData(cId, query);
    }
  };

  loadTeamMemberTableData(teamId, query) {
    this.setTeamMemberTableLoading(true);
    return Agents.admin
      .getTeamMembersData(teamId, query)
      .then(
        action('fetchSuccess', (response) => {
          this.teamMemberTableData.tableData = response.tableData;
          this.teamMemberTableData.tableHeadings = response.columns;
          this.teamMemberTableData.totalRecords = response.totalRecords;
          this.teamMemberTableData.pagPagesCount = Math.ceil(this.teamMemberTableData.totalRecords / this.teamMemberTableData.queryParams.resultsPerPage);
          this.resetCheckboxState('teamMemberTableData', response.tableData); // Sets the initial state for the checkboxes used in table
          this.resetHeaderCheckbox('teamMemberTableData');
          this.setTeamMemberTableLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.teamMemberTableData.tableError = error.response || error;
          this.setTeamMemberTableLoading(false);
        })
      );
  }

  memberSearchTableData = {
    loading: null,
    error: false,
    queryParams: {
      activePg: 1,
      resultsPerPage: 25,
      sortCol: 'name',
      sortDirection: 'asc',
      searchQuery: '',
    },
    tempEndpoint: '',
    numResults: '',
    pagPagesCount: '',
    totalRecords: '',
  };

  setDefaultMemberSearchTableData() {
    this.memberSearchTableData = {
      loading: null,
      error: false,
      queryParams: {
        activePg: 1,
        resultsPerPage: 25,
        sortCol: 'name',
        sortDirection: 'asc',
        searchQuery: '',
      },
      tempEndpoint: '',
      numResults: '',
      pagPagesCount: '',
      totalRecords: '',
    };
  }

  setMemberSearchTableLoading(loading) {
    this.memberSearchTableData.loading = loading;
  }

  setMemberSearchTableError(error) {
    this.memberSearchTableData.error = error;
  }

  setMemberSearchTableParams(param, val) {
    this.memberSearchTableData.queryParams[param] = val;
  }

  userLookupTableData = {
    loading: null,
    error: false,
    queryParams: {
      activePg: 1,
      resultsPerPage: 25,
      sortCol: 'name',
      sortDirection: 'asc',
      searchQuery: '',
    },
    tempEndpoint: '',
    numResults: '',
    pagPagesCount: '',
    totalRecords: '',
  };

  setDefaultUserLookupTableData() {
    this.userLookupTableData = {
      loading: null,
      error: false,
      queryParams: {
        activePg: 1,
        resultsPerPage: 25,
        sortCol: 'name',
        sortDirection: 'asc',
        searchQuery: '',
      },
      tempEndpoint: '',
      numResults: '',
      pagPagesCount: '',
      totalRecords: '',
    };
  }

  setUserLookupTableLoading(loading) {
    this.userLookupTableData.loading = loading;
  }

  setUserLookupTableError(error) {
    this.userLookupTableData.error = error;
  }

  vendorResultsTableData = {
    loading: null,
    error: false,
    queryParams: {
      sortCol: 'started_at',
      sortDirection: 'asc',
    },
    tempEndpoint: '',
  };

  setDefaultVendorResultsTableData() {
    this.vendorResultsTableData = {
      loading: null,
      error: false,
      queryParams: {
        sortCol: 'started_at',
        sortDirection: 'asc',
      },
      tempEndpoint: '',
    };
  }

  setVendorResultsTableLoading(loading) {
    this.vendorResultsTableData.loading = loading;
  }

  setVendorResultsTableError(error) {
    this.vendorResultsTableData.error = error;
  }

  getVendorResultsTableData = (data) => {
    this.vendorResultsTableData.queryParams = {
      ...this.vendorResultsTableData.queryParams,
      start_at: data.startAt,
      end_at: data.endAt,
      content_type_id: data.contentTypeId,
    };
    const query = `${queryString.stringify(this.vendorResultsTableData.queryParams)}`;
    this.setVendorResultsTableLoading(true);
    return Agents.admin
      .getVendorResultsTableData(data.userId, query)
      .then((response) => {
        this.vendorResultsTableData.tableData = response;
        this.vendorResultsTableData.tableData = response.tableData;
        this.vendorResultsTableData.tableHeadings = response.columns;
        this.setVendorResultsTableLoading(false);
      })
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.vendorResultsTableData.error = error.response || error;
          this.setVendorResultsTableLoading(false);
        })
      );
  };

  searchMembers(team, search) {
    this.memberSearchTableData.queryParams.searchQuery = search;
    this.getMemberSearchTableData(team);
  }

  // /**
  //  * Gets a table of the members meeting the search query if they are not part of the team
  //  */
  getMemberSearchTableData = (team) => {
    const query = `?${queryString.stringify(this.memberSearchTableData.queryParams)}`;
    if (this.memberSearchTableData.tempEndpoint !== query) {
      this.memberSearchTableData.tempEndpoint = query;
      this.setMemberSearchTableLoading(true);
      return Agents.admin
        .getTeamMembersAdminSearch(team, query)
        .then(
          action('fetchSuccess', (response) => {
            this.memberSearchTableData.tableData = response.tableData;
            this.memberSearchTableData.tableHeadings = response.columns;
            this.memberSearchTableData.totalRecords = response.totalRecords;
            this.memberSearchTableData.recordsCount = this.numberWithCommas(response.totalRecords);
            this.memberSearchTableData.pagPagesCount = Math.ceil(response.totalRecords / this.memberSearchTableData.queryParams.resultsPerPage);
            this.setMemberSearchTableLoading(false);
          })
        )
        .catch(
          action('fetchError', (error) => {
            Bugsnag.notify(error);
            this.memberSearchTableData.error = error.response || error;
            this.setMemberSearchTableLoading(false);
          })
        );
    }
    return null;
  };

  getUserLookupTableData = () => {
    const query = `?${queryString.stringify(this.userLookupTableData.queryParams)}`;
    if (this.userLookupTableData.tempEndpoint === query) {
      return null;
    }
    this.adminTeamTableData.tempEndpoint = query;
    this.setUserLookupTableLoading(true);
    return Agents.admin
      .getUserLookupTableData(query)
      .then((response) => {
        this.userLookupTableData.tableData = response.tableData;
        this.userLookupTableData.totalRecords = response.totalRecords;
        this.userLookupTableData.tableHeadings = response.columns;
        this.userLookupTableData.recordsCount = this.numberWithCommas(response.totalRecords);
        this.userLookupTableData.pagPagesCount = Math.ceil(response.totalRecords / this.userLookupTableData.queryParams.resultsPerPage);
        this.setUserLookupTableLoading(false);
      })
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.userLookupTableData.error = error.response || error;
          this.setUserLookupTableLoading(false);
        })
      );
  };

  // *** Admin Member Dashboard ***

  setDashboardQueryParams = (obj, section, value) => {
    this[obj][section].queryParams = value;
  };

  adminMemberDashboardData = {
    heading: {
      loading: true,
      error: false,
      data: null,
    },
    certificates: {
      data: {
        Course: [],
        MicroCourse: [],
      },
      loading: true,
      error: false,
    },
    assignments: {
      loading: true,
      error: false,
      data: null,
      queryParams: {
        status: 'all',
      },
    },
    goals: {
      loading: true,
      error: false,
      data: null,
      queryParams: {
        status: 'all',
      },
    },
    enrollments: {
      loading: true,
      error: false,
      data: null,
      queryParams: {
        status: 'all',
      },
    },
    currEnrollments: {
      loading: true,
      error: false,
      data: null,
      queryParams: {
        status: 'all',
      },
    },
    learning: {
      loading: true,
      error: false,
      data: {},
      queryParams: {
        activePg: 1,
        recordsPerPage: 20,
        sortCol: 'content_description_title',
        sortDirection: 'asc',
        status: 'all',
      },
    },
    workroleData: {
      loading: true,
      error: false,
      data: null,
    },
    filters: {
      interval: null,
      range: 'allTime',
    },
  };

  setDefaultAdminMemberDashboardData() {
    delete axios.defaults.headers.common['x-cybrary-larp-user'];
    this.adminMemberDashboardData = {
      heading: {
        loading: true,
        error: false,
        data: null,
      },
      certificates: {
        data: {
          Course: [],
          MicroCourse: [],
        },
        loading: true,
        error: false,
      },
      assignments: {
        loading: true,
        error: false,
        data: null,
        queryParams: {
          status: 'all',
        },
      },
      goals: {
        loading: true,
        error: false,
        data: null,
        queryParams: {
          status: 'all',
        },
      },
      enrollments: {
        loading: true,
        error: false,
        data: null,
      },
      workroleData: {
        loading: true,
        error: false,
        data: null,
      },
      currEnrollments: {
        loading: true,
        error: false,
        data: null,
      },
      learning: {
        loading: true,
        error: false,
        data: {},
        queryParams: {
          activePg: 1,
          recordsPerPage: 20,
          sortCol: 'content_description_title',
          sortDirection: 'asc',
          status: 'all',
        },
      },
      filters: {
        interval: null,
        range: 'allTime',
      },
    };
  }

  setAdminMemberDashboardLoading(obj, loading) {
    this.adminMemberDashboardData[obj].loading = loading;
  }

  setAdminMemberDashboardError(obj, error) {
    this.adminMemberDashboardData[obj].error = error;
  }

  getAllScores = (contentDescriptionId, params) => {
    const query = params ? `?${queryString.stringify(params)}` : null;
    return Agents.admin.getAllScores(contentDescriptionId, query);
  };

  getWorkroleData = (enrollmentId, scoreId) => {
    this.adminMemberDashboardData.workroleData = {
      loading: true,
      error: false,
      data: null,
    };
    let getMethod = scoreId ? () => Agents.admin.getScoreById(scoreId) : null;
    if (!getMethod && enrollmentId) {
      getMethod = () => Agents.admin.getWorkroleData(enrollmentId);
    }

    if (!getMethod) {
      return null;
    }

    return getMethod()
      .then(
        action('fetchSuccess', (response) => {
          this.adminMemberDashboardData.workroleData.data = response;
          this.adminMemberDashboardData.workroleData.loading = false;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.adminMemberDashboardData.workroleData.error = error.response || error;
          this.adminMemberDashboardData.workroleData.loading = false;
        })
      );
  };

  getUserData(userId) {
    this.setAdminMemberDashboardLoading('heading', true);
    this.setAdminMemberDashboardError('heading', false);
    return Agents.admin
      .getUserData(userId)
      .then(
        action('fetchSuccess', (response) => {
          this.adminMemberDashboardData.heading = response;
          this.adminMemberDashboardData.sendgridEvents = response.data.sendgridEvents;
          this.setAdminMemberDashboardLoading('heading', false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.adminMemberDashboardData.heading.error = error.response || error;
          this.setAdminMemberDashboardLoading('heading', false);
        })
      );
  }

  getAdminMemberDashboardAssignmentDetails(enrollId, contentId) {
    const query = `?includeEmpty=1&${queryString.stringify(this.adminMemberDashboardData.assignments.queryParams)}&format=table`;
    if (enrollId) {
      return Agents.admin.getEnrollmentDetails(enrollId, query);
    }
    // We have no enrollment ID (likely a child row that hasn't been started), so just get the content as generic items without any progress data
    return Agents.enrollments.getChildProgress(contentId, query);
  }

  getUserAssignmentData() {
    this.setAdminMemberDashboardLoading('assignments', true);
    this.setAdminMemberDashboardError('assignments', false);
    return Agents.admin
      .getAssignmentsList()
      .then(
        action('fetchSuccess', (response) => {
          this.adminMemberDashboardData.assignments.data = response;
          this.setAdminMemberDashboardLoading('assignments', false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.adminMemberDashboardData.assignments.error = error.response || error;
          this.setAdminMemberDashboardLoading('assignments', false);
        })
      );
  }

  getUserGoalData(transformGoalData) {
    this.setAdminMemberDashboardLoading('goals', true);
    this.setAdminMemberDashboardError('goals', false);
    return Agents.goals
      .getUserGoalsProgress()
      .then(
        action('fetchSuccess', (response) => {
          this.adminMemberDashboardData.goals.data = transformGoalData(response);
          this.setAdminMemberDashboardLoading('goals', false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.adminMemberDashboardData.goals.error = error.response || error;
          this.setAdminMemberDashboardLoading('goals', false);
        })
      );
  }

  getUserLearningData() {
    this.setAdminMemberDashboardLoading('learning', true);
    this.setAdminMemberDashboardError('learning', false);
    const queryParams = { ...this.adminMemberDashboardData.learning.queryParams };

    if (queryParams.status !== 'all') {
      queryParams.isComplete = queryParams.status === 'completed' ? 1 : 0;
    }
    delete queryParams.status;

    const query = `?showScore=1&${queryString.stringify(queryParams)}&format=table`;
    return Agents.admin
      .getEnrollmentsList(query)
      .then(
        action('fetchSuccess', (response) => {
          this.adminMemberDashboardData.learning.data = response;
          this.adminMemberDashboardData.learning.data.pagPagesCount = Math.ceil(this.adminMemberDashboardData.learning.data.totalRecords / queryParams.recordsPerPage);
          this.setAdminMemberDashboardLoading('learning', false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.adminMemberDashboardData.learning.error = error.response || error;
          this.setAdminMemberDashboardLoading('learning', false);
        })
      );
  }

  getUserEnrollmentData() {
    this.setAdminMemberDashboardLoading('enrollments', true);
    this.setAdminMemberDashboardError('enrollments', false);

    return Agents.admin
      .getEnrollmentsList()
      .then(
        action('fetchSuccess', (response) => {
          this.adminMemberDashboardData.enrollments.data = response;
          this.setAdminMemberDashboardLoading('enrollments', false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.adminMemberDashboardData.enrollments.error = error.response || error;
          this.setAdminMemberDashboardLoading('enrollments', false);
        })
      );
  }

  getUserCurrentEnrollmentData() {
    this.setAdminMemberDashboardLoading('currEnrollments', true);
    this.setAdminMemberDashboardError('currEnrollments', false);

    return Agents.admin
      .getCurrentEnrollment()
      .then(
        action('fetchSuccess', (response) => {
          this.adminMemberDashboardData.currEnrollments.data = response;
          this.setAdminMemberDashboardLoading('currEnrollments', false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.adminMemberDashboardData.currEnrollments.error = error.response || error;
          this.setAdminMemberDashboardLoading('currEnrollments', false);
        })
      );
  }

  getUserCertificates() {
    this.setAdminMemberDashboardLoading('certificates', true);
    this.setAdminMemberDashboardError('certificates', false);

    return Agents.admin
      .getCertificates()
      .then(
        action('fetchSuccess', (response) => {
          this.adminMemberDashboardData.certificates.data = response;
          this.setAdminMemberDashboardLoading('certificates', false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.adminMemberDashboardData.certificates.error = error.response || error;
          this.setAdminMemberDashboardLoading('certificates', false);
        })
      );
  }

  larping = {
    teamId: null,
    larpRole: null,
  };

  setDefaultLarp = () => {
    this.larping.teamId = null;
    this.larping.larpRole = null;
    sessionStorage.removeItem('larpData');
    window.location.href = '/admin/teams';
  };

  checkStorageLarping = (storage) => {
    if (!!storage && storage.larpData) {
      return true;
    }
    return false;
  };

  startLarping = (userStore, orgId, navigate) => {
    if (!userStore || !orgId) {
      return null;
    }
    const larpStorage = `${orgId}|org-owner`;
    sessionStorage.setItem('larpData', larpStorage);
    this.setLarping(larpStorage); // Sets x-cybrary-larp headers
    return userStore.loadAndSetTeamData(orgId, 'org-owner', true).then(() => {
      navigate(`/enterprise/${orgId}/organization/members`);
    });
  };

  setUserLarping = (id) => {
    axios.defaults.headers.common['x-cybrary-larp-user'] = id;
  };

  setLarping = (data) => {
    const larpData = data.split('|');
    this.larping = {
      teamId: larpData[0],
      larpRole: larpData[1],
    };
    const [larpTeam, larpRole] = larpData;
    axios.defaults.headers.common['x-cybrary-larp-team'] = larpTeam;
    axios.defaults.headers.common['x-cybrary-larp-role'] = larpRole;
  };

  toggleReleaseTag = false;

  // Add a header so that back end pulls data from different instance (right now just for v2 vs v3 reporting). May pass in a version number in future to be appended to headers if expanded
  setToggleReleaseTag(bool) {
    this.toggleReleaseTag = bool;
    if (bool) {
      axios.defaults.headers.common['X-Release-Tag'] = 'Canary';
      sessionStorage.setItem('toggleReleaseTag', true);
    } else {
      // Default is X-Release-Tag = "Stable", but backend will default if nothing sent
      delete axios.defaults.headers.common['X-Release-Tag'];
      sessionStorage.removeItem('toggleReleaseTag');
    }
  }
}

export default new AdminStore();
