import React, { useMemo, useState, useCallback, memo } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Checkbox from '../FormFields/Checkbox';
import withRouter from '../Router/withRouter';
import CurriculumConfirm from './CurriculumConfirm';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import FormatUtil from '../../utils/formatUtil';
import GaUtil from '../../utils/gaUtil';
import AddLink from '../AddLink/AddLink';
import NotificationUtil from '../../utils/notificationUtil';
import ContentUpdateFlag from '../ContentUpdates/ContentUpdateFlag';
import ContentUpdateLabel from '../ContentUpdates/ContentUpdateLabel';
import OptionsMenu from '../OptionsMenu/OptionsMenu';
import Icon from '../Icon/Icon';
import If from '../If/If';
import Container from '../Container/Container';
import EmptyContent from '../EmptyContent/EmptyContent';
import { useCustomPathsContext } from '../../providers/CustomPathsProvider';

const CurriculaActionsMenu = memo(function CurriculaActionsMenu({
  orgId,
  curriculum,
  handleEditCurriculum,
  copyCurriculum,
  deleteCurricula,
  canManageCurricula,
  isAssessmentCurricula,
  navigate,
  view,
}) {
  // Determine which action options are available based on team permissions
  const menuOptions = [];
  const isDemo = orgId === 'demo';
  if ((canManageCurricula && view === 'user') || (canManageCurricula === 'all' && view === 'team')) {
    menuOptions.push({
      text: 'Edit',
      action: () => handleEditCurriculum(curriculum.id),
      icon: 'edit',
    });
  }
  if (canManageCurricula && !isAssessmentCurricula) {
    menuOptions.push({
      text: 'Copy',
      action: () => copyCurriculum(curriculum.id, curriculum.team_id, curriculum.name),
      icon: 'copy outline',
    });
  }
  if (curriculum.assignment_count) {
    menuOptions.push({
      text: 'Assignment Reports',
      action: () => navigate(`/enterprise/${orgId}/reporting/report/assignment/?contentDescriptionId=${curriculum.id}&contentName=${encodeURIComponent(curriculum.name)}`),
      icon: 'line graph',
    });
  }
  if ((canManageCurricula && view === 'user') || (canManageCurricula === 'all' && view === 'team')) {
    menuOptions.push({
      text: 'Delete',
      action: () => deleteCurricula(curriculum.id),
      icon: 'trash alternate outline',
    });
  }
  if (!menuOptions.length) {
    return null;
  }
  return <OptionsMenu options={menuOptions} disabled={isDemo} />;
});

const CurriculumItem = memo(function CurriculumItem({
  curriculum,
  assignCurriculum,
  handleEditCurriculum,
  copyCurriculum,
  deleteCurricula,
  updateCurriculumVisibility,
  canManageCurricula,
  navigate,
  orgId,
  view,
  updateMessage,
  hasArchivedContent,
  index,
}) {
  const [isPublic, setIsPublic] = useState(curriculum.in_team_catalog);
  const { fetchPublicCustomPaths } = useCustomPathsContext();
  const canChangeVisibility = useMemo(() => view !== 'cybrary' && !!canManageCurricula, [view, canManageCurricula]);

  const isAssessmentCurricula = useMemo(() => curriculum.type === 'Assessment Path', []);

  const isDemo = useMemo(() => orgId === 'demo', [orgId]);

  // rotate between a few names if we are in demo view
  const demoName = useMemo(() => {
    const demoNames = ['Ella Vader', 'Jacqueline Hyde', 'Lee King', 'Paige Turner'];

    return index > 0 ? demoNames[index % demoNames.length] : demoNames[0];
  }, []);

  const onChange = useCallback(
    (_event, { checked }) => {
      setIsPublic(checked);
      updateCurriculumVisibility(checked, curriculum);
      /** refetch custom paths that are available for display */
      fetchPublicCustomPaths();
    },
    [updateCurriculumVisibility, curriculum, fetchPublicCustomPaths]
  );

  return (
    <div className="justify-between md:flex">
      <div className="max-w-2xl">
        {updateMessage}
        {hasArchivedContent && <ContentUpdateLabel basic tooltipText={'Click "View Details" for more info'} labelText="Content scheduled to be Archived" />}
        <h2 className="mb-2 text-lg font-bold" style={{ marginTop: '1em' }}>
          {curriculum.name}
        </h2>
        {curriculum.description ? (
          <div>
            <p className="text-sm text-gray-600">{FormatUtil.truncateString(curriculum.description, 30)}</p>
          </div>
        ) : null}
        {curriculum.assigned_user_count ? (
          <div className="mt-4 text-xs text-gray-600">
            <Icon name="user-group" className="inline-block mb-1 w-4 h-4" /> Used in {curriculum.assigned_user_count} member(s) goals
          </div>
        ) : null}
        {view === 'team' && curriculum.real_name && curriculum.created_at ? (
          <p className="mt-4 text-xs text-gray-600">
            Created by {isDemo ? demoName : FormatUtil.ucwords(curriculum.real_name)} on {moment(curriculum.created_at).format('M/D/YYYY')}
          </p>
        ) : null}
      </div>
      <div className="flex flex-col justify-end items-end mt-8 md:ml-8">
        <div className="flex items-center">
          <AddLink
            className={`mr-8 text-cyb-pink-500 hover:text-black underline whitespace-nowrap ${isDemo ? 'cursor-not-allowed' : ''}`}
            to={isDemo ? null : `/enterprise/${orgId}/path/show/${curriculum.id}`}
          >
            View Details
            <span className="sr-only">for {curriculum.name}</span>
          </AddLink>
          <If condition={isAssessmentCurricula}>
            <AddLink
              className={`mr-8 text-cyb-pink-500 hover:text-black underline whitespace-nowrap ${isDemo ? 'cursor-not-allowed' : ''}`}
              to={isDemo ? null : `/enterprise/${orgId}/paths/assessments/${curriculum.id}`}
            >
              View Results
              <span className="sr-only">for {curriculum.name}</span>
            </AddLink>
          </If>
          <If condition={canManageCurricula}>
            <button
              onClick={() => assignCurriculum(curriculum.id, curriculum.team_id, curriculum.type)}
              className={`py-2.5 px-6 mr-2 text-sm font-bold leading-5 text-center text-black whitespace-nowrap bg-gray-200	rounded-sm ${isDemo ? 'cursor-not-allowed' : ''}`}
              disabled={isDemo}
            >
              Use <span className="sr-only">{curriculum.name}</span> in Goal
            </button>
          </If>

          <CurriculaActionsMenu
            orgId={orgId}
            curriculum={curriculum}
            handleEditCurriculum={handleEditCurriculum}
            copyCurriculum={copyCurriculum}
            deleteCurricula={deleteCurricula}
            canManageCurricula={canManageCurricula}
            isAssessmentCurricula={isAssessmentCurricula}
            navigate={navigate}
            view={view}
          />
        </div>
        {canChangeVisibility && (
          <div className="flex flex-row items-center mt-3 mr-3">
            <span className="mr-2 text-sm text-gray-600">Public to Members:</span>
            <Checkbox
              id="change-curricula-visibility"
              className="flex pb-[3px]"
              label="Public to Members"
              ariaDescribedBy="change-curricula-visibility"
              height={15}
              width={30}
              onChange={onChange}
              toggle
              checked={isPublic}
            />
          </div>
        )}
      </div>
    </div>
  );
});

const CurriculaList = inject(
  'userStore',
  'enterpriseStore',
  'commonStore',
  'notificationsStore'
)(
  observer(({ orgId, userId, view, curricula, loading, error, userStore, commonStore, enterpriseStore, notificationsStore }) => {
    const navigate = useNavigate();

    const { cybraryCurriculaUpdates, teamCurriculaUpdates, userCurriculaUpdates } = notificationsStore;
    const cybraryCurriculaUpdatesTransformed = useMemo(
      () => NotificationUtil.transformContentNotifications(cybraryCurriculaUpdates, 'new_content_description_id'),
      [cybraryCurriculaUpdates]
    );
    const thisTeamCurriculaUpdates = useMemo(() => (teamCurriculaUpdates ? teamCurriculaUpdates[orgId] : []), [teamCurriculaUpdates, orgId]);
    const teamCurriculaUpdatesTransformed = useMemo(() => NotificationUtil.transformContentNotifications(thisTeamCurriculaUpdates, 'id'), [thisTeamCurriculaUpdates]);
    const thisTeamUserCurriculaUpdates = useMemo(() => (userCurriculaUpdates ? userCurriculaUpdates[orgId] : []), [userCurriculaUpdates, orgId]);
    const userCurriculaUpdatesTransformed = useMemo(() => NotificationUtil.transformContentNotifications(thisTeamUserCurriculaUpdates, 'id'), [thisTeamUserCurriculaUpdates]);
    const canManageCurricula = useMemo(() => !!userStore.team?.permissions?.canManageCurricula, [userStore.team?.permissions?.canManageCurricula]);

    const handleEditCurriculum = useCallback(
      (id) => {
        if (!id) {
          return null;
        }
        return navigate(`/enterprise/${orgId}/path/edit/${id}`);
      },
      [orgId]
    );

    const deleteCurricula = useCallback(
      (id) => {
        commonStore.triggerConfirm({
          content: 'Are you sure you want to delete this path?\nDeleting this path will delete the assignment for current members.',
          confirmBtn: 'Delete Path',
          cancelBtn: 'Cancel',
          cancel: () => commonStore.resetConfirmState(),
          continue: async () => {
            try {
              await enterpriseStore.deleteCurriculum(orgId, id);

              commonStore.resetConfirmState();
              commonStore.triggerToast('success', {
                content: `The path was successfully deleted.`,
              });

              enterpriseStore.loadCurricula(orgId, userId);
            } catch (e) {
              commonStore.resetConfirmState();

              commonStore.triggerToast('error', {
                content: 'There was a problem deleting this path. Please try again.',
              });
            }
          },
        });
      },
      [orgId, userId]
    );

    const assignCurriculum = useCallback(
      (id, teamId, type) => {
        let assignUrl = `/enterprise/${orgId}/goals/?action=add&content_type=path&content_ids=${id}`;

        if (type === 'Assessment Path') {
          assignUrl += '&outcome_type=team_assessment';
        }

        enterpriseStore.curriculumAction(assignUrl, !teamId, commonStore.triggerConfirm, <CurriculumConfirm type="goal" />, commonStore.resetConfirmState, navigate);
      },
      [orgId]
    );

    const copyCurriculum = useCallback(
      (id, teamId, name) => {
        const copyUrl = `/enterprise/${orgId}/path/copy/${id}`;
        if (view === 'cybrary') {
          GaUtil.fireEvent('Copy', 'Product Interaction', `Copy ${name}`);
        }
        enterpriseStore.curriculumAction(copyUrl, !teamId, commonStore.triggerConfirm, <CurriculumConfirm type="copy" />, commonStore.resetConfirmState, navigate);
      },
      [orgId, view]
    );

    const updateCurriculumVisibility = useCallback(
      async (isPublic, curriculum) => {
        try {
          const payload = {
            in_team_catalog: isPublic,
            curricula: [curriculum],
          };

          await enterpriseStore.updateCurriculaVisibility(orgId, payload);

          commonStore.triggerToast('success', {
            content: isPublic ? 'The path was successfully made public' : 'The path was successfully made private',
          });
        } catch (e) {
          commonStore.triggerToast('error', {
            content: 'There was a problem updating this path. Please try again.',
          });
        }
      },
      [orgId, userId]
    );

    const getContentUpdateFlag = useCallback(
      (curriculumUpdates) => {
        if (view === 'cybrary') {
          return (
            <div className="mb-4">
              <ContentUpdateFlag showAsLabel data={curriculumUpdates} summaryTitle="A new version of a Cybrary Paths has been released!" />
            </div>
          );
        }
        if (view === 'team' || view === 'user') {
          return (
            <div className="mb-4">
              <ContentUpdateFlag showAsLabel flagText="New Content Released" popupText='Click "View Details" for more info' omitSummaryModal />
            </div>
          );
        }
        return null;
      },
      [view]
    );

    if (loading) {
      return (
        <Container>
          <Loading message="Loading..." />
        </Container>
      );
    }

    if (error) {
      return (
        <Container>
          <StyledError error={error} />
        </Container>
      );
    }

    return (
      <div>
        {!curricula || !curricula.length ? (
          <EmptyContent>
            <p className="mb-0 text-sm">You have not created any paths yet.</p>
            <p className="text-sm">To add your own, click Add Path or contact your Customer Success Manager.</p>
          </EmptyContent>
        ) : (
          <ul>
            {curricula.map((curriculum, idx) => {
              const cybraryCurriculumUpdate = cybraryCurriculaUpdatesTransformed[curriculum.id] || null;
              const teamCurriculumUpdate = teamCurriculaUpdatesTransformed[curriculum.id] || null;
              const userCurriculumUpdate = userCurriculaUpdatesTransformed[curriculum.id] || null;
              const hasCurriculumUpdates = !!cybraryCurriculumUpdate || !!teamCurriculumUpdate || !!userCurriculumUpdate;
              // Check if the path has content set to archive after current date/time
              const hasArchivedContent = curriculum.content_archive_at && moment(curriculum.content_archive_at).isAfter();
              const styles = { padding: '0 0 2rem 0', margin: 0 };
              if (idx > 0) {
                styles.borderTop = '0.5px solid #CCC';
                styles.padding = '2rem 0';
              } else {
                styles.border = 'none';
                styles.borderRadius = 0;
              }
              const curriculumUpdates = hasCurriculumUpdates ? getContentUpdateFlag(cybraryCurriculumUpdate) : null;

              return (
                <li key={curriculum.id}>
                  <div className={`${hasCurriculumUpdates ? 'curriculum-update' : ''}`} style={styles}>
                    <CurriculumItem
                      orgId={orgId}
                      curriculum={curriculum}
                      updateMessage={curriculumUpdates}
                      assignCurriculum={assignCurriculum}
                      handleEditCurriculum={handleEditCurriculum}
                      copyCurriculum={copyCurriculum}
                      deleteCurricula={deleteCurricula}
                      updateCurriculumVisibility={updateCurriculumVisibility}
                      canManageCurricula={canManageCurricula}
                      navigate={navigate}
                      hasArchivedContent={hasArchivedContent}
                      view={view}
                      index={idx}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  })
);

export default withRouter(memo(CurriculaList));
