import React from 'react';

function WizardStepDescription({ step, disabled, outcomeType, goalType }) {
  const disabledMessage = disabled && <div className="mb-4 font-bold text-black">This section can not be edited. </div>;
  switch (step) {
    case 'outcome_type':
      if (goalType === 'user') {
        return <div>Set your professional development goal.</div>;
      }
      return <div>{disabledMessage}What outcome are you trying to achieve?</div>;
    case 'ceus':
      if (goalType === 'user') {
        return <div>Add the number of CEUs/CPEs you wish to earn and we&apos;ll help track your progress.</div>;
      }
      return <div>How many CEUs?</div>;
    case 'certification':
      if (goalType === 'user') {
        return <div>Select your desired industry certification.</div>;
      }
      return <div>Select a certification below. Next, you can select a practice test that your participants will need to pass to complete this goal (optional).</div>;
    case 'certification-test':
      if (goalType === 'user') {
        return <div>{disabledMessage}Add a practice test to ensure you’re ready to pass your certification exam (optional).</div>;
      }
      return <div>{disabledMessage}Select a practice test your participants will need to pass to complete this goal (optional).</div>;
    case 'owner_ids':
      return <div>{disabledMessage}Who owns this goal? Owners will be able to view the participant progress as well as receive notifications. Select one or more team admins.</div>;
    case 'participant_type':
      return <div>Who needs to complete this goal?</div>;
    case 'start_date':
      return <div>When should participants start working on this goal?</div>;
    case 'content_type':
      if (goalType === 'user') {
        if (outcomeType === 'certification') {
          return null;
        }
        return (
          <div>
            {disabledMessage}
            Choose your preferred learning approach and we&apos;ll provide recommendations to get you started.
          </div>
        );
      }
      return (
        <div>
          {disabledMessage}
          <p className="mb-4">
            What learning do you want the participants to complete to achieve this goal? You can choose a path, or search for a specific course or hands-on learning activity from
            the catalog.
          </p>
          {outcomeType === 'ceus' || outcomeType === 'learning_hours' ? (
            <p>Choose &quot;Learner Decides&quot; if you want to allow your participants to find content on their own.</p>
          ) : (
            ''
          )}
        </div>
      );
    case 'due_type':
      if (goalType === 'user') {
        return <div>Set a deadline to hold yourself accountable. You can always update, if necessary.</div>;
      }
      return <div>When does this goal need to be completed?</div>;
    case 'start_specific_date':
      return <div>Select a future date from the calendar below. Progress towards this goal will begin on the selected start date.</div>;
    case 'date':
      if (goalType === 'user') {
        return <div>Select a target date to track your progress toward your goal.</div>;
      }
      return <div>Select a future date from the calendar below. Progress towards this goal will be counted up until that due date.</div>;
    case 'recurring':
      return <div>How often should this goal recur?</div>;
    default:
      break;
  }
  return null;
}

export default WizardStepDescription;
