import React from 'react';
import OnboardingStep from './OnboardingStep';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { useOnboardingContext } from '../../../providers/OnboardingProvider';
import { COUNTRIES, MANAGER_PLUS_ROLES, PRACTITIONER_ROLES, SHARED_DEPARTMENTS, SHARED_ROLES, TEAM_LEADS, TEAM_LEAD_ROLES } from '../../../constants';

const isTeamLead = (value) => TEAM_LEAD_ROLES.includes(value);
const isPractitioner = (value) => PRACTITIONER_ROLES.includes(value);
const isManagerPlus = (value) => MANAGER_PLUS_ROLES.includes(value);

const CIP_ROLES = [
  ...SHARED_ROLES,
  {
    text: 'Student',
    value: 'Student',
  },
];

function CIPFormStep({ isCip, isEnterprise }) {
  const { setSavedFormFields, teamOrCip, cipGoal, cipExperience, cipYearsOfExperience, saveOnboardingData, setStep, setNavigationLocation, isSubmitting } = useOnboardingContext();

  const CIP_ONBOARDING_FORM_FIELDS = {
    order: [
      ['firstName', 'lastName'],
      'country',
      ['title', 'teamLead'],
      'membershipFor',
      'goal',
      'experienceLevel',
      'yearsExperience',
      ['phone', 'company'],
      'department',
      'submit',
    ],
    fields: {
      firstName: {
        id: 'onboarding-cip-first-name',
        type: 'text',
        label: 'First Name',
        validations: ['required'],
      },
      lastName: {
        id: 'onboarding-cip-last-name',
        type: 'text',
        label: 'Last Name',
        validations: ['required'],
      },
      country: {
        id: 'onboarding-cip-country',
        type: 'select',
        label: 'Country',
        placeholder: 'Select a country',
        defaultValue: 'US',
        options: COUNTRIES,
        validations: ['required'],
      },
      title: {
        id: 'onboarding-cip-title',
        type: 'select',
        label: 'Role',
        placeholder: 'Select a role',
        /** this form field is similar to teams with the addition of students */
        options: CIP_ROLES,
        validations: ['required'],
      },
      teamLead: {
        id: 'onboarding-cip-do-you-lead-a-team',
        type: 'select',
        label: 'Do you lead a team?',
        placeholder: 'Select an option',
        options: TEAM_LEADS,
        validations: ['required'],
      },
      company: {
        id: 'onboarding-cip-company',
        type: 'text',
        label: 'Company',
        /** Quasi required for Team Leads 🤷 */
        quasiRequired: true,
        conditions: (formState) => isTeamLead(formState.teamLead) || isPractitioner(formState.title) || isManagerPlus(formState.title),
        /**
         * Actually required for practitioners and managers+
         * Trailing comma is required or last item is dropped during validation
         */
        validations: [`required_if:title,${[...PRACTITIONER_ROLES, ...MANAGER_PLUS_ROLES].map((role) => `"${role}"`).join(',')},}`],
      },
      department: {
        id: 'onboarding-cip-department',
        type: 'select',
        label: 'Department',
        placeholder: 'Select Department',
        defaultValue: '',
        options: SHARED_DEPARTMENTS,
        /** Quasi required for Team Leads 🤷 */
        quasiRequired: true,
        conditions: (formState) => isTeamLead(formState.teamLead) || isPractitioner(formState.title) || isManagerPlus(formState.title),
        /**
         * Actually required for practitioners and managers+
         * Trailing comma is required or last item is dropped during validation
         */
        validations: [`required_if:title,${[...PRACTITIONER_ROLES, ...MANAGER_PLUS_ROLES].map((role) => `"${role}"`).join(',')},}`],
      },
      membershipFor: {
        id: 'onboarding-cip-membership-for',
        type: 'text',
        defaultValue: teamOrCip,
        className: 'hidden',
      },
      goal: {
        id: 'onboarding-cip-goal',
        type: 'text',
        defaultValue: cipGoal,
        className: 'hidden',
      },
      experienceLevel: {
        id: 'onboarding-cip-experience-level',
        type: 'text',
        defaultValue: cipExperience,
        className: 'hidden',
      },
      yearsExperience: {
        id: 'onboarding-cip-years-experience',
        type: 'text',
        defaultValue: cipYearsOfExperience,
        className: 'hidden',
      },
      phone: {
        // the id is done like this intentionally for DS purposes
        id: 'onboarding-cip-phone',
        className: 'w-full',
        type: 'phone',
        label: 'Phone Number',
        conditions: (formState) => isTeamLead(formState.teamLead) || isPractitioner(formState.title) || isManagerPlus(formState.title),
        /**
         * Only require this field for team leads and managers+
         * Trailing comma is required or last item is dropped during validation
         */
        validations: [`required_if:title,${[...TEAM_LEAD_ROLES, ...MANAGER_PLUS_ROLES].map((role) => `"${role}"`).join(',')},}`],
      },

      submit: {
        type: 'button',
        color: 'pink',
        label: 'Submit',
        className: 'mx-auto',
        loading: isSubmitting,
      },
    },
  };

  const handleStep = async (formFields) => {
    const hasCertExperience = cipGoal === 'Get a Certification' && cipYearsOfExperience === '2+ years';
    /** if user is cip or enterprise user, skip select package */
    const skipSelectPackage = isCip || isEnterprise;
    setSavedFormFields(formFields);
    await saveOnboardingData(formFields);
    /** if a user has cert experience send them to cert prep page at the end of onboarding */
    if (hasCertExperience) {
      setNavigationLocation('/browse/certification-prep');
    }
    /** if a user is cip or enterprise user, skip the select package step */
    if (skipSelectPackage) {
      setStep('contentRecommendations');
    } else {
      setStep('selectPackage');
    }
  };
  return (
    <OnboardingStep title="Create Your Profile">
      <div className="p-12 m-auto bg-white rounded-[24px] border-1 border-gray-600">
        <DynamicForm form={CIP_ONBOARDING_FORM_FIELDS} onSubmit={handleStep} />
      </div>
    </OnboardingStep>
  );
}
export default CIPFormStep;
