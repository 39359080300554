import React from 'react';
import { inject } from 'mobx-react';
import CareerProgramsProvider from './CareerProgramsProvider';
import CustomPathsProvider from './CustomPathsProvider';
import ForumsSidebarProvider from './ForumsSidebarProvider';
import ProfileProvider from './ProfileProvider';
import BadgeModalProvider from './BadgeModalProvider';
import CybMeasureProvider from './CybMeasureProvider';

/**
 * Global Providers that wrap the entire application.
 * This exists to help keep App.js clean while wrapping the app in multiple providers.
 * @param {*} children - React children
 */
const GlobalProviders = inject(
  'userStore',
  'enrollmentStore'
)(({ children, userStore, enrollmentStore }) => {
  return (
    <ProfileProvider userStore={userStore}>
      <CustomPathsProvider userStore={userStore} enrollmentStore={enrollmentStore}>
        <BadgeModalProvider>
          <ForumsSidebarProvider>
            <CybMeasureProvider userStore={userStore}>
              <CareerProgramsProvider user={userStore.user}>{children}</CareerProgramsProvider>
            </CybMeasureProvider>
          </ForumsSidebarProvider>
        </BadgeModalProvider>
      </CustomPathsProvider>
    </ProfileProvider>
  );
});

export default GlobalProviders;
