import React, { createContext, useContext, useState, useMemo, useCallback } from 'react';
import Bugsnag from '@bugsnag/js';
import GaUtil from '../utils/gaUtil';
import Agents from '../agents/agents';

const OnboardingContext = createContext();

function OnboardingProvider({ children }) {
  const [userId, setUserId] = useState('');
  const [navigationLocation, setNavigationLocation] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState('initial');

  /* First step */
  const [teamOrCip, setTeamOrCip] = useState('');

  /* CIP FLOW: Second Step */
  /* options: Launch My Career, Get a Certification, Upskill & Practice */
  const [cipGoal, setCipGoal] = useState('');
  /* Third Step */
  const [cipExperience, setCipExperience] = useState('');
  const [cipYearsOfExperience, setCipYearsOfExperience] = useState('');

  /* Third Step */
  const [savedFormFields, setSavedFormFields] = useState({});

  /*
    refer to original figma for additional context 
    https://www.figma.com/file/Vd3kbqT1lHgaDoB1lqogHs/Freemium-Signup-Flow-Q1-2023?node-id=6%3A425&t=1vcUm668JDZK6MRb-0
  */
  const saveOnboardingData = useCallback(
    async (formFields) => {
      try {
        setIsSubmitting(true);
        const isTeams = teamOrCip === 'team';
        const profileData = await Agents.profile.getProfile();
        const defaultUserName = profileData?.data?.personal?.username || '';
        // default payload the backend accepts, however it does not want null values including empty strings
        const payload = {
          personal: {
            firstName: '',
            lastName: '',
            phone: '', // only required for teams
            country: '',
            username: '',
          },
          onboarding_data: {
            title: '', // required for both... referred to as "role" for cip and "title" for teams... but the key is the same
            goal: '', // required for both
            membershipFor: '', // required for both
            experienceLevel: '', // only for cip but can be null depending on flow/route
            teamLead: '', // is in one cip flow but not the other... is also in teams form...
            yearsExperience: '', // only for cip but can be null depending on flow/route
            department: '', // both cip and teams... quasi required
            company: '', // both cip and teams... quasi required
            business_email: '', // only teams
          },
        };

        /** common personal fields amongst both TEAM and CIP forms */
        payload.personal = {
          firstName: formFields.firstName,
          lastName: formFields.lastName,
          country: formFields.country,
          username: defaultUserName,
        };

        /** common onboarding data fields amongst both TEAM and CIP forms */
        payload.onboarding_data = {
          title: formFields.title,
          goal: isTeams ? '' : cipGoal,
          membershipFor: teamOrCip,
          teamLead: formFields.teamLead,
        };

        /** CIP - company is quasi required... can be undefined... TEAM - company is required */
        if (formFields?.company) {
          payload.onboarding_data.company = formFields.company;
        }
        /** CIP - department is conditionally rendered based on isTeamLead... TEAM - department is optional */
        if (formFields?.department) {
          payload.onboarding_data.department = formFields.department;
        }
        /** CIP - phone is conditionally required if team lead... TEAM - phone is required */
        if (formFields?.phone) {
          payload.personal.phone = formFields.phone;
        }

        if (isTeams) {
          /** TEAM only specific flow/payload */
          payload.onboarding_data = {
            ...payload.onboarding_data,
            business_email: formFields.business_email,
          };
        } else {
          /** CIP specific flow/payload */
          payload.onboarding_data = {
            ...payload.onboarding_data,
            ...(formFields?.experienceLevel && { experienceLevel: formFields.experienceLevel }), // will exist in one cip flow but not the other
            ...(formFields?.yearsExperience && { yearsExperience: formFields.yearsExperience }), // will exist in one cip flow but not the other
          };
        }
        // Push profile data to GA/GTM
        GaUtil.fireEvent('CourseEnrollment', 'PQL', 'Onboarding');
        GaUtil.pushEventToDataLayer({
          event: 'create_profile',
          userId,
          profile_country: payload?.personal?.country || '',
          what_brings: payload?.onboarding_data?.goal === "I'm here for my business" ? 'Team' : 'Career',
          // title in our form is the equivalent of role in GA
          role: payload?.onboarding_data?.title || '',
        });
        await Agents.profile.updateProfile(payload);
      } catch (error) {
        Bugsnag.notify(error, (errorEvent) => {
          const { errorMessage } = errorEvent.errors[0];
          // eslint-disable-next-line no-param-reassign
          errorEvent.context = `Onboarding Provider: ${errorMessage}`;
          errorEvent.addMetadata('saveOnboardingData', {
            cipGoal,
            cipExperience,
            cipYearsOfExperience,
            teamOrCip,
          });
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [teamOrCip, cipGoal, cipExperience, cipYearsOfExperience]
  );

  const switchToManager = useCallback(() => {
    setTeamOrCip('team');
    setCipGoal('');
    setCipExperience('');
    setStep('teamForm');
  }, []);

  const values = useMemo(
    () => ({
      step,
      teamOrCip,
      cipGoal,
      cipExperience,
      cipYearsOfExperience,
      savedFormFields,
      navigationLocation,
      isSubmitting,
      setStep,
      setTeamOrCip,
      setCipGoal,
      setCipExperience,
      setCipYearsOfExperience,
      setSavedFormFields,
      setUserId,
      switchToManager,
      saveOnboardingData,
      setNavigationLocation,
    }),
    [step, teamOrCip, isSubmitting, cipGoal, cipExperience, savedFormFields, navigationLocation]
  );

  return <OnboardingContext.Provider value={values}>{children}</OnboardingContext.Provider>;
}

export const useOnboardingContext = () => useContext(OnboardingContext);
export default OnboardingProvider;
