import React from 'react';
import OnboardingStep from './OnboardingStep';
import { useOnboardingContext } from '../../../providers/OnboardingProvider';

/** Step shown at the start of the onboarding flow */
function InitialStep() {
  const { setTeamOrCip, setStep } = useOnboardingContext();
  const handleCipStep = () => {
    setTeamOrCip('cip');
    setStep('cipGoals');
  };

  const handleTeamsStep = () => {
    setTeamOrCip('team');
    setStep('teamForm');
  };
  const individualsHighlights = ['Prepare for a certification', 'Start a cybersecurity career', 'Develop advanced skills', 'Get mentorship'];
  const teamsHighlights = [
    'Assess and baseline my team',
    'Create custom learning paths',
    'Prepare my team for a certification',
    'Keep my team skilled on recent threats',
    'Develop blue and red team operations',
    'Monitor and track team performance',
  ];
  return (
    <OnboardingStep title="Hey there, welcome to Cybrary.">
      <div className="flex flex-col justify-center items-center sm:flex-row sm:items-stretch">
        <OnboardingStep.Button
          title="I'm here for my business"
          icon="user-group"
          onClick={handleTeamsStep}
          className="flex-1 py-11 px-14 max-w-[360px] h-auto"
          membershipHighlights={teamsHighlights}
        />
        <OnboardingStep.Button
          title="I'm here for myself"
          icon="user"
          onClick={handleCipStep}
          className="flex-1 py-11 px-14 max-w-[360px] h-auto"
          membershipHighlights={individualsHighlights}
        />
      </div>
    </OnboardingStep>
  );
}

export default InitialStep;
