import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';

import Confetti from 'react-confetti';
import { useWindowSize } from '@react-hook/window-size';
import Modal from '../Modal/Modal';
import GradientTrophyIcon from '../Dashboard/CybraryAssessmentsWidget/GradientTrophyIcon';
import { BASE_BUTTON_STYLES, CONFETTI_COLORS } from '../../constants';
import If from '../If/If';

/** @important see this page to better understand the props https://github.com/alampros/react-confetti/blob/develop/src/Confetti.ts  */
const LevelUpMessage = inject('userStore')(
  observer(({ userStore }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [width, height] = useWindowSize();

    const { xpChanges, userProfileStats } = userStore;
    const { level = 0 } = userProfileStats || {};

    /** watch for level up */
    useEffect(() => {
      if (xpChanges.level > 0) {
        setIsOpen(true);
      }
    }, [xpChanges]);

    const close = () => setIsOpen(false);
    return (
      <>
        <Modal open={isOpen} ariaLabelledBy="level-up-modal" position="center" size="md" toggle={close} omitCloseX>
          <div id="progress-container" role="status" className="text-center">
            <h1 className="mt-4 text-5xl font-black"> Congratulations! </h1>
            <p className="mt-4 text-xl font-black">You&apos;ve reached Level {level}</p>
            <GradientTrophyIcon />
            <p className="mt-4 text-sm font-medium">Keep learning to level up</p>
            <button className={BASE_BUTTON_STYLES} onClick={close}>
              Close
            </button>
          </div>
        </Modal>
        {/** We pass width and height so the confetti animation works if the screen is resized */}
        <If condition={isOpen}>
          {/** lol 🥳 */}
          <Confetti width={width} height={height} colors={CONFETTI_COLORS} style={{ zIndex: 51 }} initialVelocityY={20} friction={0.9959} />
        </If>
      </>
    );
  })
);

export default LevelUpMessage;
