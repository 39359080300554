import React from 'react';
import OnboardingStep from './OnboardingStep';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { useOnboardingContext } from '../../../providers/OnboardingProvider';
import { COUNTRIES, SHARED_DEPARTMENTS, SHARED_ROLES, TEAM_LEADS } from '../../../constants';
import { firePendoTrackEvent } from '../../../utils/pendoUtil';
import { trackSnowplowEvent } from '../../../utils/snowplowUtil';

function TeamFormStep({ isEnterprise, isCip }) {
  const { setSavedFormFields, teamOrCip, saveOnboardingData, setStep, step, setNavigationLocation, isSubmitting } = useOnboardingContext();
  const handleStep = async (formFields) => {
    const skipSelectPackage = isCip || isEnterprise;
    setSavedFormFields(formFields);
    await saveOnboardingData(formFields);
    if (isEnterprise) {
      // Fired here because we do not change step for this flow, and still need to track completion.
      // Track when a user completes the teams form onboarding step. label is the step that was completed
      trackSnowplowEvent({ category: 'OnboardingStep', action: 'completed', label: step });
      firePendoTrackEvent(`OnboardingStep|${process.env.NODE_ENV}|${step}|Completed`, { step });
      // ik...ik... fixes bug with missing teams nav icon for newly invited admins
      // passing in true specifically for firefox to force reload page...
      window.location.reload(true);
    } else {
      // show them the demo teams dashboard
      setNavigationLocation('/enterprise/demo/organization/welcome');
    }

    /** if a user is cip or enterprise user, skip the select package step
     *  because this is teams flow, we want to skip content recommendations as well
     * ( b/c there is no recommendations for users that choose teams flow)
     */
    if (skipSelectPackage) {
      setStep('navigate');
    } else {
      setStep('selectPackage');
    }
  };
  const TEAM_ONBOARDING_FORM_FIELDS = {
    order: [['firstName', 'lastName'], ['title', 'teamLead'], 'department', 'company', 'business_email', 'phone', 'country', 'membershipFor', 'goal', 'submit'],
    fields: {
      firstName: {
        id: 'onboarding-team-first-name',
        type: 'text',
        label: 'First Name',
        validations: ['required'],
      },
      lastName: {
        id: 'onboarding-team-last-name',
        type: 'text',
        label: 'Last Name',
        validations: ['required'],
      },
      title: {
        id: 'onboarding-team-title',
        type: 'select',
        label: 'Title',
        placeholder: 'Select a title',
        options: SHARED_ROLES,
        validations: ['required'],
      },
      teamLead: {
        id: 'onboarding-team-team-lead',
        type: 'select',
        label: 'Do you lead a team?',
        placeholder: 'Select an option',
        options: TEAM_LEADS,
        validations: ['required'],
      },
      department: {
        id: 'onboarding-team-department',
        type: 'select',
        placeholder: 'Select Department',
        label: 'Department',
        quasiRequired: true,
        options: SHARED_DEPARTMENTS,
      },
      company: {
        id: 'onboarding-team-company',
        type: 'text',
        label: 'Company',
        validations: ['required'],
      },
      business_email: {
        id: 'onboarding-business-email',
        type: 'email',
        label: 'Business Email',
        placeholder: 'name@example.com',
        validations: ['required'],
        description: 'Please enter your business email address. This form does not accept addresses from public email providers.',
      },
      phone: {
        id: 'onboarding-team-phone',
        type: 'phone',
        label: 'Phone Number',
        validations: ['required'],
      },
      country: {
        id: 'onboarding-team-country',
        type: 'select',
        label: 'Country',
        placeholder: 'Select a country',
        defaultValue: 'US',
        options: COUNTRIES,
        validations: ['required'],
      },
      membershipFor: {
        id: 'onboarding-team-membership-for',
        type: 'text',
        defaultValue: teamOrCip,
        className: 'hidden',
      },
      goal: {
        id: 'onboarding-team-goal',
        type: 'text',
        defaultValue: '',
        className: 'hidden',
      },
      submit: {
        type: 'button',
        color: 'pink',
        className: 'mx-auto',
        label: 'Submit',
        loading: isSubmitting,
      },
    },
  };
  return (
    <OnboardingStep title="Create Your Profile">
      <div className="p-12 m-auto bg-white rounded-[24px] border-1 border-gray-600">
        <DynamicForm form={TEAM_ONBOARDING_FORM_FIELDS} onSubmit={handleStep} />
      </div>
    </OnboardingStep>
  );
}
export default TeamFormStep;
