import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Agents from '../../agents/agents';
import BlankTemplate from '../../components/BlankTemplate/BlankTemplate';
import DynamicForm from '../../components/DynamicForm/DynamicForm';
import { FooterBottom } from '../../components/Footer/Footer';
import TOSForm from '../../forms/TOSForm';
import GaUtil from '../../utils/gaUtil';
import { useAuth } from '../../contexts/UseAuth';

const TOSPage = inject(
  'commonStore',
  'authStore'
)(
  observer(({ commonStore, authStore }) => {
    const [serverErrors, setServerErrors] = useState();
    const auth = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
      authStore.fireAttributionEvent();
      commonStore.setPageTitle('Terms Of Service | Cybrary');
    }, []);

    // Submit logic for the form.
    const submitForm = (data) => {
      Agents.profile
        .putTosOptIn(data)
        .then(() => {
          // This is a new user that came through SSO or Social Login, fire the event to GA
          GaUtil.fireEvent('Success', 'Registration Form', 'New Member Registration');
          authStore.fireAttributionEvent('NRU');
          auth.refreshUser(() => navigate('/'));
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.status === 422) {
            setServerErrors(error.response.data.errors);
          } else {
            commonStore.triggerToast('error', {
              content: 'An unknown error occurred.  Please try again.',
            });
          }
        });
    };

    const clearErrorForField = (fieldName) => {
      if (serverErrors && serverErrors[fieldName]) {
        const updatedServerErrors = {
          ...serverErrors,
          [fieldName]: undefined,
        };
        setServerErrors(updatedServerErrors);
      }
    };

    return (
      <div className="relative">
        <div className="hidden absolute top-0 left-0 w-5/12 bg-gray-200 md:block md:bottom-0" />
        <div className="hidden absolute top-0 right-0 w-7/12 bg-white md:block md:bottom-0" />
        <BlankTemplate />
        <div className="flex flex-col w-screen h-screen md:flex-row">
          <div className="md:z-10 md:mt-12 md:w-5/12">
            <div className="grid grid-cols-6 px-4">
              <div className="col-span-full md:col-span-4 md:col-start-2">
                <img
                  src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg?w=120&h=16"
                  alt="Cybrary text logo"
                  width="120"
                  height="16"
                  className="my-6 mx-auto md:mx-0 md:mt-0"
                />
                <p className="my-6 text-lg font-black leading-10 text-center md:mt-16 md:mb-10 md:text-5xl md:text-left">Terms of Service</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col h-full md:z-10 md:mt-10 md:w-7/12 md:h-auto">
            <div className="grid flex-1 grid-cols-6 px-4 md:px-8">
              <div className="col-span-full md:col-span-4 md:col-start-2">
                <div className="md:mt-12">
                  <DynamicForm form={TOSForm} serverErrors={serverErrors} clearErrorForField={clearErrorForField} onSubmit={submitForm} />
                </div>
              </div>
            </div>
            <div className="m-4">
              <FooterBottom />
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default TOSPage;
