import React, { useState } from 'react';
import Icon, { ICON_NAMES } from '../../components/Icon/Icon';
import Container from '../../components/Container/Container';

function IconsPage() {
  const [size, setSize] = useState(8);

  const PreviewIcons = ICON_NAMES.map((ICON_NAME) => {
    return (
      <div className="mb-5 w-1/4" key={ICON_NAME}>
        <p className="flex text-lg">
          <Icon name={ICON_NAME} className={`mr-4 w-${size} h-${size} text-cyb-pink-500 fill-current`} />
          <p className="pl-4">{ICON_NAME}</p>
        </p>
      </div>
    );
  });

  const handleChangeSize = (e) => {
    const value = e.target.value * 1;
    // invalid sizes
    if (![9, 11, 13, 17, 19, 21, 22, 23, 24].includes(value)) {
      setSize(value);
    }
  };

  return (
    <Container size="lg" className="flex flex-wrap mt-5 w-full">
      <div className="mx-auto mb-4 w-1/2 text-lg">
        <input type="range" min="1" max="24" value={size} onChange={handleChangeSize} className="w-[100px]" />
        <br />
        <code className="p-2 mx-auto text-sm bg-slate-200">{`<Icon name="{iconName}" className="w-${size} h-${size} text-cyb-pink-500 fill-current" />`}</code>
      </div>
      {PreviewIcons}
    </Container>
  );
}

export default IconsPage;
