import React from 'react';
import { twMerge } from 'tailwind-merge';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import AddLink from '../AddLink/AddLink';

/** TeamsDemoBanner shown on the teams demo page at the top of the page
 * @param {string} className - additional class names to add to the component
 */
function TeamsDemoBanner({ className }) {
  return (
    <div className={twMerge(`p-2`, className)}>
      <div className="items-center p-3 mb-4 font-bold text-center text-white bg-gradient-to-r from-[#CF0469] to-[#25135F] md:flex">
        <div className="w-full item-center">
          <div className="text-[24px] font-black">Make this dashboard yours</div>
          <div className="grow px-6 text-lg">Learn how you can start managing your team&apos;s development</div>
        </div>
        <AddLink
          className="inline-block shrink-0 py-2.5 px-6 mt-2 text-sm font-bold leading-5 text-center text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm md:mt-0"
          to="https://www.cybrary.it/demo-request"
          onClick={() => {
            trackSnowplowEvent({ category: 'TeamsDemoBanner', action: 'click', label: 'requestDemoButton' });
          }}
          target="_blank"
          rel="noreferrer"
        >
          Request Demo
        </AddLink>
      </div>
    </div>
  );
}

export default TeamsDemoBanner;
