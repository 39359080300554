import React, { useEffect, useState, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import Container from '../../components/Container/Container';
import Loading from '../../components/Loading/Loading';
import Title from '../../components/Title/Title';
import ContentCard from '../../components/Card/ContentCard';
import ContentTransformer from '../../transformers/contentTransformer';
import CIPUpgradeBanner from '../../components/Banners/CIPUpgradeBanner';

const Bookmarks = inject(
  'commonStore',
  'userStore',
  'catalogStore',
  'authStore'
)(
  observer(function Bookmarks({ commonStore, userStore, catalogStore, authStore }) {
    const [items, setItems] = useState(null);
    const { bookmarksLoading } = userStore;

    const getBookmarks = useCallback(async () => {
      const bookmarks = userStore.getBookmarks();
      if (bookmarks?.length) {
        catalogStore.getBookmarkedContent(bookmarks).then((results) => {
          setItems(results?.content?.hits ? results.content.hits : []);
        });
      }
    }, []);

    useEffect(() => {
      commonStore.setPageTitle('Bookmarks | Cybrary');
      authStore.fireAttributionEvent();
      getBookmarks();
    }, []);

    useEffect(() => {
      if (!bookmarksLoading && !items) {
        getBookmarks();
      }
    }, [userStore.bookmarksLoading, items]);

    if (bookmarksLoading) {
      return <Loading />;
    }
    return (
      <Container size="cards-container">
        <CIPUpgradeBanner />
        <Title title="Saved Items" />
        {!items ? (
          'There are no bookmarked items to display.'
        ) : (
          <div className="flex flex-wrap gap-8 my-4">
            {items.map((item) => {
              const prepped = ContentTransformer.formatDataSources(item);
              return (
                <ContentCard data={prepped} key={prepped.id} objectID={prepped.id} className="w-full sm:w-60" cardProps={{ menuAbove: true }} isComingSoon={prepped.comingSoon} />
              );
            })}
          </div>
        )}
      </Container>
    );
  })
);

export default Bookmarks;
