import React from 'react';
import { HashLink } from 'react-router-hash-link';
import If from '../If/If';

function ListTableCell({ children }) {
  return <div className="hidden py-7 text-sm text-center text-gray-600 align-middle border-t-xs border-gray-400 lg:table-cell">{children}</div>;
}

function ListTableRowAnchor({ column, children }) {
  if (column.interactive) {
    return (
      <HashLink to={column.href} className="flex text-black hover:text-black hover:no-underline">
        {children}
      </HashLink>
    );
  }
  return <div className="flex text-black hover:text-black hover:no-underline">{children}</div>;
}

function ListRow({ row }) {
  return (
    <div className="table-row">
      {row.values.map((column) => {
        if (column.href) {
          return (
            <div className="table-cell py-5 border-t-xs border-gray-400 lg:py-7" key={column.key}>
              <div className="flex">
                <ListTableRowAnchor column={column}>
                  <If condition={column.image}>
                    <img className="hidden pr-4 w-auto min-w-[240px] h-40 rounded-sm lg:block" src={column.image} alt="" />
                  </If>
                  <If condition={column.description}>
                    <div>
                      <p className="mb-0 text-sm text-black lg:pb-2 lg:text-base lg:font-bold">{column.value}</p>
                      <div className="hidden pt-2 lg:block">
                        <p className="pb-2 mb-0 text-sm leading-6 text-black">{column.description}</p>
                      </div>
                    </div>
                  </If>
                </ListTableRowAnchor>
              </div>
            </div>
          );
        }
        return <ListTableCell key={column.key}>{column.value}</ListTableCell>;
      })}
    </div>
  );
}

function ListTableHeaders({ headings }) {
  return (
    <div className="table-header-group">
      <div className="table-row text-2xs font-semibold text-gray-600 uppercase">
        {headings.map((heading, index) => {
          if (index === 0) {
            return (
              <div className="table-cell pt-8 pb-4 pl-0 w-3/4 lg:pt-10 lg:pl-60 lg:w-1/2" key={heading}>
                {heading}
              </div>
            );
          }

          const classes = index === headings.length - 1 ? 'table-cell px-4 text-center' : 'hidden px-4 text-center lg:table-cell';

          return (
            <div className={classes} key={heading}>
              {heading}
            </div>
          );
        })}
        <div className="table-cell" />
      </div>
    </div>
  );
}

/**
 * `ListTable` - generates a list of tabular rows based on a given set of data.
 * @param {ListTableProps} props - component props as `ListTableProps`
 *
 * @see [BrowseCareerPrograms](../../pages/CareerPrograms/BrowseCareerPrograms.js) for example usage.
 *
 * @returns JSX.Element
 */
export default function ListTable({ headings, rows }) {
  return (
    <div className="table w-full">
      <ListTableHeaders headings={headings} />
      <div className="table-row-group mb-10">
        {rows.map((row) => (
          <ListRow row={row} key={row.id} />
        ))}
      </div>
    </div>
  );
}

// Type Defs

/**
 * The props schema for the `ListTable`.
 * @typedef {Object} ListTableProps
 * @property {string[]} headings - array of strings that represent the table headers, ex: `['name', 'age', 'height']`
 * @property {ListTableRow[]} rows - array of `ListTableRow` data
 */

/**
 * The schema for a row within the `ListTable`.
 * @typedef {Object} ListTableRow
 * @property {string} id - unique `id` for the row.
 * @property {ListTableRowColumn[]} values - array of `ListTableRowColumn`.
 */

/**
 * The schema for a row column within the `ListTable`.
 * @typedef {Object} ListTableRowColumn
 * @property {string} key - unique `key` for the column, should correspond to one of the `ListTable` headings.
 * @property {(string | number)} value - row column value. If `description` is provided, this acts as the `title`.
 * @property {RenderColumnFunc} [renderColumnValue] - callback function that renders custom column value.
 * @property {string} [description] - description for column value.
 * @property {string} [image] - url to an image to display for this column.
 * @property {string} [href] - href for this column.
 */

/**
 * The definition for the `renderColumnValue` func of type `ListTableRowColumn`
 * @typedef {Function} RenderColumnFunc
 * @param {ListTableRowColumn} data - column data.
 * @returns JSX.Element
 */
