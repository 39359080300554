import Bugsnag from '@bugsnag/js';
import Agents from '../agents/agents';

export const getRecommendationsTitle = (cipGoal, cipExperience, cipYearsOfExperience) => {
  switch (true) {
    case cipGoal === 'Launch My Career' && (cipExperience === `I'm brand new` || cipExperience === `I've got some IT experience`):
      return 'Welcome! Our expertly curated beginner content is here to guide you through the world of cybersecurity.';
    case cipGoal === 'Get a Certification' && (cipYearsOfExperience === `0-2 years` || cipYearsOfExperience === `2+ years`):
      return 'Nice! Get ready to ace your exam with our free cert prep video courses.';
    case cipGoal === 'Upskill & Practice':
      return 'Great! Get hands-on with paths, courses, labs, and assessments.';
    default:
      return '';
  }
};

export const getRecommendationsDescription = (cipGoal, cipExperience, cipYearsOfExperience) => {
  switch (true) {
    case cipGoal === 'Launch My Career' && (cipExperience === `I'm brand new` || cipExperience === `I've got some IT experience`):
      return 'Check out our Foundations career path and learn the essential concepts at the heart of every security role!';
    case cipGoal === 'Get a Certification' && cipYearsOfExperience === `0-2 years`:
      return 'Enroll in our most in-demand certification course for early career practitioners!';
    case cipGoal === 'Get a Certification' && cipYearsOfExperience === `2+ years`:
      return 'Enroll in our most in-demand certification path for security practitioners!';
    case cipGoal === 'Upskill & Practice':
    default:
      return '';
  }
};
const getFullfilledData = (fetchedContent) => fetchedContent?.filter((item) => item.status === 'fulfilled');
const getRejectedData = (fetchedContent) => fetchedContent?.filter((item) => item.status === 'rejected');

const getFullfilledValues = (fetchedContent) => fetchedContent?.map((item) => item.value);
const getRejectedReason = (fetchedContent) => fetchedContent?.map((item) => item.reason);

const getContentItems = async (itemsToFetch) => {
  if (itemsToFetch?.length < 1) {
    throw Error('no fetch items included');
  } else {
    // creates an array of functions with the permalink as the argument
    const contentRequests = itemsToFetch.map((permalink) => Agents.catalog.getContentDescriptionByPermalink(permalink));
    // allSettled will return an array of objects with the status and value (fulfilled) / reason (rejected) of each promise...
    // even if it fails which is why we use it over promise.all
    return Promise.allSettled([...contentRequests]);
  }
};

const getFilteredContent = async (itemsToFetch) => {
  const fetchedContent = await getContentItems(itemsToFetch);

  const fullfilledData = getFullfilledData(fetchedContent);
  const rejectedData = getRejectedData(fetchedContent);

  const filteredFullfilledData = getFullfilledValues(fullfilledData) || [{}];
  const filteredRejectedData = getRejectedReason(rejectedData) || [{}];

  return { filteredFullfilledData, filteredRejectedData };
};

export const getSecondaryContent = async (cipGoal, cipExperience, cipYearsOfExperience) => {
  const content = [];
  const failedContent = [];
  try {
    if (cipGoal === 'Launch My Career' && (cipExperience === `I'm brand new` || cipExperience === `I've got some IT experience`)) {
      const moreOptionsPermalinks = ['paths/career-paths/soc-analyst', 'paths/career-paths/penetration-tester', 'paths/cert-prep-paths/security-plus'];
      const { filteredFullfilledData, filteredRejectedData } = await getFilteredContent(moreOptionsPermalinks);
      content.push(...filteredFullfilledData);
      failedContent.push(...filteredRejectedData);
    } else if (cipGoal === 'Get a Certification' && cipYearsOfExperience === '0-2 years') {
      const moreOptionsPermalinks = [
        'paths/cert-prep-paths/certified-information-systems-security-professional',
        'paths/career-paths/foundations',
        'paths/career-paths/soc-analyst',
      ];
      const { filteredFullfilledData, filteredRejectedData } = await getFilteredContent(moreOptionsPermalinks);
      content.push(...filteredFullfilledData);
      failedContent.push(...filteredRejectedData);
    } else if (cipGoal === 'Get a Certification' && cipYearsOfExperience === '2+ years') {
      const moreOptionsPermalinks = [
        'paths/cert-prep-paths/certified-information-security-manager',
        'paths/cert-prep-paths/certified-cloud-security-professional',
        'paths/cert-prep-paths/cybersecurity-analyst',
      ];
      const { filteredFullfilledData, filteredRejectedData } = await getFilteredContent(moreOptionsPermalinks);
      content.push(...filteredFullfilledData);
      failedContent.push(...filteredRejectedData);
    } else if (cipGoal === 'Upskill & Practice') {
      const moreOptionsPermalinks = ['paths/career-paths/foundations', 'paths/career-paths/soc-analyst', 'paths/career-paths/penetration-tester'];
      const { filteredFullfilledData, filteredRejectedData } = await getFilteredContent(moreOptionsPermalinks);

      content.push(...filteredFullfilledData);
      failedContent.push(...filteredRejectedData);
    }
    // if we have items in the failedContent array, throw an error to bugsnag
    if (failedContent.length > 0) {
      throw Error('failed to fetch some secondary content');
    }
  } catch (error) {
    Bugsnag.notify(error, (errorEvent) => {
      const { errorMessage } = errorEvent.errors[0];
      // eslint-disable-next-line no-param-reassign
      errorEvent.context = `useOnboardingRecommendation: ${errorMessage}`;
      errorEvent.addMetadata('getSecondaryContent', {
        failedContent,
      });
    });
  }

  return content;
};

export const getCardContent = async (cipGoal, cipExperience, cipYearsOfExperience) => {
  const content = [];
  const failedContent = [];
  try {
    if (cipGoal === 'Launch My Career' && cipExperience === `I'm brand new`) {
      const { filteredFullfilledData, filteredRejectedData } = await getFilteredContent([`paths/career-paths/foundations`]);
      content.push(...filteredFullfilledData);
      failedContent.push(...filteredRejectedData);
    } else if (cipGoal === 'Launch My Career' && cipExperience === `I've got some IT experience`) {
      const { filteredFullfilledData, filteredRejectedData } = await getFilteredContent([`paths/career-paths/foundations`]);
      content.push(...filteredFullfilledData);
      failedContent.push(...filteredRejectedData);
    } else if (cipGoal === 'Get a Certification' && cipYearsOfExperience === '0-2 years') {
      const { filteredFullfilledData, filteredRejectedData } = await getFilteredContent([`paths/cert-prep-paths/security-plus`]);
      content.push(...filteredFullfilledData);
      failedContent.push(...filteredRejectedData);
    } else if (cipGoal === 'Get a Certification' && cipYearsOfExperience === '2+ years') {
      const { filteredFullfilledData, filteredRejectedData } = await getFilteredContent([`paths/cert-prep-paths/certified-information-systems-security-professional`]);
      content.push(...filteredFullfilledData);
      failedContent.push(...filteredRejectedData);
    } else if (cipGoal === 'Upskill & Practice') {
      const upskillContentCardPermalinks = [
        /**
         * IMPORTANT - Do NOT prefix threat-actor-campaigns with threat-actor-campaigns as the permalink is in algolia without the prefix unlike other types
         */
        'paths/career-paths/foundations',
        'paths/career-paths/soc-analyst',
        'paths/career-paths/penetration-tester',
      ];
      const { filteredFullfilledData, filteredRejectedData } = await getFilteredContent(upskillContentCardPermalinks);
      content.push(...filteredFullfilledData);
      failedContent.push(...filteredRejectedData);
    }
    // if we have items in the failedContent array, throw an error to bugsnag
    if (failedContent.length > 0) {
      throw Error('failed to fetch some card content');
    }
  } catch (error) {
    Bugsnag.notify(error, (errorEvent) => {
      const { errorMessage } = errorEvent.errors[0];
      // eslint-disable-next-line no-param-reassign
      errorEvent.context = `useOnboardingRecommendation: ${errorMessage}`;
      errorEvent.addMetadata('getCardContent', {
        failedContent,
      });
    });
  }
  return content;
};
