import React, { useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import Icon from '../Icon/Icon';
import Modal from '../Modal/Modal';

function Teaser() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <button type="button" onClick={() => setOpen(true)} className="flex gap-x-4 items-center py-8 px-4 border border-gray-400 shadow-[0_1px_2px_0_rgba(0,0,0,0.3)]">
        <Icon name="play" className="w-12 h-12" />
        <p className="text-3xl font-bold">Watch Teaser</p>
      </button>
      <Modal size="md" open={open} toggle={() => setOpen(false)}>
        <div className="px-6 pt-12">
          <Vimeo id="video-player-wrapper" autoplay responsive speed video={742475992} />
        </div>
      </Modal>
    </>
  );
}

export default Teaser;
