import React, { useState, useEffect, useRef } from 'react';
import { throttle } from 'lodash';
import CodeSection from './Content/CodeSection';
import ImageSection from './Content/ImageSection';
import LabSection from './Content/LabSection';
import TextSection from './Content/TextSection';
import VideoSection from './Content/VideoSection';
import Challenge from './Challenge';
import Unauthorized from './Unauthorized';
import LegacyArticleSection from './Content/LegacyArticleSection';
import { Warning } from './Mobile/Warnings';
import A11yPause from './A11yPause';
import ContentRenderer from './Content/ContentRenderer';
import CIPUpgradeBanner from '../Banners/CIPUpgradeBanner';
import RichTextRenderer from './Content/RichTextRenderer';

// Ensure that the content array contains IDs for each entry
function ensureContentIds(content, i) {
  if (Array.isArray(content) && content.length) {
    return content.map((item, j) => {
      return {
        id: `${i}_${j}`,
        ...item,
      };
    });
  }
  return content;
}

function ContentWrapper({ authorized, activity, hasALab, content, completeActivity, isComplete, userStore }) {
  // SLATE type is content created via CPIPE and stored in Contentful
  // Filtering out SLATE type content items from the rest of content
  // If SLATE type is present, only render that; ignore all the other content blocks
  const slateContent = content.filter((block) => block.type === 'slate');
  const { lesson_content: lessonContent } = activity || {};
  const hasLessonContent = !!lessonContent?.content && !!lessonContent.content.content && !!lessonContent.content.content.length;

  return (
    <>
      {!authorized && <Unauthorized contentType={activity.type} />}
      <Warning hasALab={hasALab} className="m-4" />
      {authorized && !!hasLessonContent && <RichTextRenderer userStore={userStore} references={lessonContent.references} content={lessonContent.content.content} />}
      {/* If SLATE content type is present, only render content of this type */}
      {authorized && !hasLessonContent && slateContent.length
        ? slateContent.map((block) => {
            return <ContentRenderer key={block.id} content={block.content} activity={activity} userStore={userStore} />;
          })
        : null}
      {/* If SLATE content type is NOT present, render everything else */}
      {authorized && !hasLessonContent && !slateContent.length && content.length
        ? content.map((block, i) => {
            switch (block.type) {
              case 'text':
                return <TextSection key={block.id} title={block.title} highlight={block.highlight} content={ensureContentIds(block.content, i)} />;
              case 'article':
                return <LegacyArticleSection key={block.id} content={block.content} completeActivity={completeActivity} isComplete={isComplete} activity={activity} />;
              case 'code':
                return <CodeSection key={block.id} code={block.code} />;
              case 'image':
                return <ImageSection key={block.id} src={block.src} alt={block.alt} highlight={block.highlight} caption={block.caption} />;
              case 'video':
                return (
                  <VideoSection
                    key={block.id}
                    isComplete={isComplete}
                    videoId={block.videoId}
                    highlight={block.highlight}
                    caption={block.caption}
                    completeActivity={completeActivity}
                    legacy={!!block.legacy}
                    activity={activity}
                    isImmersiveV3
                  />
                );
              case 'lab':
                return <LabSection key={block.id} permalink={block.permalink} userStore={userStore} />;
              default:
                return null;
            }
          })
        : null}
    </>
  );
}

/** Main lesson content */
function LessonContent({
  content,
  activityTitle,
  toggleSuccessBannerDisplay,
  questions,
  gotoNextActivity,
  gotoStart,
  activity,
  isComplete,
  next,
  authorized,
  completeActivity,
  userStore,
  isLastIncomplete,
  onlyLegacyVideo,
  hideTasks = false,
  maxWidth = 750,
  videoMaxWidth = '4xl',
}) {
  const [videoSize, setVideoSize] = useState({
    width: null,
  });
  const { legacy } = activity;
  const hasALab = content.find((block) => block.type === 'lab');
  const lessonContainer = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (lessonContainer && lessonContainer.current) {
        lessonContainer.current.focus();
      }
    }, 400);
  }, [lessonContainer, lessonContainer.current]);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (window.innerWidth > 1024 && onlyLegacyVideo) {
        const newWidth = ((window.innerHeight - 125) * (16 / 9)).toFixed(2);
        setVideoSize({
          width: newWidth <= 1024 ? newWidth : 1024,
        });
      }
    }

    window.addEventListener('resize', throttle(handleResize, 1000, { leading: false, trailing: true }));
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <A11yPause />
      <div className="flex overflow-hidden relative justify-center h-full">
        <div className="pt-0 w-full bg-white transition-all duration-300">
          <div className="overflow-hidden w-full h-full">
            <div tabIndex="-1" ref={lessonContainer} className="box-content overflow-y-scroll w-full h-full outline-none">
              <CIPUpgradeBanner />
              <div
                style={onlyLegacyVideo ? { maxWidth: `${videoSize.width}px` } : {}}
                id="video"
                className={`${onlyLegacyVideo ? `px-0 lg:py-4 lg:px-12` : `py-4 sm:py-10 px-2 md:px-12 max-w-${videoMaxWidth}`} mx-auto`}
              >
                <div className={`${!legacy ? `max-w-[${maxWidth}px] mx-auto` : ''}`}>
                  <ContentWrapper
                    authorized={authorized}
                    activity={activity}
                    hasALab={hasALab}
                    content={content}
                    completeActivity={completeActivity}
                    isComplete={isComplete}
                    userStore={userStore}
                  />
                  {legacy || !questions || !questions.length || hideTasks ? null : (
                    <Challenge
                      activity={activity}
                      questions={questions}
                      isComplete={isComplete}
                      isLast={!(next && next.url)}
                      gotoStart={gotoStart}
                      gotoNextActivity={gotoNextActivity}
                      completeActivity={completeActivity}
                      showSuccessBar={toggleSuccessBannerDisplay}
                      isLastIncomplete={isLastIncomplete}
                    />
                  )}
                  <div className={`${onlyLegacyVideo ? 'lg:hidden block py-4 pr-5 ' : 'hidden'}`}>{activityTitle}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LessonContent;
