import React, { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { twMerge } from 'tailwind-merge';
import Segment from '../../components/Segment/Segment';
import Price from '../../components/Checkout/Price';
import { PADDLE_SUBSCRIPTIONS } from '../../constants';
import DiscountBubble from '../../components/Checkout/DiscountBubble';

function PricingBillingCycleToggle({ optimisticToggleState, handleToggle }) {
  return (
    <div className="flex justify-center items-center mt-2 w-full">
      <div className="mr-1 md:mr-2.5">Billed Monthly</div>
      <PricingToggleButton enabled={optimisticToggleState} handleToggle={handleToggle} />
      <div className="ml-1 md:ml-2.5">Billed Annually</div>
    </div>
  );
}

function PricingToggleButton({ enabled, handleToggle }) {
  return (
    <button type="button" className="flex items-center w-10 h-6 bg-gray-100 rounded-full border-xs border-gray-400" onClick={handleToggle}>
      <div className={`mx-auto h-5 w-5 transform rounded-full bg-cyb-pink-500 duration-300 ease-in-out ${enabled ? 'translate-x-2' : '-translate-x-2'}`} />
    </button>
  );
}

function PricingTitle({ children, className = '', checkoutLoading }) {
  return (
    <div className={twMerge('mb-8 text-center transition-opacity duration-500', checkoutLoading ? 'opacity-0' : 'opacity-100', className)}>
      <p className="mb-0 text-lg font-bold capitalize">{children}</p>
    </div>
  );
}

function PricingContainer({ children }) {
  return (
    <Segment className="py-8 mt-0">
      <Fade triggerOnce>{children}</Fade>
    </Segment>
  );
}

function Pricing({ checkoutData, checkoutLoading, swapCheckoutItems, isTeams }) {
  const { items, currency_code, discount, recurring_totals, totals } = checkoutData || {};
  const item = items ? items[0] : {};
  const { billing_cycle } = item || {}; // For now, just support the first index. We'll get fancy later if we want to support multiple items in the cart.
  const priceTotals = recurring_totals || totals;
  const isAnnual = billing_cycle?.interval === 'year';
  const monthsPerCycle = isAnnual ? 12 : 1;
  const totalMonthly = priceTotals ? `${priceTotals.balance / monthsPerCycle}` : '';
  const priceClassNames = twMerge(`mb-3 transition-opacity duration-500`, checkoutLoading ? 'opacity-0' : 'opacity-100');
  const [optimisticToggleState, setOptimisticToggleState] = useState(isAnnual);
  // calculate the additional discount being offered today on top of the recurring price
  const additionalDiscount = discount ? ((100 * totals.discount) / totals.subtotal).toFixed() : '0';

  // toggle between monthly and annual billing for CIP
  const handleToggle = () => {
    // teams is only annual
    if (isTeams) return;
    const currentPlan = PADDLE_SUBSCRIPTIONS.cip[isAnnual ? 'annual' : 'monthly'];
    const newPlan = PADDLE_SUBSCRIPTIONS.cip[isAnnual ? 'monthly' : 'annual'];
    swapCheckoutItems(currentPlan, newPlan);
    setOptimisticToggleState(!optimisticToggleState);
  };

  /** Pricing for Teams
   * We can probably refactor this to be more DRY, but for now, it's easier to maintain this way.
   */
  if (isTeams) {
    return (
      <PricingContainer>
        <PricingTitle>Monthly Fee</PricingTitle>
        <div className="flex flex-col justify-between items-center">
          <Price className={priceClassNames} price={totalMonthly} currencyCode={currency_code} />
          <p className="m-0">Billed Annually</p>
        </div>
      </PricingContainer>
    );
  }

  return (
    <PricingContainer>
      <PricingTitle checkoutLoading={checkoutLoading}>{isAnnual ? 'Annual' : 'Monthly'}</PricingTitle>
      <div className="flex flex-col justify-between items-center">
        <Price className={priceClassNames} price={totalMonthly} currencyCode={currency_code} />
        <DiscountBubble percentOff={additionalDiscount} sizeClasses={discount ? 'w-24 h-8' : 'w-24 h-2'} />
        <PricingBillingCycleToggle optimisticToggleState={optimisticToggleState} handleToggle={handleToggle} />
      </div>
    </PricingContainer>
  );
}

export default Pricing;
