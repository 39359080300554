import moment from 'moment';
import React from 'react';
import FormatUtil from './formatUtil';
import SearchUtil from './searchUtil';
import If from '../components/If/If';
import AddLink from '../components/AddLink/AddLink';
import ChevronRight from '../Icons/ChevronRight';

const goalCreationConfig = {
  team: {
    obj: 'goalData',
    requiredSections: ['name', 'outcome_type', 'owner_ids', 'participant_type', 'content_type', 'due_type', 'start_date'],
  },
  user: {
    obj: 'userGoalData',
    requiredSections: ['outcome_type', 'due_type', 'content_type', 'name'],
  },
};

const userGoalOutcomeMap = {
  'Start my career in Cybersecurity or IT': 'start_career',
  'Determine if pursuing a career in Cybersecurity or IT is for me': 'explore_career',
  'Prepare for a job role': 'career_program',
  'Prepare for an industry certification': 'certification',
  'Learn a new skill': 'skill_program',
  'Learn new skills needed for my job or education': 'skills',
  'I want to transition from IT to Cybersecurity': 'it_to_cybersecurity',
  'Earn CEU/CPEs': 'ceus',
  'Advance my Career': 'advance_career',
  'Assess my team': 'team_assessment',
  'Baseline my team': 'team_baseline',
};

export default class GoalsUtil {
  static getUserGoalOutcomeMap() {
    return userGoalOutcomeMap;
  }

  static getOwnerIdsQuery(data) {
    let ownerIdsQuery = '';
    if (data && data.length) {
      data.forEach((ownerId) => {
        ownerIdsQuery += `&ownerIds[]=${ownerId}`;
      });
    }
    return ownerIdsQuery;
  }

  static checkGoalValid(goalType, data, isEditView, upcomingGoal) {
    const { requiredSections } = goalCreationConfig[goalType];
    for (let i = 0; i < requiredSections.length; i++) {
      const sectionStatus = GoalsUtil.checkSectionValid(goalType, data, requiredSections[i], isEditView, upcomingGoal);
      if (!sectionStatus.valid) {
        return false;
      }
    }
    return true;
  }

  static checkSectionValid(goalType, data, section, isEditView, upcomingGoal) {
    const sectionData = data[section];

    /* Outcome checks */
    if (sectionData === 'learning_hours') {
      return { valid: !!data.outcome_settings.learning_seconds_total, error: 'Please include the number of learning hours needed for completion before proceeding.' };
    }
    if (sectionData === 'ceus') {
      return { valid: !!data.outcome_settings.ceus_total, error: 'Please include the number of CEUs needed for completion before proceeding.' };
    }
    if (section === 'outcome_type') {
      return { valid: !!sectionData, error: 'Please choose your goal outcome from the options above.' };
    }

    /* Owner checks */
    if (section === 'owner_ids') {
      return { valid: !!data.owner_ids.length, error: 'Please select one or more owners from the options above before proceeding.' };
    }

    /* Participant checks */
    if (section === 'participant_type') {
      return this.getParticipantValidations(data, sectionData);
    }

    /* Content checks */
    if (section === 'content_type' && !sectionData && !(goalType === 'user' && data.outcome_type === 'certification')) {
      return { valid: false, error: 'Please select a learning activity type from the options above.' };
    }
    if (sectionData === 'path') {
      return { valid: !!data.content_ids.length, error: 'Please select a path from the options above.' };
    }
    if (sectionData === 'activity') {
      return {
        valid: !!data.content_ids.length,
        error:
          goalType === 'user'
            ? 'Please select a course from the options above.'
            : 'Please select a learning activity. Type in a search query to see a list of relevant content and then choose an item to assign to your participants.',
      };
    }

    /* Start Date Checks */
    if (section === 'start_date') {
      return this.getStartDateValidations(data, isEditView, upcomingGoal);
    }

    /* Deadline checks */
    if (section === 'due_type') {
      return this.getDeadlineValidations(data, sectionData);
    }

    /* Details checks */
    if (section === 'name') {
      return { valid: !!data.name, error: 'Please provide a name for this goal.' };
    }

    return { valid: !!sectionData && !!sectionData.length, error: 'Please select from the options above.' };
  }

  static getParticipantValidations(data, sectionData) {
    if (!sectionData) {
      return { valid: false, error: 'Please select a participant type from the options above.' };
    }
    return { valid: !!data.participant_ids.length, error: `Please select one or more ${sectionData === 'team' ? 'groups' : 'users'} from the options above before proceeding.` };
  }

  static getStartDateValidations(data, isEditView, upcomingGoal) {
    // If this is a recurring goal, can't have start date be before the previous instance's due date
    if (data.due_type === 'recurring' && data.previous_due_date && moment(data.start_date).isBefore(moment(data.previous_due_date))) {
      return { valid: false, error: 'Unable to schedule a new recurring goal to start prior to the due date of an existing recurring goal.' };
    }
    // Can not select a start date that is in the past or today.
    // UNLESS this is edit view on a started goal (those goals will have start dates in the past -- Field can't be edited so won't be changed by user)
    if (moment(data.start_date).isBefore(moment().endOf('day')) && (!isEditView || !!upcomingGoal)) {
      return { valid: false, error: 'You cannot select a start date that is in the past or is today.' };
    }
    // if start date is "today" it will be null... so return validation as true
    return { valid: true };
  }

  static getDeadlineValidations(data, sectionData) {
    const dateInPastError = 'You cannot select a due date that is in the past.';
    if (sectionData === 'recurring') {
      const dueInterval = GoalsUtil.getIntervalBreakdown(data.due_interval);
      if (dueInterval.count === '0') {
        return { valid: false, error: 'Please add a recurrence interval greater than 0.' };
      }
      // If due date with recurrence timeline factored in is before today (when editing a recurring goal and trying to shorten recurrence that results in due before today)
      if (
        data.start_date &&
        dueInterval.count &&
        dueInterval.increment &&
        moment
          .utc(data.start_date)
          .add(dueInterval.count, dueInterval.increment === 'W' ? 'weeks' : 'months')
          .isSameOrBefore(moment.utc().startOf('day'))
      ) {
        return { valid: false, error: dateInPastError };
      }
      return { valid: !!data.due_interval, error: "Please select how often you'd like this goal to recur." };
    }
    if (sectionData === 'date') {
      if (data.due_date) {
        // If due date is before start date
        if (data.start_date && moment(data.due_date).isBefore(moment(data.start_date))) {
          return { valid: false, error: 'You cannot select a due date that is before the start date.' };
        }
        // If date selected is in the past
        return { valid: !moment(data.due_date).isBefore(moment().startOf('day')), error: dateInPastError };
      }

      return { valid: !!data.due_date, error: 'Please enter in or select a date in the input above.' };
    }
    return { valid: !!sectionData, error: 'Please choose whether or not this goal has a due date.' };
  }

  static getGoalValidationMap(goalType, data) {
    const { requiredSections } = goalCreationConfig[goalType];
    const validationMap = {};
    requiredSections.forEach((section) => {
      validationMap[section] = GoalsUtil.checkSectionValid(goalType, data, section);
    });
    return validationMap;
  }

  static suggestGoalName(goalType, data) {
    const { outcome_type, outcome_settings, participant_type, participants } = data;
    let name = null;
    if (outcome_type === 'learning_hours' && outcome_settings.learning_seconds_total) {
      name = `Complete ${Math.round(1 * FormatUtil.convertSecondsToHours(outcome_settings.learning_seconds_total))} learning hours`;
    }
    if (outcome_type === 'ceus' && outcome_settings.ceus_total) {
      name = `Earn ${outcome_settings.ceus_total} CEUs`;
    }
    if (outcome_type === 'onboarding' && participant_type && participants && participants.length) {
      if (participants.length === 1) {
        name = `Complete onboarding for ${participants[0].text || participants[0].name}`;
      } else {
        name = 'Complete Onboarding';
      }
    }
    // If this is a user goal and the outcome is not ceus, just use the outcome text
    if (goalType === 'user' && outcome_type !== 'ceus' && outcome_type !== 'certification') {
      const reverseUserGoalOutcomeMap = FormatUtil.objectFlip(userGoalOutcomeMap);
      name = reverseUserGoalOutcomeMap[outcome_type];
    }
    return name;
  }

  static dedupAdminList(listData) {
    const cleanData = [];
    if (listData && listData.length) {
      // Get rid of any dups
      listData.forEach((item) => {
        let isClean = true;
        cleanData.forEach((cleanItem) => {
          if (item.value === cleanItem.value) {
            isClean = false;
          }
        });
        if (isClean) {
          cleanData.push(item);
        }
      });
    }
    return cleanData;
  }

  static getParticipantType(data, team) {
    if (!data.participant_type || !data.participant_ids || !team) {
      return null;
    }
    if (data.participant_type === 'team') {
      return data.participant_ids.indexOf(team.id) === -1 ? 'group' : 'team';
    }
    return 'user';
  }

  // Ge the primary metric used to measure completion of this goal based on its outcome type/settings
  static getPrimaryGoalMetric(data) {
    const optionalProgressMetrics = ['ceus', 'learning_hours'];
    if (data && data.outcome_type && optionalProgressMetrics.indexOf(data.outcome_type) > -1) {
      return data.outcome_type;
    }
    // If we have a certification test, or we have attempts/pending results in progress, user certification metric
    if ((data && data.certification_test) || (data.progress && 'attempts' in data.progress) || (data.progress && 'pending_results' in data.progress)) {
      return 'certification';
    }
    // Default
    return 'activities';
  }

  // Get Practice Test goals status based on test attempts
  static getPracticeTestStatus(data) {
    let status = 'No practice test taken';
    if (!data || !data.progress) {
      return status;
    }
    if (data.progress.attempts) {
      status = `Practice test ${1 * data.progress.percent_completed === 100 ? 'passed' : 'failed'}`;
    }
    if (data.progress.pending_results) {
      status = 'Waiting for practice test score';
    }

    return status;
  }

  static getContentSectionName(outcome, contentType) {
    let section = contentType === 'path' ? 'choose_career_path' : 'learn_basics';
    switch (outcome) {
      case 'start_career':
        section += '_start';
        break;
      case 'explore_career':
        section += '_determine';
        break;
      case 'skills':
        section += '_learn_skills';
        break;
      case 'it_to_cybersecurity':
        section += '_transition';
        break;
      case 'ceus':
        section += '_earn_ceu';
        break;
      case 'advance_career':
        section += '_advance_career';
        break;
      case 'certification':
        section += '_prepare_cert';
        break;
      default:
        break;
    }
    return section;
  }

  static getOutcomeDisplay(type, settings) {
    if (!type) {
      return null;
    }

    const displayMap = {
      ...FormatUtil.objectFlip(userGoalOutcomeMap),
      ceus: 'Earn CEUs',
      learning_hours: 'Complete Minimal Learning Hours',
      certification: 'Prepare for a Certification Exam',
    };

    if (type === 'recommended_learning') {
      displayMap.recommended_learning = 'Recommended Learning';
    }

    let display = displayMap[type] ? displayMap[type] : FormatUtil.ucwords(type).split('_').join(' ');

    if (type.includes('baselining_')) {
      display = displayMap.team_baseline;
    }

    if (settings) {
      const typeTotal = settings[`${type}_total`];
      display += typeTotal ? ` | ${typeTotal}` : '';
    }

    return display;
  }

  static getTileOutcomeDisplay(goal, metric, practiceTestStatus) {
    const outcome = {
      targetOutcome: null,
      currentProgress: null,
    };

    if (metric === 'certification') {
      outcome.targetOutcome = `Pass practice test`;
      outcome.currentProgress = practiceTestStatus || null;
    }
    // Outcome/progress data for learning_hours comes in seconds (learning_seconds)
    const metricKey = metric === 'learning_hours' ? 'learning_seconds' : metric;

    const metricValue = goal.outcome_settings[`${metricKey}_total`];
    const progressValue = goal.progress && goal.progress[`${metricKey}_completed`] ? goal.progress[`${metricKey}_completed`] : 0;
    if (metric === 'learning_hours') {
      const targetHours = FormatUtil.convertSecondsToHours(metricValue, true);
      const currentHours = FormatUtil.convertSecondsToHours(progressValue, true);
      outcome.targetOutcome = `${targetHours} learning hour${targetHours === 1 ? '' : 's'}`;
      outcome.currentProgress = `${currentHours} learning hour${currentHours === 1 ? '' : 's'}`;
    }
    if (metric === 'ceus') {
      outcome.targetOutcome = `${metricValue} CEU${metricValue === 1 ? '' : 's'}`;
      outcome.currentProgress = `${progressValue} CEU${progressValue === 1 ? '' : 's'}`;
    }
    if (metric === 'activities') {
      outcome.targetOutcome = `${metricValue} learning activit${metricValue === 1 ? 'y' : 'ies'}`;
      outcome.currentProgress = `${progressValue} learning activit${progressValue === 1 ? 'y' : 'ies'}`;
    }
    return outcome;
  }

  static getLearningActivityDisplay(contentType, contents, omitContentType, disableLink) {
    if (!contentType) {
      return null;
    }

    /** If we have multiple pieces of content, display them all */
    if (contents?.length > 0) {
      return contents.map((content) => {
        const { id, title, text, type, meta, permalink } = content;

        let typeString = '';

        const typeValue = meta?.type || type;

        if (contentType === 'none') {
          typeString = 'Learner Decides';
        } else if (typeValue) {
          const str = `${typeValue}`;
          typeString = str.replace('_', ' ');
        }

        const link = `/browse/${permalink}`;

        return (
          <p key={id}>
            <AddLink to={disableLink ? undefined : link} title={title || text}>
              <If condition={!omitContentType}>{FormatUtil.ucwords(typeString)} | </If>
              {title || text} <ChevronRight classes="ml-4 w-4 h-4 inline-block" />
            </AddLink>
          </p>
        );
      });
    }
    return null;
  }

  static getParticipantsDisplay(type, participants, team) {
    if (!type || !team) {
      return null;
    }

    // If the type is team, and there's only one participant and it's the same as the team ID, display 'Entire Team'
    if (type === 'team' && participants && participants.length === 1 && (participants[0].id === team.id || participants[0].value === team.id)) {
      return 'Entire team';
    }

    if (participants && participants.length) {
      const participantType = type === 'team' || type === 'group' ? 'groups' : 'users';
      return participants.length > 1 ? `${participants.length} ${participantType}` : participants[0].text || participants[0].name;
    }

    return null;
  }

  static TransformRecommendationsData(data) {
    if (!data || !data.length) {
      return [];
    }
    return data.map((hit) => {
      const prepped = SearchUtil.transformCatalogHit(hit);
      prepped.hit = hit;
      return prepped;
    });
  }

  static getStartDateDetails(date) {
    return date ? moment(date).format('MM/DD/YYYY') : 'Today';
  }

  static getIntervalBreakdown = (interval) => {
    if (!interval) {
      return {};
    }
    const intervalWithoutPeriod = interval.slice(1);
    return {
      increment: intervalWithoutPeriod.slice(intervalWithoutPeriod.length - 1),
      count: intervalWithoutPeriod.slice(0, intervalWithoutPeriod.length - 1),
    };
  };

  static getDeadlineDetails(dueType, dueDate, dueInterval) {
    if (!dueType) {
      return null;
    }
    if (dueType === 'date' && !!dueDate) {
      return moment(dueDate).format('MM/DD/YYYY');
    }
    if (dueType === 'recurring' && dueInterval) {
      const incrementObj = GoalsUtil.getIntervalBreakdown(dueInterval);
      const increment = incrementObj.increment === 'W' ? 'week' : 'month';
      return `Recurring every ${incrementObj.count} ${FormatUtil.pluralize(1 * incrementObj.count, increment)}`;
    }
    return 'None';
  }

  static getPracticeTestDetails(goalData) {
    if (goalData.certification_test) {
      return `Practice Test: ${goalData.certification_test.text || goalData.certification_test.title}`;
    }
    return null;
  }

  static redirectToGoal(goal, navigate) {
    // If no content type, this goal is a 'learner decides' Send user to My Enrollments
    if (!goal || goal.content_type === 'none' || !goal.contents) {
      navigate('/enrollments');
    } else if (goal.content_type === 'path') {
      // If this is a path, send them to the goal path details page
      const enrollmentId = goal.contents[0].enrollment_id ? goal.contents[0].enrollment_id : 'unstarted';
      navigate(`/goals/${enrollmentId}/content/${goal.contents[0].id}`);
    } else if (goal.content_type === 'activity' && goal.progress && goal.progress.percent_completed) {
      // if the goal is a single activity and we have progress launch immersive
      navigate(`/enrollments-launcher?cache=1&launch=${goal.contents[0].enrollment_id}`);
    } else if (goal.content_type === 'remediation') {
      const contentToStart = goal.contents.filter((content) => !content.completed_at)[0];
      if (!contentToStart) {
        // Send them to browse page of first item if they are all done (this should never happen)
        navigate(`/browse/${goal.contents[0].permalink}`);
        return;
      }

      if (contentToStart.enrollment_id) {
        navigate(`/enrollments-launcher?cache=1&launch=${contentToStart.enrollment_id}`);
      } else {
        navigate(`/browse/${contentToStart.permalink}`);
      }
    } else {
      // Otherwise, send them to the catalog page for the assigned content
      navigate(`/browse/${goal.contents[0].permalink}`);
    }
  }

  static continueLearning(goal, goalsStore, navigate) {
    if (goal) {
      // Check if we have content details
      if (goal.content_type && goal.contents && goal.contents.length) {
        GoalsUtil.redirectToGoal(goal, navigate);
      } else {
        // We don't have content details (user clicked continue learning on tile). We need to get the full goal details first
        goalsStore.getGoalData(goal.id).then(() => {
          const { goalData } = goalsStore;
          GoalsUtil.redirectToGoal(goalData.data, navigate);
        });
      }
    }
  }

  static getNonBaselineGoals(goals) {
    if (goals && Array.isArray(goals)) {
      return goals?.filter((userGoal) => !userGoal.outcome_type.includes('baselining'));
    }
    return null;
  }
}
