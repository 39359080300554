import React, { useMemo } from 'react';
import { Fade } from 'react-awesome-reveal';
import WidgetContainer from '../Container/WidgetContainer';
import Header from '../Header/Header';
import { useCareerPrograms } from '../../providers/CareerProgramsProvider';
import If from '../If/If';
import CircularProgress from '../ProgressBar/CircularProgress';
import Button from '../Button/Button';
import { useProfileContext } from '../../providers/ProfileProvider';
import CareerProgramWidgetLoadingText from './CareerProgramWidgetLoadingText';
import { CONTENT_TYPES } from '../../constants';

/**
 * Displays a single row in the study guide widget
 * @param {object} badge - the badge to display
 * @param {number} index - the index of the badge in the list
 * @returns <StudyGuideRow /> the study guide row
 * @example
 * <StudyGuideRow badge={badge} index={index} />
 */
function StudyGuideRow({ badge, index }) {
  if (!badge) return null;
  const title = `${index}. ${badge.study_guide_title || 'Learn'}:`;
  const description = badge.study_guide_description || 'Complete this badge to unlock the next step in your career program';
  return (
    <li key={badge.id} className="group flex justify-between items-center py-3 first:pt-0 space-x-6">
      <span>
        <span className="pr-2 font-bold">{title}</span>
        {description}
      </span>
      <span className="shrink-0 w-16 h-16">
        <div className="absolute w-16 h-32 group-last:h-0">
          <div className="absolute inset-0 m-auto w-1 h-full bg-gray-400" />
        </div>

        <CircularProgress percentageValue={badge.progress} classes="absolute w-16 h-16 bg-[#FBFBFB]" strokeWidth={15}>
          {badge.progress || 0}%
        </CircularProgress>
      </span>
    </li>
  );
}

/**
 * Takes requirements for a career program and displays them in a widget with progress
 * Required one of contentDescriptionId or contentPermalink
 * @returns <CareerProgramStudyGuideWidget /> the study guide widget
 */
function CareerProgramStudyGuideWidget() {
  const { badges } = useProfileContext();
  const {
    careerProgramBrowseDataError,
    isLoadingCareerProgramBrowseData,
    isLoadingCurrentProgram,
    claimCredlyBadge,
    currentProgramEnrollment,
    claimCredlyBadgeError,
    isClaimingCredlyBadge,
    currentPageProgram: program,
    isUserEnrolledInCurrentPageProgram,
  } = useCareerPrograms();
  const isLoading = isLoadingCareerProgramBrowseData[program?.url] || isLoadingCurrentProgram;
  const error = careerProgramBrowseDataError[program?.url];
  const requiredBadgeIds = program?.content_item?.requirement_badge_ids || [];
  const credlyBadgeUrl = currentProgramEnrollment?.credly_badge?.view_badge_url;

  const isCertPrepProgram = program?.content_type?.id === CONTENT_TYPES.CERT_PREP_PROGRAM;
  const certExamLink = useMemo(
    () => program?.content_item?.children?.find((child) => child.content_type.id === CONTENT_TYPES.CERTIFICATION_PREP)?.content_item?.meta?.examLink,
    [program]
  );

  const isProgramComplete = Boolean(currentProgramEnrollment?.completed_at);

  return (
    <WidgetContainer secondaryWidget>
      <Header as="h3" className="pb-2 border-b border-b-gray-400">
        Study Guide
      </Header>
      {/** Loading */}
      <If condition={isLoading && !error}>
        <CareerProgramWidgetLoadingText />
      </If>
      {/** Error */}
      <If condition={!isLoading && error}>
        <p>We had an error while loading this programs requirements. Please try again later.</p>
      </If>
      {/** Empty */}
      <If condition={!isLoading && !error && !requiredBadgeIds?.length}>
        <p>We had some trouble loading this programs requirements. Please try again later.</p>
      </If>
      {/** Display the Study Guide */}
      <If condition={!isLoading && !error && requiredBadgeIds?.length}>
        <Fade triggerOnce>
          {/** Show each Required Badge as its own line item in an ordered list */}
          <ol className="px-3">
            {/** Loop requirement_badge_ids because its guaranteed to be in order from the backend */}
            {requiredBadgeIds?.map((badgeId, i) => {
              const badge = badges?.find((b) => b.id === badgeId);
              if (!badge) return null;
              return <StudyGuideRow badge={badge} index={i + 1} key={badge?.id} />;
            })}
          </ol>
          <div className="flex justify-center">
            {/** Career / Skill Programs */}
            <If condition={!isCertPrepProgram}>
              {/** Error State */}
              <If condition={claimCredlyBadgeError}>
                <p className="text-red-500">There was an error claiming your credential. Please try again later.</p>
              </If>
              <If condition={!claimCredlyBadgeError}>
                {/** Claimed State */}
                <If condition={credlyBadgeUrl}>
                  <Button color="pink" icon={{ name: 'arrow-right', position: 'right' }} href={credlyBadgeUrl} target="_blank">
                    View Your Credly Credential
                  </Button>
                </If>
                {/** Unclaimed State (In progress/Ready to claim) */}
                <If condition={!credlyBadgeUrl}>
                  <Button
                    disabled={!isProgramComplete || !isUserEnrolledInCurrentPageProgram}
                    color={isUserEnrolledInCurrentPageProgram ? 'pink' : 'medium-gray'}
                    icon={{ name: isProgramComplete ? 'arrow-right' : 'lock', position: 'right' }}
                    className="justify-center items-center py-0 px-1 w-72 h-11"
                    loading={isClaimingCredlyBadge}
                    loadingProps={{ wrapperClassName: 'flex items-center py-0' }}
                    onClick={claimCredlyBadge}
                    target="_blank"
                  >
                    <If condition={isUserEnrolledInCurrentPageProgram}>Claim Your Credential at Credly</If>
                    <If condition={!isUserEnrolledInCurrentPageProgram}>Enroll to View</If>
                  </Button>
                </If>
              </If>
            </If>
            {/** Certification Prep Programs */}
            <If condition={isCertPrepProgram && certExamLink}>
              <Button color="pink" icon={{ name: 'arrow-right', position: 'right' }} href={certExamLink} target="_blank">
                Take the Official Exam
              </Button>
            </If>
          </div>
        </Fade>
      </If>
    </WidgetContainer>
  );
}

export default CareerProgramStudyGuideWidget;
