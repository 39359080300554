import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import OnboardingModal from './OnboardingModal';
import OnboardingProvider from '../../providers/OnboardingProvider';

const OnboardingPage = inject('commonStore')(
  observer(({ commonStore }) => {
    useEffect(() => {
      commonStore.hidePrimaryNav();
      commonStore.setHideFooter(true);
      return () => {
        commonStore.showPrimaryNav();
        commonStore.setHideFooter(false);
      };
    }, []);
    return (
      <OnboardingProvider>
        {/** This style allows the business demo form to fit the page */}
        <style>
          {`.cyb-router {
                height: 100vh !important;
            }`}
        </style>
        <OnboardingModal inline />
      </OnboardingProvider>
    );
  })
);

export default OnboardingPage;
