import React from 'react';
import Vimeo from '@u-wave/react-vimeo';

import Icon from '../../components/Icon/Icon';
import AddLink from '../../components/AddLink/AddLink';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';

function IconText({ text }) {
  if (!text) return null;
  return (
    <div className="flex items-center mb-5">
      <Icon name="checkmark-circle" className="w-5 h-5 text-cyb-pink-500" active />
      <p className="pl-3 text-base font-normal md:text-xl">{text}</p>
    </div>
  );
}

function WelcomeToTeams() {
  return (
    <div className="flex flex-col gap-8 items-center mx-4 mt-8 md:mx-9 md:w-full lg:flex-row">
      <div id="welcome-to-cybrary-video" className="w-full rounded-lg lg:w-[54%]">
        {/* This video Id  belongs to the marketing video */}
        <Vimeo video={836657843} responsive speed />
      </div>
      <div id="welcome-to-team-container" className="flex flex-col items-center p-4 w-full rounded-lg border-1 border-cyb-pink-500 md:py-4 md:px-8 lg:w-[38%]">
        <h1 className="mb-2.5 text-xl font-bold md:mb-5 md:text-3xl">Welcome to Cybrary for Teams</h1>
        <p className="mb-7 text-base italic font-normal md:text-xl">
          We encourage you to explore the platform with your free account. Please note that it&apos;s a limited experience, here&apos;s what you can do with a paid account:
        </p>
        <div className="mb-3">
          <IconText text="Build targeted learning with custom skill paths" />
          <IconText text="Measure results with comprehensive reporting" />
          <IconText text="Find skill gaps with unlimited assessments" />
          <IconText text="Access exclusive advanced content for teams" />
        </div>
        <h2 className="mb-2.5 text-lg font-bold md:mb-5 md:text-xl">Schedule a Demo with a Product Specialist now!</h2>
        <AddLink
          className="flex justify-center py-4 w-full text-xl font-bold text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-lg"
          to="https://www.cybrary.it/demo-request"
          onClick={() => {
            trackSnowplowEvent({ category: 'WelcomeToTeamCTA', action: 'click', label: 'requestDemoButton' });
          }}
          target="_blank"
          rel="noreferrer"
        >
          Book a Demo
        </AddLink>{' '}
      </div>
    </div>
  );
}

export default WelcomeToTeams;
