import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import FormatUtil from '../../utils/formatUtil';

// Data is expected to be an array of objects, each object represents a single line, with an "id" and a data array. The data array
// for each line contains objects with two properties - x and y.

function LineChart({
  data,
  tooltip = null,
  axisLeftFormatter = null,
  stacked = true,
  yMin = 0,
  legends = false,
  maxLegendLength = 10,
  onClick = null,
  colorScheme = 'category10',
  areaOpacity = 0.2,
  markers = null,
}) {
  const tooltipProps = tooltip ? { tooltip } : {};
  const axisLeftFormatterProps = axisLeftFormatter
    ? {
        format: axisLeftFormatter,
      }
    : {};
  const yScale = stacked
    ? {
        yScale: {
          type: 'linear',
          min: yMin,
          max: 'auto',
          stacked: true,
          reverse: false,
        },
      }
    : {};
  const legendsProps = legends
    ? {
        legendLabel: (legendData) => FormatUtil.formatLongText(legendData.id, maxLegendLength),
        legends: [
          {
            dataFrom: 'keys',
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 60,
            itemsSpacing: 2,
            itemWidth: 138,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ],
        margin: { top: 20, right: 20, bottom: 80, left: 40 },
      }
    : {
        margin: { top: 50, right: 60, bottom: 50, left: 60 },
      };
  return (
    <ResponsiveLine
      data={data}
      xScale={{ type: 'point' }}
      {...yScale}
      yFormat=" >-.2f"
      axisTop={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        truncateTickAt: 0,
        ...axisLeftFormatterProps,
      }}
      axisRight={null}
      colors={{ scheme: colorScheme }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabel="data.yFormatted"
      pointLabelYOffset={-12}
      enableArea
      areaOpacity={areaOpacity}
      enableTouchCrosshair
      useMesh
      onClick={onClick}
      curve="monotoneX"
      markers={markers}
      {...tooltipProps}
      {...legendsProps}
    />
  );
}

export default LineChart;
