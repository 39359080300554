import React from 'react';
import { useContinueLearningContext } from '../../providers/ContinueLearningProvider';
import Loading from '../Loading/Loading';
import ContinueLearningNoContent from './ContinueLearningNoContent';
import ContinueLearningBookmarksTab from './tabs/ContinueLearningBookmarksTab';
import ContinueLearningCurrentEnrollmentTab from './tabs/ContinueLearningCurrentEnrollmentTab';
import ContinueLearningTabInProgress from './tabs/ContinueLearningInProgressTab';
import Header from '../Header/Header';
import If from '../If/If';

// Tabs to display in the continue learning block
const TABS = [
  {
    key: 'current',
    tabTitle: 'Current',
    display: <ContinueLearningCurrentEnrollmentTab />,
  },
  {
    key: 'inProgress',
    tabTitle: 'In Progress',
    display: <ContinueLearningTabInProgress />,
  },
  {
    key: 'bookmarked',
    tabTitle: 'Bookmarked',
    display: <ContinueLearningBookmarksTab />,
  },
];

const ACTIVE_CLASSES = ' border-cyb-pink-500 font-semibold text-black';
const INACTIVE_CLASSES = ' border-slate-200 cursor-pointer';

/**
 * Tabbed interface to display the current course, in progress courses, and bookmarked courses
 * @returns <ContinueLearningTabs />
 */
export default function ContinueLearningTabs() {
  const { title, activeTab, setActiveTab, isLoading } = useContinueLearningContext();
  const tab = TABS.find(({ key }) => key === activeTab);
  const display = tab?.display || <ContinueLearningNoContent />;

  return (
    <div className="w-full h-full">
      {/** Tab Navigation */}
      <div className="block px-4 pt-4 lg:flex">
        <If condition={title}>
          <Header as="h2" className="pr-8 mb-4">
            {title}
          </Header>
        </If>
        <nav className="flex grow">
          {TABS.map(({ key, tabTitle }) => (
            <button
              key={key}
              aria-pressed={activeTab === key}
              aria-label={`View ${tabTitle} content`}
              className={`inline-block text-sm px-1 w-full mb-4 border-b-2  ${activeTab === key ? ACTIVE_CLASSES : INACTIVE_CLASSES}`}
              onClick={() => setActiveTab(key)}
            >
              {tabTitle}
            </button>
          ))}
        </nav>
      </div>
      {/** Tab Content */}
      {isLoading ? <Loading message="Loading your learning..." wrapperClassName="w-full h-40" /> : display}
    </div>
  );
}
