import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import withRouter from '../../components/Router/withRouter';
import Title from '../../components/Title/Title';
import MinimalistConversationLinks from '../../components/ConversationLinks/MinimalistConversationLinks';
import FeaturesSummary from '../../components/Analytics/FeaturesSummary';
import Container from '../../components/Container/Container';
import FormatUtil from '../../utils/formatUtil';
import './reports.css';

const Reporting = inject(
  'commonStore',
  'reportsStore',
  'userStore',
  'authStore',
  'adminStore'
)(
  observer(
    class Reporting extends Component {
      state = {
        orgId: null,
        view: this.props.match.params.section || 'user',
      };

      componentDidMount() {
        const isDemo = this.props.match.params.orgId === 'demo';
        if (isDemo) {
          this.props.userStore.enterDemo();
        } else {
          this.props.userStore.setPreferredTeamInit('', this.props.match.params.orgId, this.init);
        }
        this.props.authStore.fireAttributionEvent();
        this.setNewPageTitle(this.state.view, isDemo);
      }

      componentDidUpdate(prevProps) {
        this.props.userStore.setPreferredTeamInit(prevProps.match.params.orgId, this.props.match.params.orgId, this.init);
        this.checkUpdateView();
      }

      componentWillUnmount() {
        const isDemo = this.props.match.params.orgId === 'demo';
        if (isDemo) {
          this.props.userStore.exitDemo();
        }
      }

      checkUpdateView = () => {
        const { section } = this.props.match.params;
        const newState = {
          ...this.state,
          view: section || 'user',
        };
        if (this.state.view !== newState.view) {
          this.setNewPageTitle(newState.view);
          this.setState(newState);
        }
      };

      setNewPageTitle = (section) => {
        const pageSection = section === 'tools' ? 'Tools' : `${section || ''} Analytics`;
        const formattedPageSection = pageSection ? FormatUtil.ucwords(pageSection) : '';
        return this.props.commonStore.setPageTitle(`${formattedPageSection} | Cybrary`);
      };

      init = (orgId) => {
        const newState = {
          ...this.state,
          orgId,
        };
        if (/reporting\/content$/.test(window.location.pathname)) {
          newState.view = 'content';
        } else if (/reporting\/tools$/.test(window.location.pathname)) {
          newState.view = 'tools';
        }
        this.setState(newState, () => {
          this.checkUpdateView();
        });
      };

      getHeader = (view) => {
        if (view === 'user') {
          return 'User Analytics';
        }

        if (view === 'content') {
          return 'Content Analytics';
        }

        return 'Tools';
      };

      render() {
        const { team } = this.props.userStore;
        if (!team?.package_types?.slice().length && !this.props.adminStore?.larping?.teamId) {
          return <FeaturesSummary featureType="reporting" />;
        }
        const { view } = this.state;
        // disabled all links while in report view
        const isDemo = this.props.match.params.orgId === 'demo';
        const reportLinks = this.props.reportsStore.getReportLinks(view, team).map((link) => {
          return { ...link, disabled: isDemo };
        });
        const header = this.getHeader(view);
        return (
          <Container size="md">
            <Title title={header} />
            <MinimalistConversationLinks data={reportLinks} />
          </Container>
        );
      }
    }
  )
);

export default withRouter(Reporting);
