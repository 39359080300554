import React, { useState, useEffect, useRef } from 'react';
import DropdownFilter from '../../Dropdown/DropdownFilter';
import ReportHeadingTooltip from '../../Enterprise/ReportHeadingTooltip';
import LeaderboardTable from './LeaderboardTable';
import '../analytics.css';

function Leaderboard({ filterChange, name, filters, filterValue, formatColumns, orgId, loading, error, heading, headingTooltip, data, ignoredCols, href }) {
  const [ariaLive, setAriaLive] = useState('off');
  const placementRefs = useRef([]);
  const tableRef = useRef(null);

  const setPlacementRef = (ref) => {
    if (ref) {
      placementRefs.current.push(ref);
    }
  };

  const setLeaderboardFocus = () => {
    setTimeout(() => {
      if (tableRef.current) {
        tableRef.current.focus();
      }
    }, 300);
  };

  const setPlacements = () => {
    if (placementRefs.current) {
      let delay = 100;
      placementRefs.current.forEach((ref) => {
        delay += 100;
        setTimeout(() => {
          ref.classList.remove('hidden');
        }, delay);
      });
    }
  };

  const changeFilter = ({ value }) => {
    filterChange(name, value, setLeaderboardFocus);
    setAriaLive('polite');
  };

  // handles setting placements on mount and when it updates..
  useEffect(() => {
    setPlacements();
  }, [placementRefs.current]);

  const isDemo = orgId === 'demo';
  return (
    <div className="py-4 leaderboard-container">
      <div className="print:flex print:justify-between print:items-center sm:flex sm:justify-between sm:items-center">
        <div className="flex items-center">
          {heading && (
            <h2 className="float-left mr-2 text-lg font-black" ref={tableRef.current} tabIndex="-1">
              {heading}
            </h2>
          )}
          {headingTooltip && <ReportHeadingTooltip iconClass="mt-2" />}
        </div>
        <div className="flex items-center mt-4 sm:justify-end sm:mt-0">
          Sort:
          <div className="inline-block ml-4 w-60 text-left">
            <DropdownFilter
              name={name}
              options={filters}
              value={filterValue}
              onChange={changeFilter}
              placeholder="Filter"
              ariaLabel="Change leaderboard metric"
              isSearchable
              disabled={isDemo}
            />
          </div>
        </div>
      </div>
      <LeaderboardTable
        data={data}
        name={name}
        formatColumns={formatColumns}
        filter={filterValue}
        loading={loading}
        error={error}
        orgId={orgId}
        heading={heading}
        ignoredCols={ignoredCols}
        ariaLive={ariaLive}
        filterValue={filterValue}
        href={href}
        setPlacementRef={setPlacementRef}
      />
    </div>
  );
}
export default Leaderboard;
