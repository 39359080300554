/**
 * @why - this is a helper function to format the labels for the radar chart in the event they get cut off
 * @param {array} labels -  array of strings
 * @param {number} threshold - number of spaces in a string to trigger the label to be split into an array of strings
 * returns array of strings with the first letter capitalized i.e.[["My", "long", "long", "long", "label"], "another label",...],
 * @example
 * "My long long label"...will look like
 * My
 * long
 * long
 * label
 *  ...in the UI
 */
export function LabelTransformer(labels = [], threshold = 1) {
  return labels.map((label) => {
    const splitLabel = label.split(' ');
    const numSpaces = splitLabel.length - 1;
    /** our threshold is 1 space. If more then one space return an array of string, else return the original string */
    if (numSpaces > threshold) {
      return splitLabel;
    }
    return label;
  });
}
