import React from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * Error Message Component
 * @param containerClasses - Error Message Container classes
 * @param message - allows you to pass in a custom error message
 * @param errorMessageClasses - allows you to pass in custom error message classes
 * @param children - allows you to override the default error message
 */
function ErrorMessage({ containerClasses = '', message = '', errorMessageClasses = '', children }) {
  const defaultErrorContainerStyles = 'p-8 mx-8 bg-red-300 rounded-md border-1 border-red-500';
  return (
    <div className={twMerge(defaultErrorContainerStyles, containerClasses)}>
      {message && <p className={errorMessageClasses}>{message}</p>}
      {/* Default Error Message */}
      {!message && !children && (
        <p>
          Oh no, something went wrong! Please try refreshing your page, if that does not work please reach out to
          <a href="mailto:support@cybrary.it" className="underline">
            {' '}
            support@cybrary.it
          </a>
        </p>
      )}
      {children}
    </div>
  );
}
export default ErrorMessage;
