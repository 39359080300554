import React, { useEffect, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import Icon from '../Icon/Icon';
import AddLink from '../AddLink/AddLink';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import GaUtil from '../../utils/gaUtil';
import AuthUtil from '../../utils/authUtil';
import If from '../If/If';
import { HELP_DESK_LINKS, USERPILOT_EVENTS } from '../../constants';

/**
 * Cyb colored gradient banner container
 * @param {*} param0
 * @returns
 */
function GradientBanner({ className, children }) {
  const classes = useMemo(
    () => twMerge(`md:flex text-center items-center p-3 mb-4 mx-auto w-full text-white bg-gradient-to-r from-[#CF0469] to-[#25135F] font-bold`, className),
    [className]
  );
  return <div className={classes}>{children}</div>;
}

/**
 * Determines what copy is shown based on the user goal
 * @param {String} goal user goal chosen during onboarding
 * @returns {BannerContent} { title: "", body: "" } object containing the content to show
 */
function getBannerContent(goal) {
  switch (goal) {
    case 'Launch My Career':
      return {
        title: 'Enroll In a Career Path',
        body: 'Take the next step in your cybersecurity career and sign up for Cybrary Insider Pro to unlock access to all of our career paths.',
      };
    case 'Get a Certification':
      return {
        title: 'Confidently Pass The Exam',
        body: 'Practice exams can help you improve your test scores by over 25%. Sign up for Cybrary Insider Pro and get unlimited access to practice exams and labs.',
      };
    case 'Upskill & Practice':
      return {
        title: 'Get Your Dream Role',
        body: 'Sign up for Cybrary Insider Pro to master the hands-on skills that security leaders are hiring for.',
      };
    default:
      return {
        title: `Prove you're ready for the job`,
        body: `Upgrade to Cybrary Insider Pro and unlock access to practice tests that'll help you ace your certifications, virtual labs to help you learn and practice the hands-on
      skills employers are hiring for, career paths to help you build role-specific knowledge and skills, and much more.`,
      };
  }
}
/** CIP upgrade banner shown when a user selects "Here for myself" during the onboarding flow
 * Shown on the LIHP and in immersive.
 */
const CIPUpgradeBanner = inject(
  'userStore',
  'profileStore'
)(
  observer(({ userStore, profileStore, className }) => {
    const navigate = useNavigate();
    const isNewReleasePromoEnabled = false; // Hardcode this value for now. Eventually this should live in contentful so it can be toggled on/off
    const isEduGovMilPromoEnabled = false; // Hardcode this value for now. Eventually this should live in contentful so it can be toggled on/off

    useEffect(() => {
      profileStore.getUserAccounts();
    }, []);

    const { isEnterprise, isCip, user } = userStore;
    const { goal, membershipFor } = user?.onboarding_data || {};
    const content = getBannerContent(goal);
    // remember that a user can be free but also enterprise... (on a team but has no license)
    // we want to show for all free users who aren't cip or enterprise users
    const shouldNotShow = (isCip || isEnterprise || membershipFor === 'team') && !isNewReleasePromoEnabled;
    if (shouldNotShow) {
      return null;
    }

    const { data: accounts } = profileStore?.profileData?.accounts || {};
    const isEduGovMilUser = AuthUtil.isEduGovMilUser(accounts);

    const handleUpgradeClick = () => {
      const targetUrl = `${process.env.REACT_APP_V2_SITE_URL}/upgrade/`;
      trackSnowplowEvent({ category: 'CIPUpgradeBanner', action: 'click', label: 'upgradeButton', property: goal });
      GaUtil.fireEvent('CIPUpgradeBanner', 'click', targetUrl);
      navigate(targetUrl);
    };

    const handleAddEmailClick = () => {
      trackSnowplowEvent({ category: 'CIPUpgradeBanner', action: 'click', label: 'addEmailButtonEduMilGov' });
      navigate(`/settings/account?settings=1`);
      Userpilot.track(USERPILOT_EVENTS.CIP_BANNER_CLICKED_ADD_EMAIL);
    };

    const handleEduMilGovUpgradeClick = () => {
      trackSnowplowEvent({ category: 'CIPUpgradeBanner', action: 'click', label: 'upgradeButtonEduMilGov' });
      navigate(`/upgrade/checkout`);
      Userpilot.track(USERPILOT_EVENTS.CIP_BANNER_CLICKED_UPGRADE);
    };

    /** New release banner */
    if (isNewReleasePromoEnabled) {
      return (
        <GradientBanner className={className}>
          <div className="grow px-6 text-2xl">
            <div>What’s new at Cybrary</div>
          </div>
          <AddLink className="flex p-3 px-6 mx-auto mt-2 text-black bg-slate-100 hover:bg-slate-200 rounded-md md:mt-0" to="https://www.cybrary.it/announcement" target="_blank">
            Check it out <Icon name="sparkler" className="ml-1 w-5 h-5 fill-current" />
          </AddLink>
        </GradientBanner>
      );
    }

    /** EDU MIL GOV Banner */
    if (isEduGovMilPromoEnabled) {
      return (
        <GradientBanner className={className}>
          {/** Don't show Add Email for user's who already qualify */}
          <If condition={!isEduGovMilUser}>
            <AddLink className="flex shrink-0 p-3 px-6 mx-auto mt-2 text-black bg-slate-100 hover:bg-slate-200 rounded-md md:mt-0" onClick={handleAddEmailClick}>
              Add Your Email
            </AddLink>
          </If>
          <div className="grow px-6 text-lg">
            <div>
              Empower your future with exclusive savings for student, military and government employees!
              <br />
              <span className="border-b-2">Save 50%</span> on your Cybrary Insider Pro subscription today!
            </div>
            <If condition={HELP_DESK_LINKS.EDU_MIL_GOV_UPGRADE}>
              <AddLink to={HELP_DESK_LINKS.EDU_MIL_GOV_UPGRADE} className="py-0 text-xs italic font-normal">
                Learn more about our .edu, .mil, and .gov discount program &gt;
              </AddLink>
            </If>
          </div>
          <AddLink className="flex p-3 px-6 mx-auto mt-2 text-black bg-slate-100 hover:bg-slate-200 rounded-md md:mt-0" onClick={handleEduMilGovUpgradeClick}>
            Upgrade <Icon name="sparkler" className="ml-1 w-5 h-5 fill-current" />
          </AddLink>
        </GradientBanner>
      );
    }

    /** Default CIP Upgrade Banner - Based on Onboarding choices */
    return (
      <GradientBanner className={className}>
        <div className="min-w-[175px] text-[24px] font-black">{content.title}</div>
        <div className="grow px-6 text-lg">{content.body}</div>
        <AddLink className="flex p-3 px-6 mx-auto mt-2 text-black bg-slate-100 hover:bg-slate-200 rounded-md md:mt-0 md:mr-4" onClick={handleUpgradeClick}>
          Upgrade <Icon name="sparkler" className="ml-1 w-5 h-5 fill-current" />
        </AddLink>
      </GradientBanner>
    );
  })
);
export default CIPUpgradeBanner;
