import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/UseAuth';
import Container from '../../components/Container/Container';
import CodeInput from '../../components/Login/RegisterCode';
import LoginLinks from '../../components/Login/LoginLinks';
import AddLink from '../../components/AddLink/AddLink';
import StatusMessage from '../../components/Login/StatusMessage';
import { bootPendo } from '../../utils/pendoUtil';
import agents from '../../agents/agents';

const EMAIL_REGEX = /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;
const RegisterCode = inject('commonStore')(
  observer(({ commonStore }) => {
    const params = queryString.parse(window.location.search, { decode: false });
    const { email, newPurchase } = params;
    // to avoid text-injection attacks we must extract a valid email from the query param
    // and only show the first matched email, if any.
    const matchedEmails = email.match(EMAIL_REGEX) || []; // returns array of matched emails from query param i.e. ['user@cybrary.it']
    const safeEmail = matchedEmails[0];
    const [submitting, setSubmitting] = useState(false);
    const [status, setStatus] = useState(null);
    const { submitRegisterCode, loginWithCustomToken } = useAuth();

    useEffect(() => {
      commonStore.setPageTitle('Verify Account | Cybrary');
      // Pendo - Boot anonymously
      bootPendo();
    }, []);

    if (!safeEmail) {
      return <Navigate replace to="/login" />;
    }

    const handleResendEmail = async () => {
      try {
        await agents.authGoogle.verifyEmail({ email: safeEmail, verificationMethod: 'code' });
        setStatus({
          type: 'success',
          message: (
            <div>
              <p className="mb-0 font-semibold">Verification Email Sent</p>
              <p>We just sent a verification email to {safeEmail}. Please check your email and verify your account to continue.</p>
            </div>
          ),
        });
      } catch (e) {
        setStatus({ type: 'error', code: 'resendRegisterCode' });
      }
    };

    const onCodeSubmit = async (data) => {
      setStatus(null);
      setSubmitting(true);
      const { code } = data;
      // Submit the provided code and user's email to the server for verification
      try {
        const authToken = await submitRegisterCode({ email: safeEmail, code });
        // If successful, login with the custom token returned from the server
        const { googleToken } = authToken;
        loginWithCustomToken(googleToken);
      } catch (e) {
        if ((e.response && e.response.status === 401) || e.code === 401) {
          setStatus({
            type: 'error',
            message: (
              <div>
                Invalid code, you can resend your verification email by clicking{' '}
                <button type="button" className="text-black hover:text-cyb-pink-500 underline" aria-label="Resend verification email" onClick={handleResendEmail}>
                  here
                </button>
                . If you encounter any issues or require assistance, please contact{' '}
                <a className="text-black hover:text-cyb-pink-500 underline" href="mailto:support@cybrary.it">
                  support@cybrary.it
                </a>
                .
              </div>
            ),
          });
        } else {
          setStatus({ type: 'error', code: 'registerCodeError' });
        }
        setSubmitting(false);
      }
    };

    return (
      <Container className="py-4 mb-8 max-w-screen-xl">
        <div className="mx-auto w-full md:w-120">
          <img
            alt=""
            width="224"
            height="58"
            className="m-auto mb-8"
            src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg?w=250"
          />
          {status && <StatusMessage status={status} type={status.type} />}
          <h1 className="my-8 text-2xl font-black text-center md:text-3xl">Verify Your Account</h1>
          <div className="mb-6 text-sm text-center">
            <p className="mb-0">We&apos;ve sent a one-time code to {safeEmail}.</p>
            {/** Hide change account link for new purchases. They can't change account here, they need to verify the email on the purchase */}
            {!newPurchase && (
              <AddLink className="text-cyb-pink-500 hover:text-black underline" to="/login">
                Change account
              </AddLink>
            )}
          </div>
          <CodeInput onSubmit={onCodeSubmit} submitting={submitting} />
          <LoginLinks omitLoginLink omitForgotPasswordLink omitRegisterLink />
          <p className="text-sm text-center">
            Haven&apos;t received the code?{' '}
            <button type="button" aria-label="Resend verification email" onClick={handleResendEmail} className="text-cyb-pink-500 hover:text-black underline">
              Resend email
            </button>
          </p>
        </div>
      </Container>
    );
  })
);

export default RegisterCode;
