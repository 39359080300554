import Bugsnag from '@bugsnag/js';
import { observable, action, computed, makeObservable } from 'mobx';
import queryString from 'query-string';
import Agents from '../agents/agents';
import EnrollmentTransformer from '../transformers/enrollmentTransformer';
import EnrollmentUtil from '../utils/enrollmentUtil';
import { CONTENT_TYPES } from '../constants';

/** Builds query for fetching enrollments */
const buildEnrollmentsQuery = ({ isAssigned = 0, isActivity = 0, isRoot = 1, contentTypeIds = [] }) => {
  // if isActivity is -1, we don't want to include it in the query
  const isActivityQuery = isActivity === -1 ? '' : `&isActivity=${isActivity}`;
  // if isAssigned is -1, we don't want to include it in the query
  const isAssignedQuery = isAssigned === -1 ? '' : `&isAssigned=${isAssigned}`;
  // if isRoot is -1, we don't want to include it in the query
  const isRootQuery = isRoot === -1 ? '' : `&isRoot=${isRoot}`;
  let query = `?${isActivityQuery}${isAssignedQuery}${isRootQuery}`;
  // add each contentTypeIds to the query as contentTypeIds[]=8
  contentTypeIds.forEach((contentId) => {
    query += `&contentTypeIds[]=${contentId}`;
  });
  return query;
};

class EnrollmentStore {
  loading = true;

  loadingTimeline = true;

  loadingCompletedEnrollments = true;

  error = false;

  enrollments = null;

  activeEnrollmentData = {};

  freshEnrollmentId = null; // if we have just enrolled, the id will be contained here

  displayEnrollmentForm = false;

  displayOnboardingModal = false;

  enrollmentErrors = null;

  enrollmentsResponse = null;

  completedEnrollments = null;

  useActiveEnrollment = false; // a flag to avoid round trips when we have an active enrollment

  // Track a map of content description ids to progress, for displaying progress throughout the app
  enrollmentProgressMap = null;

  enrollmentData = null;

  constructor() {
    makeObservable(this, {
      loading: observable,
      loadingTimeline: observable,
      loadingCompletedEnrollments: observable,
      error: observable,
      enrollments: observable,
      activeEnrollmentData: observable,
      freshEnrollmentId: observable,
      displayEnrollmentForm: observable,
      displayOnboardingModal: observable,
      enrollmentErrors: observable,
      enrollmentsResponse: observable,
      completedEnrollments: observable,
      useActiveEnrollment: observable,
      enrollmentProgressMap: observable,
      enrollmentData: observable,
      assignments: computed,
      setUseActiveEnrollmentFlag: action,
      setLoading: action,
      setLoadingTimeline: action,
      setLoadingCompletedEnrollments: action,
      setInitialized: action,
      setEnrollmentErrors: action,
      setFreshEnrollmentId: action,
      clearEnrollmentErrorsForKey: action,
      setEnrollmentsResponse: action,
      setError: action,
      setEnrollments: action,
      setEnrollmentProgressMap: action,
      setCompletedEnrollments: action,
      updateEnrollment: action,
      setEnrollmentSettings: action,
      updateSettingsForEnrollment: action,
      updateStatusForEnrollment: action,
      reset: action,
      setActiveEnrollmentData: action,
      startEnrollment: action,
      pauseEnrollment: action,
      resumeEnrollment: action,
      stopEnrollment: action,
      getCompletedEnrollments: action,
      setEnrollmentListLoading: action,
      setEnrollmentListError: action,
      setEnrollmentList: action,
      enrollmentList: observable,
      setDefaultEnrollmentList: action,
      getEnrollmentList: action,
      setEnrollmentTableLoading: action,
      setEnrollmentTableError: action,
      setEnrollmentTable: action,
      setEnrollmentTableQueryParams: action,
      setEnrollmentTableFilter: action,
      enrollmentTable: observable,
      setDefaultEnrollmentTable: action,
      getEnrollmentTable: action,
      setWorkroleDataLoading: action,
      setWorkroleDataError: action,
      setWorkroleData: action,
      workroleData: observable,
      getWorkroleData: action,
      unenrollFromEnrollment: action,
      getEnrollmentById: action,
      recentlyComplete: observable,
      setDefaultRecentlyComplete: action,
      setRecentlyCompleteLoading: action,
      setRecentlyCompleteError: action,
      setRecentlyCompleteData: action,
      getRecentlyComplete: action,
      fetchEnrollmentProgressMap: action,
      getEnrollmentProgressForContentDescriptionId: action,
      getEnrollmentData: action,
    });
  }

  get assignments() {
    if (!this.enrollments || (!!this.enrollment && this.enrollments.length === 0)) {
      return [];
    }
    return this.enrollments.filter((enrollment) => ['Curriculum', 'Assignment'].indexOf(enrollment.type) !== -1);
  }

  get careerPaths() {
    if (!this.enrollments || (!!this.enrollment && this.enrollments.length === 0)) {
      return [];
    }
    return this.enrollments.filter((enrollment) => ['Career Path'].indexOf(enrollment.type) !== -1);
  }

  setUseActiveEnrollmentFlag(flag) {
    this.useActiveEnrollment = flag;
  }

  getEnrollmentSettings(id) {
    return this.enrollmentSettings[id]
      ? this.enrollmentSettings[id]
      : {
          timePerWeek: 4 * 60,
          status: 'Started',
        };
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setLoadingTimeline(loading) {
    this.loadingTimeline = loading;
  }

  setLoadingCompletedEnrollments(loading) {
    this.loadingCompletedEnrollments = loading;
  }

  setInitialized(init) {
    this.initialized = init;
  }

  setEnrollmentErrors(errors) {
    this.enrollmentErrors = errors;
  }

  setFreshEnrollmentId(id) {
    this.freshEnrollmentId = id;
  }

  clearEnrollmentErrorsForKey(key) {
    if (this.enrollmentErrors && this.enrollmentErrors.errors && this.enrollmentErrors.errors[key]) {
      this.enrollmentErrors.errors[key] = undefined;
    }
  }

  setEnrollmentsResponse(response) {
    this.enrollmentsResponse = response;
  }

  setError(error) {
    this.error = error;
  }

  setEnrollments(enrollments) {
    this.enrollments = enrollments;
  }

  setEnrollmentProgressMap(map) {
    this.enrollmentProgressMap = map;
  }

  setCompletedEnrollments(completedEnrollments) {
    this.completedEnrollments = completedEnrollments;
  }

  updateEnrollment(enrollmentIndex, enrollment) {
    const newlyTransformed = EnrollmentTransformer.transformSingleEnrollment(enrollment);
    if (newlyTransformed) {
      this.enrollments[enrollmentIndex] = newlyTransformed;
    }
  }

  setEnrollmentSettings(enrollmentSettings) {
    this.enrollmentSettings = enrollmentSettings;
  }

  // Get enrollment index by enrollment id
  findEnrollmentIndexById(id) {
    if (!this.enrollments) {
      return null;
    }
    for (let i = 0; i < this.enrollments.length; i++) {
      const enrollment = this.enrollments[i];
      if (enrollment.id === id) {
        return i;
      }
    }
    return null;
  }

  // Get a completed enrollment by it's id
  findCompletedEnrollmentById(id) {
    if (!this.completedEnrollments) {
      return null;
    }
    for (let i = 0; i < this.completedEnrollments.length; i++) {
      const enrollment = this.completedEnrollments[i];
      if (enrollment.id === id) {
        return enrollment;
      }
    }
    return null;
  }

  updateSettingsForEnrollment(id, settings) {
    // Locate the enrollment by id.
    const enrollmentIndex = this.findEnrollmentIndexById(id);
    if (enrollmentIndex === null) {
      return; // no enrollment found
    }

    const existingEnrollment = this.enrollments[enrollmentIndex];

    if (!existingEnrollment) {
      return; // we couldn't find the enrollment?  Should never happen..
    }

    const enrollment = existingEnrollment._raw;

    const existingSettings = enrollment.settings;

    const replacementSettings = {
      ...existingSettings,
      ...settings,
    };

    // Optimistically let the server know to save the settings
    Agents.enrollments.saveEnrollmentSettings(id, replacementSettings);
    // Update the raw settings
    enrollment.settings = replacementSettings;
    // re-process this enrollment
    this.updateEnrollment(enrollmentIndex, enrollment);
  }

  updateStatusForEnrollment(id, status) {
    // Locate the enrollment by id.
    const enrollmentIndex = this.findEnrollmentIndexById(id);
    if (enrollmentIndex === null) {
      return; // no enrollment found
    }

    const existingEnrollment = this.enrollments[enrollmentIndex];
    const currentEnrollments = [...this.enrollments];

    if (!existingEnrollment) {
      return; // we couldn't find the enrollment?  Should never happen..
    }

    const enrollment = existingEnrollment._raw;
    // Update the raw status
    enrollment.status = status;
    if (status === 'Canceled') {
      currentEnrollments.splice(enrollmentIndex, 1);
      this.enrollments = currentEnrollments;
    } else {
      // re-process this enrollment
      this.updateEnrollment(enrollmentIndex, enrollment);
    }
  }

  reset() {
    // set all of our properties to the way their defaults, good for unmounting
    this.loading = false;
    this.enrollments = null;
    this.error = null;
  }

  processEnrollmentsData() {
    this.setEnrollments(this.enrollmentsResponse);
    this.setLoading(false);
  }

  setActiveEnrollmentData(enrollment) {
    this.activeEnrollmentData = enrollment;
  }

  setActiveEnrollDataById(id) {
    let activeEnrollment = null;
    for (let i = 0; i < this.enrollments.length; i++) {
      if (!activeEnrollment && this.enrollments[i].id === id) {
        activeEnrollment = this.enrollments[i];
      }
    }
    this.setActiveEnrollmentData(activeEnrollment);
  }

  getEnrollDataById(id) {
    let activeEnrollment = null;
    for (let i = 0; i < this.enrollments.length; i++) {
      if (this.enrollments[i].id === id) {
        activeEnrollment = this.enrollments[i];
      }
    }
    return activeEnrollment;
  }

  startEnrollment(id, data, type) {
    this.setEnrollmentErrors(null); // ensure we are starting with no enrollment errors upon a second submission.
    const newData = data || {};
    // If this is an external item or an internal assessment, need to include 'exclude_progress_before' with post data
    if (!EnrollmentUtil.isInternalActivity(type) || type === 'Assessment') {
      newData.exclude_progress_before = 'now';
    }
    return Agents.enrollments
      .startEnrollment(id, newData)
      .then((response) => {
        this.setEnrollmentErrors(null);
        if (response.status !== 'Completed') {
          this.updateStatusForEnrollment(response.id, 'Started', true);
          this.setFreshEnrollmentId(response.id);
        }
        return {
          enrollment: response,
          enrollmentId: response.id,
          status: response.status,
        };
      })
      .catch((error) => {
        Bugsnag.notify(error);
        if (error.response && error.response.data) {
          this.setEnrollmentErrors(error.response.data);
          throw error.response.data;
        } else {
          throw error;
        }
      });
  }

  pauseEnrollment(id) {
    Agents.enrollments
      .pauseEnrollment(id)
      .then(() => {
        this.updateStatusForEnrollment(id, 'Paused');
      })
      .catch((error) => {
        Bugsnag.notify(error);
      });
  }

  resumeEnrollment(id) {
    Agents.enrollments
      .resumeEnrollment(id)
      .then(() => {
        this.updateStatusForEnrollment(id, 'Started');
      })
      .catch((error) => {
        Bugsnag.notify(error);
      });
  }

  stopEnrollment(id) {
    Agents.enrollments
      .stopEnrollment(id)
      .then(() => {
        this.updateStatusForEnrollment(id, 'Canceled');
      })
      .catch((error) => {
        Bugsnag.notify(error);
      });
  }

  getCompletedEnrollments() {
    this.setLoadingCompletedEnrollments(true);
    this.fetchEnrollmentProgressMap(); // keep progress map fresh
    return Agents.enrollments
      .getCompletedEnrollments()
      .then(
        action('fetchSuccess', (response) => {
          this.setCompletedEnrollments(response);
          this.setLoadingCompletedEnrollments(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setError(error);
        })
      );
  }

  /* New enrollments display - CIP Dashboard */

  setEnrollmentListLoading(obj, loading) {
    this.enrollmentList[obj].loading = loading;
  }

  setEnrollmentListError(obj, error) {
    this.enrollmentList[obj].error = error;
  }

  setEnrollmentList(obj, data) {
    this.enrollmentList[obj].data = data;
  }

  enrollmentList = {
    assignments: {
      loading: true,
      error: false,
      data: null,
      filter: 'active',
    },
    'career-paths': {
      loading: true,
      error: false,
      data: null,
      filter: 'incomplete',
    },
    collections: {
      loading: true,
      error: false,
      data: null,
      filter: 'incomplete',
    },
    courses: {
      loading: true,
      error: false,
      data: null,
      filter: 'incomplete',
    },
    'virtual-labs': {
      loading: true,
      error: false,
      data: null,
      filter: 'incomplete',
    },
    challenges: {
      loading: true,
      error: false,
      data: null,
      filter: 'incomplete',
    },
    'cybrary-assessments': {
      loading: true,
      error: false,
      data: null,
      filter: 'incomplete',
    },
    assessments: {
      loading: true,
      error: false,
      data: null,
      filter: 'incomplete',
    },
    'practice-tests': {
      loading: true,
      error: false,
      data: null,
      filter: 'incomplete',
    },
    curriculum: {
      loading: true,
      error: false,
      data: null,
      filter: 'incomplete',
    },
  };

  setDefaultEnrollmentList() {
    this.enrollmentList = {
      assignments: {
        loading: true,
        error: false,
        data: null,
      },
      'career-paths': {
        loading: true,
        error: false,
        data: null,
      },
      collections: {
        loading: true,
        error: false,
        data: null,
      },
      courses: {
        loading: true,
        error: false,
        data: null,
      },
      'virtual-labs': {
        loading: true,
        error: false,
        data: null,
      },
      challenges: {
        loading: true,
        error: false,
        data: null,
      },
      'cybrary-assessments': {
        loading: true,
        error: false,
        data: null,
      },
      assessments: {
        loading: true,
        error: false,
        data: null,
      },
      'practice-tests': {
        loading: true,
        error: false,
        data: null,
      },
      curriculum: {
        loading: true,
        error: false,
        data: null,
      },
    };
  }

  enrollmentListConfig = {
    assignments: {
      query: '?isActivity=0',
    },
    'career-paths': {
      query: buildEnrollmentsQuery({ contentTypeIds: [6, 17] }),
    },
    collections: {
      query: buildEnrollmentsQuery({ contentTypeIds: [48] }),
    },
    courses: {
      query: buildEnrollmentsQuery({ contentTypeIds: [1, 11, 25, 35, 13] }),
    },
    'virtual-labs': {
      query: buildEnrollmentsQuery({ isRoot: 0, contentTypeIds: [12, 16, 22, 24, 26, 27, 28, 29, 30, 31, 34, 37, 38, CONTENT_TYPES.CYBRARY_LAB_COURSE] }),
    },
    challenges: {
      query: buildEnrollmentsQuery({ contentTypeIds: [CONTENT_TYPES.CHALLENGE] }),
    },
    assessments: {
      query: buildEnrollmentsQuery({ isActivity: 1, contentTypeIds: [15, 21] }),
    },
    // CASS (Cybrary Assessments) Courses
    'cybrary-assessments': {
      query: buildEnrollmentsQuery({ isRoot: 0, contentTypeIds: [46] }),
    },
    'practice-tests': {
      query: buildEnrollmentsQuery({ isActivity: 1, contentTypeIds: [8, 32, 33, 36, 14, 18] }),
    },
    curriculum: {
      query: buildEnrollmentsQuery({ contentTypeIds: [5] }),
    },
  };

  getEnrollmentList(list, filter) {
    this.setEnrollmentListLoading(list, true);
    this.setEnrollmentListError(list, false);
    let { query } = this.enrollmentListConfig[list];
    this.fetchEnrollmentProgressMap(); // keep progress map fresh
    const status = filter === 'completed' ? 1 : 0;
    query += `&isComplete=${status}`;
    if (list === 'assignments') {
      return Agents.enrollments
        .getAssignmentsList(query)
        .then(
          action('fetchSuccess', (response) => {
            this.enrollmentList[list].data = response;
            this.setEnrollmentListLoading(list, false);
          })
        )
        .catch(
          action('fetchError', (error) => {
            Bugsnag.notify(error);
            this.setEnrollmentListError(list, error.response || error);
            this.setEnrollmentListLoading(list, false);
          })
        );
    }

    return Agents.enrollments
      .getEnrollmentsList(query)
      .then(
        action('fetchSuccess', (response) => {
          this.enrollmentList[list].data = response;
          this.setEnrollmentListLoading(list, false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setEnrollmentListError(list, error.response || error);
          this.setEnrollmentListLoading(list, false);
        })
      );
  }

  retakeEnrollment = (contentDescriptionId) => {
    return Agents.enrollments.retakeEnrollment(contentDescriptionId);
  };

  getEnrollmentDetails(enrollmentId, contentDescriptionId, list, listview) {
    let query = '?includeEmpty=1';
    if (!listview) {
      query += '&format=table';
    }
    if (list === 'career-paths') {
      query += '&showAttempts=1';
    }
    if (enrollmentId) {
      return Agents.enrollments.getEnrollmentDetails(enrollmentId, query);
    }

    // We have no enrollment ID (likely a child row that hasn't been started), so just get the content items without any progress data
    return Agents.enrollments.getChildProgress(contentDescriptionId, query);
  }

  setEnrollmentTableLoading(loading) {
    this.enrollmentTable.loading = loading;
  }

  setEnrollmentTableError(error) {
    this.enrollmentTable.error = error;
  }

  setEnrollmentTable(data) {
    this.enrollmentTable.data = data;
  }

  setEnrollmentTableQueryParams(params) {
    this.enrollmentTable.queryParams = params;
  }

  setEnrollmentTableFilter(filter) {
    this.enrollmentTable.filter = filter;
  }

  enrollmentTable = {
    loading: true,
    error: false,
    data: {},
    queryParams: {
      activePg: 1,
      recordsPerPage: 20,
      sortCol: 'content_description_title',
      sortDirection: 'asc',
    },
    filter: 'incomplete',
  };

  setDefaultEnrollmentTable() {
    this.enrollmentTable = {
      loading: true,
      error: false,
      data: {},
      queryParams: {
        activePg: 1,
        recordsPerPage: 20,
        sortCol: 'content_description_title',
        sortDirection: 'asc',
      },
      filter: 'incomplete',
    };
  }

  getEnrollmentTable() {
    this.setEnrollmentTableLoading(true);
    this.setEnrollmentTableError(false);
    this.fetchEnrollmentProgressMap(); // keep progress map fresh

    let query = `?${queryString.stringify(this.enrollmentTable.queryParams)}&isActivity=1&includeEmpty=0&showScore=1&format=table`;

    const queryFilter = this.enrollmentTable.filter;
    const status = queryFilter === 'completed' ? 1 : 0;
    query = queryFilter === 'all' ? query : `${query}&isComplete=${status}`;

    return Agents.enrollments
      .getEnrollmentsList(query)
      .then(
        action('fetchSuccess', (response) => {
          this.enrollmentTable.data = response;
          this.enrollmentTable.data.pagPagesCount = Math.ceil(this.enrollmentTable.data.totalRecords / this.enrollmentTable.queryParams.recordsPerPage);
          this.setEnrollmentTableLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setEnrollmentTableError(error.response || error);
          this.setEnrollmentTableLoading(false);
        })
      );
  }

  getAllScores = (contentDescriptionId, params) => {
    const query = params ? `?${queryString.stringify(params)}` : null;
    return Agents.enrollments.getAllScores(contentDescriptionId, query);
  };

  getEnrollmentActivity = (enrollmentId) => {
    return Agents.enrollments.getEnrollmentActivity(enrollmentId);
  };

  setWorkroleDataLoading(loading) {
    this.workroleData.loading = loading;
  }

  setWorkroleDataError(error) {
    this.workroleData.error = error;
  }

  setWorkroleData(data) {
    this.workroleData.data = data;
  }

  workroleData = {
    loading: true,
    error: false,
    data: null,
  };

  getWorkroleData = (enrollmentId, scoreId) => {
    this.workroleData = {
      loading: true,
      error: false,
      data: null,
    };
    let getMethod = scoreId ? () => Agents.enrollments.getScoreById(scoreId) : null;
    if (!getMethod && !!enrollmentId) {
      getMethod = () => Agents.enrollments.getWorkroleData(enrollmentId);
    }

    if (!getMethod) {
      return null;
    }

    return getMethod()
      .then(
        action('fetchSuccess', (response) => {
          this.workroleData.data = response;
          this.workroleData.loading = false;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.workroleData.error = error.response || error;
          this.workroleData.loading = false;
        })
      );
  };

  unenrollFromEnrollment(contentId) {
    return Agents.enrollments.stopEnrollment(contentId);
  }

  getEnrollmentById(enrollId) {
    return Agents.enrollments.getEnrollmentById(enrollId);
  }

  // Helper function to grab the curriculum item by content description id.
  findCurriculumItemByContentId(enrollment, id) {
    // ensure id is always cast to an int
    const contentId = 1 * id;
    if (enrollment && enrollment.curriculumItems && enrollment.curriculumItems.length) {
      for (let i = 0; i < enrollment.curriculumItems.length; i++) {
        const item = enrollment.curriculumItems[i];

        if (item && item.curriculumItem) {
          if (item.curriculumItem.content_description && item.curriculumItem.content_description.id === contentId) {
            return item.curriculumItem;
          }
          if (item.curriculumItem && item.curriculumItem.learning_modules && item.curriculumItem.learning_modules.length) {
            for (let x = 0; x < item.curriculumItem.learning_modules.length; x++) {
              const module = item.curriculumItem.learning_modules[x];
              if (module.id === contentId) {
                return item.curriculumItem;
              }
              if (module.activities && module.activities.length) {
                for (let y = 0; y < module.activities.length; y++) {
                  const activity = module.activities[y];
                  if (activity.id === contentId) {
                    return item.curriculumItem;
                  }
                }
              }
            }
          }
        }
      }
    }
    return undefined;
  }

  recentlyComplete = {
    loading: true,
    error: false,
    data: {},
  };

  setDefaultRecentlyComplete() {
    this.recentlyComplete = {
      loading: true,
      error: false,
      data: {},
    };
  }

  setRecentlyCompleteLoading(loading) {
    this.recentlyComplete.loading = loading;
  }

  setRecentlyCompleteError(error) {
    this.recentlyComplete.error = error;
  }

  setRecentlyCompleteData(data) {
    this.recentlyComplete.data = data;
  }

  getRecentlyComplete() {
    this.setRecentlyCompleteLoading(true);
    this.setRecentlyCompleteError(false);
    this.fetchEnrollmentProgressMap(); // keep progress map fresh
    const queryParams = {
      activePg: 1,
      recordsPerPage: 5,
      isActivity: 1,
      sortCol: 'completed_at',
      sortDirection: 'desc',
      includeEmpty: 0,
      showScore: 1,
      format: 'table',
      isComplete: 1,
      isRoot: 0,
      contentTypeIds: [24, 14, 15, 18, 21, 22, 26, 27, 28, 29, 30],
    };

    const query = `?${queryString.stringify(queryParams, { arrayFormat: 'bracket' })}`;

    return Agents.enrollments
      .getEnrollmentsList(query)
      .then(
        action('fetchSuccess', (response) => {
          this.setRecentlyCompleteData(response);
          this.setRecentlyCompleteLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setRecentlyCompleteError(error.response || error);
          this.setRecentlyCompleteLoading(false);
        })
      );
  }

  // Fetch an updated list of enrollments, mapping progress to content description ids
  fetchEnrollmentProgressMap() {
    // Fetch enrollments from the server
    return Agents.enrollments
      .getEnrollmentsList(`?isRoot=1`)
      .then((response) => {
        if (response && Array.isArray(response) && response.length) {
          // Generate a new map!
          const progressMap = {};
          for (let i = 0; i < response.length; i++) {
            const item = response[i];

            if (item.content_description_id) {
              progressMap[`id_${item.content_description_id}`] = item.progress || 0;
            }
          }
          this.setEnrollmentProgressMap(progressMap);
        }
      })
      .catch((error) => {
        Bugsnag.notify(error);
      });
  }

  // Helper function to retrieve the progress value for a given content description, if it is in our map
  getEnrollmentProgressForContentDescriptionId(id) {
    // Retrieve the value from the progress map, returning null if nothing is present
    const idKey = `id_${id}`;
    if (this.enrollmentProgressMap && typeof this.enrollmentProgressMap[idKey] !== 'undefined') {
      return this.enrollmentProgressMap[idKey];
    }
    return null;
  }

  getEnrollmentData(id) {
    if (!id) {
      return null;
    }

    return Agents.enrollments
      .getEnrollment(id)
      .then(
        action('fetchSuccess', (response) => {
          if (response.id) {
            Agents.enrollments
              .getEnrollmentById(response.id)
              .then((enrollmentResponse) => {
                this.enrollmentData = enrollmentResponse;
                return this.enrollmentData;
              })
              .catch(
                action('fetchError', (error) => {
                  Bugsnag.notify(error);
                })
              );
          }
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
        })
      );
  }
}

export default new EnrollmentStore();
