import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { defineCustomElements } from '@duetds/date-picker/dist/loader';

/* https://github.com/duetds/date-picker#usage-with-react */

defineCustomElements(window);

function DateLabel({ label, fieldId }) {
  if (!label || !fieldId) {
    return null;
  }
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="sr-only" htmlFor={fieldId}>
        {label}
      </label>
    </>
  );
}

function useListener(ref, eventName, handler) {
  useEffect(() => {
    if (ref.current) {
      const element = ref.current;
      element.addEventListener(eventName, handler);
      return () => element.removeEventListener(eventName, handler);
    }
    return null;
  }, [eventName, handler, ref]);
}

function DatePickerInit({ onChange, setInit, onInputFocus, onInputBlur, ...props }) {
  const DATE_FORMAT_US = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

  const dateAdapter = {
    parse(value, createDate) {
      const val = value || '';
      const matches = val.match(DATE_FORMAT_US);
      if (matches) {
        return createDate(matches[3], matches[1], matches[2]);
      }
      return null;
    },
    format(date) {
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    },
  };

  const localization = {
    buttonLabel: 'Choose date',
    placeholder: 'mm/dd/yyyy',
    selectedDateMessage: 'Selected date is',
    prevMonthLabel: 'Previous month',
    nextMonthLabel: 'Next month',
    monthSelectLabel: 'Month',
    yearSelectLabel: 'Year',
    closeLabel: 'Close window',
    keyboardInstruction: 'You can use arrow keys to navigate dates',
    calendarHeading: 'Choose a date',
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    locale: 'en-US',
  };

  const ref = useRef(null);

  const handleDateChange = (date) => {
    const actualDate = date.detail.valueAsDate;
    // Having it default timestamp to end of day - Can add prop to control this if needed
    const formattedDate = actualDate ? moment(actualDate).endOf('day') : null;
    onChange(formattedDate);
  };

  useListener(ref, 'duetChange', handleDateChange);
  useListener(ref, 'duetFocus', onInputFocus);
  useListener(ref, 'duetBlur', onInputBlur);

  useEffect(() => {
    ref.current.localization = localization;
    ref.current.dateAdapter = dateAdapter;
  }, [localization, dateAdapter]);

  useEffect(() => {
    setInit(true);
  }, []);

  return <duet-date-picker ref={ref} {...props} />;
}

function DatePicker({ id, value, label, onChange, className, disabled, style = {}, direction = 'right', defaultOpen, ariaDescribedBy, required }) {
  const [init, setInit] = useState(false);
  const commonClasses = 'border rounded';
  const classes = `${className || 'w-40'} ${commonClasses}`;
  const [wrapperClasses, setWrapperClasses] = useState(`${classes} border-gray-400`);
  const fieldId = id || uuidv4();

  useEffect(() => {
    if (defaultOpen && init) {
      document.querySelector('duet-date-picker').show();
    }
  }, [init, defaultOpen]);

  useEffect(() => {
    if (fieldId && init) {
      const inputElement = document.getElementById(fieldId);
      if (inputElement) {
        if (ariaDescribedBy) {
          inputElement.setAttribute('aria-describedby', ariaDescribedBy);
        } else {
          inputElement.removeAttribute('aria-describedby');
        }
        if (required) {
          inputElement.setAttribute('aria-required', required);
        } else {
          inputElement.removeAttribute('aria-required');
        }
      }
    }
  }, [ariaDescribedBy, required, init]);

  const onFocus = () => {
    setWrapperClasses(`${classes} border-blue-500`);
  };

  const onBlur = () => {
    setWrapperClasses(`${classes} border-gray-400`);
  };

  return (
    <>
      <DateLabel label={label} fieldId={fieldId} />
      <div className={wrapperClasses} style={{ ...style }}>
        <DatePickerInit
          disabled={disabled}
          identifier={fieldId}
          value={value ? moment(value).format('YYYY-MM-DD') : null}
          onChange={onChange}
          direction={direction}
          setInit={setInit}
          onInputFocus={onFocus}
          onInputBlur={onBlur}
        />
      </div>
    </>
  );
}

export default DatePicker;
