import { useState, useEffect, useCallback } from 'react';
import Bugsnag from '@bugsnag/js';
import { getRecommendationsTitle, getRecommendationsDescription, getCardContent, getSecondaryContent } from '../utils/onboardingUtil';
import Agents from '../agents/agents';

/**
 * @param goal - equivalent to cipGoal from the useOnboardingContext
 * @param experience - equivalent to cipExperience from the useOnboardingContext
 * @param yearsOfExperience - equivalent to cipYearsOfExperience from the useOnboardingContext
 */

function useOnboardingRecommendations(goal, experience, yearsOfExperience) {
  const [isLoading, setIsLoading] = useState(false);
  const [recommendationsTitle, setRecommendationsTitle] = useState('');
  const [recommendationsDescription, setRecommendationsDescription] = useState('');
  const [cardContent, setCardContent] = useState([]);
  const [secondaryContent, setSecondaryContent] = useState([]);

  useEffect(() => {
    const title = getRecommendationsTitle(goal, experience, yearsOfExperience);
    const description = getRecommendationsDescription(goal, experience, yearsOfExperience);
    setRecommendationsTitle(title);
    setRecommendationsDescription(description);
  }, []);
  /** handle the fetching and setting for the card content and secondary content... bookmarking is not handled here */
  const fetchRecommendations = useCallback(async () => {
    let allContentIds = [];
    let recommendedCardContent = [];
    let recommendedSecondaryContent = [];
    try {
      setIsLoading(true);
      recommendedCardContent = await getCardContent(goal, experience, yearsOfExperience);
      recommendedSecondaryContent = await getSecondaryContent(goal, experience, yearsOfExperience);
      setCardContent(recommendedCardContent);
      setSecondaryContent(recommendedSecondaryContent);
    } catch (error) {
      Bugsnag.notify(error, (errorEvent) => {
        const { errorMessage } = errorEvent.errors[0];
        // eslint-disable-next-line no-param-reassign
        errorEvent.context = `useOnboardingRecommendations: ${errorMessage}`;
        errorEvent.addMetadata('fetchRecommendationContent', {
          goal,
          experience,
          yearsOfExperience,
        });
      });
    } finally {
      const cardContentIds = recommendedCardContent.map((content) => content?.id);
      const secondaryContentIds = recommendedSecondaryContent.map((content) => content?.id);
      /** added filtering to ensure we don't return a list with any null ids so bookmarking can succeed */
      allContentIds = [...cardContentIds, ...secondaryContentIds].filter((id) => id !== null && id !== undefined);
      setIsLoading(false);
    }
    return allContentIds;
  }, [goal, experience, yearsOfExperience]);

  const bookmarkContent = useCallback(
    async (allContentIds, fetchBookmarks) => {
      try {
        await Agents.catalog.batchBookmarkContent(allContentIds);
        await fetchBookmarks();
      } catch (error) {
        Bugsnag.notify(error, (errorEvent) => {
          const { errorMessage } = errorEvent.errors[0];
          // eslint-disable-next-line no-param-reassign
          errorEvent.context = `RecommendationsStep: ${errorMessage}`;
        });
      }
    },
    [Agents]
  );
  return { recommendationsTitle, recommendationsDescription, isLoading, cardContent, secondaryContent, fetchRecommendations, bookmarkContent };
}
export default useOnboardingRecommendations;
