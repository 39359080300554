import React, { useState, useEffect, useRef, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { twMerge } from 'tailwind-merge';
import { Fade } from 'react-awesome-reveal';
import GoalsUtil from '../../utils/goalsUtil';
import GoalsCarousel from './DashboardGoals/GoalsCarousel';
import ContentOverlay from '../ContentOverlay/ContentOverlay';
import Title from '../Title/Title';
import GoalDetails from './GoalDetails';
import UserGoalsWizard from './UserGoalsWizard';
import Loading from '../Loading/Loading';
import If from '../If/If';

const DashboardGoals = inject(
  'goalsStore',
  'userStore',
  'commonStore'
)(
  observer(({ goalsStore, userStore, commonStore, hideIfNoGoals, navigate, className }) => {
    const [overlayOpen, setOverlayOpen] = useState(false);
    const [overlayAction, setOverlayAction] = useState(null);
    const [view, setView] = useState('in_progress');
    const [goalId, setGoalId] = useState(null);
    const [goalPreselection, setGoalPreselection] = useState(null);
    const goalsRef = useRef();
    // Grab queryParams off the window object every render, not from the hook.
    // This prevents an infinite loop due to cached queryParams and mutations directly to the window object
    const queryParams = queryString.parse(window.location.search);
    const { trackingGoal, userGoalsList, userGoals, getUserGoals, hasTrackingGoal, lookForNewTrackingGoal, startTrackingGoalPolling, trackingGoalInit } = goalsStore;
    const { goal, isEnterprise, userTeams } = userStore;

    const goals = useMemo(() => GoalsUtil.getNonBaselineGoals(userGoals?.data), [userGoals?.data]);

    const containerClasses = useMemo(() => twMerge('mx-auto', className), [className]);

    const fetchUserGoals = () => {
      const statusQueryParams = `?status=${view}`;
      getUserGoals(statusQueryParams).then(() => {
        // If we are looking at incomplete goals, and we aren't currently tracking a goal, but have goals, set one as default tracking
        // trackingGoalInit prevents this from conflicting with the search for a tracking goal that occurs in app load (if this page is refreshed)
        if (view === 'in_progress' && !hasTrackingGoal && trackingGoalInit && userGoals?.data?.length) {
          lookForNewTrackingGoal(userGoals.data);
          startTrackingGoalPolling();
        }
      });
    };

    const checkQueryParams = () => {
      // If we have a query param specifying a goal, open that goal details
      if (queryParams?.goal) {
        setOverlayOpen(true);
        setOverlayAction('view');
        setGoalId(queryParams.goal);
      } else if (queryParams?.['add-goal'] || queryParams?.action === 'add') {
        setOverlayOpen(true);
        setOverlayAction('add');
      }
    };

    const toggleOverlay = (action, overlayGoalId, isOpen, preselection) => {
      setOverlayAction(action);
      if (isOpen) {
        // isOpen is to manually open or close the overlay,
        setOverlayOpen(true);
      } else {
        // if not passed, toggle the overlay
        setOverlayOpen(!overlayOpen);
      }

      setGoalPreselection(preselection);
      setGoalId(overlayGoalId);
    };

    const deleteGoal = (itemGoalId) => {
      goalsStore
        .deleteGoal(itemGoalId)
        .then(() => {
          commonStore.triggerToast('success', { content: 'Goal deleted.' });
          fetchUserGoals();
        })
        .catch(() => {
          commonStore.triggerToast('error', { content: 'Something went wrong. Unable to delete this goal at this time.' });
        });
    };

    const deleteGoalConfirm = (itemGoalId) => {
      commonStore.triggerConfirm({
        content: 'Are you sure you want to delete this goal? This cannot be undone and all goal progress will be lost',
        cancel: () => commonStore.resetConfirmState(),
        confirmBtn: 'Continue',
        continue: () => {
          commonStore.resetConfirmState();
          deleteGoal(itemGoalId);
        },
      });
    };

    const getMenuOptions = (currentGoal, canTrackGoal) => {
      const isTeamGoal = currentGoal?.team_id;
      const canEditOrDelete = !isTeamGoal && view === 'in_progress';
      const { updateGoalTracking } = goalsStore;
      if (!currentGoal) {
        return [];
      }
      const options = [
        {
          text: 'View info',
          action: () => toggleOverlay('view', currentGoal.id),
        },
      ];
      if (canEditOrDelete) {
        options.push({
          text: 'Edit goal',
          action: () => toggleOverlay('edit', currentGoal.id),
        });
      }
      if (canTrackGoal) {
        options.push({
          text: 'Set as achievement tracker',
          action: () => updateGoalTracking(currentGoal),
        });
      }
      if (canEditOrDelete) {
        options.push({
          dividerLine: true,
        });
        options.push({
          text: 'Delete goal',
          action: () => deleteGoalConfirm(currentGoal.id),
        });
      }
      return options;
    };

    const cancelConfirm = () => {
      if (!goalsStore.addEditGoalPrestine) {
        commonStore.triggerConfirm({
          content: 'Are you sure you want to cancel? Your goal will not be saved',
          cancel: () => commonStore.resetConfirmState(),
          confirmBtn: 'Continue',
          continue: () => {
            // Clear query params to prevent auto opening overlay on page load
            navigate({
              search: '',
            });
            toggleOverlay();
            commonStore.resetConfirmState();
            goalsStore.resetAddEditGoalPrestine();
          },
        });
      } else {
        toggleOverlay();
        commonStore.resetConfirmState();
      }
    };

    const continueLearning = (currentGoal) => {
      GoalsUtil.continueLearning(currentGoal, goalsStore, navigate);
    };

    useEffect(() => {
      fetchUserGoals();

      if (queryParams?.goals && goalsRef?.current) {
        // Slight delay for rendering - Then scroll to section
        setTimeout(() => {
          goalsRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }, 700);
      }
      // reset the users goals to default on unmount
      return () => {
        goalsStore.setDefaultUserGoals();
      };
    }, []);

    useEffect(() => {
      fetchUserGoals();
    }, [view]);

    useEffect(() => {
      checkQueryParams();
    }, [queryParams]);

    if (hideIfNoGoals) {
      // We fetch all goals on app init to show in goal widget

      if ((!goals?.length || userGoalsList?.error) && !userGoalsList?.loading) {
        return null;
      }
    }

    return (
      <div className={containerClasses} ref={goalsRef}>
        <If condition={Boolean(userGoalsList?.loading)}>
          <Loading message="Loading goals..." wrapperClassName="py-6" />
        </If>
        <If condition={!userGoalsList?.loading}>
          <>
            <Fade triggerOnce>
              <GoalsCarousel
                data={goals}
                loading={userGoals?.loading || userGoalsList?.loading}
                userTeams={userTeams}
                getMenuOptions={getMenuOptions}
                view={view}
                toggleOverlay={toggleOverlay}
                continueLearning={continueLearning}
                trackingGoal={trackingGoal}
                userGoal={goal}
                setView={setView}
                isEnterprise={isEnterprise}
              />
            </Fade>
            <ContentOverlay
              omitPadding
              open={overlayOpen}
              dismiss={cancelConfirm}
              width={overlayAction === 'add' ? 'w-[70vw]' : 'w-[40vw]'}
              maxWidth={overlayAction !== 'add' ? 'max-w-xl' : 'max-w-5xl'}
              ariaLabelledBy="dashboard-goal-wizard"
            >
              <If condition={overlayAction === 'add'}>
                <div className="py-4 px-8">
                  <Title title="Create Goal" titleId="create-goal" omitPadding wrapperClasses="mb-4" />
                  <p className="hidden mb-9 text-sm text-gray-600 md:block">
                    Follow the guide below to set your professional development goal and we&apos;ll get you started in the right direction.
                  </p>
                  <UserGoalsWizard
                    goalPreselection={goalPreselection}
                    onSubmit={() => {
                      toggleOverlay();
                      fetchUserGoals();
                    }}
                  />
                </div>
              </If>
              <If condition={overlayAction !== 'add'}>
                <GoalDetails
                  goalId={goalId}
                  continueLearning={continueLearning}
                  editView={overlayAction === 'edit'}
                  editCancel={cancelConfirm}
                  onEdit={() => {
                    toggleOverlay('view', goalId, true);
                    fetchUserGoals();
                  }}
                />
              </If>
            </ContentOverlay>
          </>
        </If>
      </div>
    );
  })
);

export default DashboardGoals;
