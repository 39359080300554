import React, { useState, useEffect } from 'react';
import Button from '../Button/Button';

function AdminEmailButtons(props) {
  const { text, commonStore, sendEmail, email } = props;
  const [button, setButton] = useState({ loading: false, disable: false, error: null });

  const handleClick = async () => {
    setButton({ ...button, loading: true });
    try {
      await sendEmail({ email });
      commonStore.triggerToast('success', {
        content: `${text} sent successfully`,
      });
      setButton({ ...button, loading: false, disable: true });
    } catch (e) {
      commonStore.triggerToast('error', {
        content: 'Something went wrong. Please try again.',
      });
      setButton({ ...button, error: e, loading: false });
    }
  };

  // disable button for 30 sec
  useEffect(() => {
    let disabledTimer = null;
    if (button.disable) {
      disabledTimer = setTimeout(() => setButton({ ...button, disable: false }), 30000);
    }
    return () => {
      clearTimeout(disabledTimer);
    };
  }, [button.disable]);

  return (
    <Button
      className="ml-3"
      color="pink"
      disabled={button.disable || button.loading}
      loading={button.loading}
      loadingProps={{ message: 'Sending', messageClassName: 'ml-2', wrapperClassName: 'flex items-center' }}
      onClick={() => handleClick()}
    >
      Send {text}
    </Button>
  );
}

export default AdminEmailButtons;
