import React from 'react';
import { inject, observer } from 'mobx-react';
import withRouter from '../../components/Router/withRouter';
import CurriculumBuilder from '../../components/Curriculum/CurriculumBuilder';
import Container from '../../components/Container/Container';

const CurriculumBuild = inject(
  'userStore',
  'enterpriseStore',
  'commonStore',
  'authStore',
  'notificationsStore'
)(
  observer(
    class CurriculumBuild extends React.Component {
      componentDidMount() {
        const mode = this.props.enterpriseStore.editMode ? 'Edit' : 'Create';
        this.props.commonStore.setPageTitle(`${mode} Path | Cybrary`);
        this.props.authStore.fireAttributionEvent();
        this.props.userStore.setPreferredTeamInit('', this.props.match.params.orgId);
      }

      getBreadCrumbs() {
        const crumbs = [];
        const lastCrumbText = this.props.match.params.id ? 'Edit' : 'Create';
        const lastCrumbLink = this.props.match.params.id
          ? `/enterprise/${this.props.match.params.orgId}/path/edit/${this.props.match.params.id}`
          : `/enterprise/${this.props.match.params.orgId}/path/create`;
        crumbs.push({
          href: `/enterprise/${this.props.match.params.orgId}/paths`,
          label: 'Path',
        });
        crumbs.push({
          href: lastCrumbLink,
          label: `${lastCrumbText} Path`,
        });
        return crumbs;
      }

      handlePostSuccess = (data) => {
        const { team } = this.props.userStore;
        this.props.commonStore.triggerConfirm({
          content: 'Would you like to use this path in a goal for your team members?',
          cancel: () => {
            this.props.commonStore.resetConfirmState();
            this.props.navigate(`/enterprise/${team.id}/paths`);
          },
          continue: () => {
            this.props.commonStore.resetConfirmState();
            this.props.navigate(`/enterprise/${team.id}/goals/?action=add&content_type=path&content_ids=${data.id}`);
          },
          confirmBtn: 'Use in Goal',
          cancelBtn: 'View All Paths',
          focusLockDelay: 6000,
        });
        // If this curriculum had replaced content, refresh user notifications in case they replaced old content
        if (this.props.enterpriseStore.curriculum && this.props.enterpriseStore.curriculum.content_replaced_at) {
          this.props.notificationsStore.getUserNotifications(this.props.userStore);
        }
      };

      render() {
        const { team } = this.props.userStore;
        // Wait for team to be set
        if (!team || 1 * team.id !== 1 * this.props.match.params.orgId) {
          return null;
        }
        return (
          <Container size="md">
            <CurriculumBuilder
              onSubmit={this.handlePostSuccess}
              navigate={this.props.navigate}
              curriculumId={this.props.match.params.id}
              copyId={this.props.match.params.copyid}
              breadcrumbs={this.getBreadCrumbs()}
            />
          </Container>
        );
      }
    }
  )
);

export default withRouter(CurriculumBuild);
