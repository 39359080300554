import React from 'react';
import { twMerge } from 'tailwind-merge';
import Container from '../../Container/Container';
import Header from '../../Header/Header';
import Icon from '../../Icon/Icon';
import If from '../../If/If';

function PackageHighlight({ hasAccess, description }) {
  const iconName = hasAccess ? 'check' : 'x';
  const iconClasses = hasAccess ? 'text-cyb-green-500' : 'text-red-500';
  return (
    <li className="flex items-center mb-2">
      <Icon name={iconName} className={twMerge('mr-2 w-4 h-4 shrink-0', iconClasses)} />
      <p className="text-xs font-normal">{description}</p>
    </li>
  );
}
/**
 * Single clickable button for use in the onboarding model steps
 * @param icon name of icon
 * @param title text shown below icon
 * @param description text shown below title
 * @param onClick callback function of button click
 * @param className classes to add to main component
 * @param iconClassName classes to add to the icon itself
 * @param membershipHighlights array of strings that describe the membership highlights
 * @param packageHighlights array of objects that describe the package highlights. Each object has two keys, hasAccess @boolean and description @string
 */
function OnboardingStepButton({ icon, title, description = '', onClick, className, iconClassName, membershipHighlights = [], packageHighlights = [] }) {
  const wrapperClasses = `md:m-8 px-4 lg:px-2 xl:px-8 w-full h-full py-4 cursor-pointer rounded border 
  flex flex-col items-center bg-white border-1 border-gray-600 rounded-[24px]`;
  const hoverClasses =
    'peer-focus-visible:outline-1 peer-focus-visible:outline-blue-400 peer-focus-visible:outline peer-focus-visible:border-cyb-pink-500 hover:border-1 hover:border-cyb-pink-500';

  const hasMembershipHighlights = membershipHighlights?.length > 0;
  const hasPackageHighlights = packageHighlights?.length > 0;
  return (
    <div className={twMerge(wrapperClasses, hoverClasses, className)} aria-hidden="true" onClick={onClick}>
      <If condition={!!icon}>
        <Icon name={icon} className={twMerge('mb-4 w-24 h-24 fill-cyb-pink-500', iconClassName)} />
      </If>
      <p className="mb-8 text-xl font-bold text-center text-black">{title}</p>

      <If condition={!!description}>
        <p className="text-xs">{description}</p>
      </If>
      <If condition={hasMembershipHighlights}>
        <ul className="list-disc list-inside">
          {membershipHighlights.map((highlight) => (
            <li key={highlight} className="text-sm font-normal">
              {highlight}
            </li>
          ))}
        </ul>
      </If>
      <If condition={hasPackageHighlights}>
        <ul className="w-full">
          {packageHighlights.map((highlight) => (
            <PackageHighlight key={highlight.description} hasAccess={highlight.hasAccess} description={highlight.description} />
          ))}
        </ul>
      </If>
    </div>
  );
}

/**
 * Header component for use in onboarding steps
 * @param title the text to display
 */
function OnboardingStepTitle({ title, className }) {
  return (
    <Header as="h2" className={twMerge('px-8 pb-4 m-auto text-2xl md:text-4xl font-black text-center', className)}>
      {title}
    </Header>
  );
}
function OnboardingStepDescription({ description, className }) {
  return (
    <Header as="h3" className={twMerge('px-8 pb-4 m-auto text-xl md:text-2xl font-black text-center', className)}>
      {description}
    </Header>
  );
}
/**
 * Container for a single step in the onboarding flow
 * For use in the onboarding modal
 * @param title text shown at top of step
 */
function OnboardingStep({ title, children, className }) {
  return (
    <Container className={twMerge('p-8', className)}>
      <OnboardingStepTitle title={title} />
      {children}
    </Container>
  );
}
/** Export child components for reuse in custom onboarding displays */
OnboardingStep.Button = OnboardingStepButton;
OnboardingStep.Title = OnboardingStepTitle;
OnboardingStep.Description = OnboardingStepDescription;

export default OnboardingStep;
