import React from 'react';
import { observer, inject } from 'mobx-react';
import Bugsnag from '@bugsnag/js';
import { twMerge } from 'tailwind-merge';
import DynamicForm from '../DynamicForm/DynamicForm';
import FormatUtil from '../../utils/formatUtil';
import Camera from '../../Icons/Camera';
import Edit from '../../Icons/Edit';
import Modal from '../Modal/Modal';
import Tooltip from '../Tooltip/Tooltip';

function TeamLogo({ logo, toggleModal, restrictLogoEdit, className }) {
  const logoSrc = logo ? FormatUtil.correctImageUrl(logo) : null;
  let buttonClassList = 'hover:opacity-70 bg-contain bg-center bg-no-repeat';
  if (!logo) {
    buttonClassList = twMerge(buttonClassList, 'empty');
  }
  if (restrictLogoEdit) {
    buttonClassList = twMerge(buttonClassList, 'restricted cursor-not-allowed');
  } else {
    buttonClassList = twMerge(buttonClassList, 'cursor-pointer');
  }
  // add className last so it take priority
  buttonClassList = twMerge(buttonClassList, className);

  return (
    <Tooltip
      content="Add/Edit team logo"
      disabled={restrictLogoEdit}
      triggerContent={
        <button
          onClick={() => toggleModal('logoModal', true)}
          className={buttonClassList}
          style={{
            backgroundImage: logo ? `url(${logoSrc})` : 'none',
            backgroundColor: logo ? 'none' : 'rgb(230, 230, 230)',
          }}
          disabled={restrictLogoEdit}
        >
          <ImgIcon logo={logo} restrictLogoEdit={restrictLogoEdit} />
        </button>
      }
      omitTabIndex
    />
  );
}

function ImgIcon({ logo, restrictLogoEdit }) {
  const editIconColor = restrictLogoEdit ? 'text-grey-500' : 'text-cyb-pink-500';
  const cameraIconColor = restrictLogoEdit ? 'text-grey-500' : 'text-green-500';
  return logo ? <Edit classes={`${editIconColor} w-12 h-12`} /> : <Camera classes={`${cameraIconColor} w-12 h-12`} />;
}

const OrganizationLogo = inject(
  'commonStore',
  'enterpriseStore',
  'userStore',
  'adminStore'
)(
  observer(
    class OrganizationLogo extends React.Component {
      state = {
        logoModal: false,
      };

      /**
       * handle modal closing states
       */
      toggleModal = () => {
        if (!this.props.restrictLogoEdit) {
          const newState = {
            ...this.state,
            logoModal: !this.state.logoModal,
          };
          this.setState(newState);
        }

        const newState = { ...this.state, logoModal: !this.state.logoModal };
        this.setState(newState);
        return this.state.logoModal;
      };

      handleLogoSubmit = (data) => {
        this.toggleModal();
        const isSalesDashboard = window.location.pathname.split('/')[1] === 'admin';
        this.props.enterpriseStore
          .postTeamLogo(this.props.teamId, data, isSalesDashboard)
          .then((response) => {
            this.props.userStore.loadAndSetTeamData(this.props.teamId);
            this.props.commonStore.triggerToast('success', {
              content: 'Organization logo has been uploaded',
            });
            if (isSalesDashboard) {
              this.props.adminStore.setTeamLogo(response);
            } else {
              this.props.userStore.setTeamLogo(response);
            }
          })
          .catch((e) => {
            Bugsnag.notify(e);
            this.props.commonStore.triggerToast('error', {
              content: 'Something went wrong. Please check that your image meets the requirements',
            });
          });
      };

      addImgUploadForm = () => {
        return {
          order: ['image', 'submit'],
          fields: {
            image: {
              type: 'singleImageUpload',
              dropWidth: '300px',
              dropHeight: '300px',
              imgPreview: true,
              popupText: 'Click here, or drag in an image to upload. Accepted image types include jpeg, png, gif, or bmp. Max width and height is restricted to 300px',
              icon: 'camera',
              acceptedTypes: 'image/jpeg, image/png, image/gif, image/bmp',
              defaultValue: this.props.logoUrl,
              className: 'flex flex-col text-center',
            },
            submit: {
              type: 'button',
              color: 'pink',
              label: 'Save Logo',
            },
          },
        };
      };

      render() {
        const { className: formattedClassName } = this.props;
        return (
          <>
            <TeamLogo className={formattedClassName} logo={this.props.logoUrl} toggleModal={this.toggleModal} restrictLogoEdit={this.props.restrictLogoEdit} />
            <Modal
              open={this.state.logoModal}
              toggle={this.toggleModal}
              size="sm"
              paddingBottom="pb-0"
              position="center"
              closeIconColor="text-white"
              ariaLabel="Upload Team Logo Modal"
              transparentBg
            >
              <div>
                <div className="flex justify-center">
                  <DynamicForm form={this.addImgUploadForm(this.props.teamId)} formName="addImgUploadForm" onSubmit={this.handleLogoSubmit} />
                </div>
              </div>
            </Modal>
          </>
        );
      }
    }
  )
);

export default OrganizationLogo;
