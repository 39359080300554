import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Title from '../Title/Title';
import DynamicForm from '../DynamicForm/DynamicForm';
import FormatUtil from '../../utils/formatUtil';
import NotificationUtil from '../../utils/notificationUtil';
import ContentUpdateFlag from '../ContentUpdates/ContentUpdateFlag';
import ContentUpdateLabel from '../ContentUpdates/ContentUpdateLabel';
import ArchivedLabel from '../ContentUpdates/ArchivedLabel';
import BetaLabel from '../Label/BetaLabel';
import Tooltip from '../Tooltip/Tooltip';
import Label from '../Label/Label';

const CurriculumBuildContent = inject(
  'authStore',
  'notificationsStore'
)(
  observer(
    class CurriculumBuildContent extends React.Component {
      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
      }

      /**
       * list item component
       * @param value
       * @returns {*}
       */
      getListItemComponent = (value) => {
        const { replacedContent } = this.props.notificationsStore;
        const contentUpdates = NotificationUtil.transformContentNotifications(replacedContent, 'old_content_description_id');
        const contentUpdate = contentUpdates[value.id] && contentUpdates[value.id].processed ? contentUpdates[value.id] : null;
        const contentType = FormatUtil.formatContentType(value.type);
        const isNewContentComingSoon = NotificationUtil.checkContentUpdateComingSoon(contentUpdate);
        const contentArchiveDate = value.archive_at && !value.isArchived ? moment(value.archive_at).format('MM/DD/YYYY') : null;
        return (
          <div className="content-item" style={{ display: 'flex', width: '100%' }}>
            <span style={{ width: '80%', paddingRight: '10%' }}>
              {value.title}
              <Label className="ml-2" basic>
                {contentType}
              </Label>
              {value.isArchived ? <ArchivedLabel className="ml-2" /> : null}
              {!!value.tags && value.tags.indexOf('Beta') !== -1 ? <BetaLabel className="ml-2" /> : null}
              {contentArchiveDate && <ContentUpdateLabel basic className="ml-2" labelText={`Archiving on ${contentArchiveDate}`} />}
              {contentUpdate && !isNewContentComingSoon ? (
                <ContentUpdateFlag style={{ marginLeft: '0.5em' }} showAsLabel data={contentUpdate} summaryTitle="New Version Available!" contentTitle={value.title} />
              ) : null}
            </span>
            {value.duration || value.duration_seconds ? (
              <span style={{ width: '10%', fontSize: '0.875em' }}>{value.duration || FormatUtil.formatTime(value.duration_seconds, 'hm')}</span>
            ) : null}
          </div>
        );
      };

      render() {
        const customRenderers = {
          contents: this.getListItemComponent,
        };

        return (
          <div className="curriculum-build-content">
            <div className="flex items-end">
              <div>
                <Title title={`${this.props.mode} Path`} breadcrumbs={this.props.breadcrumbs} titleId="paths-modal" />
              </div>
              {this.props.mode === 'Edit' ? (
                <div className="flex-1 pb-12 text-right">
                  <Tooltip
                    omitTabIndex
                    triggerContent={
                      <button
                        onClick={() => this.props.handleCurriclumArchive(this.props.curriculum.id)}
                        className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm"
                      >
                        Delete
                      </button>
                    }
                    content="Deleting this path will delete the assignment for current members."
                  />
                </div>
              ) : null}
            </div>
            <DynamicForm
              form={this.props.form}
              serverErrors={this.props.serverErrors}
              clearErrorForField={this.props.clearErrorForField}
              customRenderers={customRenderers}
              onSubmit={this.props.handleSubmit}
            />
          </div>
        );
      }
    }
  )
);

export default CurriculumBuildContent;
