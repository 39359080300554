import React from 'react';
import DashboardIcon from './Icons/DashboardIcon';
import BrowseIcon from './Icons/BrowseIcon';
import LearningIcon from './Icons/LearningIcon';
import TeamsIcon from '../../Icons/TeamsIcon';
import Apple from './Icons/Apple';
import Android from './Icons/Android';
import SlackIcon from './Icons/SlackIcon';
import BellIcon from './Icons/BellIcon';
import HelpIcon from '../../Icons/HelpIcon';
import Certificate from './Icons/Certificate';
import Certification from './Icons/Certification';
import Terminal from './Icons/Terminal';
import CareerPathIcon from './Icons/CareerPathIcon';
import AchievementIcon from './Icons/AchievementIcon';
import ForumsIcon from './Icons/ForumsIcon';

function NavIconSwitch({ name, active, classes, id }) {
  switch (name) {
    case 'dashboard':
      return <DashboardIcon active={active} classes={classes} />;
    case 'teams':
      return <TeamsIcon active={active} classes={classes} id={id} />;
    case 'career':
      return <CareerPathIcon active={active} classes={classes} id={id} />;
    case 'achievement':
      return <AchievementIcon active={active} classes={classes} id={id} />;
    case 'learning':
      return <LearningIcon active={active} classes={classes} id={id} />;
    case 'browse':
      return <BrowseIcon active={active} classes={classes} />;
    case 'apple':
      return <Apple classes={classes} />;
    case 'android':
      return <Android classes={classes} />;
    case 'slack':
      return <SlackIcon active={active} classes={classes} id={id} />;
    case 'bell':
      return <BellIcon active={active} classes={classes} id={id} />;
    case 'help':
      return <HelpIcon active={active} classes={classes} />;
    case 'certificate':
      return <Certificate classes={classes} id={id} />;
    case 'certification':
      return <Certification active={active} classes={classes} id={id} />;
    case 'terminal':
      return <Terminal active={active} classes={classes} id={id} />;
    case 'forums':
      return <ForumsIcon active={active} classes={classes} />;
    default:
      return null;
  }
}

export default NavIconSwitch;
