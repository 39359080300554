import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import queryString from 'query-string';
import Agents from '../agents/agents';
import SearchUtil from '../utils/searchUtil';
import CurrentEnrollmentTransformer from '../transformers/currentEnrollmentTransformer';

// constants for max bookmarks and in progress items
// used during loading of data, and also for the number of items to rendered in the respective tabs
const MAX_BOOKMARKS = 5;
const MAX_IN_PROGRESS = 6;

const AUTO_ENROLLED_CONTENT_DESCRIPTION_IDS = [32928, 52154];

const ContinueLearningContext = createContext();

// Incomplete enrollments query params (defaults to sort by updated_at date)
const incompleteEnrollmentsQuery = queryString.stringify({
  isComplete: 0,
  isActivity: 0,
  sortDirection: 'desc',
});

/**
 * Fetches next course and activity data for the given enrollment. Uses linear progression to find the next course and activity.
 * @param {Object} enrollment parent enrollment object (course or content collection) that we will use to load course and activity data from
 * @returns {Promise} Promise that resolves with the current course, activity data, parent enrollment id, and any errors
 */
export async function loadCurrentCourseAndActivityData(enrollment) {
  try {
    // Make sure we have the full enrollment object data with content.content_description which is needed for transformation
    let data = enrollment; // default to the passed in enrollment
    if (!enrollment?.content?.content_description) {
      // Get the full enrollment data by ID which has the content description object needed for transformation
      data = await Agents.enrollments.getEnrollmentById(enrollment.id);
    }
    // transform the full enrollment object to match the current enrollment transformer from My Learning
    const transformedData = CurrentEnrollmentTransformer.transformCurrentEnrollmentData(data, true);
    return { id: enrollment?.id, transformedData };
  } catch (e) {
    Bugsnag.notify(e);
    // resolve with the enrollment id so we can put this error on the enrollment object for graceful error handling downstream
    return { id: enrollment?.id, error: e };
  }
}

// Context provider for the continue learning block on the logged in dashboard
function ContinueLearningProvider({ children }) {
  const [title, setTitle] = useState('Continue Learning');
  const [activeTab, setActiveTab] = useState('current');
  const [enrollments, setEnrollments] = useState([]);
  const [userChosenEnrollments, setUserChosenEnrollments] = useState([]);
  const [bookmarkedContent, setBookmarkedContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [initEnrollmentsError, setInitEnrollmentsError] = useState();
  const [initBookmarksError, setInitBookmarksError] = useState();

  /**
   * Initialize the continue learning block bookmarked content data by loading all bookmarked content
   * Only loads up to MAX_BOOKMARKS number of items.
   */
  const initBookmarks = async () => {
    try {
      // Get user bookmarks from backend
      const bookmarks = await Agents.catalog.getBookmarks();
      if (!bookmarks?.length) {
        setBookmarkedContent([]);
        return;
      }
      // get bookmarked content from algolia
      let filters = SearchUtil.getDefaultFiltersByCategory();
      filters += ` AND (${SearchUtil.buildFilters('objectID', bookmarks?.splice(0, MAX_BOOKMARKS), 'OR')})`;
      const bookmarkData = await SearchUtil.fetchFromAlgolia(process.env.REACT_APP_INSTANTSEARCH_POPULAR_INDEX, { filters, hitsPerPage: 100 });
      if (bookmarkData?.hits?.length > 0) {
        setBookmarkedContent(bookmarkData?.hits);
      }
    } catch (err) {
      Bugsnag.notify(err);
      setInitBookmarksError(err);
    }
  };

  /**
   * Initialize the continue learning block enrollments data by loading all incomplete enrollments,
   * and then concurrently load the current course and activity data for the first X enrollments
   */
  const initEnrollments = async () => {
    try {
      const incompleteEnrollments = await Agents.enrollments.getEnrollmentsList(`?${incompleteEnrollmentsQuery}`);
      setEnrollments(incompleteEnrollments);

      // We don't want to show automatic enrollments in some displays, so we filter them out here by content_description_id
      const tempUserChosenEnrollments = incompleteEnrollments?.filter((enrollment) => !AUTO_ENROLLED_CONTENT_DESCRIPTION_IDS.includes(enrollment.content_description_id)) || [];
      if (tempUserChosenEnrollments?.length) {
        // create array of promises to get the full enrollment data for the first X enrollments
        const requests = tempUserChosenEnrollments?.slice(0, MAX_IN_PROGRESS)?.map(loadCurrentCourseAndActivityData);

        // send concurrent requests to load current course and activity
        const response = await Promise.allSettled(requests);

        // update the tempUserChosenEnrollments array with the current course and activity data
        response.forEach(({ value }) => {
          const { transformedData, id, error } = value;
          const tempEnrollment = tempUserChosenEnrollments.find((enrollment) => enrollment.id === id) || {};
          tempEnrollment.error = error;
          tempEnrollment.transformedData = transformedData;
        });
        setUserChosenEnrollments(tempUserChosenEnrollments);
      }
    } catch (e) {
      Bugsnag.notify(e);
      setInitEnrollmentsError(e);
    }
  };

  /**
   * Initialize the continue learning block by concurrently fetching data for the enrollments and bookmarks
   */
  const init = async () => {
    setIsLoading(true);
    // Fetch data for the continue learning block including enrollments and bookmarks
    await Promise.allSettled([initEnrollments(), initBookmarks()]);
    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const values = useMemo(
    () => ({
      init,
      title,
      setTitle,
      activeTab,
      setActiveTab,
      enrollments,
      userChosenEnrollments,
      bookmarkedContent,
      isLoading,
      initEnrollmentsError,
      initBookmarksError,
      MAX_BOOKMARKS,
      MAX_IN_PROGRESS,
    }),
    [enrollments, userChosenEnrollments, isLoading, activeTab, title, bookmarkedContent, initEnrollmentsError, initBookmarksError]
  );

  return <ContinueLearningContext.Provider value={values}>{children}</ContinueLearningContext.Provider>;
}

export const useContinueLearningContext = () => useContext(ContinueLearningContext);
export default ContinueLearningProvider;
