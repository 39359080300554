import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import Bugsnag from '@bugsnag/js';
import Container from '../../components/Container/Container';
import withRouter from '../../components/Router/withRouter';
import Title from '../../components/Title/Title';
import CertificationsAndVendors from '../../components/Browse/CertificationsAndVendors';
import FilterUtil from '../../utils/filterUtil';
import FormatUtil from '../../utils/formatUtil';
import SearchResults from '../../components/Browse/SearchResults';
import BrowseFilters from '../../components/Browse/BrowseFilters';
import Icon from '../../components/Icon/Icon';
import Dropdown from '../../components/Dropdown/Dropdown';
import SearchInputField from '../../components/Browse/SearchInputField';
import FiltersSidebar from '../../components/Browse/FiltersSidebar';
import CIPUpgradeBanner from '../../components/Banners/CIPUpgradeBanner';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import { DEFAULT_COOKIE_SETTINGS } from '../../constants';
import ActionUtil from '../../utils/actionsUtil';

function getQueryParams(searchFilters, view, resultsView) {
  let newQueryParams = queryString.stringify(searchFilters.filters);
  newQueryParams += newQueryParams.length ? `&resultsView=${resultsView}` : `?resultsView=${resultsView}`;
  if (view !== 'all') {
    newQueryParams += newQueryParams.length ? `&view=${view}` : `?view=${view}`;
  }
  if (searchFilters.q) {
    newQueryParams += newQueryParams.length ? `&q=${searchFilters.q}` : `?q=${searchFilters.q}`;
  }
  if (searchFilters.page > 1) {
    newQueryParams += newQueryParams.length ? `&page=${searchFilters.page}` : `?page=${searchFilters.page}`;
  }
  if (searchFilters.sort) {
    newQueryParams += newQueryParams.length ? `&sort=${searchFilters.sort}` : `?sort=${searchFilters.sort}`;
  }
  if (searchFilters.license) {
    newQueryParams += newQueryParams.length ? `&license=${searchFilters.license}` : `?license=${searchFilters.license}`;
  }
  return newQueryParams;
}
function addFocusToFilterHeader(facet, filter) {
  let filterHeader = FilterUtil.getFilterHeader(facet);
  if (facet === 'terms_info') {
    switch (true) {
      case /domains/.test(filter):
        filterHeader = FilterUtil.getFilterHeader('domains');
        break;
      case /topics/.test(filter):
        filterHeader = FilterUtil.getFilterHeader('topics');
        break;
      case /workrole/.test(filter):
        filterHeader = FilterUtil.getFilterHeader('work_role');
        break;
      case /nice-work-role/.test(filter):
        filterHeader = FilterUtil.getFilterHeader('nice_work_role');
        break;
      case /nice-category/.test(filter):
        filterHeader = FilterUtil.getFilterHeader('nice_category');
        break;
      default:
        break;
    }
  }

  const filterHeaderId = FormatUtil.lowerCaseHyphenText(filterHeader);
  setTimeout(() => {
    const element = document.getElementById(filterHeaderId);
    if (element) {
      element.setAttribute('tabIndex', -1);
      element.focus();
    }
  }, 500);
}

function init(setView, setResultsView, setContentView, location, searchStore) {
  // Set page filters based on query params
  const queryParams = queryString.parse(window.location.search);

  if (location && location.state && location.state.facet && location.state.filter) {
    const { facet, filter } = location.state;
    addFocusToFilterHeader(facet, filter);
  }
  const currState = {};
  // Grab the relevant query params from the URL and set them in the store/state to set up the results and view initially
  Object.keys(queryParams).forEach((param) => {
    /**
     * @important
     * @param utm_ tracking query param for marketing related to google analytics... starts with utm_
     * @param eml tracking query param for email marketing (i think... idk why they don't use utm_ tbh...)
     * @param mkt_tok tracking query param for marketing which belongs to marketo (idk if we still use or pay for this)
     * @param refreshUser query param for refreshing the user manually... usually used by devs
     */
    const WHITE_LISTED_PARAMS = ['utm_', 'eml', 'mkt_tok', 'refreshUser'];
    const isWhiteListedParam = WHITE_LISTED_PARAMS.some((whiteListedParam) => {
      /**
       * @important utm_ has a variety of params but they all start with utm_...
       * so for this one case we just check if the param starts with utm_... the rest we look for exact matches */
      if (whiteListedParam === 'utm_') {
        return param.startsWith(whiteListedParam);
      }
      return param.includes(whiteListedParam);
    });

    if (param === 'view') {
      currState.view = queryParams[param] || 'all';
    } else if (param === 'resultsView') {
      currState.resultsView = queryParams[param];
      setResultsView(queryParams[param]);
    } else if (param === 'page') {
      searchStore.pageChange(queryParams[param]);
    } else if (param === 'q') {
      searchStore.setSearchQuery(queryParams[param]);
      searchStore.setSearchVal(queryParams[param]);
    } else if (param === 'sort') {
      searchStore.setSort(queryParams[param]);
    } else if (['new', 'status', 'license'].indexOf(param) !== -1) {
      currState.contentView = param;
      if (param === 'license') {
        searchStore.addLicense(queryParams[param]);
      }
    } else if (Array.isArray(queryParams[param])) {
      queryParams[param].forEach((filter) => {
        searchStore.addFilter(param, filter);
      });
    } else if (!isWhiteListedParam && queryParams[param]) {
      searchStore.addFilter(param, queryParams[param]);
    }
  });

  Object.keys(currState).forEach((state) => {
    if (state === 'view') {
      setView(currState.view);
    }
    if (state === 'resultsView') {
      setResultsView(currState.resultsView);
    }
    if (state === 'contentView') {
      const contentViewMap = { status: 'coming', new: 'new', license: 'license' };
      setContentView(contentViewMap[currState.contentView]);
    }
  });

  const getSearchItems = async () => {
    try {
      await searchStore.loadSearchItems();
    } catch (err) {
      Bugsnag.notify(err);
    }
  };
  getSearchItems();
}

function dismissBanner(sectionBanners, setSectionBanners, sectionName) {
  if (sectionBanners.indexOf(sectionName) === -1) {
    const newBanners = [...sectionBanners];
    newBanners.push(sectionName);
    const dismissedBanners = JSON.stringify(newBanners);
    Cookies.set(`browse_refined_section_banners`, dismissedBanners, {
      ...DEFAULT_COOKIE_SETTINGS,
      path: '/',
      expires: 365,
    });
    setSectionBanners(newBanners);
  }
}

function getFiltersCount(searchFilters, view) {
  let filtersCount = 0;
  filtersCount += searchFilters.q ? 1 : 0;
  filtersCount += searchFilters.license === '1' ? 1 : 0;
  filtersCount += view !== 'all' ? 1 : 0;
  Object.keys(searchFilters.filters).forEach((filter) => {
    filtersCount += searchFilters.filters[filter].length;
  });
  return filtersCount;
}

function BrowseResultsViewButtons({ resultsView, changeResultsView }) {
  const buttonClass = 'rounded-sm h-10 w-10 flex items-center justify-center';
  const isListActive = resultsView === 'list';
  return (
    <>
      <button
        className={`mr-2 ${buttonClass} ${isListActive ? 'bg-black' : 'bg-gray-200'}`}
        onClick={() => changeResultsView('list')}
        aria-label="List View"
        aria-pressed={isListActive}
      >
        <Icon name="list" className={`w-4 h-4 ${isListActive ? 'text-white' : 'text-black'}`} />
      </button>
      <button
        className={`${buttonClass} ${!isListActive ? 'bg-black' : 'bg-gray-200'}`}
        onClick={() => changeResultsView('grid')}
        aria-label="Grid View"
        aria-pressed={!isListActive}
      >
        <Icon name="grid" className={`w-4 h-4 ${!isListActive ? 'text-white' : 'text-black'}`} />
      </button>
    </>
  );
}

function DisplayControlContainer({
  setQueryParams,
  hasFilters,
  searchStore,
  filtersCount,
  filterOptions,
  changeSort,
  resultsView,
  changeResultsView,
  mobileSidebarOpen,
  setMobileSidebarOpen,
}) {
  return (
    <>
      <div className="hidden items-center px-[31px] mb-3.5 w-full lg:flex">
        <div className="flex-1">
          <SearchInputField setQueryParams={setQueryParams} />
        </div>
        {hasFilters ? (
          <>
            <div className="flex gap-x-2 items-center mr-8">
              <p className="mb-0 text-2xs font-semibold text-gray-600 uppercase">Sorted by</p>
              <div className="w-28">
                <Dropdown placeholder={searchStore.searchFilters.sort || 'Popular'} options={filterOptions.sort.slice()} onChange={changeSort} onBlur={false} classes="text-sm" />
              </div>
            </div>
            <BrowseResultsViewButtons resultsView={resultsView} changeResultsView={changeResultsView} />
          </>
        ) : null}
      </div>
      <div className="flex sticky top-0 z-50 gap-x-2 items-center py-3 px-6 h-16 bg-white border-b-xs border-gray-300 lg:hidden lg:gap-x-4">
        <button
          className={`flex gap-x-2 justify-center items-center py-2 px-4 w-30 h-10 text-sm rounded-sm border-xs border-gray-400 lg:w-[108px] ${
            hasFilters ? 'text-black font-semibold' : 'text-gray-600'
          }`}
          type="button"
          aria-label="Toggle Filters Selection"
          onClick={() => setMobileSidebarOpen(!mobileSidebarOpen)}
        >
          <Icon name="filter" className="w-5 h-6" active={hasFilters} />
          Filter
          <span className="block">{hasFilters ? `(${filtersCount})` : null}</span>
        </button>
        {hasFilters ? (
          <>
            <Dropdown
              placeholder={searchStore.searchFilters.sort || 'Popular'}
              options={filterOptions.sort.slice()}
              onChange={changeSort}
              onBlur={false}
              classes="text-sm ml-auto"
            />
            <div className="flex items-center">
              <BrowseResultsViewButtons resultsView={resultsView} changeResultsView={changeResultsView} />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

function BrowseBody({
  view,
  resultsView,
  mobileSidebarOpen,
  sectionBanners,
  loading,
  data,
  error,
  facets,
  filtersCount,
  hasFilters,
  filterOptions,
  changeSort,
  addFilter,
  removeFilter,
  removeAllFilters,
  changeResultsView,
  changeCategoryView,
  setMobileSidebarOpen,
  setQueryParams,
  cipPromoData,
  searchStore,
  userStore,
  pageChange,
  handleSectionBannerDismiss,
  contentView,
  handleHighlightView,
  permissions,
}) {
  return (
    <div className="flex justify-between">
      <div className="hidden w-[245px] lg:block">
        <BrowseFilters
          loading={loading}
          data={data}
          facets={facets}
          searchFilters={searchStore.searchFilters.filters}
          view={view}
          filtersCount={filtersCount}
          addFilter={addFilter}
          removeFilter={removeFilter}
          removeAllFilters={removeAllFilters}
          changeCategoryView={changeCategoryView}
          contentView={contentView}
          handleHighlightView={handleHighlightView}
          certCount={searchStore.certCount}
          containerClasses="ml-4"
          isDefaultOpen
        />
      </div>
      <div className="w-full lg:w-[1120px]">
        <DisplayControlContainer
          setQueryParams={setQueryParams}
          hasFilters={hasFilters}
          searchStore={searchStore}
          filtersCount={filtersCount}
          filterOptions={filterOptions}
          changeSort={changeSort}
          resultsView={resultsView}
          changeResultsView={changeResultsView}
          mobileSidebarOpen={mobileSidebarOpen}
          setMobileSidebarOpen={setMobileSidebarOpen}
        />
        <SearchResults
          view={view}
          resultsView={resultsView}
          sectionBanners={sectionBanners}
          loading={loading}
          error={error}
          data={data}
          facets={facets}
          cipPromoData={cipPromoData}
          currentPage={searchStore.searchFilters.page}
          pageChange={pageChange}
          changeCategoryView={changeCategoryView}
          handleSectionBannerDismiss={handleSectionBannerDismiss}
          userStore={userStore}
          searchStore={searchStore}
          addToPathOption={Object.keys(userStore.teamsWithPathsAdminAccess).length}
        />
        <CertificationsAndVendors view={view} filtersCount={filtersCount} permissions={permissions} addFilter={addFilter} userStore={userStore} />
      </div>
    </div>
  );
}

const BrowseRefined = inject(
  'authStore',
  'commonStore',
  'searchStore',
  'userStore'
)(
  observer(({ authStore, commonStore, searchStore, userStore, location, navigate }) => {
    const [view, setView] = useState('all');
    const [contentView, setContentView] = useState('all');
    const [resultsView, setResultsView] = useState('grid');
    const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
    const [sectionBanners, setSectionBanners] = useState([]);
    const [isPageInitialized, setIsPageInitialized] = useState(false);

    const setQueryParams = () => {
      const { searchFilters } = searchStore;

      const params = getQueryParams(searchFilters, view, resultsView);

      navigate({
        search: params,
      });
    };

    const changeCategoryView = (selected) => {
      const category = typeof selected === 'object' ? selected.value : selected;

      setView(category || 'all');

      if (searchStore.searchFilters.q || searchStore.searchFilters.searchVal) {
        searchStore.setSearchQuery('');
        searchStore.setSearchVal('');
        searchStore.loadSearchItems();
      }
      // Reset back to page 1 when changing category view, then have to get new results
      if (searchStore.searchFilters.page > 1) {
        searchStore.pageChange(1);
        searchStore.loadSearchItems();
      }
      // If switching to All view and currently sorted by Newest or Relevance, set sort back to default and get default results sorting (Popular)
      if (category === 'all' && (searchStore.searchFilters.sort === 'Newest' || searchStore.searchFilters.sort === 'Relevance')) {
        searchStore.setSort('');
        searchStore.loadSearchItems();
      }

      // Fire event to track category (Activity Type) change
      trackSnowplowEvent({
        category: 'ExplorePageSearchFilter',
        action: 'changeCategoryView',
        label: category || 'all', // What the user changed the category to (or 'all' if they cleared the category)
        property: 'Category filter updated on Explore Page', // Description of the action
      });
    };

    const addFilter = (facet, filter) => {
      if (facet === 'q') {
        searchStore.setSearchQuery(filter);
        searchStore.setSearchVal(filter);
      } else {
        searchStore.addFilter(facet, filter);
      }
      searchStore.pageChange(1);
      setQueryParams();
      searchStore.loadSearchItems();
      addFocusToFilterHeader(facet, filter);
      // fire event when a filter (Content Filter/Domains/etc...) is added to the search
      trackSnowplowEvent({
        category: 'ExplorePageSearchFilter',
        action: `addFilter|${facet}`,
        label: filter, // The value we applied to the facet
        property: `${facet} Filter added on Explore Page`, // Description of the action
      });
    };

    const removeFilter = (facet, filter) => {
      const removedFacet = searchStore.removeFilter(facet, filter);
      searchStore.pageChange(1);
      setQueryParams();
      searchStore.loadSearchItems();
      addFocusToFilterHeader(facet, filter);
      // fire event when a filter is removed to the search
      trackSnowplowEvent({
        category: 'ExplorePageSearchFilter',
        action: `removeFilter|${facet}`,
        label: removedFacet, // The value we removed from the facet
        property: `${facet} Filter removed on Explore Page`, // Description of the action
      });
    };

    const removeAllFilters = () => {
      setView('all');
      setContentView('all');
      searchStore.setDefaultSearchItems();
      searchStore.setDefaultSearchFilters();
      setQueryParams();
      searchStore.loadSearchItems();
      // fire event when all filters are removed from the search
      trackSnowplowEvent({
        category: 'ExplorePageSearchFilter',
        action: `removeAllFilters`,
        property: `All Filters removed on Explore Page`, // Description of the action
      });
    };

    const pageChange = (page) => {
      searchStore.pageChange(page);
      setQueryParams();
      searchStore.loadSearchItems();
    };

    const changeSort = (sort) => {
      searchStore.setSort(sort.value);
      searchStore.pageChange(1);
      setQueryParams();
      searchStore.loadSearchItems();
    };

    const licenseChange = (value) => {
      searchStore.addLicense(value);
      searchStore.pageChange(1);
      setQueryParams();
      searchStore.loadSearchItems();
    };

    const handleSectionBannerDismiss = (sectionName) => {
      dismissBanner(sectionBanners, setSectionBanners, sectionName);
    };

    const handleHighlightView = (special) => {
      // reset to default search data
      searchStore.setDefaultSearchItems();
      searchStore.setDefaultSearchFilters();
      const { facet, filter, value } = special;
      if (['coming', 'new'].indexOf(value) !== -1) {
        addFilter(facet, filter);
      } else if (value === 'license') {
        licenseChange(filter);
      } else {
        removeAllFilters();
      }
      setContentView(value);
    };

    useEffect(() => {
      commonStore.setPageTitle('Browse | Cybrary');
      authStore.fireAttributionEvent();

      init(setView, setResultsView, setContentView, location, searchStore);

      const userId = `${userStore.user.id}`;
      searchStore.setUserIdForSearchInsights(userId);
      searchStore.getCIPPromoData(userStore.user);

      const sectionBannersCookie = Cookies.get(`browse_refined_section_banners`);
      if (sectionBannersCookie) {
        const banners = JSON.parse(sectionBannersCookie);
        setSectionBanners(banners);
      }

      const setPromoBannerHeight = debounce(() => {
        commonStore.setPromoBannerHeight();
      }, 400);

      window.addEventListener('resize', setPromoBannerHeight);

      setIsPageInitialized(true);

      ActionUtil.scrollToTop();
      return () => {
        window.removeEventListener('resize', setPromoBannerHeight);
        searchStore.setDefaultSearchItems();
        searchStore.setDefaultSearchFilters();
      };
    }, []);

    // Single Select filter update dependant on local state changes
    useEffect(() => {
      setQueryParams();
    }, [view, resultsView]);

    useEffect(() => {
      // external routing in this case refers to any route changes not triggered by interactions within the explore page,
      // i.e., navigation links
      const isExternalRouting = location?.search && location.search.includes('view=') && !location.search.includes(`view=${view}`);

      if (isPageInitialized && isExternalRouting) {
        const queryParams = queryString.parse(location.search);
        changeCategoryView(queryParams.view);
      }
    }, [location?.search]);

    const { permissions } = userStore;
    const { cipPromoData, searchItems } = searchStore;

    const { loading, error, facets, data } = searchItems;
    const filtersCount = getFiltersCount(searchStore.searchFilters, view);
    const hasFilters = filtersCount !== 0;
    const filterOptions = FilterUtil.getFilterOptions();

    const commonProps = {
      view,
      loading,
      data,
      facets,
      filtersCount,
      contentView,
      addFilter,
      removeFilter,
      removeAllFilters,
      changeCategoryView,
      handleHighlightView,
    };

    return (
      <Container omitPadding size="browse-refined" className="lg:min-h-screen">
        <CIPUpgradeBanner />
        <Title title="Explore" wrapperClasses="hidden lg:block lg:ml-4 pl-4 md:pl-0" />
        <BrowseBody
          resultsView={resultsView}
          mobileSidebarOpen={mobileSidebarOpen}
          sectionBanners={sectionBanners}
          error={error}
          hasFilters={hasFilters}
          filterOptions={filterOptions}
          cipPromoData={cipPromoData}
          permissions={permissions}
          searchStore={searchStore}
          userStore={userStore}
          changeSort={changeSort}
          changeResultsView={(selected) => setResultsView(selected)}
          setMobileSidebarOpen={setMobileSidebarOpen}
          setQueryParams={setQueryParams}
          pageChange={pageChange}
          handleSectionBannerDismiss={handleSectionBannerDismiss}
          {...commonProps}
        />
        <FiltersSidebar mobileSidebar={mobileSidebarOpen} toggleMobileSidebar={setMobileSidebarOpen}>
          <BrowseFilters
            searchFilters={searchStore.searchFilters.filters}
            certCount={searchStore.certCount}
            containerClasses="relative [height:calc(100vh-72px)] overflow-y-scroll overflow-x-hidden"
            filterClasses="p-4"
            isDefaultOpen
            {...commonProps}
          />
        </FiltersSidebar>
      </Container>
    );
  })
);

export default withRouter(BrowseRefined);
