import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { twMerge } from 'tailwind-merge';
import { trackSelfDescribingEvent } from '@snowplow/browser-tracker';
import AddLink from '../../../AddLink/AddLink';
import { BASE_BUTTON_STYLES } from '../../../../constants';
import { trackSnowplowEvent } from '../../../../utils/snowplowUtil';

function UserAccessDeniedPrompt({ userId = -1 }) {
  return (
    <div className="flex flex-row items-center mx-2 mt-6">
      <div className="flex flex-1">
        <Vimeo className="w-full" id="video-player-wrapper" autoplay responsive speed video="925413515" />
      </div>
      <div className="flex flex-1 justify-center">
        <div className="px-15">
          <p className="text-md">
            <b>Baseline is only available to Cybrary for Teams Customers</b>
          </p>
          <p className="text-sm">
            <b>Empower Your Team with Practical Assessments:</b> Measure your team&apos;s cybersecurity aptitude against critical job roles through practical evaluations, offering
            a clear understanding of current capabilities and enabling informed decisions about security training.
          </p>
          <p className="text-sm">
            <b>Stand Tall on Global Scale:</b> Cybrary&apos;s Global Benchmarking feature allows you to compare your team&apos;s performance against a worldwide community of
            cybersecurity practitioners, providing critical insights into your global standing and identifying areas for improvement.
          </p>
          <p className="text-sm">
            <b>Streamline Career Progression:</b> Cybrary Baseline isn&apos;t just an assessment tool; it&apos;s a stepping stone for career progression. Our platform identifies
            skill gaps and provides comprehensive recommendations for Career Paths, helping your team members effectively advance their careers in cybersecurity.
          </p>
          <p className="text-sm">
            <b>Comply with SEC Cyber Disclosure Rules:</b> As part of your risk management strategy, Cybrary enables you to generate reports on your team&apos;s knowledge, helping
            you comply with the recent SEC Cyber Disclosure requirements.
          </p>
          <p className="text-sm">
            <b>Unlock trending performance insights and benchmark your team&apos;s skills against data from tens of thousands of practitioners worldwide.</b>
          </p>
          <div className="mt-8 text-center">
            <AddLink
              className={twMerge(BASE_BUTTON_STYLES, 'bg-cyb-pink-500 hover:bg-pink-600')}
              to="https://www.cybrary.it/demo-request"
              onClick={() => {
                trackSnowplowEvent({ category: 'BaselineTeams', action: 'click', label: 'requestDemoButton' });
                trackSelfDescribingEvent({
                  event: {
                    schema: 'iglu:com.cybrary/BaselineDemoRequest/jsonschema/1-0-0',
                    data: {
                      event: 'BaselineDemoRequest',
                      userId,
                    },
                  },
                });
              }}
              target="_blank"
            >
              Request Demo
            </AddLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAccessDeniedPrompt;
