import React from 'react';
import moment from 'moment-timezone';
import { isObservableObject } from 'mobx';
import supportsWebP from 'supports-webp';
import queryString from 'query-string';
import TimeUtil from './timeUtil';
import { CURRENCY_SYMBOLS } from '../constants';

const DEFAULT_LENGTH = 150;

/**
 * Formatter for displayed strings and numbers
 * Author EG
 */
export default class FormatUtil {
  static formatCertificateDownloadUrl = (certificateId) => {
    return `${process.env.REACT_APP_COURSE_API_URL}/certificate/${certificateId}/view`;
  };

  /**
   * Truncate text
   * @param text
   * @param length
   * @returns {*}
   */
  static formatLongText = (text, length = DEFAULT_LENGTH) => {
    if (text && text.length > length) {
      return `${text.substr(0, length)}...`;
    }
    return text;
  };

  /**
   * Truncate a string down to specified number of words
   * @param text string
   * @param length int
   * returns string
   */
  static truncateString = (text, length) => {
    let retStr = text;
    if (text && text.length) {
      const strArr = text.split(' ');
      if (strArr.length > length) {
        retStr = `${strArr.splice(0, length).join(' ')}...`;
      }
    }
    return retStr;
  };

  /**
   * Lower case a string and replace the spaces with hyphens.
   * @param text string
   * returns string
   */
  static lowerCaseHyphenText = (text) => {
    if (!text) {
      return null;
    }
    return text.replace(/\s+/g, '-').toLowerCase();
  };

  /**
   * Format a module's title
   * @param {string} title - the title of the module we are formatting
   * @param {int} num - which module is it?
   */
  static formatModuleTitle(title, num) {
    // Module Patterns:
    // "Module 1 - "
    // "Module 1: "
    // "Domain 1"

    const stripped = title.replace(/^(module|domain|section)\s\d+\s*(:|-)?\s*/i, '');
    if (!stripped) {
      // Just return the original string in this case
      return title;
    }
    return `Module ${num}: ${stripped}`;
  }

  /**
   * Removes the prefix (1.1, 2.3, etc) and adds the module and activity number if provided.
   * @param {string} title - the title of the activity we are formatting
   * @param {int} module - which module is it?
   * @param {int} num - which activity is it?
   */
  static formatActivityTitle(title, module, num) {
    // Learning Activity Patterns:
    // "1.1"
    // "1.01"
    // "Layer 1 - "
    // "Part 1 - "
    // "Part 1.1 - "

    const stripped = title.replace(/^(part|layer|(\d+\.*\d*))\s(\d+\.?\d*)?\s*(:|-)?\s*/i, '');
    if (!stripped) {
      // Just return the original string in this case
      return title;
    }
    if (!module || !num) {
      // just return the stripped in this case
      return stripped;
    }
    return `${module}.${num} ${stripped}`;
  }

  /**
   * Equivalent of ucwords from PHP, capitalize the first letter for each word in a string.
   */
  static ucwords = (str) => {
    return `${str}`.replace(/^(.)|\s+(.)/g, ($1) => {
      return $1.toUpperCase();
    });
  };

  /**
   * Uppercase first letter of string
   */
  static ucFirstLetter = (str) => {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
  };

  // turn 'virtual-labs' into 'Virtual Labs'
  static replaceHyphensWithSpace = (text) => {
    return FormatUtil.ucwords(text.replace(/-/g, ' '));
  };

  /**
   * Convert a string to a slug
   */
  static slugify = (str) => {
    if (typeof str !== 'string') {
      return str;
    }

    return str
      .toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-');
  };

  /**
   * Perform the logic from the highlight.js component, but inside a function (returning a string)
   */
  static formatHighlight = (text, highlightClass, pattern) => {
    let output = '';

    const highlightPattern = pattern || '$$$';
    const className = highlightClass || 'highlight';
    // Look for our pattern in text, splitting text
    const parts = text ? text.split(highlightPattern) : null;

    if (!parts || !parts.length) {
      return output;
    }

    // Any odd "part" is one of our matches
    parts.forEach((part, i) => {
      if (i % 2) {
        output += ` <span class="${className}">${part}</span> `;
      } else {
        output += part;
      }
    });

    return output;
  };

  /**
   *  Display human readable time
   *  Shorthand for time util
   * @param display
   * @param duration (in seconds)
   * @returns {*}
   */
  static formatTime = (duration, display) => {
    if (!duration) {
      return '';
    }
    switch (display) {
      case 'colon': // HH:MM:SS
        return TimeUtil.convertDurationToColonDelimitedDisplay(duration);
      case 'hm':
        return TimeUtil.convertDurationToHoursAndMinutesDisplay(duration);
      case 'hma':
        return TimeUtil.convertDurationToHoursAndMinutesDisplay(duration, true);
      case 'dhm':
      default:
        // DDdays HHhr(s) MMmin(s)
        return TimeUtil.convertDurationToDaysHoursAndMinutesDisplay(duration);
    }
  };

  /**
   * Format the times
   * @param date
   * @returns {string}
   */
  static formatResumeDate = (date) => {
    if (!date) {
      return 'present';
    }
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const formatDate = new Date(Date.parse(date));
    return `${monthNames[formatDate.getMonth()]} ${formatDate.getFullYear()}`;
  };

  /**
   * Sort items in descending order
   * @param items array
   * @param term string
   * returns {array}
   */
  static sortItemsDES = (items, term) => {
    return items.sort((a, b) => {
      const c = new Date(a[term]);
      const d = new Date(b[term]);
      return d - c;
    });
  };

  static hm = (secs) => {
    let minutes = Math.floor(secs / 60);
    const hours = Math.floor(minutes / 60);
    minutes %= 60;

    const h = hours ? `${hours} ${this.pluralize(hours, 'hour')}` : '';
    const m = minutes ? `${minutes} ${this.pluralize(minutes, 'minute')}` : '';

    return `${h} ${m}`;
  };

  static correctImageUrl(url) {
    if (url.indexOf('https') === 0 || url.indexOf('gravatar') > -1) {
      return url;
    }
    if (url.indexOf('assets.cybrary.it') === 0) {
      return `https://${url}`;
    }
    if (url.indexOf('/') === 0) {
      return `${process.env.REACT_APP_V2_SITE_URL}${url}`;
    }
    return `${process.env.REACT_APP_V2_SITE_URL}/${url}`;
  }

  /**
   * Zip two arrays together
   * @param a1
   * @param a2
   * @returns {*}
   */
  static zipArrays = (a1, a2) => a1.map((x, i) => [x, a2[i]]);

  /**
   * Check a string to determine the indefinite article to use before it
   * @param string
   * @returns {string}
   */
  static indefiniteArticleCheck = (string) => {
    const nounArray = ['a', 'e', 'i', 'o', 'u'];
    const firstLetter = string.substring(0, 1);
    if (nounArray.includes(firstLetter)) {
      return `an ${string}`;
    }
    return `a ${string}`;
  };

  /**
   * Convert role into readable name
   * @param level
   * @param role
   * @returns {string}
   */
  static convertRoleName = (role, level) => {
    const stringLevel = level || ' ';
    const stringRole = role || '';
    return FormatUtil.ucwords(stringRole.replace(/-/g, ' ').replace('team', stringLevel));
  };

  /**
   * get the key from an array of objects
   */
  static getKeyFromArray = (items, key) => {
    const array = [];
    if (items && items.length) {
      items.forEach((item) => {
        if (item[key]) {
          array.push(item[key]);
        } else {
          array.push(item);
        }
      });
    }
    return array;
  };

  /**
   * check if two arrays have same values
   * @param a1
   * @param a2
   * @returns bool
   */
  static areArraysSame = (a1, a2) => {
    if (!a1.length && !a2.length) {
      return true;
    }
    const superSet = {};
    for (let i = 0; i < a1.length; i++) {
      const e = a1[i] + typeof a1[i];
      superSet[e] = 1;
    }

    for (let i = 0; i < a2.length; i++) {
      const e = a2[i] + typeof a2[i];
      if (!superSet[e]) {
        return false;
      }
      superSet[e] = 2;
    }

    const keys = Object.keys(superSet);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (superSet[key] === 1) {
        return false;
      }
    }

    return true;
  };

  static formatContentType = (type) => {
    let name = '';
    switch (type) {
      case 'MicroCourse':
        name = 'Course';
        break;
      case 'CyDefe Assessment':
      case 'Interview Mocha Test':
      case 'iMocha Test':
      case 'Next Tech Assessment':
      case 'Next Tech Project':
      case 'Next Tech Challenge':
      case 'Avatao Challenge':
      case 'Rangeforce Assessment':
      case 'Learn on Demand Assessment':
        name = 'Assessment';
        break;
      case 'Kaplan Practice Test':
      case 'CyberVista Practice Test':
      case 'Practice Labs Exam':
      case 'Next Tech Practice Test':
      case 'Next Tech Quiz':
        name = 'Practice Test';
        break;
      case 'Practice Labs Lab':
      case 'Practice Labs Module':
      case 'Practice Labs Lab Module':
      case 'Next Tech Course':
      case 'Next.Tech Course':
      case 'Avatao Course':
      case 'Avatao Tutorial':
      case 'Rangeforce Lab':
      case 'Rangeforce':
      case 'CyberScore Lab':
      case 'Learn on Demand IT Pro Challenges':
      case 'LabBundle':
      case 'Next Tech Lab':
      case 'Next Tech Activity':
      case 'Next Tech Lesson':
      case 'Infosec Lab':
        name = 'Virtual Lab';
        break;
      case 'Cybrary Live Series':
        name = 'Cybrary Live';
        break;
      case 'Video Activity':
        name = 'On Demand Video';
        break;
      case 'Article Activity':
      case 'Next Tech Reading':
      case 'Next Tech Article':
        name = 'Reading Activity';
        break;
      default:
        name = type;
    }
    return name;
  };

  /**
   * Format numbers with commas
   */
  static formatNumbers = (num) => {
    if (!num) return 0;
    const parts = num.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };

  /**
   * Return total of all counts - Used for stat cards/ chartJS data formatting
   */
  static getItemsCount = (obj) => {
    let total = 0;
    const segments = Object.keys(obj);
    segments.forEach((segment) => {
      total += obj[segment].count;
    });
    return total;
  };

  /**
   * Decorate large integers with commas
   */
  static numberWithCommas = (number) => {
    return number ? number.toLocaleString('en', { useGrouping: true }) : 0;
  };

  /*
   * Return a human readable label for a metric
   */
  static getMetricLabel = (metric) => {
    const lowerCaseMetric = metric.toLowerCase();
    const labelMappings = {
      ceu: 'CEU',
      'completeceus?assigned': 'Learning Hours',
      completeceus: 'CEU',
      completeactivities: 'Learning Activity Interactions',
      'completeactivities?contenttype': 'Learning Activities',
      'completeactivities?assigned': 'Completed',
      'completeactivities?unassigned': 'Unassigned',
      'completeactivities?contenttype:hands-on': 'Hands-on',
      'completeactivities?contenttype:video': 'Video',
      'completeactivities?contenttype:other': 'Other',
      'completelearninghours?assigned': 'Assigned',
      completeassignments: 'Completed',
      incompleteassignments: 'Incomplete',
      completelearninghours: 'Total Learning Hours',
      'completelearninghours?unassigned': 'Additional Learning Hours',
      averageperday: 'Average Per Day',
      enrolledactivities: 'Total Enrollments',
      'enrolledactivities?unique': 'Unique Users',
      averagescore: 'Average Score',
      proficientactivities: 'Proficient Attempts',
    };
    if (labelMappings[lowerCaseMetric]) {
      return labelMappings[lowerCaseMetric];
    }
    const formattedMetric = metric.split('_').join(' ');
    return formattedMetric.charAt(0).toUpperCase() + formattedMetric.substring(1);
  };

  /*
   * Get the proficiency level based on score range
   */
  static getProficiencyLevel = (score) => {
    if (score > 76) {
      return 'Proficient';
    }
    if (score > 51) {
      return 'Advanced';
    }
    if (score > 26) {
      return 'Intermediate';
    }
    return 'Novice';
  };

  /**
   * Pluralize string based off count
   */
  static pluralize = (count, noun, suffix = 's') => {
    const lastCharacter = noun.charAt(noun.length - 1);
    // if the last character is y, check if its plural... if it is replace the last character of the noun (y) with ies
    if (lastCharacter === 'y') {
      return `${count !== 1 ? noun.replace(/.$/, 'ies') : noun}`;
    }
    return `${noun}${count !== 1 ? suffix : ''}`;
  };

  /**
   * Get the index of a specified table column. Expects that the data is in the standard tabular format from the backend
   */
  static getColIndex = (data, col) => {
    if (!data) {
      return null;
    }
    // If this isn't the array of columns, assuming it is the overall table obj
    const loopData = isObservableObject(data) ? data.columns : data;
    let colIdx = -1;
    for (let i = 0; i < loopData.length; i++) {
      if (loopData[i].key === col) {
        colIdx = i;
        break;
      }
    }
    return colIdx;
  };

  static convertTableDataToList = (headings, data) => {
    if (!data || !data.length) {
      return null;
    }
    const listData = [];
    data.forEach((row) => {
      const listItem = {};
      row.forEach((item, idx) => {
        listItem[headings[idx].key] = item.value;
      });
      listData.push(listItem);
    });
    return listData;
  };

  /**
   * Convert seconds to hours
   * @param {int} seconds - Seconds
   * @param {bool} roundDown - Round down to the nearest whole number?
   * @param {int} decimalLength - How many decimal places to show. Default = 2
   */
  static convertSecondsToHours = (seconds, roundDown, decimalLength = 2) => {
    if (!seconds || 1 * seconds === 0) {
      return 0;
    }
    const minutes = (1 * seconds) / 60;
    const hours = minutes / 60;
    return roundDown ? Math.floor(hours) : hours.toFixed(decimalLength);
  };

  /**
   * Convert hours to seconds - Used in goals to translate learning hours to seconds
   */
  static convertHoursToSeconds = (hoursData) => {
    // eslint-disable-next-line no-restricted-globals
    const hours = isNaN(hoursData) ? hoursData.value : hoursData;
    const minutes = hours * 60;

    return minutes * 60;
  };

  static replaceInString = (string, replace, replaceWith) => {
    const str = string.replace(replace, replaceWith);
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  };

  static getActivityTypes = (modules) => {
    if (!modules && !modules.length) {
      return null;
    }
    const activityTypes = {};
    modules.forEach((module) => {
      if (module.lessons && module.lessons.length) {
        module.lessons.forEach((lesson) => {
          const isFree = lesson.content.is_free;
          // Check if the activityTypes obj contains this activity type of not. If not, add it
          if (lesson.content && lesson.content.type) {
            if (Object.keys(activityTypes).indexOf(lesson.content.type) === -1) {
              activityTypes[lesson.content.type] = {
                hasPaidActivity: false,
              };
            }
            // Check if this activity is free or not. If not, sets hasPaidActivity to true so we know there's at least one paid instance of this activity type
            if (!isFree) {
              activityTypes[lesson.content.type].hasPaidActivity = true;
            }
          }
        });
      }
    });
    return activityTypes;
  };

  static checkOffersLabs = (activityTypes) => {
    const types = Array.isArray(activityTypes) ? activityTypes : Object.keys(activityTypes);
    let offersLabs = false;
    const labTypes = [
      'Lab',
      'Practice Labs Lab',
      'Practice Labs',
      'Next Tech Lab',
      'Next Tech Activity',
      'Next Tech Lesson',
      'Avatao Tutorial',
      'CyberScore Lab',
      'CyberScore',
      'Cyberscore',
      'Rangeforce Lab',
      'Rangeforce',
      'Learn on Demand IT Pro Challenges',
      'Learn On Demand Pro Series',
      'Infosec Lab',
      'Virtual Lab',
    ];

    for (let i = 0; i < types.length; i++) {
      if (labTypes.indexOf(types[i]) > -1) {
        offersLabs = true;
        break;
      }
    }
    return offersLabs;
  };

  static getModalityMeta = (type) => {
    // Checking on lowercased value because there are some that only have differences in casing -- CyberScore vs Cyberscore
    const lowerCaseType = type ? type.toLowerCase() : null;
    switch (lowerCaseType) {
      // Labs
      case 'lab':
      case 'practice labs lab':
      case 'practice labs':
      case 'practice labs lab module':
      case 'next tech course':
      case 'next tech lab':
      case 'next tech lab module':
      case 'next tech activity':
      case 'next tech lesson':
      case 'avatao course':
      case 'avatao tutorial':
      case 'cyberscore lab':
      case 'cyberscore':
      case 'rangeforce lab':
      case 'rangeforce':
      case 'learn on demand it pro challenges':
      case 'learn on demand pro series':
      case 'infosec lab':
      case 'virtual lab':
        return {
          icon: 'flask',
          description: (
            <span>
              <strong>Virtual Labs</strong>
              <span> to gain hands on experience and apply what you learned</span>
            </span>
          ),
          type: 'Lab',
        };
      // Assessments
      case 'assessment':
      case 'cydefe assessment':
      case 'cydefe':
      case 'interview mocha test':
      case 'imocha test':
      case 'next tech assessment':
      case 'next tech project':
      case 'next tech challenge':
      case 'avatao challenge':
      case 'rangeforce assessment':
      case 'learn on demand assessment':
        return {
          icon: 'pencil',
          description: (
            <span>
              <strong>Assessments</strong>
              <span> to gauge understanding and comprehension</span>
            </span>
          ),
          type: 'Assessment',
        };
      // Practice Tests
      case 'practice test':
      case 'kaplan practice test':
      case 'cybervista practice test':
      case 'practice labs exam':
      case 'next tech practice test':
      case 'next tech quiz':
        return {
          icon: 'clipboard-list',
          description: (
            <span>
              <strong>Practice Tests</strong>
              <span> to check your knowledge and prepare for certifications</span>
            </span>
          ),
          type: 'Practice Test',
        };
      // Articles
      case 'article':
      case 'article activity':
      case 'next tech reading':
      case 'next tech article':
        return {
          icon: 'book',
          description: (
            <span>
              <strong>Reading Activities</strong>
              <span> to provide background knowledge and key concepts crucial to the course</span>
            </span>
          ),
          type: 'Article',
        };
      // Videos
      case 'video':
      case 'video activity':
        return {
          icon: 'video',
          description: (
            <span>
              <strong>On Demand Videos</strong>
              <span> to learn from industry leaders</span>
            </span>
          ),
          type: 'Video',
        };
      default:
        return {
          icon: 'circle',
          description: null,
          type: 'Other',
        };
    }
  };

  static removeDuplicateObjectFromArray(array, key) {
    const check = new Set();
    return array.filter((obj) => !check.has(obj[key]) && check.add(obj[key]));
  }

  static getModalitiesByContentType = (types) => {
    const modalities = {};
    if (!!types && Object.keys(types).length) {
      Object.keys(types).forEach((type) => {
        const modality = FormatUtil.getModalityMeta(type);
        const { hasPaidActivity } = types[type];
        // Check if the modality obj contains this modality type of not. If not, add it
        if (!!modality.type && Object.keys(modalities).indexOf(modality) === -1) {
          modalities[modality.type] = {
            hasPaidActivity: false,
          };
        }
        // Check if this activity is free or not. If not, sets hasPaidActivity to true so we know there's at least one paid instance of an activity for this modality type
        if (hasPaidActivity) {
          modalities[modality.type].hasPaidActivity = true;
        }
      });
    }
    return modalities;
  };

  // Force a newline break on @ in emails - Helps with slimming down certain table columns
  static breakEmails = (email) => {
    if (email.indexOf('@') === -1) {
      return email;
    }

    return email.split('@').join('\n@');
  };

  /**
   * Get the colors scheme for the promotional banner
   */
  static getColorsScheme = (data) => {
    const { textColor, backgroundColor, backgroundImage } = data;

    const pink = '#e14696';
    const pinkLabel = '#D2227D';
    const white = '#fff';
    const whiteLabel = '#E6E6E6';
    const black = '#000';
    const blackLabel = '#1A1A1A';
    const green = '#0db350';
    const greenLabel = '#10E365';
    const scheme = {
      background: green,
      color: white,
      labelBackground: greenLabel,
    }; // default colors if none are provided

    if (textColor === 'Black') {
      scheme.color = black;
    }

    if (backgroundColor === 'Pink') {
      scheme.background = pink;
      scheme.labelBackground = pinkLabel;
    } else if (backgroundColor === 'Black') {
      scheme.background = black;
      scheme.labelBackground = blackLabel;
    } else if (backgroundColor === 'White') {
      scheme.background = white;
      scheme.labelBackground = whiteLabel;
    }

    if (!!backgroundImage && backgroundImage.fields && backgroundImage.fields.file && backgroundImage.fields.file.url) {
      scheme.background = `${scheme.background} url("${backgroundImage.fields.file.url}?w=1920") no-repeat center top/cover`;
    }

    return scheme;
  };

  static formatPrice = (price, withCurrency) => {
    if (!price) {
      return null;
    }
    const hasCurrencySymbol = !Number.isInteger(price.charAt(0));
    let formattedPrice = price;
    // remove $ symbol
    if (hasCurrencySymbol) {
      formattedPrice = formattedPrice.substring(1);
    }

    // split dollars from cents
    const format = formattedPrice.split('.');

    if (format.length > 1) {
      const dollar = format[0];
      const cent = format[1];
      formattedPrice = (
        <>
          {withCurrency && hasCurrencySymbol ? price.charAt(0) : null}
          {dollar}
          <span style={{ fontSize: '.5em', position: 'relative' }}>.{cent}</span>
        </>
      );
    }

    return formattedPrice;
  };

  static formatPriceWithSymbol = (price, currencyCode) => {
    if (!price) {
      return null;
    }
    const parsedPrice = parseFloat(price).toFixed(2); // 59 => "59.00"
    const currencySymbol = FormatUtil.convertCurrencyCodeToSymbol(currencyCode); // USD => "$""
    return `${currencySymbol}${parsedPrice.toLocaleString(null, { style: 'currency' })}`; // "$59.00"
  };

  static convertCurrencyCodeToSymbol = (currency) => {
    return CURRENCY_SYMBOLS[currency] || '$';
  };

  static convertCentsToDollars = (cents, currencyCode) => {
    const dollars = (cents / 100).toFixed(2); // 5900 / 100 => "59.00"
    const currencySymbol = FormatUtil.convertCurrencyCodeToSymbol(currencyCode); // USD => "$"
    return `${currencySymbol}${dollars}`; // "$59.00"
  };

  static stripNumberFromActivityTitle = (title) => {
    return title.replace(/^(part|layer|(\d+\.*\d*))\s(\d+\.?\d*)?\s*(:|-)?\s*/i, '');
  };

  static convertUtcToLocal = (d) => {
    return moment.tz(d.replace('T', ' ').replace('.000000Z', ''), 'Etc/UTC').local();
  };

  static formatGroupInfo = (groupInfo) => {
    if (!groupInfo) {
      return [];
    }

    return groupInfo.map((val) => {
      return val.toLowerCase().replace(' ', '-');
    });
  };

  static getTeamRoleMeta = (role) => {
    const meta = {};
    switch (role) {
      case 'org-owner':
        meta.color = 'orange';
        meta.tooltip = 'An organization owner is the primary owner of the account and has full administrative privileges';
        break;
      case 'team-admin':
        meta.color = 'purple';
        meta.tooltip = 'An admin has the ability to manage users and access reports across the organization';
        break;
      case 'team-reporting-admin':
        meta.color = 'grey';
        meta.tooltip = 'A reporting admin has the ability to access reports across the organization';
        break;
      case 'team-member':
      default:
        meta.color = 'blue';
        meta.tooltip = 'A member is any member of the team and does not have any administrative privileges';
        break;
    }
    return meta;
  };

  /**
   * Sort an array of objects by objects key(s). Currently can pass two keys, sorting on the first, then the second provided key, if provided
   */
  static sortArrayObjects = (array, sortKey1, sortKey2) => {
    if (!array || !sortKey1) {
      return array;
    }

    const arrayCopy = [...array];
    arrayCopy.sort((item1, item2) => {
      const itemAKey1 = item1[sortKey1].toUpperCase();
      const itemBKey1 = item2[sortKey1].toUpperCase();

      let comparison = 0;
      // Compare object key values from key 1
      if (itemAKey1 > itemBKey1) {
        comparison = 1;
      } else if (itemAKey1 < itemBKey1) {
        comparison = -1;
      } else if (itemAKey1 === itemBKey1 && sortKey2) {
        // Compare object key values from key 2
        const itemAKey2 = item1[sortKey2].toUpperCase();
        const itemBKey2 = item2[sortKey2].toUpperCase();
        if (itemAKey2 > itemBKey2) {
          comparison = 1;
        } else if (itemAKey2 < itemBKey2) {
          comparison = -1;
        }
      }
      return comparison;
    });
    return arrayCopy;
  };

  static canSupportWebP() {
    return supportsWebP;
  }

  static imgUrlHeightDimension(imageUrl, defaultHeight) {
    let height = null;
    if (imageUrl) {
      height = FormatUtil.getQueryString('h', imageUrl);
    } else {
      height = defaultHeight;
    }
    return height;
  }

  static imgUrlWidthDimension(imageUrl, defaultWidth) {
    let width = null;
    if (imageUrl) {
      width = FormatUtil.getQueryString('w', imageUrl);
    } else {
      width = defaultWidth;
    }
    return width;
  }

  static getQueryString(field, imageUrl) {
    let url = imageUrl;
    const pattern = /^((https):\/\/)/;
    if (!pattern.test(imageUrl)) {
      url = `https:${imageUrl}`;
    }
    try {
      const constructedUrl = new URL(url);
      const params = queryString.parse(constructedUrl.search);
      return params[field];
    } catch {
      return false;
    }
  }

  static getAspectRatios(height, width) {
    const ratios = {};
    if (height && !width) {
      ratios.h = Math.floor(height * 1);
      ratios.w = Math.floor(height * 1 * (16 / 9));
    } else if (width && !height) {
      ratios.w = Math.floor(width * 1);
      ratios.h = Math.floor(width * 1 * (9 / 16));
    } else if (height && width) {
      ratios.h = Math.floor(height * 1);
      ratios.w = Math.floor(width * 1);
    } else {
      ratios.h = 'auto';
      ratios.w = '100%';
    }
    return ratios;
  }

  static calcPercentOff(subTotal, discountAmount) {
    return Math.floor((discountAmount / subTotal) * 100);
  }

  static breakLargeNumber(value) {
    let newValue = Number(value);
    if (value >= 1000) {
      const suffixes = ['', 'k', 'm', 'b', 't'];
      const suffixNum = Math.floor(`${value}`.length / 3);
      let shortValue = '';
      for (let precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat((suffixNum !== 0 ? value / 1000 ** suffixNum : value).toPrecision(precision));
        const dotLessShortValue = `${shortValue}`.replace(/[^a-zA-Z 0-9]+/g, '');
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  }

  static dedupArray = (arr) => {
    return [...new Set(arr)];
  };

  static dedupArrayOfObjects = (data, key) => {
    const cleanData = [];
    if (data && data.length) {
      // Get rid of any dups
      data.forEach((item) => {
        let isClean = true;
        cleanData.forEach((cleanItem) => {
          if (item[key] === cleanItem[key]) {
            isClean = false;
          }
        });
        if (isClean) {
          cleanData.push(item);
        }
      });
    }
    return cleanData;
  };

  /*  Reverse key/values in an object - For reversing maps */
  static objectFlip(obj) {
    const ret = {};
    Object.keys(obj).forEach((key) => {
      ret[obj[key]] = key;
    });
    return ret;
  }

  static getOnboardingGoalId(goal) {
    const goalMap = {
      'Start my career in Cybersecurity or IT': 'startCareer',
      'Determine if pursuing a career in Cybersecurity or IT is for me': 'determineCareer',
      'Prepare for an industry certification': 'prepareForCert',
      'Earn CEU/CPEs': 'earnCEU',
      'Learn new skills needed for my job or education': 'newSkills',
      "Develop my organization or team's skills": 'developTeamSkills',
      'Advance my career': 'advanceCareer',
      'I want to transition from IT to Cybersecurity': 'transition',
      Other: 'other',
    };
    return goal && goalMap[goal] ? goalMap[goal] : null;
  }

  /* Create a mock anchor link and initiate download for CSV with data from server */
  static downloadCSV(data, name) {
    const csvContent = `data:text/csv;charset=utf-8,${data}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', name);
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  }
}

// convert price to a float that can be used for math operations... note if it ends in .00 Float wont include it. You will need to toFixed it when returned.
// ex: string $230.44 --> float 230.44
export const priceToFloat = (price) => {
  if (!price) {
    return null;
  }
  let formattedPrice = price;
  // remove $ symbol
  formattedPrice = formattedPrice.replace('$', '');
  // remove commas
  formattedPrice = formattedPrice.replace(',', '');
  return parseFloat(formattedPrice);
};

export const getCN = (className) => {
  return className
    .replace(/(\r\n|\n|\r|undefined|false)/gm, '')
    .replace(/\s+/g, ' ')
    .trim();
};
