import React, { useEffect, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import AddLink from '../AddLink/AddLink';
import Header from '../Header/Header';
import ProfileStreaksAndSocials from '../Profile/ProfileStreaksAndSocials/ProfileStreaksAndSocials';
import { useProfileContext } from '../../providers/ProfileProvider';
import If from '../If/If';
import Loading from '../Loading/Loading';

/**
 * Profile widget to show user profile image, name, and some stats about their profile
 * @param {string} className - additional classes to add to the component
 * @returns <ProfileWidget />
 */
const ProfileWidget = inject('userStore')(
  observer(({ userStore, className }) => {
    const { userProfileStats, user } = userStore;
    const { personalProfileData, isLoading } = useProfileContext();
    const { username } = personalProfileData || {};
    const { daily_activity_streak = 0, level, experience_points } = userProfileStats || {};

    useEffect(() => {
      (async () => {
        // reload userProfileStats whenever the component mounts
        await userStore.loadUserProfileStats();
      })();
    }, []);

    const { isEnterprise, isFree, isCip, isEnterpriseAdmin } = userStore || {};
    const licenseType = useMemo(() => {
      if (isEnterprise && !isFree) {
        return 'Enterprise';
      }
      if (isEnterprise && !isFree) {
        return 'Enterprise (Free)';
      }
      if (isCip) {
        return 'Insider Pro';
      }
      return 'Free';
    }, [isEnterprise, isFree, isCip, isEnterpriseAdmin]);
    const registeredAt = useMemo(() => moment(new Date(user?.registered_at)).format('MMMM Do, YYYY'), [user?.registered_at]);

    return (
      <div className={twMerge('p-4 flex flex-col justify-evenly', className)}>
        {/** Loading State */}
        <If condition={isLoading}>
          <Header as="h2" className="mb-4">
            Profile
          </Header>
          <Loading message="Loading profile..." wrapperClassName="w-full h-36 my-1" />
        </If>
        <If condition={!isLoading}>
          <div className="pb-0 w-full min-h-[80px] border-b-2 border-slate-200">
            <div className="flex justify-between w-full">
              <Header as="h2" className="mb-4">
                Profile
              </Header>
              <AddLink to="/profile" title="View profile" className="text-cyb-pink-500 underline">
                View Profile
              </AddLink>
            </div>

            <div className="flex items-center mx-2 mt-2 mb-6 w-full">
              <AddLink to="/profile" title="View profile">
                <img
                  src={user?.avatar_url}
                  alt="avatar"
                  className="grow-0 mr-4 w-16 h-16 rounded-full border border-cyb-pink-500 transition-transform duration-300 hover:scale-105"
                />
              </AddLink>
              <div className="flex flex-col space-y-2">
                <If condition={username}>
                  <AddLink to="/profile" title="View profile">
                    <span className="text-xl font-bold text-cyb-pink-500">@{username}</span>
                  </AddLink>
                </If>
                <div className="font-bold">{user?.real_name || 'No Name Set'}</div>
                <div className="text-sm">
                  License: {licenseType} | Joined {registeredAt}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center pt-4 h-12">
            <ProfileStreaksAndSocials currentStreak={daily_activity_streak} currentLevel={level} currentXp={experience_points || '0'} />
          </div>
        </If>
      </div>
    );
  })
);
export default ProfileWidget;
