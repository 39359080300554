import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/UseAuth';
import Container from '../../components/Container/Container';
import StatusMessage from '../../components/Login/StatusMessage';
import LoginEmail from '../../components/Login/LoginEmail';
import { bootPendo } from '../../utils/pendoUtil';

const EmailLogin = inject('commonStore')(
  observer(({ commonStore }) => {
    const auth = useAuth();
    const [submitting, setSubmitting] = useState(false);
    const [storageEmail, setStorageEmail] = useState(null);
    const [status, setStatus] = useState(null);
    const navigate = useNavigate();

    const loginWithEmail = async (email) => {
      try {
        await auth.loginInWithEmailLink(email, window.location.href);
      } catch (e) {
        if (/auth\/invalid-action-code/.test(e.message)) {
          setStatus({ type: 'error', code: 'magicEmailInvalidToken' });
        } else {
          // Invalid email & all other errors message
          setStatus({ type: 'error', code: 'magicEmail' });
        }
        setSubmitting(false);
      }
    };

    const onSubmit = (data) => {
      setSubmitting(true);
      loginWithEmail(data.email);
    };

    useEffect(() => {
      commonStore.setPageTitle('Login | Cybrary');
      // If this URL isn't from a signInWithEmailLink, redirect to login
      if (auth.checkIsSignInWithEmailLink(window.location.href)) {
        // Check if we have email in storage, set when requesting magic email link. If not, need to have user confirm email
        const email = window.localStorage.getItem('emailForSignIn');
        setStorageEmail(email || null);
        if (email) {
          setSubmitting(true);
          // We have the email in storage (user is using same device as magic email request). Just sign them in
          window.localStorage.removeItem('emailForSignIn');
          loginWithEmail(email);
        }
        // Pendo - Boot anonymously
        bootPendo();
      } else {
        navigate('/login');
      }
    }, []);

    return (
      <Container className="py-4 mb-8 max-w-screen-xl">
        <div className="mx-auto w-full md:w-120">
          <img
            alt=""
            width="224"
            height="58"
            className="m-auto mb-8"
            src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg?w=250"
          />
          {status && <StatusMessage status={status} type={status.type} />}
          <LoginEmail onSubmit={onSubmit} submitting={submitting} email={storageEmail} />
        </div>
      </Container>
    );
  })
);

export default EmailLogin;
