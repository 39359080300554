import React from 'react';
import { observer, inject } from 'mobx-react';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import Bugsnag from '@bugsnag/js';
import withRouter from '../../components/Router/withRouter';
import Loading from '../../components/Loading/Loading';
import Segment from '../../components/Segment/Segment';
import Agents from '../../agents/agents';
import Container from '../../components/Container/Container';
import { DEFAULT_COOKIE_SETTINGS } from '../../constants';

const TeamInvitePage = inject(
  'authStore',
  'userStore',
  'authStore'
)(
  observer(
    class TeamInvitePage extends React.Component {
      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        // Get the uuid and key from the route
        const { match } = this.props;
        const { params } = match;
        const { uuid, key } = params;
        const queryParams = queryString.parse(window.location.search);
        if (!uuid || !key) {
          window.location.href = `/?refreshUser=1`;
        } else {
          // Construct the data to post
          const data = {
            inviteToken: key,
            teamPermalink: uuid,
            source: queryParams.source || null,
          };
          // Clean up the cookie, in case it exists
          Cookies.remove('team_invite', { path: '/', domain: '.cybrary.it' });
          // Just redirect the user after making the request.
          Agents.enterprise
            .joinTeam(data)
            .then((response) => {
              // Set a cookie with this team ID to show a Welcome guide for new team members when user visits home page
              if (response && response.id) {
                Cookies.set('team_welcome', response.id, {
                  ...DEFAULT_COOKIE_SETTINGS,
                  path: '/',
                  expires: 365,
                });
              }
              // If the 'destination=account-settings' param exists, redirect user to settings for user to add secondary email
              if (queryParams.destination && queryParams.destination === 'account-settings') {
                window.location.href = `/settings/account?settings=1${queryParams.action ? `&action=${queryParams.action}` : ''}`;
              } else if (this.props.userStore.user.total_accounts < 2) {
                // If user only has one email, show them the recovery email page
                window.location.href = `/onboarding/recovery-email`;
              } else {
                // do a full js redirect, since we want to reload the users information now that they are on a new team
                window.location.href = `/?refreshUser=1`;
              }
            })
            .catch((err) => {
              Bugsnag.notify(err);
              window.location.href = `/?refreshUser=1`;
            });
        }
      }

      render() {
        return (
          <Container>
            <Segment className="border-none">
              <Loading />
            </Segment>
          </Container>
        );
      }
    }
  )
);

export default withRouter(TeamInvitePage);
