import React from 'react';
import DropdownFilter from '../Dropdown/DropdownFilter';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
// eslint-disable-next-line import/no-cycle
import ExpandingContentTableRow from './ExpandingContentTableRow';
import ReportHeadingtooltip from './ReportHeadingTooltip';
import '../Analytics/analytics.css';
import Container from '../Container/Container';

function ContentTableHeading({ isNested, heading, headingTooltip, hideFilters, filterPlaceholder, filterOptions, filterValue, filterContent }) {
  if (isNested) {
    return null;
  }
  return (
    <div className="grid grid-cols-2">
      <div className="flex relative items-center">
        {heading && <h2 className="mr-2 text-2xl font-black">{heading}</h2>}
        {headingTooltip && <ReportHeadingtooltip />}
      </div>
      <div className="text-left sm:text-right">
        {!hideFilters && (
          <div className="flex gap-x-4 justify-end items-center w-full">
            <span>Filters:</span>
            <DropdownFilter placeholder={filterPlaceholder} options={filterOptions} value={filterValue} onChange={filterContent} classes="filter-dropdown  w-48 text-left" />
          </div>
        )}
      </div>
    </div>
  );
}

function ContentSection({
  enrollmentData,
  certData,
  iaView,
  tableKey,
  launchItem,
  topLevelEnrollment,
  isNested,
  expandFunction,
  showSummary,
  hideCurriculaDetails,
  omitCurriculaLink,
  orgId,
  userId,
  checkCerts,
  getWorkroleIcons,
  progressFormat,
  titleTagFormat,
  learningHoursCalc,
  formatCertificateLink,
  formatDate,
  unenrollFunction,
  openFeedbackModal,
  retakeFunction,
  noResultsMsg,
  queryParams,
  getAllScores,
  switchToNewContentVersion,
  showContentUpdates,
  status,
  checkShouldExpand,
}) {
  const { loading, error, data } = enrollmentData;
  if (loading || (certData && certData.loading)) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }
  if (error) {
    return (
      <Container>
        <StyledError error={error} />
      </Container>
    );
  }
  return (
    <div className="assignments-container">
      {!!data && data.length && data.map
        ? data.map((content) => {
            return (
              <React.Fragment key={content.id || content.content_description_id}>
                <ExpandingContentTableRow
                  iaView={iaView}
                  tableKey={tableKey}
                  content={content}
                  launchItem={launchItem}
                  topLevelEnrollment={topLevelEnrollment || content}
                  isNested={isNested}
                  expandFunction={expandFunction}
                  showSummary={showSummary}
                  hideCurriculaDetails={hideCurriculaDetails}
                  omitCurriculaLink={omitCurriculaLink}
                  orgId={orgId}
                  userId={userId}
                  openFeedbackModal={openFeedbackModal}
                  checkCerts={checkCerts}
                  getWorkroleIcons={getWorkroleIcons}
                  progressFormat={progressFormat}
                  titleTagFormat={titleTagFormat}
                  learningHoursCalc={learningHoursCalc}
                  formatCertificateLink={formatCertificateLink}
                  formatDate={formatDate}
                  unenrollFunction={unenrollFunction}
                  retakeFunction={retakeFunction}
                  noResultsMsg={noResultsMsg}
                  queryParams={queryParams}
                  getAllScores={getAllScores}
                  switchToNewContentVersion={switchToNewContentVersion}
                  showContentUpdates={!!showContentUpdates}
                  status={status}
                  checkShouldExpand={checkShouldExpand}
                />
              </React.Fragment>
            );
          })
        : noResultsMsg || 'There are no activities for this user during this time period'}
    </div>
  );
}

function ExpandingContentTable({
  data,
  omitIfEmpty,
  isNested,
  heading,
  headingTooltip,
  hideFilters,
  filterPlaceholder,
  filterOptions,
  filterValue,
  filterChange,
  tableKey,
  iaView,
  launchItem,
  topLevelEnrollment,
  expandFunction,
  showSummary,
  hideCurriculaDetails,
  omitCurriculaLink,
  orgId,
  userId,
  checkCerts,
  getWorkroleIcons,
  progressFormat,
  titleTagFormat,
  learningHoursCalc,
  formatCertificateLink,
  formatDate,
  unenrollFunction,
  openFeedbackModal,
  retakeFunction,
  noResultsMsg,
  queryParams,
  getAllScores,
  switchToNewContentVersion,
  showContentUpdates,
  status,
  checkShouldExpand,
}) {
  const filterContent = ({ value }) => {
    filterChange(value, tableKey);
  };
  const isEmpty = data && !data.loading && !data.error && data.data && !data.data.length;
  if (isEmpty && omitIfEmpty) {
    return null;
  }
  return (
    <div className={`${!isNested ? 'py-4' : ''} member-dashboard-assignments`}>
      <ContentTableHeading
        isNested={isNested}
        heading={heading}
        headingTooltip={headingTooltip}
        hideFilters={hideFilters}
        filterPlaceholder={filterPlaceholder}
        filterOptions={filterOptions}
        filterValue={filterValue}
        filterContent={filterContent}
      />
      <ContentSection
        enrollmentData={data}
        iaView={iaView}
        tableKey={tableKey}
        launchItem={launchItem}
        topLevelEnrollment={topLevelEnrollment}
        isNested={isNested}
        expandFunction={expandFunction}
        showSummary={showSummary}
        hideCurriculaDetails={hideCurriculaDetails}
        omitCurriculaLink={omitCurriculaLink}
        orgId={orgId}
        userId={userId}
        checkCerts={checkCerts}
        getWorkroleIcons={getWorkroleIcons}
        progressFormat={progressFormat}
        titleTagFormat={titleTagFormat}
        learningHoursCalc={learningHoursCalc}
        formatCertificateLink={formatCertificateLink}
        formatDate={formatDate}
        unenrollFunction={unenrollFunction}
        openFeedbackModal={openFeedbackModal}
        retakeFunction={retakeFunction}
        noResultsMsg={noResultsMsg}
        queryParams={queryParams}
        getAllScores={getAllScores}
        switchToNewContentVersion={switchToNewContentVersion}
        showContentUpdates={showContentUpdates}
        status={status}
        checkShouldExpand={checkShouldExpand}
      />
    </div>
  );
}

export default ExpandingContentTable;
