import React, { useRef, useState } from 'react';
import moment from 'moment';
import DatePicker from '../DatePicker/DatePicker';
import SelectBoxes from '../SelectBoxes/SelectBoxes';
import WizardStepDescription from './WizardStepDescription';
import Divider from '../Divider/Divider';

export default function WizardStartTimeSelection({ setValue, goalType, selectedStartDate }) {
  const [selectedStartType, setSelectedStartType] = useState(selectedStartDate ? 'date' : 'today');
  const inputRef = useRef();
  const handleClick = (section, val) => {
    if (section === 'start_type') {
      setValue('start_date', null);
      setSelectedStartType(val);
      if (val === 'date') {
        setTimeout(() => {
          inputRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 400);
      } else {
        setValue('start_date', null);
      }
    } else {
      setValue(section, val);
    }
  };
  const startDate = selectedStartDate ? new Date(moment(selectedStartDate)) : null;
  return (
    <>
      <SelectBoxes
        containerClasses="overflow-y-auto w-11/12"
        selections={selectedStartType}
        handleSelect={(selection) => handleClick('start_type', selection)}
        options={[
          {
            text: 'Today',
            value: 'today',
          },
          {
            text: 'On a specific date',
            value: 'date',
          },
        ]}
      />
      {selectedStartType === 'date' ? (
        <div>
          <Divider marginTop="mt-6" marginBottom="mb-6" />
          <div ref={inputRef} />
          <div className="mb-4 text-sm text-gray-600">
            <WizardStepDescription step="start_specific_date" goalType={goalType} />
          </div>
          <DatePicker
            onChange={(date) => handleClick('start_date', moment(date).format('YYYY-MM-DD'))}
            value={startDate || null}
            className="w-60"
            style={{ paddingTop: '5px', paddingBottom: '5px' }}
            label="Start Date"
            defaultOpen
          />
        </div>
      ) : null}
    </>
  );
}
