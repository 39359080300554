import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import Bugsnag from '@bugsnag/js';
import OnboardingStep from './OnboardingStep';
import { useOnboardingContext } from '../../../providers/OnboardingProvider';
import useOnboardingRecommendations from '../../../hooks/useOnboardingRecommendations';
import ContentCard from '../../Card/ContentCard';
import Divider from '../../Divider/Divider';
import ContentTransformer from '../../../transformers/contentTransformer';
import Loading from '../../Loading/Loading';
import Agents from '../../../agents/agents';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import Chip from '../../Chip/Chip';
import If from '../../If/If';

/**
 * @param setStep - update context step
 * @param setNavigationLocation - update navigation location in context
 * @param cardContent - array of objects to be fed into content transformer for print out content cards
 */
function UpskillRecommendation({ handleClick, cardContent }) {
  return (
    <OnboardingStep className="pt-0 pb-8">
      {cardContent?.length > 0 ? (
        <div className="flex flex-col gap-4 justify-center sm:items-center md:flex-row md:flex-wrap md:items-stretch">
          {cardContent?.map((contentItem) => {
            const prepped = ContentTransformer.formatDataSources(contentItem);
            const { permalink = '' } = contentItem;
            const contentFullUrl = `/browse/${permalink}`;
            return <ContentCard key={uuid()} data={prepped} handleClick={() => handleClick(contentFullUrl)} className="w-full sm:w-60" />;
          })}
        </div>
      ) : (
        <ErrorMessage />
      )}
    </OnboardingStep>
  );
}

/**
 * Last step of CIP flow
 * @param handleClick - function to trigger navigation step
 * @param recommendationsDescription - description for the recommendations
 * @param cardContent - object to be fed into content transformer for content card
 * @param secondaryContent - array of objects to be fed into content transformer for right side content buttons
 */
function Recommendations({ handleClick, recommendationsDescription, cardContent = [], secondaryContent = [] }) {
  return (
    <div className="flex flex-col justify-center items-start mb-8 md:flex-row">
      {/* Don't show card content if it DNE */}
      {cardContent?.length > 0 && (
        <div className="flex flex-col justify-center items-center mb-8 md:w-1/2">
          <OnboardingStep.Description description={recommendationsDescription} className="px-0 text-left sm:px-8 sm:text-center" />
          {cardContent?.map((cardItem) => {
            const { permalink = '' } = cardItem;
            const cardContentFullUrl = `/browse/${permalink}`;
            const prepped = ContentTransformer.formatDataSources(cardItem);
            return <ContentCard key={uuid()} className="w-full sm:w-60" data={prepped} handleClick={() => handleClick(cardContentFullUrl)} />;
          })}
        </div>
      )}
      {/* Require both card content and secondary content to show divider */}
      {secondaryContent?.length > 0 && cardContent?.length > 0 && (
        <div id="divider" className="hidden md:inline-block">
          <Divider className="my-4 h-96" vertical>
            <span className="text-gray-600 uppercase">OR</span>
          </Divider>
        </div>
      )}
      {/* Don't show secondary content it nothing was returned */}
      {secondaryContent?.length > 0 && (
        <div className="flex flex-col justify-center items-center w-full md:px-8 md:w-1/2">
          <OnboardingStep.Description description="Unsure? Explore more options:" />
          {secondaryContent.map((content) => {
            const { title = '', permalink: contentPermalink = '', content_type } = content || {};
            const contentFullUrl = `/browse/${contentPermalink}`;
            const ariaLabel = `navigate to ${title}`;
            return (
              <button
                aria-label={ariaLabel}
                key={uuid()}
                onClick={() => handleClick(contentFullUrl)}
                className="flex justify-between items-center p-4 my-4 space-x-4 w-full text-black rounded border-1 border-gray-500 hover:border-cyb-pink-500"
              >
                <If condition={content_type?.nice_name}>
                  <Chip className="font-bold bg-gray-300 rounded-full" color="gray">
                    {content_type?.nice_name}
                  </Chip>
                </If>
                <p className="grow text-base font-semibold text-center">{title}</p>
              </button>
            );
          })}
        </div>
      )}
      {/* Don't show error message if there is content */}
      {!secondaryContent?.length && !cardContent?.length && <ErrorMessage />}
    </div>
  );
}

/**
 * Last step of CIP flow
 * @param allContentIds - array of content ids to be bookmarked
 * @param fetchBookmarks - function to trigger refetch of bookmarks from user store
 * necessary to update users bookmarks in the UI
 */
async function bookmarkContent(allContentIds, fetchBookmarks) {
  try {
    await Agents.catalog.batchBookmarkContent(allContentIds);
    await fetchBookmarks();
  } catch (error) {
    Bugsnag.notify(error, (errorEvent) => {
      const { errorMessage } = errorEvent.errors[0];
      // eslint-disable-next-line no-param-reassign
      errorEvent.context = `RecommendationsStep: ${errorMessage}`;
    });
  }
}

function RecommendationsStep({ fetchBookmarks }) {
  const { cipGoal = '', cipYearsOfExperience = '', cipExperience = '', setNavigationLocation, setStep } = useOnboardingContext();
  const { recommendationsTitle, recommendationsDescription, isLoading, cardContent, secondaryContent, fetchRecommendations } = useOnboardingRecommendations(
    cipGoal,
    cipExperience,
    cipYearsOfExperience
  );

  useEffect(() => {
    const bookmarkRecommendedContent = async () => {
      const contentIds = await fetchRecommendations();
      if (contentIds?.length > 0) {
        await bookmarkContent(contentIds, fetchBookmarks);
      }
    };
    bookmarkRecommendedContent();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const handleClick = (url) => {
    setNavigationLocation(url);
    setStep('navigate');
  };

  return (
    <>
      <OnboardingStep className="pb-0">
        <OnboardingStep.Title title={recommendationsTitle} className="px-0 mb-8 text-left sm:px-8 sm:text-center" />
        {cipGoal === 'Upskill & Practice' ? (
          <UpskillRecommendation cardContent={cardContent} handleClick={handleClick} />
        ) : (
          <Recommendations handleClick={handleClick} recommendationsDescription={recommendationsDescription} cardContent={cardContent} secondaryContent={secondaryContent} />
        )}
      </OnboardingStep>
      <div id="onboarding-modal-footer" className="flex justify-end items-center py-2 px-8 w-full bg-cyb-pink-500">
        <p className="mb-0 text-white">
          Looking for something different? &nbsp;
          <button className="font-semibold text-black underline" onClick={() => handleClick('/browse/refined/')}>
            Check out our catalog.
          </button>
        </p>
      </div>
    </>
  );
}
export default RecommendationsStep;
