import React from 'react';
import OnboardingStep from './OnboardingStep';
import { useOnboardingContext } from '../../../providers/OnboardingProvider';
import useOnboardingRecommendations from '../../../hooks/useOnboardingRecommendations';
import Loading from '../../Loading/Loading';
import If from '../../If/If';

const FREE_PACKAGE_HIGHLIGHTS = [
  { hasAccess: true, description: 'Limited Access to core learning' },
  {
    hasAccess: true,
    description: 'Limited access to virtual labs and assessments',
  },
  {
    hasAccess: false,
    description: 'Discord Community',
  },
  {
    hasAccess: false,
    description: 'Skill proficiency reports',
  },
  {
    hasAccess: false,
    description: 'Team Management',
  },
  {
    hasAccess: false,
    description: 'Advanced Red Team and DFIR content',
  },
];
const TEAM_PACKAGE_HIGHLIGHTS = [
  { hasAccess: true, description: 'Onboarding - Get new cybersecurity team members up to speed quickly ' },
  {
    hasAccess: true,
    description: 'Certification & CEUs -  Ensure your security team is exam ready',
  },
  {
    hasAccess: true,
    description: 'Retention - Identify up-and-coming talent and drive up retention of your current cybersecurity workforce',
  },
  {
    hasAccess: true,
    description: 'Skill Development - In-Depth functional skills and domain coverage',
  },
  {
    hasAccess: true,
    description: 'Stay Ahead - Treat informed training for mission ready skills',
  },
  {
    hasAccess: true,
    description: 'Baselining - Evaluate theory and practical skills and benchmark capabilities',
  },
];

const CIP_PACKAGE_HIGHLIGHTS = [
  {
    hasAccess: true,
    description: 'Full access to core learning',
  },
  {
    hasAccess: true,
    description: 'Virtual labs and assessments',
  },
  {
    hasAccess: true,
    description: 'Discord Community',
  },
  { hasAccess: true, description: 'Skill proficiency reports' },
  { hasAccess: false, description: 'Team Management' },
  { hasAccess: false, description: 'Advanced Red Team and DFIR content' },
];

function SelectPackageStep({ fetchBookmarks }) {
  const { setStep, setNavigationLocation, cipGoal = '', cipYearsOfExperience = '', cipExperience = '', teamOrCip = '' } = useOnboardingContext();
  const { fetchRecommendations, bookmarkContent, isLoading } = useOnboardingRecommendations(cipGoal, cipExperience, cipYearsOfExperience);

  const BUTTON_CLASSES = 'md:m-2 grow';

  // Fetches recommendations and bookmarks content based on the selected package
  const bookmarkPackageContent = async () => {
    const contentIds = await fetchRecommendations();
    await bookmarkContent(contentIds, fetchBookmarks);
  };

  const handleStep = async (selectedPackage) => {
    switch (selectedPackage) {
      case 'teams':
        bookmarkPackageContent();
        setStep('demoForm');
        break;
      case 'cip':
        bookmarkPackageContent();
        /** make them go pay for dis ting */
        setNavigationLocation('/upgrade/checkout');
        setStep('navigate');
        break;
      /** If free go to recommendations */
      case 'free':
      default:
        if (teamOrCip === 'cip') {
          setStep('contentRecommendations');
        } else {
          setStep('navigate');
        }
        break;
    }
  };
  return (
    <>
      <If condition={isLoading}>
        <div id="select-package-loader" className="flex fixed inset-0 z-50 justify-center items-center p-8 w-full h-full bg-black/25 rounded shadow-lg">
          <Loading className="w-12 h-12 border-4" />
        </div>
      </If>
      <OnboardingStep title="Select Your Package">
        <OnboardingStep.Description description="Select one of the packages below to get started. You can change at any time" />
        <div className="grid relative grid-cols-1 gap-2 lg:grid-cols-3">
          <OnboardingStep.Button
            title="Free"
            description="Our basic version of Cybrary for individuals looking for introductory video training"
            packageHighlights={FREE_PACKAGE_HIGHLIGHTS}
            onClick={() => handleStep('free')}
            className={BUTTON_CLASSES}
          />
          <OnboardingStep.Button
            title="Cybrary for Business"
            description="Access to free video-based courses plus Premium Content, career development, and management support, including:"
            packageHighlights={TEAM_PACKAGE_HIGHLIGHTS}
            onClick={() => handleStep('teams')}
            className={BUTTON_CLASSES}
          />
          <OnboardingStep.Button
            title="Cybrary Insider Pro"
            description={`Enroll in job-ready career paths, work directly with mentors, and access to 
          Premium Content to get you the hands on experience you will need for the job interview`}
            packageHighlights={CIP_PACKAGE_HIGHLIGHTS}
            onClick={() => handleStep('cip')}
            className={BUTTON_CLASSES}
          />
        </div>
      </OnboardingStep>
    </>
  );
}

export default SelectPackageStep;
