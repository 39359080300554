import React from 'react';
import AddLink from '../../components/AddLink/AddLink';
import FormatUtil from '../../utils/formatUtil';
import CreateAccount from '../../components/Checkout/CreateAccount';
import usePaddleCheckout from '../../hooks/checkout/usePaddleCheckout';

function ErrorMessage({ title = '', message = '' }) {
  return (
    <p className="p-2 my-4 text-sm rounded border-2 border-cyb-pink-500">
      <span className="pr-2 font-bold">{title}:</span>
      {message}
    </p>
  );
}

function TransactionDetails({ transactionId = '', email = '', children }) {
  return (
    <>
      <ErrorMessage title="Transaction ID" message={transactionId} />
      <ErrorMessage title="Email" message={email} />
      {children}
    </>
  );
}

function RegistrationErrors({ registrationError }) {
  if (!registrationError) return null; // No errors
  const errors = registrationError?.response?.data?.errors; // Laravel errors
  if (!errors) {
    // Not laravel errors, this is a generic error
    return <ErrorMessage title="Error" message={registrationError?.message} />;
  }
  const errorKeys = Object.keys(errors);
  return errorKeys?.map((errorKey) => {
    return <ErrorMessage key={`${errorKey} errors`} title={`${FormatUtil.ucFirstLetter(errorKey)} Error`} message={errors[errorKey]?.join('. ')} />;
  });
}

function FailedUpgrade({ isTeams }) {
  const { checkoutData, registrationError, registerNewUser, registrationData, setRegistrationData } = usePaddleCheckout();
  const { transaction_id, customer } = checkoutData || {};
  const isEmailTaken = !!registrationError?.response?.data?.errors?.email?.includes('The email has already been taken.');
  const isReCAPTCHAInvalid = !!registrationError?.response?.data?.errors?.captchav3?.includes('validation.recaptchav3');
  /**
   * Handler for the Registration form Recovery submission
   * Used when we complete a transaction but the user's account fails to be created
   */
  const handleRegistrationFormRecoverySubmit = (newRegistrationData) => {
    // Merge the new data with the existing data
    // This will allow us to chain multiple forms together if we need to collect more data later
    const parsedRegistrationData = { ...registrationData, ...newRegistrationData };
    delete parsedRegistrationData.form_id; // remove form_id from data
    setRegistrationData(parsedRegistrationData); // Set the new registration data
    const redirectUrl = !isTeams ? '/?freshCip=1' : '/enterprise/new?newSubscription=1';
    registerNewUser(checkoutData, parsedRegistrationData, redirectUrl); // Register the new user
  };
  return (
    <div className="pt-12 pb-8">
      <h2 className="mb-8 text-4xl text-black">Error upgrading your account</h2>
      <p className="text-left">We were able to successfully complete your transaction, but were unable to create your account.</p>
      {/** Loop through any laraval errors and display them here. */}
      <RegistrationErrors registrationError={registrationError} />
      {/** If we have an error we can recover from, lets try to */}
      {isEmailTaken && (
        <div className="text-left">
          <p className="text-left">
            Try a registering using different email, or contact{' '}
            <a href="mailto:support@cybrary.it" title="Email support" className="underline">
              support@cybrary.it
            </a>{' '}
            for help.
          </p>
          <CreateAccount handleSubmit={(data) => handleRegistrationFormRecoverySubmit(data)} isTeamProduct={isTeams} hideSignIn />
        </div>
      )}
      {isReCAPTCHAInvalid && (
        <div className="text-left">
          <p className="text-left">
            Your Google ReCAPTCHA Token was invalid. Please resubmit the form below, or contact{' '}
            <a href="mailto:support@cybrary.it" title="Email support" className="underline">
              support@cybrary.it
            </a>{' '}
            for help.
          </p>
          <p className="font-black text-center">You will NOT be charged twice.</p>
          <CreateAccount
            className="p-4 mb-4 rounded border-2 border-cyb-pink-500"
            handleSubmit={(data) => handleRegistrationFormRecoverySubmit(data)}
            isTeamProduct={isTeams}
            hideSignIn
            isReCAPTCHARetry
            // Initialize the form with the data we already have
            initEmail={registrationData?.email}
            initPassword={registrationData?.password}
            initSubscribe={!!registrationData?.subscribe}
            initToS={!!registrationData?.tos}
          />
        </div>
      )}
      {/** If we can't recover from the error, show transaction details and error message */}
      <p className="font-bold">
        If you need further assitance, please contact Cybrary Support at{' '}
        <a href="mailto:support@cybrary.it" title="Email support" className="underline">
          support@cybrary.it
        </a>{' '}
        with the following transaction details as a reference:
      </p>
      <TransactionDetails email={customer?.email} transactionId={transaction_id} />
      <div className="mt-8">
        <AddLink className="py-2.5 px-6 mt-8 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm" to="/">
          Home
        </AddLink>
      </div>
    </div>
  );
}

export default FailedUpgrade;
