import { observable, action, makeObservable } from 'mobx';

// eslint-disable-next-line import/no-extraneous-dependencies
import algoliasearch from 'algoliasearch';
// eslint-disable-next-line import/no-extraneous-dependencies
import algoliasearchHelper from 'algoliasearch-helper';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import Agents from '../agents/agents';
import SearchUtil from '../utils/searchUtil';
import FormatUtil from '../utils/formatUtil';
import PermalinkUtil from '../utils/permalinkUtil';

/**
 * Store for catalog logic
 *
 */

class CatalogStore {
  constructor() {
    makeObservable(this, {
      loading: observable,
      item: observable,
      error: observable,
      collection: observable,
      comprehensiveLearningCourses: observable,
      comprehensiveLearningCareerPaths: observable,
      setCollection: action,
      setLoading: action,
      setItem: action,
      setError: action,
      reset: action,
      getRecommendedContent: action,
      getCollectionContent: action,
      getBookmarkedContent: action,
      getContentDescriptionById: action,
      setComprehensiveLearningCareerPaths: action,
      getComprehensiveLearningCareerPaths: action,
      setComprehensiveLearningCourses: action,
      getComprehensiveLearningCourses: action,
      getContentDescriptionByPermalink: action,
      searchCallback: action,
      skillAndCertOptions: observable,
      getSkillAndCertOptions: action,
    });

    this.catalogIndex = process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX;
    this.courseIndex = process.env.REACT_APP_INSTANTSEARCH_COURSES_INDEX;
    this.curriculumIndex = process.env.REACT_APP_INSTANTSEARCH_CURRICULUM_INDEX;
    this.client = algoliasearch(process.env.REACT_APP_INSTANTSEARCH_APP_ID, process.env.REACT_APP_INSTANTSEARCH_API_KEY);
  }

  client = null;

  catalogIndex = null;

  courseIndex = null;

  curriculumIndex = null;

  loading = false;

  item = null;

  error = null;

  collection = null;

  comprehensiveLearningCourses = null;

  comprehensiveLearningCareerPaths = null;

  setCollection(collection) {
    this.collection = collection;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setItem(item) {
    this.item = item;
  }

  setError(error) {
    this.error = error;
  }

  reset() {
    // set all of our properties to the way their defaults, good for unmounting
    this.loading = false;
    this.item = null;
    this.error = null;
    this.collection = null;
    this.comprehensiveLearningCourses = null;
    this.comprehensiveLearningCareerPaths = null;
  }

  getRecommendedContent(level, terms, hitsPerPage) {
    const contentHelper = algoliasearchHelper(this.client, this.catalogIndex);
    let termsFiltersString = '';
    const termsFilters = [];
    terms.forEach((term) => {
      termsFilters.push(`terms_info:"Verticals|${term}"`);
    });
    if (termsFilters.length) {
      if (termsFilters.length === 1) {
        // Special case, only one filter, no need for parentheses
        termsFiltersString = `AND ${termsFilters[0]}`;
      } else {
        const separatedTermsFilters = termsFilters.join(' OR ');
        termsFiltersString = `AND (${separatedTermsFilters})`;
      }
    }
    const options = {
      filters: `display_in_catalog:true AND level.name:${level} ${termsFiltersString}`,
      optionalFilters: [`tags_info:Featured`, `tags_info:Popular`],
      hitsPerPage,
    };
    return contentHelper.searchOnce(options).then((data) => {
      return data;
    });
  }

  getCollectionContent(id) {
    return Agents.collections
      .getCollectionData(id)
      .then(
        action('fetchSuccess', (response) => {
          this.collection = response;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setError({
            status: 404,
            message: 'There was an error loading the content.',
          });
        })
      );
  }

  getBookmarkedContent(bookmarks) {
    const contentHelper = algoliasearchHelper(this.client, this.catalogIndex);
    let bookmarksFiltersString = '';
    const bookmarksFilters = [];
    bookmarks.forEach((bookmark) => {
      bookmarksFilters.push(`objectID:${bookmark}`);
    });
    if (bookmarksFilters.length) {
      bookmarksFiltersString = bookmarksFilters.join(' OR ');
    }
    const options = {
      filters: bookmarksFiltersString,
      hitsPerPage: 2000,
    };
    return contentHelper.searchOnce(options).then((data) => {
      return data;
    });
  }

  getContentError = () => {
    this.setError({
      status: 404,
      message: 'There was an error loading the content.',
    });
    this.setLoading(false);
  };

  getContentDescriptionById(id) {
    this.setLoading(true); // we are loading
    this.setError(null);

    return Agents.catalog
      .getContentDescriptionById(id)
      .then((data) => {
        return this.searchCallback(data);
      })
      .catch(this.getContentError);
  }

  setComprehensiveLearningCareerPaths(comprehensiveLearningCareerPaths) {
    this.comprehensiveLearningCareerPaths = comprehensiveLearningCareerPaths;
  }

  getComprehensiveLearningCareerPaths(permalink) {
    return Agents.catalog
      .getComprehensiveLearningCareerPaths(PermalinkUtil.removeTrailingSlash(permalink))
      .then(
        action('fetchSuccess', (response) => {
          this.setComprehensiveLearningCareerPaths(response);
        })
      )
      .catch((error) => {
        Bugsnag.notify(error);
      });
  }

  setComprehensiveLearningCourses(comprehensiveLearningCourses) {
    this.comprehensiveLearningCourses = comprehensiveLearningCourses;
  }

  getComprehensiveLearningCourses(permalink) {
    return Agents.catalog
      .getComprehensiveLearningCourses(PermalinkUtil.removeTrailingSlash(permalink))
      .then(
        action('fetchSuccess', (response) => {
          this.setComprehensiveLearningCourses(response);
        })
      )
      .catch((error) => {
        Bugsnag.notify(error);
      });
  }

  getContentDescriptionByPermalink(permalink) {
    this.setLoading(true); // we are loading
    this.setError(null);

    return Agents.catalog
      .getContentDescriptionByPermalink(permalink)
      .then((data) => {
        return this.searchCallback(data);
      })
      .catch(this.getContentError);
  }

  // Helper function to handle finishing up finding an item
  searchCallback(item) {
    if (item) {
      // eslint-disable-next-line no-param-reassign
      item.license = SearchUtil.getContentLicense(item);
      this.setItem(item);
      this.setLoading(false);
    } else {
      this.setError({
        status: 404,
        message: 'There was an error loading the content.',
      });
      this.setLoading(false);
    }

    return item;
  }

  /*        Cybrary Live!        */

  fireLaunchActivity(contentDescriptionId) {
    return Agents.catalog.launch(contentDescriptionId);
  }

  registerForSession(id) {
    return Agents.enrollments.startEnrollment(id);
  }

  formatSeriesData(series) {
    const formattedSeries = { ...series };
    const contentItem = series.content_item;
    const sessions = contentItem.sessions.map((session) => this.formatSessionData(session));
    const meta = contentItem ? contentItem.meta : {};
    return {
      ...formattedSeries,
      sessions,
      series_meta: meta,
    };
  }

  getStatusForASession(startDate, endDate) {
    let status = '';
    const dateNow = moment();
    const gracePeriod = 60 * 15;
    const graceStart = startDate.clone().subtract(gracePeriod, 'seconds');
    const graceEnd = endDate.clone().add(gracePeriod, 'seconds');
    if (dateNow.isAfter(graceEnd)) {
      status = 'archived';
    } else if (dateNow.isBefore(graceStart)) {
      status = 'upcoming';
    } else {
      status = 'active';
    }
    return status;
  }

  formatSessionData(session) {
    const formattedSession = { ...session };
    // Handle basic session info, such as title and description
    const contentDescription = session.content_description || session.contentDescription || session;
    const contentItem = session.content_item || session;
    const sessionMeta = contentItem ? contentItem.meta : undefined;
    if (contentDescription) {
      formattedSession.title = contentDescription.title;
      formattedSession.shortDescription = contentDescription.short_description;
      formattedSession.longDescription = contentDescription.long_description;
      formattedSession.description = formattedSession.longDescription || formattedSession.shortDescription;
      formattedSession.id = contentDescription.id;
      formattedSession.url = contentDescription.url;
      formattedSession.permalink = contentDescription.permalink;
      formattedSession.instructors = contentDescription.instructors_info;
      formattedSession.image = contentDescription.thumbnail_url;
    }

    // Handle images and streams
    if (sessionMeta) {
      formattedSession.vimeoChatUrl = sessionMeta.vimeoChatUrl;
      formattedSession.vimeoStreamUrl = sessionMeta.vimeoStreamUrl;
      formattedSession.vimeoVideoUrl = sessionMeta.vimeoVideoUrl;
      const vimeoStreamId = sessionMeta.vimeoStreamUrl ? sessionMeta.vimeoStreamUrl.replace('https://player.vimeo.com/video/', '') : undefined;
      const vimeoVideoId = sessionMeta.vimeoVideoUrl ? sessionMeta.vimeoVideoUrl.replace('https://player.vimeo.com/video/', '') : undefined;
      formattedSession.vimeoStreamId = vimeoStreamId;
      formattedSession.vimeoVideoId = vimeoVideoId;
      formattedSession.additionalResources = sessionMeta.additionalResources && sessionMeta.additionalResources.length ? sessionMeta.additionalResources : null;
      formattedSession.supplementalMaterials = sessionMeta.supplementalMaterials && sessionMeta.supplementalMaterials.length ? sessionMeta.supplementalMaterials : null;
      formattedSession.surveyUrl = sessionMeta.surveyUrl;
    }

    // Grab the series background image and other info
    const series = contentItem && contentItem.series ? contentItem.series : null;
    if (series) {
      const seriesMeta = series.meta;
      if (seriesMeta) {
        formattedSession.seriesBackgroundImage = seriesMeta.background_image;
        formattedSession.seriesCalendarUrl = seriesMeta.calendar_url;
      }
      const seriesContentDescription = series.content_description;
      if (seriesContentDescription) {
        formattedSession.seriesDescription = seriesContentDescription.short_description || seriesContentDescription.long_description;
        formattedSession.seriesTitle = seriesContentDescription.title;
        formattedSession.seriesUrl = seriesContentDescription.url;
        formattedSession.seriesImage = seriesContentDescription.thumbnail_url;
      }
    }

    // Handle the dates associated with a session
    if (contentItem) {
      formattedSession.startDate = moment.unix(contentItem.start_time_timestamp);
      formattedSession.endDate = moment.unix(contentItem.end_time_timestamp);
      formattedSession.duration = contentItem.start_time_timestamp - contentItem.end_time_timestamp;
      formattedSession.localStartDate = moment.unix(contentItem.start_time_timestamp).local();
      formattedSession.localEndDate = moment.unix(contentItem.end_time_timestamp).local();
      formattedSession.localTimeRange = `${formattedSession.localStartDate.format('h:mma')} - ${formattedSession.localEndDate.format('h:mma z')}`;
      // Get the status
      formattedSession.status = this.getStatusForASession(formattedSession.startDate, formattedSession.endDate);
    }

    return formattedSession;
  }

  skillAndCertOptions = {
    loading: true,
    error: false,
    data: null,
    certificationGroups: [],
    topicGroups: [],
  };

  getSkillAndCertOptions = () => {
    this.skillAndCertOptions.loading = false;
    this.skillAndCertOptions.error = false;
    return Agents.catalog
      .categoryTerms('?categories[]=Certification&categories[]=Topic&verified=1')
      .then(
        action('fetchSuccess', (response) => {
          this.skillAndCertOptions.data = FormatUtil.sortArrayObjects([...response], 'group', 'name');
          this.skillAndCertOptions.loading = false;

          const certificationGroups = [];
          const topicGroups = [];
          // Break out the category groups
          this.skillAndCertOptions.data.forEach((item) => {
            if (item.category === 'Certification' && item.group && certificationGroups.indexOf(item.group) === -1) {
              certificationGroups.push(item.group);
            } else if (item.category === 'Topic' && item.group && topicGroups.indexOf(item.group) === -1) {
              topicGroups.push(item.group);
            }
          });
          this.skillAndCertOptions.certificationGroups = certificationGroups;
          this.skillAndCertOptions.topicGroups = topicGroups;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.skillAndCertOptions.loading = false;
          this.skillAndCertOptions.error = error;
        })
      );
  };

  findTermObjById = (termId) => {
    const termObjs = this.skillAndCertOptions.data.filter((term) => {
      return term.id === termId;
    });
    if (termObjs && termObjs.length) {
      // Shouldn't be more than one, but if so just return first found
      return termObjs[0];
    }
    return null;
  };
}

export default new CatalogStore();
