import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Icon from '../Icon/Icon';

function getColorConfig(color) {
  switch (color) {
    case 'red':
      return {
        bgColor: 'bg-white',
        textColor: 'text-red-700',
        borderColor: 'border-red-700',
      };
    case 'green':
    default:
      return {
        bgColor: 'bg-white',
        textColor: 'text-green-700',
        borderColor: 'border-green-700',
      };
  }
}

function ToastMessage({ active, color, icon, header, content }) {
  const colorConfig = getColorConfig(color);
  const toastContainerBaseClasses = 'p-4 flex items-center rounded-sm border';
  if (!active) {
    return null;
  }
  return (
    <div style={{ zIndex: 2001 }} className="fixed bottom-2 w-full sm:left-1/10 sm:w-4/5 md:left-1/4 md:w-1/2" tabIndex="-1">
      <Fade duration={500} direction="up">
        <div className={`${toastContainerBaseClasses} ${colorConfig.bgColor} ${colorConfig.textColor} ${colorConfig.borderColor}`}>
          <Icon name={icon} className="w-16 h-16" />
          <div className={`${colorConfig.textColor} ml-8 focus:ring-2 focus:ring-cyb-blue-500`} id="toast-container" role="status">
            <h3 className="mb-1 text-lg font-bold">{header}</h3>
            <div>{content}</div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default ToastMessage;
