import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import { useAuth } from '../../contexts/UseAuth';
import Container from '../../components/Container/Container';
import Loading from '../../components/Loading/Loading';
import { bootPendo } from '../../utils/pendoUtil';

const SamlResult = inject('commonStore')(
  observer(({ commonStore }) => {
    const navigate = useNavigate();
    const { loginWithCustomToken } = useAuth();
    const params = queryString.parse(window.location.search, { decode: false });

    useEffect(() => {
      commonStore.setPageTitle('Saml Login | Cybrary');
      // Pendo - Boot anonymously
      bootPendo();

      if (params.googleToken) {
        // Log user in with provided Google token from params
        (async () => {
          try {
            await loginWithCustomToken(params.googleToken);
          } catch (e) {
            Bugsnag.notify(e);
            navigate('/login?errorCode=default');
          }
        })();
      } else {
        navigate('/login');
      }
    }, []);

    return (
      <Container className="py-4 mb-8 max-w-screen-xl">
        <div className="mx-auto w-full md:w-120">
          <img
            alt=""
            width="224"
            height="58"
            className="m-auto mb-8"
            src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg?w=250"
          />
          <Loading />
        </div>
      </Container>
    );
  })
);

export default SamlResult;
