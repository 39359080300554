import React, { useState, useEffect, useRef } from 'react';

function MenuContent({ content, isActive }) {
  const classes = `mt-16 ${isActive ? '' : 'hidden'}`;
  const contentId = `${content.id}-tab`;

  return (
    <div tabIndex={isActive ? '0' : '-1'} role="tabpanel" id={contentId} aria-labelledby={content.id} className={`${classes} overflow-y-auto`}>
      {content.component}
    </div>
  );
}

function getFixedClasses(isSidebar, noFixed, fixedWidth) {
  if (noFixed) {
    return '';
  }
  return isSidebar ? `fixed top-16 ${fixedWidth || 'w-4/5'}` : `fixed top-0 w-full border-r ${fixedWidth}`;
}

function SidebarTabsMenu({ tabs, isSidebar, noFixed = false, defaultTabIndex = 0, fixedWidth = '', scrollId = 'scroll-container', ignoreDefaultTabIndex = false }) {
  const savedScrollPositions = useRef({});
  const [activeTabIdx, setActiveTabIdx] = useState(0);
  const initialActiveContentId = `${tabs[defaultTabIndex].id}-tab`;
  const [activeContentId, setActiveContentId] = useState(initialActiveContentId);

  /** handles adding the event listener to the scroll container... */
  useEffect(() => {
    const scrollContainer = document.getElementById(scrollId);
    const handleScroll = () => {
      /** get current scroll position  */
      const currentScrollPosition = scrollContainer?.scrollTop || 0;
      const savedScrollPosition = savedScrollPositions?.current[activeContentId] || 0;
      /** save it to a ref to persist the value over renders... */
      savedScrollPositions.current[activeContentId] = currentScrollPosition > 0 ? currentScrollPosition : savedScrollPosition;
    };

    scrollContainer?.addEventListener('scroll', handleScroll);

    return () => scrollContainer?.removeEventListener('scroll', handleScroll);
  }, [activeContentId]);

  /** handles updating the tab with its last known saved position */
  useEffect(() => {
    const scrollContainer = document.getElementById(scrollId);
    const savedScrollPosition = savedScrollPositions?.current[activeContentId] || 0;
    if (scrollContainer) {
      scrollContainer.scrollTop = savedScrollPosition;
    }
  }, [activeContentId]);

  useEffect(() => {
    if (!ignoreDefaultTabIndex && tabs?.length && tabs.length > defaultTabIndex) {
      setActiveTabIdx(defaultTabIndex);
    }
  }, [defaultTabIndex, tabs]);

  if (!tabs || !tabs.length) {
    return null;
  }

  function changeTabsKeyboard(e, tab) {
    const key = e.which || e.keyCode;
    const tabIdx = tabs.indexOf(tab);
    const tempTabsIdx = [];

    // handle entering letter to navigate to tab
    tabs.forEach((value, idx) => {
      if (value.label.toLowerCase().charAt(0) === e.key) {
        tempTabsIdx.push(idx);
        setActiveTabIdx(tempTabsIdx[0]);
        document.getElementById(tabs[tempTabsIdx[0]].id).focus();
      }
    });

    // end key
    if (key === 35) {
      setActiveTabIdx(tabs.length - 1);
      document.getElementById(tabs[tabs.length - 1].id).focus();
    }

    // home key
    if (key === 36) {
      setActiveTabIdx(0);
      document.getElementById(tabs[0].id).focus();
    }

    // left arrow key
    if (key === 37) {
      if (tabIdx > 0) {
        setActiveTabIdx(tabIdx - 1);
        document.getElementById(tabs[tabIdx - 1].id).focus();
      }
    }

    // right arrow key
    if (key === 39) {
      if (tabIdx < tabs.length - 1) {
        setActiveTabIdx(tabIdx + 1);
        document.getElementById(tabs[tabIdx + 1].id).focus();
      }
    }
  }

  function changeTabsClick(idx) {
    const newActiveContentId = `${tabs[idx].id}-tab`;
    setActiveContentId(() => newActiveContentId);
    setActiveTabIdx(idx);
  }

  return (
    <>
      <ul
        role="tablist"
        aria-label="Additional Information"
        className={`z-10 h-16 flex items-center bg-white flex-row border-b-xs overflow-hidden border-gray-400 px-5 justify-center ${getFixedClasses(
          isSidebar,
          noFixed,
          fixedWidth
        )} `}
      >
        {tabs.map((tab, idx) => {
          const isActive = activeTabIdx === idx;
          const bubbleBgClass = !isActive ? 'bg-gray-500' : 'bg-cyb-pink-500';
          const tabClass = isActive
            ? 'text-black font-semibold border-cyb-pink-500'
            : 'text-gray-600 border-transparent hover:border-cyb-pink-500 hover:font-semibold hover:text-black';
          return (
            <li key={tab.id} className="px-4 bg-white">
              <button
                role="tab"
                aria-selected={isActive}
                id={tab.id}
                aria-controls={`${tab.id}-tab`}
                className={`border-b-4 pb-4 mt-5 px-4 ${tabClass} flex`}
                onClick={() => changeTabsClick(idx)}
                onKeyDown={(e) => changeTabsKeyboard(e, tab)}
                tabIndex={isActive ? '0' : '-1'}
              >
                {tab.label}
                {!!tab.bubble && <span className={`block py-0.5 px-2 mt-0.5 ml-2 text-2xs font-semibold text-center text-white ${bubbleBgClass} rounded-full`}>{tab.bubble}</span>}
              </button>
            </li>
          );
        })}
      </ul>

      {tabs.map((tab, idx) => {
        const isActive = activeTabIdx === idx;
        return <MenuContent key={tab.id} content={tab} isActive={isActive} />;
      })}
    </>
  );
}

export default SidebarTabsMenu;
