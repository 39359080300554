import React, { Component } from 'react';
import queryString from 'query-string';
import { observer, inject } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import moment from 'moment';
import Icon from '../../components/Icon/Icon';
import Button from '../../components/Button/Button';
import withRouter from '../../components/Router/withRouter';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Loading from '../../components/Loading/Loading';
import { copyToClipboard } from '../../utils/actionsUtil';
import AdminTable from '../../components/AdminTable/AdminTable';
import StyledError from '../../components/Error/StyledError';
import OrganizationLogo from '../../components/Enterprise/OrganizationLogo';
import AddLink from '../../components/AddLink/AddLink';
import FormatUtil from '../../utils/formatUtil';
import { getRoleOptions } from '../../utils/teamsUtil';
import Modal from '../../components/Modal/Modal';
import Container from '../../components/Container/Container';
import ClipboardList from '../../Icons/ClipboardList';
import Tooltip from '../../components/Tooltip/Tooltip';
import Dropdown from '../../components/Dropdown/Dropdown';
import Label from '../../components/Label/Label';
import ChargebeeLabel from '../../components/Label/ChargebeeLabel';
import Title from '../../components/Title/Title';
import Input from '../../components/FormFields/Input';
import AdminTeamOnboardingTools from '../../components/Admin/AdminTeamOnboardingTools';
import './admin.css';
import Agents from '../../agents/agents';
import ConfiguredDomains from '../../components/TeamsSso/ConfiguredDomains';

function PackageTypeLabel({ packageTypes }) {
  if (!packageTypes || !packageTypes.length) {
    return null;
  }
  return packageTypes.map((packageType) => {
    let labelColor = packageType === 'team-full' ? 'blue' : 'purple';
    labelColor = packageType === 'avatao' ? 'orange' : labelColor;
    return (
      <Label key={packageType} className="p-1 text-xs rounded-sm" basic color={labelColor}>
        {FormatUtil.ucwords(packageType.split('-').join(' '))}
      </Label>
    );
  });
}

function TeamTypeLabels({ type, licenseSource }) {
  if (!type && !licenseSource) {
    return null;
  }

  return (
    <div>
      <span className="font-semibold">Team Type:</span>{' '}
      {type.name && (
        <Label className="p-1 ml-1 text-xs rounded-sm" basic>
          {type.name}
        </Label>
      )}
      {licenseSource === 'chargebee' && <ChargebeeLabel basic className="ml-1 capitalize" />}
    </div>
  );
}

const ViewTeam = inject(
  'commonStore',
  'adminStore',
  'authStore',
  'userStore'
)(
  observer(
    class ViewTeam extends Component {
      state = {
        team: null,
        selectedRole: null,
        addMembersModalOpen: false,
        copyCurriculaModalOpen: false,
        ssoButton: false,
      };

      componentDidMount() {
        const { location, match, adminStore, authStore, commonStore } = this.props;
        const queryParams = location && location.search ? queryString.parse(location.search) : {};
        const newState = {
          ...this.state,
          team: match.params.id ? match.params.id : null,
        };
        if (queryParams.bulkInvite) {
          newState.addMembersModalOpen = true;
        }
        this.setState(newState);
        // Confirm that we are allowed on the admin page
        adminStore.checkAccess(authStore);
        commonStore.hidePrimaryNav();
        if (match.params.id) {
          adminStore.getTeamDetailsData(match.params.id);
          adminStore.getTeamMemberTableData(match.params.id);
        }
      }

      componentWillUnmount() {
        this.props.adminStore.setDefaultTeamDetailsData();
        this.props.adminStore.setDefaultTeamMemberTableData();
        this.props.commonStore.showPrimaryNav();
      }

      getBreadCrumbs = () => {
        const crumbs = [];
        crumbs.push({
          href: '/admin',
          label: 'Admin',
        });
        crumbs.push({
          href: '/admin/teams',
          label: 'Browse Teams',
        });
        crumbs.push({
          href: `/admin/view-team/${this.state.team}`,
          label: 'View Team',
        });
        return crumbs;
      };

      copyInviteLink = () => {
        copyToClipboard(this.input);
      };

      // /**
      //  * Handles checkbox changes - Accumulates selection indexes in an array
      //  */
      handleCheckBoxes = (e, { checked }, idx) => {
        this.props.adminStore.setCheckboxState(this.props.adminStore.teamMemberTableData, idx, checked);
      };

      // /**
      //  * Handles header checkbox change - Selects/deselects all child checkboxes
      //  */
      headerCheckBoxFunc = (e, { checked }) => {
        this.props.adminStore.setAllSelectedRows(this.props.adminStore.teamMemberTableData, checked);
      };

      // /**
      //  * Sets sort column and direction vars in store for service call query params
      //  */
      tableSort = (heading) => {
        if (heading) {
          if (heading === this.props.adminStore.teamMemberTableData.queryParams.sortCol) {
            this.props.adminStore.teamMemberTableData.queryParams.sortDirection = this.props.adminStore.teamMemberTableData.queryParams.sortDirection === 'desc' ? 'asc' : 'desc';
          } else {
            this.props.adminStore.teamMemberTableData.queryParams.sortCol = heading;
            this.props.adminStore.teamMemberTableData.queryParams.sortDirection = 'desc';
          }
          this.props.adminStore.teamMemberTableData.queryParams.activePg = 1;
          this.props.adminStore.getTeamMemberTableData(this.state.team);
        }
      };

      // /**
      //  * Sets page number from pagination in store for service call query params
      //  */
      pagChangePg = (pg) => {
        this.props.adminStore.teamMemberTableData.queryParams.activePg = pg;
        this.props.adminStore.getTeamMemberTableData(this.state.team);
      };

      // /**
      //  * Sets search value in store for service call searchQuery
      //  */
      setSearch = (event, data) => {
        this.props.adminStore.teamMemberTableData.queryParams.searchQuery = data.value;
      };

      sendSearch = () => {
        this.props.adminStore.getTeamMemberTableData(this.state.team, true);
      };

      handleError = (e) => {
        if (e.response.status === 403) {
          this.props.commonStore.triggerToast('error', {
            errorCode: e.response.status,
          });
        } else {
          this.props.commonStore.triggerToast('error');
        }
      };

      // /**
      //  * Changes the user's role on the team
      //  */
      changeRole = (value) => {
        this.props.adminStore
          .changeUserRoles(this.props.adminStore.teamMemberTableData.selectedRows, value, this.state.team)
          .then(() => {
            this.props.commonStore.triggerToast('success', {
              content: 'User role has been updated for this team',
            });
            // After adding user to new role, get new data set with updated info
            this.props.adminStore.getTeamMemberTableData(this.state.team, false, true);
          })
          .catch((e) => {
            this.handleError(e);
          });
      };

      changeRoleConfirm = (e) => {
        if (this.props.adminStore.teamMemberTableData.selectedRows.length) {
          const currState = {
            ...this.state,
            selectedRole: e.value,
          };
          this.setState(currState, () =>
            this.props.commonStore.triggerConfirm({
              content: 'Are you sure you want to change user roles for this team?',
              cancel: () => {
                const newState = { ...this.state, selectedRole: null };
                this.setState(newState);
                this.props.commonStore.resetConfirmState();
              },
              continue: () => {
                this.props.commonStore.resetConfirmState();
                this.changeRole(e.value);
              },
            })
          );
        }
      };

      // /**
      //  * Removes members from the team
      //  */
      removeMembers = () => {
        if (this.props.adminStore.teamMemberTableData.selectedRows.length) {
          this.props.adminStore
            .removeMembers(this.state.team, this.props.adminStore.teamMemberTableData.selectedRows)
            .then(() => {
              this.props.commonStore.triggerToast('success', {
                content: 'User(s) removed from team',
              });
              // After removing user to new group, get new data set with updated info
              this.props.adminStore.getTeamDetailsData(this.state.team);
              this.props.adminStore.getTeamMemberTableData(this.state.team, false, true);
            })
            .catch((e) => {
              this.handleError(e);
            });
        }
      };

      showRemoveConfirm = () => {
        this.props.commonStore.triggerConfirm({
          content: 'Are you sure you want to remove users from this team?',
          cancel: () => this.props.commonStore.resetConfirmState(),
          continue: () => {
            this.props.commonStore.resetConfirmState();
            this.removeMembers();
          },
        });
      };

      // /**
      //  * Deletes the team
      //  */
      deleteTeam = () => {
        this.props.adminStore
          .deleteTeam(this.state.team)
          .then(() => {
            this.props.commonStore.triggerToast('success', {
              content: 'Team has been deleted. Redirecting back to the team browse page',
            });
            // After deleting team, redirect back to browse page
            setTimeout(() => {
              this.props.navigate('/admin/teams/');
            }, 3000);
          })
          .catch((e) => {
            this.handleError(e);
          });
      };

      showDeleteConfirm = () => {
        this.props.commonStore.triggerConfirm({
          content: 'WOAH, hold on. Are you sure you want to DELETE this team?',
          cancel: () => this.props.commonStore.resetConfirmState(),
          continue: () => {
            this.props.commonStore.resetConfirmState();
            this.deleteTeam();
          },
        });
      };

      toggleModal = (modal, action) => {
        const currState = {
          ...this.state,
        };
        currState[modal] = action;
        this.setState(currState);
      };

      addMemberCloseModal = (response) => {
        this.toggleModal('addMembersModalOpen', false);
        if (response === 'success') {
          this.props.commonStore.triggerToast('success', {
            content: 'User has been added to team',
          });
          this.props.adminStore.setDefaultTeamMemberTableData();
          this.props.adminStore.getTeamMemberTableData(this.state.team, true);
          this.props.adminStore.getTeamDetailsData(this.state.team);
        } else {
          this.props.commonStore.triggerToast('error');
        }
      };

      getTeamMembersTable = (data) => {
        if (data.loading) {
          return (
            <div>
              <Loading message="Loading..." />
            </div>
          );
        }
        if (data.error) {
          return (
            <div>
              <StyledError error={data.error} />
            </div>
          );
        }
        if (!data.totalRecords) {
          return <p className="no-results">No members on team!</p>;
        }
        return (
          <AdminTable
            /* Table Data */
            headings={data.tableHeadings}
            data={data.tableData}
            tableLoading={data.loading}
            tableError={data.error}
            displayCheckBox
            headerCheckBoxFunc={this.headerCheckBoxFunc}
            headerClickFunc={this.tableSort}
            sortCol={data.queryParams.sortCol}
            sortDirection={data.queryParams.sortDirection}
            rowCheckBoxFunc={this.handleCheckBoxes}
            headerCheckBoxState={data.headerCheckBox}
            checkBoxesState={data.ckBoxesState}
            /* Pagination options */
            pagNumPgs={data.pagPagesCount}
            pagChangePg={this.pagChangePg}
            pagActivePg={data.queryParams.activePg}
            ignoredCols={[FormatUtil.getColIndex(data.tableHeadings, 'groups')]}
          />
        );
      };

      getTableOptions = () => {
        return (
          <div className="grid grid-cols-12 view-team-options">
            <div className="col-span-8">
              <div className="flex gap-x-2 items-center">
                <Button color="green" className="flex gap-x-2 items-center" onClick={() => this.toggleModal('addMembersModalOpen', true)}>
                  <Icon name="user-plus" />
                  Add Members
                </Button>
                <Button color="red" className="flex gap-x-2 items-center" onClick={this.showRemoveConfirm}>
                  <Icon name="user-minus" />
                  Remove Members
                </Button>
                <Dropdown
                  classes="w-64 inline-block ml-4"
                  placeholder="Change Role"
                  options={getRoleOptions(true)}
                  value={this.props.adminStore.roleSelected}
                  onChange={this.changeRoleConfirm}
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="flex justify-end">
                <form className="relative" onSubmit={(e) => e.preventDefault()}>
                  <Input
                    className="py-2	px-4 w-11/12 max-w-lg text-sm text-black rounded-sm border border-gray-400 md:w-40 lg:w-72"
                    placeholder="Search"
                    onChange={this.setSearch}
                    value={this.props.adminStore.teamMemberTableData?.queryParams?.searchQuery}
                  />
                  <button onClick={this.sendSearch} className="absolute top-3 right-2">
                    <Icon name="search" className="w-4 h-4" />
                  </button>
                </form>
                <button
                  onClick={() => this.props.adminStore.exportMemberData(this.state.team)}
                  className="p-2 ml-2 text-sm font-bold text-center text-black bg-gray-200 rounded-sm"
                >
                  <Icon name="download" />
                </button>
                <AddLink
                  className="p-2 ml-2 text-sm font-bold text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm"
                  to={`/admin/view-team/${this.state.team}/logs`}
                >
                  <ClipboardList classes="w-6 h-6" />
                </AddLink>
              </div>
            </div>
          </div>
        );
      };

      startTeamLarping = () => {
        const orgId = this.state.team;
        this.props.adminStore.startLarping(this.props.userStore, orgId, this.props.navigate);
      };

      setSsoStatus = async (enabled) => {
        const updatedState = { ...this.state, ssoButton: true };
        this.setState(updatedState);
        try {
          await Agents.admin.setSsoStatus({ enabled }, this.state.team);
          this.props.adminStore.teamDetailsData.data.sso.enabled = enabled;
          this.props.commonStore.triggerToast('success', {
            content: `Team SSO has been ${enabled ? 'enabled' : 'disabled'} successfully`,
          });
        } catch (e) {
          this.props.commonStore.triggerToast('error', {
            content: 'Something went wrong. Please try again.',
          });
        }
        this.props.adminStore.getTeamDetailsData(this.props.match.params.id);
        this.setState({ ...updatedState, ssoButton: false });
      };

      render() {
        const { denyAccess, loading, teamDetailsData } = this.props.adminStore;
        // If we should deny access, just get out now
        if (denyAccess) {
          return <Navigate to="/" />;
        }
        if (this.props.adminStore.teamDetailsData.loading || loading) {
          return (
            <div>
              <Loading message="Loading..." />
            </div>
          );
        }
        if (!this.props.adminStore.teamDetailsData.data) {
          return null;
        }
        const { package_types, name, max_seats, active_seats, invite_url, member_count, team_type, license_source, license_expires_at, status, domains, salesforce_id, sso } =
          teamDetailsData.data;
        return (
          <Container size="xl" className="mt-6 admin-view-team">
            <Breadcrumbs crumbs={this.getBreadCrumbs()} />
            <div className="grid grid-cols-12 mb-8">
              <div className="col-span-2">
                <div className="w-44 h-44">
                  <OrganizationLogo
                    className="flex justify-center items-center w-full h-full"
                    teamId={this.state.team}
                    logoUrl={this.props.adminStore.teamDetailsData.data.logo_url}
                  />
                </div>
              </div>
              <div className="col-span-10 header-container">
                <div className="grid grid-cols-12">
                  <div className="col-span-10">
                    <div className="flex gap-x-2 items-center">
                      <Title classes="text-3xl lg:text-4xl view-team-header" omitPadding title={name} />
                      <PackageTypeLabel packageTypes={package_types} />
                    </div>
                    <div className="grid grid-cols-2">
                      <div>
                        <div>
                          <span className="font-semibold">Total Members:</span> {member_count}
                        </div>
                        {max_seats && active_seats ? (
                          <div>
                            <span className="font-semibold">Licenses Used:</span> {FormatUtil.formatNumbers(active_seats)} of {FormatUtil.formatNumbers(max_seats)}
                          </div>
                        ) : null}
                        {salesforce_id ? (
                          <div>
                            <span className="font-semibold">Salesforce ID:</span> {salesforce_id}
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <div>
                          <span className="font-semibold">Status: </span>
                          <span className={status === 'Active' ? 'text-green-700' : 'text-red-700'}>{status}</span>
                        </div>
                        {license_expires_at ? (
                          <div>
                            <span className="font-semibold">Contract End Date:</span> {moment(license_expires_at).format('M/D/YYYY')}
                          </div>
                        ) : null}
                        <TeamTypeLabels type={team_type} licenseSource={license_source} />
                        <span className="font-semibold">Email Domains: </span>
                        <ConfiguredDomains domains={domains} />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <AddLink
                      to={`/admin/edit-team/${this.state.team}`}
                      className="block py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm"
                    >
                      <Icon name="wrench" className="inline-block mr-2 w-4 h-4" />
                      Edit Team
                    </AddLink>
                    <Button color="gray" className="mt-4 w-full" onClick={() => this.startTeamLarping()}>
                      <Icon name="spy" className="inline-block mr-2 w-4 h-4" />
                      LARP
                    </Button>
                    {sso ? (
                      <Button
                        className="mt-4 w-full"
                        loading={this.state.ssoButton}
                        disabled={this.state.ssoButton}
                        color={sso.enabled ? 'red' : 'green'}
                        onClick={() => this.setSsoStatus(!sso.enabled)}
                      >
                        {`${sso.enabled ? 'Disable' : 'Enable'} SSO`}
                      </Button>
                    ) : null}
                  </div>
                </div>
                <div className="flex items-center mt-12 invite-url">
                  <Tooltip
                    omitTabIndex
                    triggerContent={
                      <button onClick={this.copyInviteLink}>
                        <Icon name="copy" />
                      </button>
                    }
                    trigger="click"
                    content="Copied"
                    position="top"
                  />
                  <input
                    ref={(input) => {
                      this.input = input;
                    }}
                    readOnly
                    value={invite_url}
                  />
                </div>
              </div>
            </div>
            <>{this.getTableOptions()}</>
            {this.getTeamMembersTable(this.props.adminStore.teamMemberTableData)}
            <Button color="red" className="flex gap-x-2 items-center" onClick={this.showDeleteConfirm}>
              <Icon name="x-circle" /> Delete Team
            </Button>
            <Modal
              open={this.state.addMembersModalOpen}
              toggle={() => this.toggleModal('addMembersModalOpen', false)}
              size="lg"
              className="p-4 view-team-add-members"
              ariaLabelledBy="add-members-modal"
            >
              <h2 className="mb-4 text-2xl font-bold" id="add-members-modal">
                Add Members
              </h2>
              <AdminTeamOnboardingTools team={this.state.team} teamName={name} addMember={this.addMemberCloseModal} />
            </Modal>
          </Container>
        );
      }
    }
  )
);

export default withRouter(ViewTeam);
