import React, { createContext, useContext, useState, useMemo, useCallback, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import Agents from '../agents/agents';

const ProfileContext = createContext();

function ProfileProvider({ children, userStore }) {
  const defaultPersonalProfileData = {
    avatar_url: '',
    firstName: '',
    lastName: '',
    pronouns: '',
    username: '',
    phone: '',
    email: '',
    country: '',
    bio: '',
    location: '',
    tagline: '',
    github: '',
    instagram: '',
    linkedin: '',
    facebook: '',
    twitter: '',
    website: '',
  };
  const [personalProfileData, setPersonalProfileData] = useState(defaultPersonalProfileData);
  const [certificationsProfileData, setCertificationsProfileData] = useState({});
  const [badges, setBadges] = useState([]);
  const [isPublic, setIsPublic] = useState(false);
  const [serverErrors, setServerErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchProfileData = useCallback(async () => {
    try {
      setIsLoading(true);
      // will return an object with ALL of the user's profile data...
      const response = await Agents.profile.getProfile();
      if (response?.data) {
        setCertificationsProfileData(response.data.certifications);
        setIsPublic(response.data.is_public);
        setBadges(response.data.badges);
        const personalData = { ...response.data.personal, email: response.data.email };
        setPersonalProfileData(personalData);
      }
      return true;
    } catch (error) {
      Bugsnag.notify(error);
      return false;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateProfile = useCallback(async (section, data) => {
    try {
      setIsLoading(true);
      setServerErrors({});

      const requestData = data[section] ? data : { [section]: data };
      // @important - When saving personal data set has_default_username to 0 b/c the laravel was not working well with sending along a boolean value
      if (section === 'personal') {
        requestData.personal.has_default_username = 0;
      }
      // returns boolean if request was successful
      const updatedData = await Agents.profile.updateProfile(requestData);
      if (updatedData && section === 'personal') {
        setPersonalProfileData(requestData.personal);
      } else if (updatedData && section === 'certifications') {
        setCertificationsProfileData(requestData.certifications);
      }
      return true;
    } catch (error) {
      const errors = error?.response?.data?.errors || error || {};

      setServerErrors(errors);
      Bugsnag.notify(error);
      return false;
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    // fetch user personal and certification data on mount
    if (userStore?.user) {
      fetchProfileData();
    }
  }, [userStore?.user]);
  // values returned to be used in the user's profile page
  const values = useMemo(
    () => ({
      isLoading,
      personalProfileData,
      certificationsProfileData,
      badges,
      serverErrors,
      isPublic,
      setServerErrors,
      fetchProfileData,
      updateProfile,
      setPersonalProfileData,
    }),
    [isLoading, personalProfileData, certificationsProfileData, badges, serverErrors, isPublic, setServerErrors, fetchProfileData, updateProfile, setPersonalProfileData]
  );

  return <ProfileContext.Provider value={values}>{children}</ProfileContext.Provider>;
}

export const useProfileContext = () => useContext(ProfileContext);
export default ProfileProvider;
