import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Wrapper, Button, Menu, MenuItem } from 'react-aria-menubutton';
import Icon from '../Icon/Icon';

function handleSelection(value, options) {
  if (value && options && options.length) {
    // Find the option for this value
    const selectedOption = options.filter((option) => option.text === value);
    // Trigger action for the option
    if (selectedOption && selectedOption.length) {
      selectedOption[0].action();
    }
  }
}

function OptionsMenu({
  options,
  noBackground,
  menuAbove,
  buttonAriaLabel,
  wrapperClassName = '',
  menuClasses = '',
  optionClasses = '',
  iconContainerClassName = 'text-black bg-gray-200 hover:bg-white',
  disabled,
}) {
  if (!options || !options.length) {
    return null;
  }
  const menuBaseClasses = `absolute rounded-lg border box-shadow bg-white z-50 ${menuAbove ? 'bottom-6' : ''}`;
  const wrapperSizeClasses = !noBackground ? 'w-10 h-10' : 'w-6 h-6';
  const buttonProps = { 'aria-label': buttonAriaLabel || 'More Options', className: `flex ${disabled ? 'cursor-not-allowed' : ''}` };
  return (
    <Wrapper
      className={twMerge(`inline-block relative align-middle ${wrapperSizeClasses}`, wrapperClassName)}
      onClick={(e) => e.stopPropagation()}
      onSelection={(value) => handleSelection(value, options)}
    >
      <Button {...buttonProps} disabled={disabled}>
        <span className={`inline-block rounded-sm ${iconContainerClassName} ${!noBackground ? 'py-2.5 px-3 h-10' : 'py-0.5 px-1 h-6'} font-bold text-center leading-5 text-sm`}>
          <Icon className="w-5 h-5" name="dots-horizontal" />
        </span>
      </Button>
      <Menu className={twMerge(menuBaseClasses, menuClasses)}>
        <ul className="py-1 sm:py-2">
          {options.map((option) => {
            if (option.dividerLine) {
              return <div key="divider" className="pt-2 mb-2 border-b-xs border-gray-400" />;
            }
            return (
              <li className="text-xs text-left text-gray-600 cursor-pointer sm:text-sm" key={option.text}>
                <MenuItem className={twMerge('py-1 px-4 whitespace-nowrap hover:bg-gray-300 sm:py-2', optionClasses)}>{option.text}</MenuItem>
              </li>
            );
          })}
        </ul>
      </Menu>
    </Wrapper>
  );
}

export default OptionsMenu;
