import React from 'react';
import CountUp from 'react-countup';
import Icon from '../../Icon/Icon';
import Linkedin from '../../../Icons/Linkedin';
import Facebook from '../../../Icons/Facebook';
import Twitter from '../../../Icons/Twitter';
import GitHub from '../../../Icons/GitHub';
import Instagram from '../../../Icons/Instagram';
import ShareButton from '../../Share/ShareButton';
import If from '../../If/If';
import Divider from '../../Divider/Divider';
/**
 * @param {ReactNode} SocialIcon - social icon component of the relevant social media platform
 * @param {string} url - url to social page
 * @param {string} socialName - name of social media platform
 * @returns <SocialButton /> component which contains the social media icon and link to the social media page
 */
function SocialButton({ SocialIcon, url, socialName = '' }) {
  return (
    <div id={`${socialName}-button`} className="pr-2 hover:cursor-pointer">
      {/* 
        we use <a> over AddLink here b/c AddLink doesn't work well with dynamic urls... 
        i.e. google.com will be rerouted in app vs https://www.google.com will go to google.
        We know we want all urls here to be external 
      */}
      <a href={url} target="_blank" rel="noreferrer" aria-label={`Link to your ${socialName} page`}>
        <span className="sr-only">{socialName}</span>
        {SocialIcon}
      </a>
    </div>
  );
}

/**
 * Pipe divider between social icons and streaks
 * @returns <PipeDivider /> component
 */
function PipeDivider() {
  return <Divider className="hidden h-3 border-l-1 border-black sm:block" vertical />;
}

function StatLabel({ children }) {
  return <p className="pr-2 mb-0 text-base whitespace-nowrap"> {children} </p>;
}

/**
 * @param {number} currentStreak - user streak for daily logins and activity
 * @param {string} linkedin - linkedin url
 * @param {string} facebook - facebook url
 * @param {string} twitter - twitter url
 * @param {string} github - github url
 * @param {string} instagram - instagram url
 * @param {function} handleClickShare - function to toggle share button with necessary params
 * @param {number} currentLevel - user's current level
 * @param {number} currentXp - user's current xp
 * @returns <ProfileStreaksAndSocials />
 */
function ProfileStreaksAndSocials({ currentStreak, linkedin, facebook, twitter, github, instagram, handleClickShare, currentLevel, currentXp }) {
  return (
    <div className="flex justify-between items-center">
      <div id="profile-stats-wrapper" className="items-center sm:flex sm:space-x-2">
        {/* Level */}
        <If condition={currentLevel && currentLevel > 0}>
          <div id="profile-level-wrapper" className="flex items-center">
            <StatLabel>Level</StatLabel>
            <div id="streak-icon-wrapper" className="flex items-center rounded-full border-1 border-black">
              <Icon name="star" className={`m-1 w-3 h-3 text-black `} />
            </div>
            <CountUp className="pl-2 mb-0 text-base font-bold" separator="," end={currentLevel} duration={1} />
          </div>
          <PipeDivider />
        </If>
        {/* XP */}
        <If condition={currentXp && currentXp >= 0}>
          <div id="profile-level-wrapper" className="flex items-center">
            <StatLabel>Total XP</StatLabel>
            <div id="streak-icon-wrapper" className="flex items-center rounded-full border-1 border-cyb-green-400">
              <Icon name="xp" className={`m-1 w-3 h-3 text-cyb-green-400 `} />
            </div>
            <CountUp className="pl-2 mb-0 text-base font-bold" separator="," end={currentXp} duration={1} />
          </div>
          <PipeDivider />
        </If>
        {/* DAILY STREAK */}
        <div id="profile-streak-wrapper" className="flex items-center">
          <StatLabel>Daily Streak</StatLabel>
          <div id="streak-icon-wrapper" className="flex items-center rounded-full border-1 border-red-500">
            <Icon name="fire" className={`m-1 w-3 h-3 text-red-500 `} />
          </div>
          <CountUp className="pl-2 mb-0 text-base font-bold" separator="," end={currentStreak} duration={1} />
        </div>
      </div>
      {/* SOCIAL ICONS */}
      <div id="social-icons-container" className="flex pr-2">
        {!!handleClickShare && <ShareButton onClick={handleClickShare} textColor="text-white" iconClasses="w-6 h-6 mx-2" useIcon />}
        {!!instagram && (
          <SocialButton
            SocialIcon={
              <Instagram
                classes="w-6 h-6 text-white bg-left-bottom bg-gradient-to-tr from-[#FFDD55] via-[#FF543E] to-[#C837AB] rounded-md p-0.5"
                accessibilityProps={{ 'aria-label': `Instagram Icon` }}
              />
            }
            url={instagram}
            socialName="Instagram"
          />
        )}
        {!!linkedin && (
          <SocialButton
            SocialIcon={<Linkedin classes="w-6 h-6 text-white bg-[#0077B5] rounded-md" accessibilityProps={{ 'aria-label': `Linkedin Icon` }} />}
            url={linkedin}
            socialName="Linkedin"
          />
        )}
        {!!facebook && (
          <SocialButton
            SocialIcon={<Facebook classes="w-6 h-6 text-white bg-[#3b5998] rounded-md" accessibilityProps={{ 'aria-label': `Facebook Icon` }} />}
            url={facebook}
            socialName="Facebook"
          />
        )}
        {!!twitter && (
          <SocialButton
            SocialIcon={<Twitter classes="w-6 h-6 text-white bg-[#1DA1F2] rounded-md" accessibilityProps={{ 'aria-label': `Twitter Icon` }} />}
            url={twitter}
            socialName="Twitter"
          />
        )}
        {!!github && (
          <SocialButton
            SocialIcon={<GitHub classes="w-6 h-6 text-black bg-white rounded-md" accessibilityProps={{ 'aria-label': `Github Icon` }} />}
            url={github}
            socialName="Github"
          />
        )}
      </div>
    </div>
  );
}
export default ProfileStreaksAndSocials;
