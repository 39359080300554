import React, { useEffect, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { useContinueLearningContext } from '../../../providers/ContinueLearningProvider';
import FormatUtil from '../../../utils/formatUtil';
import PermalinkUtil from '../../../utils/permalinkUtil';
import AddLink from '../../AddLink/AddLink';
import ProgressBar from '../../ProgressBar/ProgressBar';
import { ContinueLearningButton } from '../ContinueLearningButtons';
import ContinueLearningFeatures from '../ContinueLearningFeatures';
import ContinueLearningNoContent from '../ContinueLearningNoContent';
import Header from '../../Header/Header';
import Chip from '../../Chip/Chip';
import { useCareerPrograms } from '../../../providers/CareerProgramsProvider';
import If from '../../If/If';
import Loading from '../../Loading/Loading';
import { BASE_BUTTON_STYLES } from '../../../constants';

/**
 * Tab for showing the most recently interacted with enrollment
 * within the continue learning block on the dashboard
 * @returns <ContinueLearningCurrentEnrollmentTab />
 */
export default function ContinueLearningCurrentEnrollmentTab() {
  const { userChosenEnrollments, isLoading, initEnrollmentsError } = useContinueLearningContext();
  const { currentProgramEnrollment, isLoadingCurrentProgram, currentProgramError, getCurrentProgram } = useCareerPrograms();

  // If career programs are enabled, use the career program enrollment as the current enrollment if we have one
  // Career programs are not actual enrollments, and needs some backfilling of data to match an actual enrollment and work with the this display
  const firstIncompleteProgramChildEnrollment = useMemo(() => {
    // No match? No program, or finished program, or no enrollments
    if (!currentProgramEnrollment) return null;

    const content = currentProgramEnrollment.content_description || {};
    const browseData = currentProgramEnrollment?.programBrowseData;

    // Meta data about the program
    currentProgramEnrollment.content_description_thumbnail_url = content?.thumbnail_url;
    currentProgramEnrollment.content_description_title = content?.title;
    currentProgramEnrollment.content_description_type = browseData?.content_type?.nice_name;
    currentProgramEnrollment.content_description_permalink = content?.permalink;
    currentProgramEnrollment.total_enrollments = browseData?.enrollments_total;
    currentProgramEnrollment.experience_points_total = browseData?.experience_points_total;

    // Transformed data about the program (We don't have this data for career programs, so we backfill it here)
    currentProgramEnrollment.transformedData = {
      domains: browseData?.terms_info?.filter((term) => term.startsWith('Domains|')).map((term) => term.replace('Domains|', '')),
      secondsTotal: browseData?.duration_seconds,
      currentContentItem: {
        level: {
          name: browseData?.level?.name || 'Unknown',
        },
      },
    };

    // This is a hack - Use xp earned / xp total for progress. We store this in the activitiesCompleted field and activitiesTotal field
    currentProgramEnrollment.transformedData.activitiesCompleted = currentProgramEnrollment.experience_points_earned;
    currentProgramEnrollment.transformedData.activitiesTotal = currentProgramEnrollment.experience_points_total;

    // We want to show an XP message along with the progress bar
    currentProgramEnrollment.transformedData.showXpMessage = true;

    return currentProgramEnrollment;
  }, [currentProgramEnrollment?.programBrowseData, currentProgramEnrollment?.content_description]);

  /**
   * Re-fetch the current program if we don't have it yet
   * This ensures we still have what we need for this display
   * when the career programs ctx is reset elsewhere in the app
   */
  useEffect(() => {
    if (!currentProgramEnrollment && !isLoadingCurrentProgram) {
      getCurrentProgram();
    }
  }, []);

  // Use the first incomplete program child enrollment if we have one, otherwise use the user chosen enrollments
  const currentEnrollment = firstIncompleteProgramChildEnrollment || userChosenEnrollments?.[0];

  const {
    content_description_thumbnail_url,
    content_description_title,
    content_description_type: type,
    content_description_permalink,
    total_enrollments: totalEnrollments,
    transformedData,
    experience_points_total: totalXp,
    experience_points_earned: earnedXp,
  } = currentEnrollment || {};

  const { currentActivity, currentContentItem, activitiesCompleted, activitiesTotal, secondsCompleted, secondsTotal, domains, isCollection, showXpMessage } = transformedData || {};
  // set attributes of the card with priority to the course level data from the transformedData object
  const title = transformedData?.title || content_description_title;
  // Only show Domains in the continue learning block tag section.
  const tags = useMemo(() => {
    let allTags = [];
    // Domains are arrays that can be null
    if (domains && Array.isArray(domains)) {
      allTags = [...allTags, ...domains];
    }
    return allTags;
  }, [domains]);

  // Build immersive link
  let linkTo = `/immersive/${currentEnrollment?.id}`;

  // Collections should link to the active item within the collection
  if (isCollection && currentContentItem?.id && currentActivity?.id) {
    linkTo += `/item/${currentContentItem.id}/activity/${currentActivity?.id}`;
  }

  // Every link should end with the current activity if we have one
  if (!isCollection && currentActivity?.id) {
    linkTo += `/activity/${currentActivity?.id}`;
  }

  const permalink = PermalinkUtil.formatInApp(content_description_permalink, type);
  const coursePermalink = currentContentItem?.permalink ? PermalinkUtil.formatInApp(currentContentItem?.permalink) : permalink;
  const thumbnailUrl = currentContentItem?.thumbnail_url || content_description_thumbnail_url;
  const difficulty = currentContentItem?.level?.name;
  const xp = currentContentItem?.experience_points_total;
  const contentType = FormatUtil.formatContentType(currentContentItem?.content_type?.nice_name || type);
  const imageLink = firstIncompleteProgramChildEnrollment ? permalink : linkTo;

  const completedPercent = useMemo(() => Math.floor((activitiesCompleted / activitiesTotal) * 100), [activitiesCompleted, activitiesTotal]);
  const remainingTime = useMemo(() => FormatUtil.formatTime(secondsTotal - secondsCompleted, 'hma')?.trim(), [secondsCompleted, secondsTotal]);

  if (!currentProgramEnrollment && isLoadingCurrentProgram) {
    return <Loading message="Loading your learning..." wrapperClassName="w-full h-40" />;
  }

  /** Career Programs - Error */
  if (currentProgramEnrollment && !isLoadingCurrentProgram && currentProgramError) {
    return <ContinueLearningNoContent title="Uh-oh, something went wrong!" body={currentProgramError?.message} />;
  }

  /** General Content - Error */
  if (!isLoading && initEnrollmentsError && !currentProgramEnrollment) {
    return <ContinueLearningNoContent title="Uh-oh, something went wrong!" body={initEnrollmentsError?.message} />;
  }

  /** General Content - Empty */
  if (!isLoading && !currentEnrollment) {
    return <ContinueLearningNoContent />;
  }

  return (
    <div className="px-4 m-auto w-full max-w-[550px] lg:max-w-full">
      <div className="block items-center mb-4 lg:flex">
        <AddLink to={imageLink}>
          <img src={`${thumbnailUrl}?w=500`} alt={`${title} ${type} thumbnail`} className="m-auto w-full max-w-[520px] lg:w-[240px]" />
        </AddLink>
        <div className="flex flex-col lg:pl-4 lg:w-full">
          {/** Header */}
          <AddLink to={coursePermalink}>
            <Header as="h3" className="mb-2">
              {title || 'Course Title'}
            </Header>
          </AddLink>

          {/** Features */}
          <ContinueLearningFeatures type={contentType} learningSecondsTotal={secondsTotal} difficulty={difficulty} totalEnrollments={totalEnrollments} xp={xp} />

          {/** Tags */}
          <div className="flex my-2 space-x-2">
            {!!tags?.length &&
              tags.map((tag) => (
                <Chip key={tag} className="py-0.5">
                  {tag}
                </Chip>
              ))}
          </div>

          {/** Progress */}
          <div className="flex justify-between items-center space-x-3 w-full">
            <div className="w-full">
              <p className="mb-2 text-xs">
                {completedPercent || 0}% complete <If condition={!!remainingTime}>({remainingTime} remaining)</If>
                <If condition={!!showXpMessage && !!totalXp}>
                  ({earnedXp || 0} / {totalXp} XP)
                </If>
              </p>
              <ProgressBar progress={completedPercent || 0} />
            </div>
            <If condition={!firstIncompleteProgramChildEnrollment}>
              <ContinueLearningButton to={linkTo} className="w-full lg:w-auto" />
            </If>
            <If condition={firstIncompleteProgramChildEnrollment}>
              <AddLink className={twMerge(BASE_BUTTON_STYLES, 'bg-purple-700 hover:bg-purple-600 w-full lg:w-auto')} to={coursePermalink}>
                Go to Path
              </AddLink>
            </If>
          </div>
        </div>
      </div>
    </div>
  );
}
