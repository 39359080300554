import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import Bugsnag from '@bugsnag/js';
import moment from 'moment';
import Card from '../../components/Card/Card';
import ComboboxMultiselect from '../../components/Enterprise/ComboboxMultiselect';
import Agents from '../../agents/agents';
import TimeUtil from '../../utils/timeUtil';
import BarChart from '../../components/Charts/BarChart';
import Table from '../../components/Table/Table';
import SolidChip from '../../components/Chip/SolidChip';
import PieChart from '../../components/Charts/PieChart';
import Container from '../../components/Container/Container';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';
import Segment from '../../components/Segment/Segment';
import NoResultsMessage from '../../components/NoResultsMessage/NoResultsMessage';
import DropdownFilter from '../../components/Dropdown/DropdownFilter';
import Checkbox from '../../components/FormFields/Checkbox';
import useBetaCookie from '../../hooks/cookies/useBetaCookie';
import { findIndexById } from '../../components/Clab/utils/helpers';
import DEMO_ORG_PROGRESS_DATA from './Demo/DEMO_ORG_PROGRESS_DATA.json';
import LineChart from '../../components/Charts/LineChart';
import If from '../../components/If/If';

function LineTooltip(props) {
  const {
    point: { id, data, serieId: label },
  } = props;

  if (data.marker) {
    return null;
  }

  return (
    <div
      key={id}
      style={{
        background: 'white',
        color: 'inherit',
        fontSize: 'inherit',
        borderRadius: 2,
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
        padding: '10px 10px',
      }}
    >
      <p className="mb-1 text-base text-center">{data.y.toFixed(2)} hours</p>
      <p className="text-xl font-semibold text-center">{label}</p>
    </div>
  );
}

function TrainingProgressDetails({ details, unit = 'hours' }) {
  if (!details) {
    return null;
  }

  const unitLabel = unit === 'hours' ? 'Learning Hours' : 'XP';

  const { breakdown, category_term_name: categoryName, formattedPeriod } = details;
  const chartData = breakdown.map((row) => {
    const { topic_measure: value, topic_name: label } = row;
    return {
      id: label,
      label,
      value,
    };
  });

  return (
    <div className="border-t-2 border-t-gray-100">
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <div className="p-4">
          <div className="flex mb-4 space-x-4">
            <h3 className="font-bold">Detail</h3>
            <SolidChip>{formattedPeriod}</SolidChip>
            <SolidChip>{categoryName}</SolidChip>
          </div>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Topic</Table.HeaderCell>
                <Table.HeaderCell>{unitLabel}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {breakdown.map((row) => {
                const { topic_measure: measure, topic_name: topic } = row;
                return (
                  <Table.Row key={topic}>
                    <Table.Cell>{topic}</Table.Cell>
                    <Table.Cell>{measure}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
        <div className="p-4">
          <div className="mb-2">
            <h3 className="font-bold">Distribution</h3>
          </div>
          <div className="h-[400px]">
            <PieChart data={chartData} />
          </div>
        </div>
      </div>
    </div>
  );
}

function TrainingProgressGraph({ interval, filters, domainFilters, orgId, unitOfMeasure = 'hours', taxonomy = 'cybrary', isDemo = false, memberId = null }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [query, setQuery] = useState(null);
  const [keys, setKeys] = useState(null);
  const [details, setDetails] = useState(null);
  const [lineData, setLineData] = useState(null);
  // Will determine if we use the experimental area chart or the regular bar chart
  const { isNewOrgDashboardEnabled } = useBetaCookie();

  useEffect(() => {
    // Build our query string, which will be used when making the request
    const queryParams = filters
      ? {
          startDate: moment(filters.startDate).format('YYYY-MM-DD 00:00:00'),
          endDate: moment(filters.endDate).format('YYYY-MM-DD 23:59:59'),
        }
      : {};
    if (interval) {
      queryParams.interval = interval === 'time' ? TimeUtil.getDateRangeInterval(filters.startDate, filters.endDate) : interval;
    }
    if (memberId) {
      queryParams.userId = memberId;
    }
    queryParams.unit = unitOfMeasure;
    queryParams.taxonomy = taxonomy;
    setQuery(`?${queryString.stringify(queryParams)}`);
  }, [filters, interval, unitOfMeasure, memberId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(false);
      setDetails(null);
      try {
        let response;
        if (isDemo) {
          response = DEMO_ORG_PROGRESS_DATA;
        } else {
          response = await Agents.reports.getReportData(orgId, 'team-taxonomy-progress', query);
        }
        // Add our data from each response to newData
        const graphData = [];
        const newKeys = [];
        const dataByPeriod = {};
        if (response?.length) {
          const queryParams = query ? queryString.parse(query) : {};
          // Only show the day in our label if we are zoomed into to an hour or day time interval
          const labelDateFormat = ['hour', 'day', 'week'].includes(queryParams.interval) ? 'MMM D, YYYY' : 'MMM YYYY';
          response.forEach((d) => {
            const { category_term_name: name, category_term_id: termId, measure, period } = d;
            // We either filter these on the request (so add to params), OR we handle this client side and don't require a round trip to the server to do so
            if (domainFilters?.length && domainFilters.indexOf('_all') === -1) {
              if (domainFilters.indexOf(termId) === -1) {
                // Skip this if we don't have the term id in our filters array
                return;
              }
            }
            const formattedPeriod = moment(period).format(labelDateFormat);
            const periodTimestamp = moment(period).unix();
            if (!dataByPeriod[formattedPeriod]) {
              const i = graphData.length;
              dataByPeriod[formattedPeriod] = {
                index: i,
                period,
                periodTimestamp,
              };
              graphData.push({
                period: formattedPeriod,
              });
            }
            if (!dataByPeriod[formattedPeriod][name]) {
              // Build our raw data, keyed by period, and then the name of the category term
              dataByPeriod[formattedPeriod][name] = {
                ...d,
                formattedPeriod,
              };
            }
            if (newKeys.indexOf(name) === -1) {
              newKeys.push(name);
            }
            // Grab the index of this period in our graph data
            const indexToUse = dataByPeriod[formattedPeriod].index;
            graphData[indexToUse][name] = parseFloat(measure || 0);
          });
        }
        setKeys(newKeys);
        setData(graphData);
        setRawData(dataByPeriod);
        // Now handle the line data
        const orderedData = [];
        const formattedPeriods = Object.keys(dataByPeriod);
        formattedPeriods.forEach((formattedPeriod) => {
          const dataForThisPeriod = dataByPeriod[formattedPeriod] || {};
          orderedData.push({
            formattedPeriod,
            ...dataForThisPeriod,
          });
        });
        orderedData.sort((a, b) => a.periodTimestamp - b.periodTimestamp);
        const newLineChartData = [];
        orderedData.forEach((periodData) => {
          const { formattedPeriod: xValue } = periodData;
          // Loop over each of our categories (aka each of our lines) and get the data
          newKeys.forEach((name) => {
            let indexOfData = findIndexById(newLineChartData, name);
            if (indexOfData === -1) {
              newLineChartData.push({
                id: name,
                data: [],
              });
              indexOfData = newLineChartData.length - 1;
            }
            const dataPoint = parseFloat(periodData?.[name]?.measure || 0);
            newLineChartData[indexOfData].data.push({
              x: xValue,
              y: dataPoint,
            });
          });
        });
        setLineData(newLineChartData);
      } catch (err) {
        Bugsnag.notify(err);
        setError(err);
      }
      setLoading(false);
    };
    // If we have an empty query string, just return
    if (!query) {
      return;
    }

    fetchData();
  }, [query, orgId, domainFilters, isDemo]);

  if (error) {
    return (
      <Container>
        <StyledError error={error} />
      </Container>
    );
  }

  if (loading || !data) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }

  if (!data?.length) {
    return (
      <Segment className="border-none empty-stats-container">
        <NoResultsMessage message="There is no data to display for this time period." />
      </Segment>
    );
  }

  const chartClickHandler = (clicked) => {
    if (taxonomy !== 'cybrary') {
      return;
    }
    const { id, indexValue } = clicked;
    setDetails(rawData?.[indexValue]?.[id] || null);
  };

  const lineChartClickHandler = (clicked) => {
    if (taxonomy !== 'cybrary') {
      return;
    }
    const {
      serieId: category,
      data: { x: month },
    } = clicked;
    setDetails(rawData?.[month]?.[category] || null);
  };

  return (
    <div>
      <div className="relative h-[600px]">
        <If condition={!!isNewOrgDashboardEnabled}>
          <LineChart data={lineData} onClick={lineChartClickHandler} legends tooltip={LineTooltip} colorScheme="category10" areaOpacity={0.7} />
        </If>
        <If condition={!isNewOrgDashboardEnabled}>
          <BarChart onClick={chartClickHandler} data={data} keys={keys} indexBy="period" ariaLabel="Training progress chart" maxLegendLength={18} />
        </If>
      </div>
      <TrainingProgressDetails details={details} unit={unitOfMeasure} />
    </div>
  );
}

function OrganizationTrainingProgress({ filters, orgId, isDemo = false, memberId = null }) {
  const [localFilters, setLocalFilters] = useState(['_all']);
  const [switching, setSwitching] = useState(false);
  const [taxonomy, setTaxonomy] = useState('cybrary');
  const [unitOfMeasure, setUnitOfMeasure] = useState('hours');

  const fetchOptions = () => {
    const categories = taxonomy === 'cybrary' ? 'Domains' : 'NICE Category';
    return Agents.catalog.categoryTerms(`?categories[]=${categories}`);
  };

  const handleDomainChange = (val) => {
    setLocalFilters(val.map((item) => item.value));
  };

  const handleTaxonomyToggle = () => {
    setSwitching(true);
    setTaxonomy(taxonomy === 'cybrary' ? 'nice' : 'cybrary');
    setTimeout(() => {
      setSwitching(false);
    }, 150);
  };

  const unitOfMeasureOptions = [
    {
      id: 'Learning Hours',
      text: 'Learning Hours',
      value: 'hours',
    },
    {
      id: 'XP',
      text: 'XP',
      value: 'xp',
    },
  ];

  const handleUnitChange = (item) => {
    setUnitOfMeasure(item.value);
  };

  const taxonomyTitle = taxonomy === 'cybrary' ? 'Domains' : 'Categories';

  return (
    <div className="mb-8">
      <div className="mb-4 learning-hours">
        <Card className="z-50 p-0 my-0">
          <div className="flex z-50 border-b border-b-gray-100">
            <div className="flex-1 p-4">
              <h3 className="mb-3 text-lg font-bold">Training Progress</h3>
              <If condition={!memberId}>
                <p className="mb-3">How is my team progressing against my training framework?</p>
              </If>
            </div>
            <div className="z-50 justify-end p-4">
              <div className="flex print:hidden justify-end items-center text-sm">
                <p className="pr-2 pb-2 mb-0">Cybrary Framework</p>
                <Checkbox toggle onChange={handleTaxonomyToggle} checked={taxonomy !== 'cybrary'} ariaLabelledBy="toggle-taxonomy" disabled={switching} />
                <p className="pb-2 pl-2 mb-0">NICE Framework</p>
              </div>
              <div className="flex z-50 items-center">
                <label className="block pr-5 w-[190px] font-semibold text-right">{taxonomyTitle}</label>
                <div className="z-50">
                  {!switching && <ComboboxMultiselect performQuery={fetchOptions} placeholder={`Select ${taxonomyTitle}`} onChange={handleDomainChange} />}
                </div>
              </div>
              <div className="flex z-50 items-center mt-4">
                <label className="block pr-5 w-[190px] font-semibold text-right">Unit of Measure</label>
                <div className="w-[220px] text-left">
                  <DropdownFilter
                    name="unit"
                    options={unitOfMeasureOptions}
                    value={unitOfMeasure}
                    onChange={handleUnitChange}
                    placeholder="Unit Of Measure"
                    ariaLabel="Change unit of measure"
                    hideSelectedOptions={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <Card className="p-4 my-0 stat-card summary">
            <h4 className="mb-3 font-semibold">Trend</h4>
            {!switching && (
              <TrainingProgressGraph
                isDemo={isDemo}
                orgId={orgId}
                filters={filters}
                taxonomy={taxonomy}
                interval="time"
                domainFilters={localFilters}
                unitOfMeasure={unitOfMeasure}
                memberId={memberId}
              />
            )}
          </Card>
        </Card>
      </div>
    </div>
  );
}

export default OrganizationTrainingProgress;
