import React, { useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import FormatUtil from '../../utils/formatUtil';
import AdminUserSubscription from './AdminUserSubscription';
import AdminTeamInfo from './AdminTeamInfo';
import AdminFirebase from './AdminFirebase';
import AdminEmailButtons from './AdminEmailButtons';
import Agents from '../../agents/agents';
import Button from '../Button/Button';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

function AdminMemberDashboardHeading(props) {
  const { loading, error, data } = props.data;
  const [disableButtonLoading, setDisableButton] = useState(false);
  const [isRevokingSubscriptionLicense, setIsRevokingSubscriptionLicense] = useState(false);

  const accountCreated = useMemo(() => {
    if (data?.registered_at) {
      return FormatUtil.convertUtcToLocal(data.registered_at).format('M/D/YYYY');
    }
    return null;
  }, [data]);

  const primaryEmailState = useMemo(() => {
    let verified = false;
    let disabled = false;
    let isFirebaseUser = false;
    if (data && data.userEmails && data.userEmails.length) {
      data.userEmails.forEach((email) => {
        if (email.email !== data.email) {
          return;
        }
        if (email.verified) {
          verified = true;
        }
        if (email.firebaseData) {
          isFirebaseUser = true;
        }
        if (email.firebaseData && email.firebaseData.disabled) {
          disabled = true;
        }
      });
    }
    return { verified, disabled, isFirebaseUser };
  }, [data]);

  const breadCrumbs = useMemo(() => {
    const crumbs = [];
    crumbs.push({
      href: '/admin',
      label: 'Admin',
    });
    crumbs.push({
      href: '/admin/user',
      label: 'Browse User',
    });
    return crumbs;
  }, []);

  const disableText = useMemo(() => `${!primaryEmailState.disabled ? 'Disable' : 'Activate'} User`, [primaryEmailState]);

  const hasActiveSubscription = useMemo(() => data?.is_cip && data?.licenses.some((license) => license.status === 'active'), [data]);

  const name = useMemo(() => {
    if (data?.name) {
      return data.name;
    }
    if (data?.real_name) {
      return data.real_name;
    }
    if (data?.first_name) {
      return `${data.first_name} ${data.last_name}`.trim();
    }
    return 'User';
  }, [data]);

  const updateUserStatus = useCallback(async (userId, disabled) => {
    setDisableButton(true);
    try {
      await Agents.admin.disableUser(userId, { disabled: !!disabled });
      props.adminStore.getUserData(userId);
      props.commonStore.triggerToast('success', {
        content: `User has been ${disabled ? 'disabled' : 'activated'} successfully`,
      });
    } catch (e) {
      props.commonStore.triggerToast('error', {
        content: 'Something went wrong. Please try again.',
      });
    }
    setDisableButton(false);
  }, []);

  /**
   * This action 'revokes' the users active subscription license by simply setting the expiry to the current date, immediatley making it invalid,
   * and is not to be confused with actual cancellation of the subscription via Paddle.
   *
   * The objective of this operation is to give CX a way to immediatley revoke CIP access to those users that have been 'wrongly charged' for an extra billing cycle, already issued a refund and had -
   * their subscription manually cancelled by CX via Paddle.
   *
   * CX doesn't want the user to have that extra ~month of free CIP access. Once Paddle formally processess the cancellation after the grace period -
   * the status and deletion of the subscription license will be handled accordingly by our system.
   */
  const onRevokeSubscription = useCallback(async () => {
    try {
      const activeSubscriptionLicense = data?.licenses.find((license) => license.status === 'active');

      if (!activeSubscriptionLicense) {
        return;
      }

      setIsRevokingSubscriptionLicense(true);

      await Agents.admin.expireUserContentLicense(data.id, activeSubscriptionLicense.id);

      props.commonStore.triggerToast('success', {
        content: `User subscription has been successfully revoked`,
      });
    } catch (e) {
      props.commonStore.triggerToast('error', {
        content: 'Something went wrong. Please try again.',
      });
    } finally {
      setIsRevokingSubscriptionLicense(false);
    }
  }, [data]);

  if (loading) {
    return (
      <div>
        <Loading message="Loading..." />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <StyledError error={error} />
      </div>
    );
  }
  return (
    <>
      <div className="p-4 rounded border-xs border-gray-400 header-container">
        <Breadcrumbs crumbs={breadCrumbs} />
        <div className="header-content">
          <div className="flex justify-between items-center w-full">
            <h1 className="text-4xl font-bold">{data.real_name ? data.real_name : data.name}</h1>
            <div>
              {!primaryEmailState.isFirebaseUser ? (
                <AdminEmailButtons text="Password Reset" email={data.email} commonStore={props.commonStore} sendEmail={Agents.authGoogle.passwordResetEmail} />
              ) : null}
              {primaryEmailState.isFirebaseUser ? (
                <Button className="ml-3" color="gray" loading={disableButtonLoading} onClick={() => updateUserStatus(data.id, !primaryEmailState.disabled)}>
                  {disableText}
                </Button>
              ) : null}
            </div>
          </div>
          <div className="md:grid md:grid-cols-2">
            <div className="header-info">
              {data.job_title ? <p className="profile-title">{data.job_title}</p> : null}
              {data.email ? (
                <p>
                  <span className="user-detail">Email:</span> {data.email}
                </p>
              ) : null}
              <p>
                <span className="user-detail">User ID:</span> {data.id}
              </p>
              {data.member_status ? (
                <p>
                  <span className="user-detail">Status:</span>
                  {data.member_status}
                </p>
              ) : null}
            </div>
            <div className="profile-data sub-data">
              {data.last_login ? (
                <p>
                  <span className="user-detail">Last Login:</span> {moment(data.last_login).format('M/D/YYYY')}
                </p>
              ) : null}
              {data.created_at || data.registered_at ? (
                <p>
                  <span className="user-detail">Account Creation:</span>
                  {accountCreated}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {data.userEmails && data.userEmails.length ? <AdminFirebase userEmails={data.userEmails} commonStore={props.commonStore} /> : null}
      {data.teamsData && data.teamsData.length ? <AdminTeamInfo teams={data.teamsData} /> : null}
      {data.licenses && data.licenses.length ? (
        <AdminUserSubscription name={name} licenses={data.licenses} isActive={hasActiveSubscription} isLoading={isRevokingSubscriptionLicense} onRevoke={onRevokeSubscription} />
      ) : null}
    </>
  );
}
export default AdminMemberDashboardHeading;
