import React from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import LoginLinks from './LoginLinks';

function EmailPassword({ startValidation, endValidation, onSubmit, submitting, email, startOver, authOptions, sendMagicEmail, sendingMagicLink }) {
  const LoginEmailPasswordForm = {
    name: 'Login Email Password Form',
    order: ['email', 'password', 'submit'],
    fields: {
      email: {
        type: 'email',
        label: 'Email',
        validations: ['required', 'email'],
        disabled: true,
        defaultValue: email,
      },
      password: {
        type: 'password',
        label: 'Password',
        validations: ['required'],
        disabled: submitting,
      },
      submit: {
        type: 'button',
        color: 'pink',
        loading: submitting,
        label: 'Sign In',
        disabled: submitting,
        className: 'w-full grid justify-items-center',
      },
    },
  };
  return (
    <>
      <h1 className="my-8 text-2xl font-black text-center md:text-3xl lg:text-5xl">Sign In</h1>
      <DynamicForm customClassName="text-base" form={LoginEmailPasswordForm} startValidation={startValidation} endValidation={endValidation} onSubmit={onSubmit} />
      {authOptions.includes('email') && (
        <button
          disabled={sendingMagicLink}
          className="mb-4 text-black hover:text-cyb-pink-500 underline hover:underline disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={() => sendMagicEmail()}
        >
          Email me a link to login
        </button>
      )}
      <LoginLinks startOver={startOver} omitLoginLink />
    </>
  );
}

export default EmailPassword;
