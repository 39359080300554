import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import FormatUtil from '../../utils/formatUtil';

// Data is expected to be an array of objects, each object contains a member that matches the indexBy value
// as well as any other values to display

function BarChart({ data, keys, indexBy = 'id', colorScheme = 'paired', ariaLabel = 'Bar Chart', onClick = null, maxLegendLength = 20 }) {
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: 20, right: 20, bottom: 80, left: 40 }}
      padding={0.15}
      groupMode="grouped"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: colorScheme }}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      onClick={onClick}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        truncateTickAt: 0,
      }}
      enableLabel={false}
      legendLabel={(legendData) => FormatUtil.formatLongText(legendData.id, maxLegendLength)}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 60,
          itemsSpacing: 2,
          itemWidth: 138,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel={ariaLabel}
      barAriaLabel={(e) => `${e.id}: ${e.formattedValue}: ${e.indexValue}`}
    />
  );
}

export default BarChart;
