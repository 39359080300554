// Import FirebaseAuth and firebase.
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/UseAuth';
import Container from '../Container/Container';
import Email from './Email';
import EmailPassword from './EmailPassword';
import StatusMessage from './StatusMessage';
import agents from '../../agents/agents';
import Loading from '../Loading/Loading';
import { OAUTH_PROVIDERS } from '../../constants';

const handleProviderLogin = async (auth, option, setStatus, setSubmitting, setLoading, navigate) => {
  const { name } = option;

  let provider = option;
  try {
    if (!option.provider_domain) {
      const providerId = option.providerId || name;
      provider = await agents.authGoogle.getProvider({ providerId });
    }

    // Cybrary SSO Handling - If saml_handler is 'cybrary' redirect user to Cybrary provider login
    if (provider.saml_handler === 'cybrary' && navigate && provider.sso?.spConfig?.loginUrl) {
      // URL returned as absolute path, likely to API route directly. Therefore, need to do full redirect rather than react router navigate
      window.location = provider.sso.spConfig.loginUrl;
    } else {
      await auth.handleProviderLogin(provider);
    }
  } catch (e) {
    setStatus({ type: 'error', code: 'socialLogin' });
    setSubmitting(false);
    setLoading(false);
  }
};

// Define our logic for submitting email
const onSubmitEmail = async (data, auth, setData, navigate) => {
  setData.setStatus(null);
  const { email: providedEmail, rememberMe } = data;
  setData.setEmail(providedEmail);
  setData.setRemember(rememberMe);
  setData.setSubmitting(true);
  try {
    const response = await auth.getProviderForEmail(providedEmail);
    // Check if we have options to log in
    if (response?.providers && response?.providers.length) {
      const loginOptions = [];

      // Loop through providers and generate a list of login options, as well as extract saml info
      response.providers.forEach((item) => {
        loginOptions.push(item.type);
        switch (item.type) {
          case 'saml':
          case 'social':
            handleProviderLogin(auth, item, setData.setStatus, setData.setSubmitting, setData.setLoading, navigate);
            break;
          case 'okta':
            loginOptions.push('password');
            loginOptions.push('email');
            setData.setStatus({ type: 'error', code: 'okta-unmigrated' });
            setData.setSubmitting(false);
            break;
          default:
        }
      });

      // Otherwise, we should have the password provider and show that
      if (loginOptions.indexOf('password') > -1) {
        setData.setAuthOptions(loginOptions);
        setData.setWhich('email_password');
        setData.setSubmitting(false);
      }
    }
    // Check if password is expired
    if (response?.message && response?.message === 'User Password Expired') {
      setData.setStatus({ type: 'info', code: 'pwExpired' });
      setData.setSubmitting(false);
    }
  } catch (e) {
    setData.setStatus({ type: 'error', code: (e.response && e.response.status) || e.code || 'default' });
    setData.setSubmitting(false);
  }
};

// Define our logic for submitting email and password
const onSubmitEmailPassword = async (data, auth, email, remember, setStatus, setSubmitting) => {
  setStatus(null);
  setSubmitting(true);
  const { password } = data;
  try {
    // This function will either sign the user in, or throw an error
    await auth.signInWithPassword(email, password, remember);
  } catch (e) {
    setStatus({ type: 'error', code: (e.response && e.response.status) || e.code || 'default' });
    setSubmitting(false);
  }
};

const sendMagicEmail = async (auth, email, setStatus, setSendingMagicLink) => {
  setSendingMagicLink(true);
  try {
    // This function will either sign the user in, or throw an error
    await auth.sendMagicLink(email);
    setStatus({ type: 'success', code: 'magicEmail' });
    setTimeout(() => setSendingMagicLink(false), 1500); // Prevent spam clicking for 1.5secs
  } catch (e) {
    setStatus({ type: 'error', code: (e.response && e.response.status) || e.code || 'default' });
    setTimeout(() => setSendingMagicLink(false), 1500); // Prevent spam clicking for 1.5secs
  }
};

const handleVerifyEmail = async (auth, params, setStatus) => {
  if (!params || !params.oobCode) {
    setStatus({ type: 'error', code: 'verifyEmail' });
  }
  try {
    await auth.handleActioncode(params.oobCode);
    setStatus({ type: 'success', code: 'verifyEmail' });
  } catch (e) {
    setStatus({ type: 'error', code: 'verifyEmail' });
  }
};

function Login() {
  const rememberMeEmail = Cookies.get('loginRememberMeEmail');
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [sendingMagicLink, setSendingMagicLink] = useState(false);
  const [which, setWhich] = useState('email');
  const [email, setEmail] = useState(rememberMeEmail || null);
  const [remember, setRemember] = useState(false);
  const [status, setStatus] = useState(null);
  const [authOptions, setAuthOptions] = useState(null);
  const auth = useAuth();
  const location = useLocation() || window.location;
  const navigate = useNavigate();
  const queryParams = queryString.parse(location.search);

  const handleLoginParams = async () => {
    // If a provider was passed in (eg. social), handle
    if (queryParams?.provider) {
      navigate('/login');
      setLoading(true);
      setSubmitting(true);

      const option = OAUTH_PROVIDERS.find((p) => queryParams.provider.startsWith(p.name));

      if (option) {
        if (option.name === 'saml') {
          option.providerId = queryParams.provider;
        }

        await handleProviderLogin(auth, option, setStatus, setSubmitting, setLoading, navigate);
      }
    }
    if (queryParams?.mode === 'verifyEmail') {
      handleVerifyEmail(auth, queryParams, setStatus);
      navigate('/login');
    }
    if (queryParams?.login_hint) {
      onSubmitEmail({ email: queryParams.login_hint }, auth, { setSubmitting, setWhich, setStatus, setEmail, setRemember, setAuthOptions }, navigate);
      setEmail(queryParams.login_hint);
    }
    // If an error param is included, display (backend may send user here with message for SSO issues)
    if (queryParams?.error) {
      setStatus({ type: 'error', message: queryParams.error });
      delete queryParams.error;
      navigate({
        search: Object.keys(queryParams).length ? `?${queryString.stringify(queryParams)}` : '',
      });
    }
    // If an error code param is included, set for display
    if (queryParams?.errorCode) {
      setStatus({ type: 'error', code: queryParams.errorCode });
      delete queryParams.errorCode;
      navigate({
        search: Object.keys(queryParams).length ? `?${queryString.stringify(queryParams)}` : '',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    // If we're already logged in, redirect out
    if (auth.user) {
      navigate('/');
    } else {
      handleLoginParams();
    }
  }, []);

  useEffect(() => {
    if (queryParams.status) {
      setStatus({ type: queryParams.status, code: queryParams.action || 'default' });
      setSubmitting(false);
      navigate('/login');
    }
  }, [queryParams]);

  useEffect(() => {
    if (auth.ssoError) {
      setStatus({ type: 'error', message: auth.ssoError });
    }
  }, [auth.ssoError]);

  const startOver = () => {
    setStatus(null);
    setEmail(null);
    setLoading(false);
    setSubmitting(false);
    setSendingMagicLink(false);
    setRemember(false);
    setWhich('email');
  };

  if (loading) {
    return (
      <div className="pt-12">
        <Loading />
      </div>
    );
  }
  return (
    <Container className="py-4 mb-8 max-w-screen-xl">
      <div className={`mx-auto w-full ${which === 'email_password' ? 'md:w-120' : ''}`}>
        <img
          alt=""
          width="224"
          height="58"
          className="m-auto mb-8"
          src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg?w=250"
        />
        {status && (
          <div className="mx-auto md:w-120">
            <StatusMessage status={status} type={status.type} />
          </div>
        )}
        {which === 'email' && (
          <Email
            submitting={submitting}
            onSubmit={(providedEmail) => onSubmitEmail(providedEmail, auth, { setSubmitting, setWhich, setStatus, setEmail, setRemember, setAuthOptions }, navigate)}
            email={email}
            rememberMe={!!rememberMeEmail}
            setStatus={setStatus}
            handleSocialLogin={(option) => handleProviderLogin(auth, option, setStatus, setSubmitting, setLoading, navigate)}
          />
        )}
        {which === 'email_password' && (
          <EmailPassword
            submitting={submitting}
            onSubmit={(data) => onSubmitEmailPassword(data, auth, email, remember, setStatus, setSubmitting)}
            startOver={!queryParams.login_hint ? startOver : null} // don't give user option to try another email if login hint is present
            email={email}
            authOptions={authOptions}
            sendMagicEmail={() => sendMagicEmail(auth, email, setStatus, setSendingMagicLink)}
            sendingMagicLink={sendingMagicLink}
          />
        )}
      </div>
    </Container>
  );
}

export default Login;
