import React from 'react';
import { observer, inject } from 'mobx-react';
import withRouter from '../../components/Router/withRouter';
import TeamHierarchy from '../../components/Enterprise/TeamHierarchy';
import './enterprise.css';

const OrganizationGroups = inject(
  'enterpriseStore',
  'commonStore',
  'userStore',
  'authStore'
)(
  observer(
    class OrganizationGroups extends React.Component {
      state = {
        orgId: null,
      };

      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        this.props.commonStore.setPageTitle('Groups | Cybrary');
        this.props.userStore.setPreferredTeamInit('', this.props.match.params.orgId, this.init);
      }

      componentDidUpdate(prevProps) {
        this.props.userStore.setPreferredTeamInit(prevProps.match.params.orgId, this.props.match.params.orgId, this.init);
      }

      componentWillUnmount() {
        this.props.enterpriseStore.setDefaultGroupTableData();
      }

      init = (orgId) => {
        const newState = {
          ...this.state,
          orgId,
        };
        this.setState(newState);
      };

      render() {
        // Do not want to try and get hierarchy before current team is set
        if (!this.props.userStore.team || this.props.userStore.team.id * 1 !== this.props.match.params.orgId * 1) {
          return null;
        }
        return <TeamHierarchy team={this.props.userStore.team} />;
      }
    }
  )
);

export default withRouter(OrganizationGroups);
