import React, { useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import { useAuth } from '../../contexts/UseAuth';

function Reauthmodal({ open, handleButtonClick }) {
  const auth = useAuth();
  const [buttonEnabled, setButtonEnabled] = useState(false);
  // Wait 10 secs, assuming user goes to check email in this time, then enable the button
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setButtonEnabled(true);
      }, 10000);
    } else {
      setButtonEnabled(false);
    }
  }, [open]);

  const handleClick = () => {
    auth.refreshUser();
    if (handleButtonClick) {
      handleButtonClick();
    }
  };

  return (
    <Modal paddingBottom="pb-0" position="center" ariaLabelledBy="reauth-header" open={open} omitCloseX>
      <div className="p-6">
        <h2 className="mb-4 text-2xl font-black" id="reauth-header">
          Please verify your account
        </h2>
        <p className="text-sm">
          For security purposes, please verify your account before proceeding. We&apos;ve sent an email to each of your verified email addresses. Click the link in the email to
          continue
        </p>
        <p className="text-sm">When complete, click the button below to finalize this change.</p>
        <Button disabled={!buttonEnabled} onClick={handleClick}>
          Complete
        </Button>
      </div>
    </Modal>
  );
}

export default Reauthmodal;
