import React from 'react';
import Icon from '../../Icon/Icon';
import AddLink from '../../AddLink/AddLink';

function TeamsNoGoalsBanner({ view, hasGoalsInProgress }) {
  // Generic messages with slight language variations per section when no goals for that section
  let topText = `You don't have any ${view === 'in_progress' ? 'goals' : ''} ${view.replace('_', ' ')}${view !== 'in_progress' ? ' goals' : ''}${
    view !== 'expired' ? `, yet.` : '.'
  }`;
  let bottomText = <p>Reach out to your Team Admin to get started on your goals today!</p>;
  // If you have active goals, but nothing to show in expired/completed sections, show different message pointing to My Learning
  if (view !== 'in_progress' && hasGoalsInProgress) {
    topText = `You're still working on your goals!`;
    bottomText = (
      <>
        <p>Get started on your goals, today.</p>
        <AddLink className="text-cyb-pink-500 hover:text-black underline" to="/my-learning/goals">
          Start Learning
        </AddLink>
      </>
    );
  }
  return (
    <div className="flex flex-col gap-y-4 items-center py-4 px-12 my-3 max-w-[32rem] font-semibold text-center border border-gray-400 lg:px-22">
      <p className="mb-0">{topText}</p>
      <Icon name="goal" className="w-24 h-24" />
      {bottomText}
    </div>
  );
}
export default TeamsNoGoalsBanner;
