import React from 'react';
import OnboardingStep from './OnboardingStep';
import { useOnboardingContext } from '../../../providers/OnboardingProvider';
import SwitchToManager from './OnboardingStepsSwitchToManager';

/** Step shown at the start of the onboarding flow */
function CIPYearsOfExperienceStep() {
  const { setCipYearsOfExperience, setStep } = useOnboardingContext();
  const handleStep = (value) => {
    setCipYearsOfExperience(value);
    setStep('cipForm');
  };

  return (
    <OnboardingStep title="How many years experience do you have in cybersecurity?">
      <div className="flex flex-col justify-center items-center sm:flex-row">
        <OnboardingStep.Button title="0-2 Years" icon="person-shield" onClick={() => handleStep('0-2 years')} className="justify-center w-64 h-64" />
        <OnboardingStep.Button title="2+ Years" icon="working-dev" onClick={() => handleStep('2+ years')} className="justify-center w-64 h-64" />
      </div>
      <SwitchToManager />
    </OnboardingStep>
  );
}

export default CIPYearsOfExperienceStep;
