import React, { useEffect, useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import If from '../../../If/If';
import Loading from '../../../Loading/Loading';
import Icon from '../../../Icon/Icon';

function XpTaxonomyTableHeader({ name }) {
  return (
    <thead>
      <tr className="flex justify-between items-center p-4 pb-2 mb-2 w-full text-sm font-black text-black uppercase align-middle rounded-none border-b-1 border-gray-600">
        <th className="mb-0 text-sm font-bold !bg-white">{name}</th>
        <th className="mr-5 text-sm font-bold !bg-white" scope="col">
          XP
        </th>
      </tr>
    </thead>
  );
}

function XpTaxonomyTableFooter({ xpTotal }) {
  return (
    <tfoot>
      <tr className="flex justify-between items-center p-4 mt-2 mb-1 w-full text-sm font-black text-black uppercase align-middle rounded-none border-t-1 border-gray-600">
        <td className="mb-0 text-sm font-bold">TOTAL</td>
        <td className="mr-6 text-sm font-bold">{xpTotal}</td>
      </tr>
    </tfoot>
  );
}

function XpTaxonomyTableRow({ name, value, id, onClick, isActive }) {
  const layoutClasses = `${onClick ? 'group' : ''} flex flex-row justify-between items-center align-middle w-full px-4 py-3 rounded-md`;
  // When the row is active, the background color is pink and the text is white, otherwise add a hover state to the row
  const hoverStateClasses = isActive ? 'bg-cyb-pink-500 text-white' : 'hover:bg-gray-300';
  // Only when we have an onClick handler, we want to add the active and hover state classes to the row
  const hoverClasses = onClick ? hoverStateClasses : '';
  const pointerClasses = onClick ? 'cursor-pointer' : 'cursor-default';
  const classes = useMemo(() => twMerge(layoutClasses, hoverClasses, pointerClasses), [layoutClasses, hoverClasses, pointerClasses]);
  const formattedValue = useMemo(() => value?.toLocaleString(), [value]);
  const rowRef = useRef(null);

  useEffect(() => {
    // Assessibility - mount event handler to call onClick when pressing enter key
    if (onClick) {
      const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
          onClick();
        }
      };
      const ref = rowRef.current;
      ref.addEventListener('keydown', handleEnterKey);
      return () => {
        ref.removeEventListener('keydown', handleEnterKey);
      };
    }
    return null;
  }, [onClick]);

  return (
    <tr id={`xp-taxonomy-table-row-${id}`} className={classes} onClick={onClick} role="button" tabIndex={0} aria-label={name} ref={rowRef}>
      <th className={`grow pr-1 mb-0 text-sm !bg-transparent text-left ${isActive ? 'font-bold' : 'font-medium'}`} scope="row">
        {name}
      </th>
      <td className={`mr-2 mb-0 text-sm ${isActive ? 'font-bold' : 'font-medium'}`}>{formattedValue}</td>
      <td className="w-4" role="presentation">
        {onClick && <Icon name="chevron-right" className={`w-4 h-4 text-white ${!isActive ? 'group-hover:text-black' : ''}`} />}
      </td>
    </tr>
  );
}

/**
 * Table to display the XP Taxonomy on private and public profile pages
 * @param {Object} xpTaxonomy - the xp taxonomy data for the user
 * @param {boolean} isLoading - boolean indicating whether the xp taxonomy is loading
 * @returns <XpTaxonomyTable />
 */
function XpTaxonomyTable({ xpTaxonomy, isLoading }) {
  const { domains } = xpTaxonomy || {};
  const [activeDomainId, setActiveDomainId] = useState(domains?.[0]?.id);
  const activeDomain = domains?.find((domain) => domain.id === activeDomainId);
  const domainsXpTotal = domains?.reduce((total, { experience_points: xp }) => total + xp, 0);

  // Set the active domain to the first domain when the domains reload
  useEffect(() => {
    setActiveDomainId(domains?.[0]?.id);
  }, [domains]);

  return (
    <div className="flex p-2 w-full rounded-md border-1 border-gray-600">
      {/** Loading State */}
      <If condition={isLoading}>
        <Loading wrapperClassName="w-full my-6" className="w-16 h-16 border-2" message="Loading XP Taxonomy" />
      </If>
      <If condition={!isLoading}>
        {/** Domains Table */}
        <div id="xp-taxonomy-domains-table" className="flex flex-col px-4 w-full">
          <table>
            <XpTaxonomyTableHeader name="DOMAIN" />
            <If condition={!!domains?.length}>
              <tbody>
                {domains?.map(({ name, experience_points: xp, id }) => {
                  return <XpTaxonomyTableRow key={id} name={name} value={xp} id={id} onClick={() => setActiveDomainId(id)} isActive={id === activeDomainId} />;
                })}
              </tbody>
              <XpTaxonomyTableFooter xpTotal={domainsXpTotal} />
            </If>
          </table>
          {/** Empty Domains State */}
          <If condition={!domains?.length}>
            <p className="pt-2 text-sm font-medium text-center">No domains found for this user.</p>
          </If>
        </div>
        {/** Topics Table */}
        <div id="xp-taxonomy-topics-table" className="flex flex-col px-4 w-full">
          <table>
            <XpTaxonomyTableHeader name="TOPIC" />
            <If condition={activeDomain?.topics?.length}>
              <tbody>
                {activeDomain?.topics?.map(({ id, name, experience_points: xp }) => {
                  return <XpTaxonomyTableRow key={id} name={name} value={xp} id={id} />;
                })}
              </tbody>
              <XpTaxonomyTableFooter xpTotal={activeDomain?.experience_points || 0} key={activeDomainId} />
            </If>
          </table>
          {/** Empty Topics State */}
          <If condition={!activeDomain?.topics?.length}>
            <p className="pt-2 text-sm font-medium text-center">No topics found for {activeDomain?.name || 'this domain'}.</p>
          </If>
        </div>
      </If>
    </div>
  );
}

export default XpTaxonomyTable;
