import React from 'react';
import OnboardingStep from './OnboardingStep';
import { useOnboardingContext } from '../../../providers/OnboardingProvider';
import SwitchToManager from './OnboardingStepsSwitchToManager';

/** Step shown at the start of the onboarding flow */
function CIPGoalsStep() {
  const { setCipGoal, setStep } = useOnboardingContext();
  const handleStep = (value, nextStep) => {
    setCipGoal(value);
    setStep(nextStep);
  };

  return (
    <OnboardingStep title="What are you looking to accomplish?">
      <div className="flex flex-col justify-center items-center sm:flex-row">
        <OnboardingStep.Button title="Launch My Career" icon="rocket-2" onClick={() => handleStep('Launch My Career', 'cipExperience')} className="justify-center w-64 h-64" />
        <OnboardingStep.Button
          title="Get a Certification"
          icon="ribbon-2"
          onClick={() => handleStep('Get a Certification', 'cipYearsOfExperience')}
          className="justify-center w-64 h-64"
        />
        <OnboardingStep.Button title="Upskill & Practice" icon="growth" onClick={() => handleStep('Upskill & Practice', 'cipForm')} className="justify-center w-64 h-64" />
      </div>
      <SwitchToManager />
    </OnboardingStep>
  );
}

export default CIPGoalsStep;
