import React from 'react';
import Table from '../../Table/Table';
import Icon from '../../Icon/Icon';
import '../analytics.css';

function getTrophyMeta(position) {
  switch (position) {
    case 1:
      return { trophyAriaLabel: 'Gold trophy', trophyIconClassName: 'text-yellow-400' };
    case 2:
      return { trophyAriaLabel: 'Silver trophy', trophyIconClassName: 'text-gray-500' };
    case 3:
      return { trophyAriaLabel: 'Bronze trophy', trophyIconClassName: 'text-yellow-600' };
    default:
      return { trophyAriaLabel: 'Trophy', trophyIconClassName: '' };
  }
}
function getFormattedCol(formattedCols, idx, col, row, data, name) {
  for (let i = 0; i < formattedCols.length; i++) {
    if (formattedCols[i].method && formattedCols[i].colIdx && formattedCols[i].colIdx === idx) {
      return formattedCols[i].method(col, row, data.columns, name);
    }
  }
  return <span className="col-value">{col.value}</span>;
}

function getPlacements(data, filteredCol) {
  let placement = 0;
  let placementBar = -1;
  const placements = [];
  let barPercent = '100%';
  let topVal = null;
  data.forEach((row, idx) => {
    if (idx === 0) {
      topVal = row[filteredCol]?.value;
    }
    row.forEach((col, idx2) => {
      if (filteredCol === idx2) {
        const roundVal = Math.round(col.value);
        if (roundVal < placementBar || placementBar === -1) {
          if (placementBar !== -1) {
            barPercent = `${(roundVal / topVal) * 100}%`;
          }
          placement++;
          placementBar = roundVal;
        }
        placements.push({ position: placement, barPercent });
      }
    });
  });
  return placements;
}

function LeaderboardTableCell({ idx, idx2, filteredColIdx, setPlacementRef, placements, formatColumns, col, row, data, name }) {
  return (
    // eslint-disable-next-line react/no-array-index-key
    <Table.Cell className={idx2 === filteredColIdx ? 'highlight' : null} key={idx2}>
      {idx2 === filteredColIdx ? <span className="hidden highlight-bar" ref={setPlacementRef} style={{ width: placements[idx].barPercent }} /> : null}{' '}
      {formatColumns ? getFormattedCol(formatColumns, idx2, col, row, data, name) : <span className="col-value">{col.value}</span>}
    </Table.Cell>
  );
}

function LeaderboardTableBody({ ignoredCols, filteredColIdx, tableData, formatColumns, setPlacementRef, data, name }) {
  const placements = getPlacements(tableData, filteredColIdx);
  return (
    <Table.Body>
      {tableData.map((row, idx) => {
        const placementsPos = placements[idx]?.position;
        const { trophyAriaLabel, trophyIconClassName } = getTrophyMeta(placementsPos);
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Table.Row className="print:text-sm" key={idx}>
            <Table.Cell className="print:hidden placement-col">{placementsPos}</Table.Cell>
            <Table.Cell className="print:hidden trophy-col">
              {placementsPos <= 3 ? <Icon className={`w-7 h-7 ${trophyIconClassName}`} accessibilityProps={{ 'aria-label': trophyAriaLabel }} name="trophy" role="img" /> : null}
            </Table.Cell>
            {row.map((col, idx2) => {
              const uniqueKey = `${idx}-${idx2}`;
              return !ignoredCols.includes(idx2) ? (
                <LeaderboardTableCell
                  key={uniqueKey}
                  idx={idx}
                  idx2={idx2}
                  filteredColIdx={filteredColIdx}
                  setPlacementRef={setPlacementRef}
                  placements={placements}
                  formatColumns={formatColumns}
                  col={col}
                  row={row}
                  data={data}
                  name={name}
                />
              ) : null;
            })}
          </Table.Row>
        );
      })}
    </Table.Body>
  );
}
export default LeaderboardTableBody;
