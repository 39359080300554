import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { observer, inject } from 'mobx-react';
import { Fade } from 'react-awesome-reveal';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icon/Icon';
import Carousel from '../Carousel/Carousel';
import AddLink from '../AddLink/AddLink';
import FormatUtil from '../../utils/formatUtil';
import { useCustomPathsContext } from '../../providers/CustomPathsProvider';
import Loading from '../Loading/Loading';

const CustomPathsWidget = inject('userStore')(
  observer(({ userStore, className }) => {
    const containerClasses = useMemo(() => twMerge('mx-auto min-h-[152px]', className), [className]);
    const { isEnterprise } = userStore;
    const { pathsData, isLoading } = useCustomPathsContext();

    /** This section should be available for all and ONLY enterprise users (even if unlicensed) */
    if (!isEnterprise) return null;

    /** we only want to display the first 10 items... */
    const trimmedPathsData = pathsData.slice(0, 10);
    /** These are the items to be displayed inside the Carousel */
    const CarouselItems = trimmedPathsData?.map((customPath) => {
      const { id, name, team_id } = customPath;
      /** if more then 35 characters truncate our text */
      const formattedName = FormatUtil.formatLongText(name, 35);
      const nameClasses = 'text-lg font-bold';

      return (
        <Tooltip key={id} content={name} position="bottom">
          <AddLink to={`/custom-paths/${team_id}/${id}`} aria-labelledby={`${id}-name`}>
            <div className="flex flex-row gap-8 items-center p-4 w-[462px] h-full bg-white rounded-lg border-1 border-black md:w-[462px]">
              <Icon name="path" className="w-8 h-8" />
              <p className={nameClasses}>{formattedName}</p>
            </div>
            <p id={`${id}-name`} className="sr-only">
              {name}
            </p>
          </AddLink>
        </Tooltip>
      );
    });

    return (
      <div className={containerClasses}>
        {isLoading ? (
          <Loading message="Loading Custom Paths..." />
        ) : (
          <Fade triggerOnce>
            <Carousel
              id="custom-paths-carousel"
              title="Custom Paths"
              noItemsText="No Custom Paths to display"
              items={CarouselItems}
              actions={
                <AddLink className="inline-block text-sm text-cyb-pink-500 hover:text-black underline whitespace-nowrap cursor-pointer" to="/custom-paths/all">
                  View all
                </AddLink>
              }
            />
          </Fade>
        )}
      </div>
    );
  })
);
export default CustomPathsWidget;
