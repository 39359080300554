import React from 'react';
import BrowseUtil from '../../utils/browseUtil';
import Divider from '../Divider/Divider';
import TilesContainer from './Tiles';

function CertificationsAndVendors({ view, filtersCount, addFilter, userStore }) {
  if (view !== 'all' || filtersCount !== 0) {
    return null;
  }
  const certBodies = BrowseUtil.getCertifications();
  // sort object by certification body name
  certBodies.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
  const certBodiesShortDescription = BrowseUtil.getSectionDescription('Certification Bodies').short;
  const vendors = BrowseUtil.getVendors();
  const learningPartnersShortDescription = BrowseUtil.getSectionDescription('Learning Partners').short;
  return (
    <>
      <div className="px-[31px]">
        <div className="box-content my-8 w-full max-w-[1058px]">
          <h2 className={`flex items-center text-2xl font-black ${certBodiesShortDescription ? '' : 'mb-4'}`}>
            <div className="flex-1">Certification Bodies</div>
          </h2>
          {certBodiesShortDescription ? <div className="mt-2 mb-4 text-sm font-normal text-gray-600">{certBodiesShortDescription}</div> : null}
          <TilesContainer tiles={certBodies} userStore={userStore} addFilter={addFilter} />
        </div>
        <Divider marginTop="mt-8" marginBottom="mb-8" />
      </div>
      <div className="px-[31px]">
        <div className="box-content my-8 w-full max-w-[1058px]">
          <h2 className={`flex items-center text-2xl font-black ${learningPartnersShortDescription ? '' : 'mb-4'}`}>
            <div className="flex-1">Learning Partners</div>
          </h2>
          {learningPartnersShortDescription ? <div className="mt-2 mb-4 text-sm font-normal text-gray-600">{learningPartnersShortDescription}</div> : null}
          <TilesContainer tiles={vendors} userStore={userStore} addFilter={addFilter} />
        </div>
      </div>
    </>
  );
}

export default CertificationsAndVendors;
