import React from 'react';
import DropdownFilter from '../Dropdown/DropdownFilter';

function GoalsFilters({ className, options, values, onFilterChange, disabled }) {
  return (
    <div className={`${className}`}>
      <div className="flex items-center">
        <div className="mr-4">
          <p>Filters:</p>
        </div>
        <div className="w-1/3">
          <DropdownFilter
            ariaLabel="Filter by owner"
            name="filter-goals"
            placeholder="Filter by Owner"
            options={options}
            value={values}
            onChange={({ value }) => onFilterChange('ownerIds', value)}
            isMulti
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}
export default GoalsFilters;
