import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import queryString from 'query-string';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import Header from '../../components/Header/Header';
import Segment from '../../components/Segment/Segment';
import withRouter from '../../components/Router/withRouter';
import Loading from '../../components/Loading/Loading';
import Agents from '../../agents/agents';
import Container from '../../components/Container/Container';
import './admin.css';

// Quick component for a content segment
function AdminContentSegment(props) {
  const { href, external, title, icon, buttonText, navigate, onClick } = props;

  const buttonClick =
    onClick ||
    (() => {
      if (external) {
        window.open(href, '_blank');
      } else {
        navigate(href);
      }
    });

  const buttonIcon = external ? 'external-link' : 'arrow-right';

  return (
    <Segment className="flex flex-col justify-center items-center first:my-4 last:my-4 h-72 bg-gray-200" onClick={buttonClick}>
      <div className="mb-0 text-[5rem] text-gray-600">
        <Icon name={icon} className="mx-auto mb-4 w-16 h-16 stroke-2" />
      </div>
      <Header as="h2">{title}</Header>
      {buttonText ? (
        <Button onClick={buttonClick} className="flex gap-x-4 justify-center items-center w-full">
          {buttonText}
          <Icon name={buttonIcon} className="w-4 h-4" />
        </Button>
      ) : null}
    </Segment>
  );
}

const Admin = inject(
  'commonStore',
  'adminStore',
  'authStore'
)(
  observer(
    class Admin extends Component {
      state = {
        queryParams: {},
      };

      componentDidMount() {
        // Confirm that we are allowed on the admin page
        this.props.adminStore.checkAccess(this.props.authStore);

        this.props.commonStore.hidePrimaryNav();
        const queryParams = queryString.parse(window.location.search);
        this.setState({
          queryParams: { ...queryParams },
        });
      }

      componentWillUnmount() {
        this.props.commonStore.showPrimaryNav();
      }

      render() {
        const { loading, denyAccess } = this.props.adminStore;
        // If we should deny access, just get out now
        if (denyAccess) {
          return <Navigate to="/" />;
        }
        if (loading) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        return (
          <Container fluid className="admin">
            <Segment className="border-none">
              <div className="lg:grid lg:grid-cols-4 lg:gap-x-8">
                <AdminContentSegment title="Teams" icon="user-group" href="/admin/teams" buttonText="Browse Teams" navigate={this.props.navigate} />
                <AdminContentSegment title="Vendor Results" icon="flask" href="/admin/vendor-results" buttonText="View Results" navigate={this.props.navigate} />
                <AdminContentSegment title="Algolia" icon="algolia" external href="https://algolia.com" buttonText="Launch Algolia" navigate={this.props.navigate} />
                <AdminContentSegment title="Contentful" icon="book" external href="https://contentful.com" buttonText="Launch CMS" navigate={this.props.navigate} />
                {this.props.authStore.hasAdminUserLookupAccess() && (
                  <AdminContentSegment title="User Lookup" icon="user" href="/admin/user" buttonText="Browse Users" navigate={this.props.navigate} />
                )}
                {this.state.queryParams.downloadCourseCatalog && (
                  <AdminContentSegment
                    title="Course Catalog"
                    icon="download"
                    buttonText="Download"
                    navigate={this.props.navigate}
                    onClick={(e) => {
                      e.stopPropagation();
                      Agents.admin.downloadCourseCatalog().then(() => {
                        this.props.commonStore.triggerToast('success', { content: 'Your Course Catalog download link will be available shortly in the Notifications Section' });
                      });
                    }}
                  />
                )}
                <AdminContentSegment title="Challenge Courses" icon="list" href="/admin/challenge/courses" buttonText="Browse" navigate={this.props.navigate} />
              </div>
            </Segment>
          </Container>
        );
      }
    }
  )
);

export default withRouter(Admin);
