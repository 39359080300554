import React, { useState, useEffect, useMemo } from 'react';
import { throttle } from 'lodash';
import Bugsnag from '@bugsnag/js';
import { useLocation } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import Agents from '../../agents/agents';
import MobilePrimaryNavigation from './Mobile/MobilePrimaryNavigation';
import FormatUtil from '../../utils/formatUtil';
import { firePendoTrackEvent } from '../../utils/pendoUtil';
import GaUtil from '../../utils/gaUtil';
import PreviousNextButtons from './PreviousNextButtons';
import ImmersiveBody from './ImmersiveBody';
import ThumbFeedbackButtons from '../Feedback/ThumbFeedbackButtons';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import Challenge from './Challenge';
import SidebarTabsMenu from './SidebarTabsMenu';
import ClabExitModal from './Content/ClabExitModal';
import DesktopPrimaryNavigation from './DesktopPrimaryNavigation';
import { useCybAssessment } from '../../providers/CybAssessmentProvider';
import QuestionsSidebar from './Content/Assessment/QuestionsSidebar';
import InstructionsRenderer from './Content/InstructionsRenderer';
import { useClab } from '../../providers/ClabProvider';
import ClabMachinesTab from './Content/ClabMachinesTab';
import ChallengeAssessmentStart from './Content/Assessment/ChallengeAssessmentStart';
import { useCybAssessmentAnalytics } from '../../providers/CybAssessmentAnalyticsProvider';
import Expand from '../../Icons/Expand';
import DoubleSidebar from './DoubleSidebar';
import { useImmersiveTasks } from '../../providers/ImmersiveTasksProvider';
import { useImmersive } from '../../providers/ImmersiveProvider';
import { checkIfActivityIsLastInItem } from '../../utils/immersiveUtil';
import { USERPILOT_EVENTS } from '../../constants';

/** Left Sidebar */
function PrimarySidebar({ isOpen, children, enrollmentTitle, activityTitle, hasDoubleSidebar, setIsDoubleSidebar }) {
  const title = enrollmentTitle || activityTitle;
  return (
    <div aria-hidden={!isOpen} aria-label={`${title} resources`} className="hidden overflow-hidden relative w-[500px] bg-white border-r border-gray-400 lg:block">
      <div id="scroll-container" className="overflow-auto relative top-0 w-[500px] lg:pb-0 second-container immersive-sidebar">
        {children}
      </div>
      {!!hasDoubleSidebar && (
        <button onClick={() => setIsDoubleSidebar(true)} className="absolute top-5 right-2 z-20 text-gray-600">
          <Expand classes="h-6 w-6" />
        </button>
      )}
    </div>
  );
}

// Helper to return the assessment report url
function getAssessmentReportUrl(assessmentCourseContentDescriptionId, getTrackIdByAssessmentId) {
  const trackId = getTrackIdByAssessmentId(assessmentCourseContentDescriptionId);
  return `/my-learning/assessments/results/${trackId}/${assessmentCourseContentDescriptionId}?fromImmersive=1`;
}

function handleReset(setIsSuccessBannerOpen, setIsParentFeedbackOpen, setIsShareOpen, setIsPrimarySidebarOpen) {
  setIsSuccessBannerOpen(false);
  setIsParentFeedbackOpen(false);
  setIsShareOpen(false);
  setIsPrimarySidebarOpen(false);
}

// Return the appropriate url for the next activity, defaulting to my-learning if there is none.
function getNextActivityUrl(nextActivity) {
  return nextActivity?.url || '/?finishedActivities=1';
}

//  Handle our attribution events
function fireTrackingEvents(activity, item, fireAttributionEvent /* , abTestCondition */) {
  const { host } = window.location;
  // Don't run this on localhost or if we have no activity
  if (!activity || host.indexOf('localhost') === 0) {
    return null;
  }
  const { slug: activityPermalink, type: activityType, id: activityId } = activity;
  const urlPieces = ['/immersive'];
  if (item) {
    const { content_description: contentDescription } = item;
    const { permalink: itemPermalink } = contentDescription;
    urlPieces.push(itemPermalink);
  }
  const formatActivityType = FormatUtil.lowerCaseHyphenText(activityType);
  urlPieces.push(formatActivityType, activityPermalink);
  // remove searchQuery for a/b testing --> const searchQuery = window.location.search ? `${window.location.search}&${abTestCondition}` : `?${abTestCondition}`;
  const searchQuery = window.location.search ? `${window.location.search}` : ``;
  const url = `${urlPieces.join('/')}${searchQuery}`; // removed a/b testing --> const url = `${urlPieces.join('/')}${searchQuery}`;
  GaUtil.fireGaPageView(url);
  firePendoTrackEvent('Launch Activity', { permalink: url });
  return fireAttributionEvent('pageview', {}, `https://${host}/immersive/launch/activity/${activityId}${searchQuery}`);
}

function getDesktopTabMenu(menu, hasIncompleteItems) {
  return hasIncompleteItems
    ? [
        {
          id: 'incompleteItems',
          label: 'Incomplete Items',
          type: 'outline',
        },
      ]
    : menu.filter((menuItem) => menuItem.id === 'resources' || menuItem.id === 'overview' || menuItem.id === 'outline');
}

function handleSkipToNextLesson() {
  return new Promise((resolve) => {
    // Inform the challenge question that we just want to skip to the next lesson (not showing success banner)
    resolve({
      skip: true,
    });
  });
}

function MobileTasks({
  activity,
  next,
  gotoStart,
  gotoNextActivity,
  gotoCompletePage,
  completeActivity,
  showSuccessBar,
  isLastIncomplete,
  completedActivityId,
  completeAssessmentActivity,
}) {
  const { questions, complete: isComplete, isCybAssessment } = activity;
  if (isCybAssessment) {
    return (
      <div className="mt-0 lg:mt-16">
        <QuestionsSidebar completeActivity={completeAssessmentActivity} />
      </div>
    );
  }
  return (
    <div className="mt-16">
      <Challenge
        activity={activity}
        questions={questions}
        isComplete={isComplete}
        isLast={!(next && next.url)}
        gotoStart={gotoStart}
        gotoNextActivity={gotoNextActivity}
        gotoCompletePage={gotoCompletePage}
        completeActivity={completeActivity}
        showSuccessBar={showSuccessBar}
        isLastIncomplete={isLastIncomplete}
        completedActivityId={completedActivityId}
      />
    </div>
  );
}

// Immersive Primary navigation
function PrimaryNavigation({
  userStore,
  commonStore,
  activity,
  outline,
  title,
  type,
  contentDescriptionId,
  progress,
  averageRating,
  ratingCount,
  permalink,
  permalinkPrefix,
  canAccessActivity,
  toggleParentFeedbackDisplay,
  toggleShareDisplay,
  isShareOpen,
  isParentFeedbackOpen,
  togglePrimarySidebarDisplay,
  desktopTabMenu,
  onCompletePage,
  hasIncompleteItems,
  previous,
  next,
  completedActivityId,
  navigate,
  isLast,
  isItemLastActivity,
  gotoCompletePage,
  emptyChallenge,
  completeActivity,
  activeInterstitial,
  setActiveInterstitial,
  isPrimarySidebarOpen,
  gotoStart,
  gotoNextActivity,
  toggleSuccessBannerDisplay,
  isLastIncomplete,
  setIsPrimarySidebarOpen,
  isMobile,
  setDisableSidebar,
  disableSidebar,
  runningClab,
  toggleClabModal,
  disableNav,
  completeAssessmentActivity,
  discourseSlug,
}) {
  return (
    <div className="flex items-center py-2 h-16 bg-gray-200">
      {!!isMobile && (
        <MobilePrimaryNavigation
          userStore={userStore}
          activity={activity}
          outline={outline}
          title={title}
          progress={progress}
          type={type}
          permalink={permalink}
          permalinkPrefix={permalinkPrefix}
          canAccessActivity={canAccessActivity}
          desktopTabMenu={desktopTabMenu}
          enableHamburgerMenu={hasIncompleteItems || !onCompletePage}
          hasIncompleteItems={hasIncompleteItems}
          disableNav={disableNav}
          discourseSlug={discourseSlug}
          tasks={
            <MobileTasks
              activity={activity}
              next={next}
              gotoStart={gotoStart}
              gotoNextActivity={gotoNextActivity}
              gotoCompletePage={gotoCompletePage}
              completeActivity={completeActivity}
              showSuccessBar={toggleSuccessBannerDisplay}
              isLastIncomplete={isLastIncomplete}
              completedActivityId={completedActivityId}
              completeAssessmentActivity={completeAssessmentActivity}
            />
          }
        />
      )}
      {!isMobile && (
        <DesktopPrimaryNavigation
          userStore={userStore}
          commonStore={commonStore}
          title={title}
          type={type}
          contentDescriptionId={contentDescriptionId}
          permalink={permalink}
          permalinkPrefix={permalinkPrefix}
          progress={progress}
          averageRating={averageRating}
          ratingCount={ratingCount}
          toggleParentFeedbackDisplay={toggleParentFeedbackDisplay}
          toggleShareDisplay={toggleShareDisplay}
          isShareOpen={isShareOpen}
          isParentFeedbackOpen={isParentFeedbackOpen}
          togglePrimarySidebarDisplay={togglePrimarySidebarDisplay}
          isPrimarySidebarOpen={isPrimarySidebarOpen}
          enableHamburgerMenu={hasIncompleteItems || !onCompletePage}
          activity={activity}
          previous={previous}
          next={next}
          navigate={navigate}
          outline={outline}
          hasIncompleteItems={hasIncompleteItems}
          canAccessActivity={canAccessActivity}
          completedActivityId={completedActivityId}
          desktopTabMenu={desktopTabMenu}
          isLast={isLast}
          isItemLastActivity={isItemLastActivity}
          gotoCompletePage={gotoCompletePage}
          emptyChallenge={emptyChallenge}
          completeActivity={completeActivity}
          activeInterstitial={activeInterstitial}
          setActiveInterstitial={setActiveInterstitial}
          disableNext={activeInterstitial === 'upgrade'}
          setIsPrimarySidebarOpen={setIsPrimarySidebarOpen}
          isMobile={isMobile}
          disableSidebar={disableSidebar}
          setDisableSidebar={setDisableSidebar}
          runningClab={runningClab}
          toggleClabModal={toggleClabModal}
          disableNav={disableNav}
          onCompletePage={onCompletePage}
          discourseSlug={discourseSlug}
        />
      )}
    </div>
  );
}

function ActivityTitle({ activity, title, className = 'ml-5', activeInterstitial }) {
  const { type: activityType, duration_seconds: duration } = activity;
  return (
    <div className={`flex-1 ${className}`}>
      {!activeInterstitial && (
        <h2 className="px-4 pt-2 mb-1 text-2xs font-semibold text-gray-600 uppercase">
          {activityType} <span className="lowercase">{duration ? `| ${FormatUtil.formatTime(duration, 'hma')}` : ''}</span>
        </h2>
      )}
      <h3 className="px-4 mb-1 text-lg font-bold">{title}</h3>
    </div>
  );
}

// Immersive Secondary navigation
function SecondaryNavigation({
  isMobile,
  activity,
  title,
  previous,
  next,
  completedActivityId,
  navigate,
  isLast,
  isItemLastActivity,
  gotoCompletePage,
  isPrimarySidebarOpen,
  emptyChallenge,
  completeActivity,
  activeInterstitial,
  setActiveInterstitial,
  setIsPrimarySidebarOpen,
}) {
  return (
    <div
      className={`h-16 w-auto bg-white hidden lg:flex items-center border-b-xs border-gray-400 transition-all duration-500 ease-in-out ${isPrimarySidebarOpen ? 'ml-96' : 'ml-0'}`}
    >
      <ActivityTitle activity={activity} title={title} activeInterstitial={activeInterstitial} />
      <div className="flex items-center mr-8">
        {!isMobile && (
          <div className="flex py-1 pr-3 mr-3 border-r-xs border-gray-400">
            <ThumbFeedbackButtons activity={activity} toolTipTheme="dark" />
          </div>
        )}
        <PreviousNextButtons
          activity={activity}
          previous={previous}
          next={next}
          navigate={navigate}
          completedActivityId={completedActivityId}
          isLast={isLast}
          isItemLastActivity={isItemLastActivity}
          gotoCompletePage={gotoCompletePage}
          emptyChallenge={emptyChallenge}
          completeActivity={completeActivity}
          activeInterstitial={activeInterstitial}
          setActiveInterstitial={setActiveInterstitial}
          disableNext={activeInterstitial === 'upgrade'}
          setIsPrimarySidebarOpen={setIsPrimarySidebarOpen}
          isMobile={isMobile}
        />
      </div>
    </div>
  );
}

function PrimarySidebarWrapper({
  isPrimarySidebarOpen,
  enrollmentTitle,
  togglePrimarySidebarDisplay,
  activityTitle,
  activity,
  next,
  gotoStart,
  gotoNextActivity,
  gotoCompletePage,
  completeActivity,
  isLastIncomplete,
  toggleSuccessBannerDisplay,
  userStore,
  completedActivityId,
  completeAssessmentActivity,
  clabResourcesCount,
  isDoubleSidebar,
  hasDoubleSidebar,
  setIsDoubleSidebar,
  setHasDoubleSidebar,
}) {
  const [tabs, setTabs] = useState(null);
  const [defaultTabIndex, setDefaultTabIndex] = useState(0);

  const { questions, complete: isComplete, isClab, activityContent, isCybAssessment, hasCybAssessment, cybAssessmentId } = activity;
  const { assessment, launched } = useCybAssessment();

  // If this is a cyb assessment, or we have one in the sidebar, use the questions count for the tasks bubble from the assessment
  const questionsCount = isCybAssessment || hasCybAssessment ? assessment?.questions?.length : questions?.length;

  // We have to rebuild the tabs whenever any of our dependencies change
  useEffect(() => {
    const newTabs = [];

    if (isClab) {
      newTabs.push({
        id: 'instructions',
        label: 'Instructions',
        type: 'component',
        component: (
          <div className="p-6">
            <InstructionsRenderer activity={activity} content={activityContent} userStore={userStore} />
            {hasCybAssessment && <ChallengeAssessmentStart id={cybAssessmentId} />}
          </div>
        ),
      });
    }
    if (!hasCybAssessment || launched) {
      // If we are a regular challenge, or we are a cyb assessment that has launched, display tasks
      newTabs.push({
        id: 'tasks',
        label: 'Tasks',
        type: 'component',
        bubble: questionsCount,
        component: (
          <div className="mt-3">
            <Challenge
              activity={activity}
              questions={questions}
              isComplete={isComplete}
              isLast={!(next && next.url)}
              gotoStart={gotoStart}
              gotoNextActivity={gotoNextActivity}
              gotoCompletePage={gotoCompletePage}
              completeActivity={hasCybAssessment ? completeAssessmentActivity : completeActivity}
              showSuccessBar={toggleSuccessBannerDisplay}
              isLastIncomplete={isLastIncomplete}
              completedActivityId={completedActivityId}
              isDoubleSidebar={isDoubleSidebar}
            />
          </div>
        ),
      });
    }
    if (isClab && clabResourcesCount) {
      // The Machines tab
      newTabs.push({
        id: 'machines',
        label: 'Machines',
        type: 'component',
        bubble: clabResourcesCount,
        component: <ClabMachinesTab />,
      });
    }

    setTabs(newTabs);
  }, [completedActivityId, clabResourcesCount, questionsCount, hasCybAssessment, launched, cybAssessmentId, isDoubleSidebar]);

  useEffect(() => {
    // If we should display the icon for expand/collapse of the double sidebar, update the value
    const isClabOrSkillAssessment = isClab || hasCybAssessment;
    const newHasDoubleSidebar = isClabOrSkillAssessment && tabs?.length > 2;
    setHasDoubleSidebar(newHasDoubleSidebar);
  }, [tabs, hasCybAssessment, isClab]);

  useEffect(() => {
    // If this is a sidebar with a cyb assessment, and we have just launched, set the default tab index to be 1 (the Tasks tab), otherwise reset to 0
    setDefaultTabIndex(hasCybAssessment && launched ? 1 : 0);
  }, [hasCybAssessment, launched]);

  // allow Primary Sidebar to be closed on press of esc key (accessibility reasons)
  const closePrimarySidebar = (e) => {
    if (e?.key === 'Escape' && isPrimarySidebarOpen) {
      togglePrimarySidebarDisplay();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', closePrimarySidebar);
    // add eventListener on Open, else remove it
    return () => {
      window.removeEventListener('keydown', closePrimarySidebar);
    };
  }, [isPrimarySidebarOpen]);

  if (!tabs?.length) {
    return null;
  }

  if (hasDoubleSidebar && isDoubleSidebar) {
    return (
      <DoubleSidebar
        isOpen={isPrimarySidebarOpen}
        enrollmentTitle={enrollmentTitle}
        activityTitle={activityTitle}
        isDoubleSidebar={isDoubleSidebar}
        setIsDoubleSidebar={setIsDoubleSidebar}
        tabs={tabs}
      />
    );
  }

  return (
    <PrimarySidebar
      isOpen={isPrimarySidebarOpen}
      enrollmentTitle={enrollmentTitle}
      activityTitle={activityTitle}
      isDoubleSidebar={isDoubleSidebar}
      hasDoubleSidebar={hasDoubleSidebar}
      setIsDoubleSidebar={setIsDoubleSidebar}
    >
      {!isCybAssessment && <SidebarTabsMenu tabs={tabs} defaultTabIndex={defaultTabIndex} />}
      {!!isCybAssessment && <QuestionsSidebar completeActivity={completeAssessmentActivity} />}
    </PrimarySidebar>
  );
}

/**
 * The actual immersive view
 */
function ImmersiveRenderer({ commonStore, userStore, authStore, navigate, enrollment, activity, item, outline, menu, onCompletePage = false }) {
  const [isParentFeedbackOpen, setIsParentFeedbackOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isPrimarySidebarOpen, setIsPrimarySidebarOpen] = useState(false);
  const [isDoubleSidebar, setIsDoubleSidebar] = useState(false);
  const [hasDoubleSidebar, setHasDoubleSidebar] = useState(false);
  const [disableSidebar, setDisableSidebar] = useState(false);
  const [isSuccessBannerOpen, setIsSuccessBannerOpen] = useState(false);
  const [completedActivityId, setCompletedActivityId] = useState();
  const [isMobile, setMobile] = useState(window && window.innerWidth < 1024);
  const [activeInterstitial, setActiveInterstitial] = useState(null);
  const [showClabModal, setShowClabModal] = useState(false);
  const [clabResourcesCount, setClabResourcesCount] = useState(0);
  const location = useLocation();

  const { createUrl, findFirstIncompleteActivityId, addCompleted, completedIds, getDirectParent, isActivityLastIncomplete, findModuleId } = useImmersive();
  const { launched: runningClab, resources, lab } = useClab();
  const { reset: resetImmersiveTasks } = useImmersiveTasks();
  const { actions: assessmentActions } = useCybAssessmentAnalytics();
  const { getTrackIdByAssessmentId } = assessmentActions;

  useEffect(() => {
    setClabResourcesCount(resources?.length);
  }, [resources, lab]);

  // Close out any open sidebars/banners
  const reset = () => {
    // Reset immersive task state
    resetImmersiveTasks();
    handleReset(setIsSuccessBannerOpen, setIsParentFeedbackOpen, setIsShareOpen, setIsPrimarySidebarOpen);
  };

  useEffect(() => {
    commonStore.setIsInImmersive(true);
    // Perform a FULL reset when we leave immersive
    return () => handleReset(setIsSuccessBannerOpen, setIsParentFeedbackOpen, setIsShareOpen, setIsPrimarySidebarOpen);
  }, []);

  const gotoCompletePage = () => {
    setIsPrimarySidebarOpen(false);
    setDisableSidebar(true);
    // Append /complete to the current path
    const completeUrl = `${location.pathname}${location.pathname.endsWith('/') ? '' : '/'}complete`;
    return navigate(completeUrl);
  };

  // Return true if the current activity is the last activity in the current item, false otherwise.
  const isItemLastActivity = () => {
    return checkIfActivityIsLastInItem(item, activity);
  };

  const toggleParentFeedbackDisplay = () => {
    setIsParentFeedbackOpen(!isParentFeedbackOpen);
    setIsShareOpen(false);
  };

  const toggleShareDisplay = () => {
    setIsShareOpen(!isShareOpen);
    setIsParentFeedbackOpen(false);
  };

  const togglePrimarySidebarDisplay = () => {
    setIsPrimarySidebarOpen(!isPrimarySidebarOpen);
  };

  const toggleClabModal = () => {
    setShowClabModal(!showClabModal);
  };

  const gotoFirstIncomplete = () => {
    reset();
    setIsPrimarySidebarOpen(!isMobile); // reopen sidebar on desktop after leaving incomplete page
    setDisableSidebar(false);
    navigate(createUrl(findFirstIncompleteActivityId(), null, true));
    return null;
  };

  /**
   * Checks an activity ID against the immersive outline to
   *  determine if it the last activity in a module or not
   * @param {int} id Activity ID
   * @returns true/false
   */
  const isActivityStartOfModule = (id) => {
    if (!id) {
      return false;
    }
    const { outline: modules } = outline; // array of modules
    if (!modules) {
      return false;
    }

    // for modules 2 through n get the first activity ID
    const validActivityIds = modules.map((module) => module.activities[0].id);
    return validActivityIds.includes(id);
  };

  const initInterstitial = () => {
    try {
      const { id } = activity || {};
      const { outline: modules } = outline || {}; // array of modules
      if (!enrollment || !modules) {
        return;
      }
      const { user } = userStore;
      const isFirstActivity = modules[0].activities[0].id === id;

      // Upgrade Interstitials
      // Highlights the benefits of a paid subscription,
      // and provides a CTA to upgrade.

      switch (true) {
        /**
         * Basic Upgrade Interstitial
         *  Shown when a free user encounters their first paid activity in a session
         */
        case !user.is_paid && !activity.isFree:
          setActiveInterstitial('upgrade');
          break;
        /**
         * Module Upgrade Interstitial
         *  Shown when a free user completes each module in a course.
         */
        case !user.is_paid && activity.isFree && isActivityStartOfModule(activity.id) && !isFirstActivity:
          setActiveInterstitial('endOfModuleUpgrade');
          break;
        /**
         * Threaded Avtivity Interstitial
         *  Shown when a user encounters an activity with "Show Upgrade Interstitial" checked in the CMS
         */
        case !user.is_paid && activity.showUpgradeInterstitial:
          setActiveInterstitial('threadedActivityUpgrade');
          break;
        /**
         * Default Interstitial (None)
         */
        default:
          setActiveInterstitial(false);
          break;
      }
    } catch (e) {
      Bugsnag.notify(e, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = `Error launching immersive interstitial`;
      });
      setActiveInterstitial(false);
    }
  };

  const completeActivity = () => {
    const { session_id: sessionId, authorized } = activity;
    if (!authorized || !activity || !sessionId) {
      return handleSkipToNextLesson();
    }
    addCompleted(1 * activity.id);
    // @Immersive - Turns out that this is still used in the previous/next buttons, so we set it here, can be removed once that component is updated
    setCompletedActivityId(activity.id);

    // Set loading spinner early
    userStore.setIsUserProfileStatsLoading(true);
    // Now complete the session, and reload the user stats (xp only), which will clear the loading spinner and get the updated user xp/level
    return Agents.catalog
      .completeSession(sessionId)
      .then(() => userStore.loadUserProfileStats(true))
      .catch((e) => {
        Bugsnag.notify(e);
        userStore.setIsUserProfileStatsLoading(false);
      });
  };

  // For assessments, we automatically move to the next item OR to the report page when we complete an activity.
  const completeAssessmentActivity = async () => {
    await completeActivity();
    // Ok, let's leave
    const firstIncompleteActivityId = findFirstIncompleteActivityId(activity.id);
    const { content_description_id: assessmentCourseContentDescriptionId } = getDirectParent();
    if (firstIncompleteActivityId && !completedIds.includes(1 * firstIncompleteActivityId)) {
      navigate(createUrl(firstIncompleteActivityId, null, true), { replace: true });
      return null;
    }
    // Redirect to the report screen
    navigate(getAssessmentReportUrl(assessmentCourseContentDescriptionId, getTrackIdByAssessmentId), { replace: true });
    return false;
  };

  const canAccessActivity = (activityItem) => userStore.checkPermissions({ is_free: activityItem.isFree, content_type: { id: activityItem.contentTypeId } });
  const isLastIncomplete = isActivityLastIncomplete();
  const { authorized } = activity || {};
  const directParent = getDirectParent();
  const hasIncompleteItems = onCompletePage && outline?.incompleteOutline?.length;
  const progress = outline?.requiredProgress || 0;

  const activityId = activity?.id;
  const activityIsComplete = !!activity?.complete;
  const activityIsCybAssessment = activity?.isCybAssessment;

  const userPilotMetadata = useMemo(() => {
    const moduleId = findModuleId(activityId);
    const isCollection = !!item;
    const itemId = item?.contentDescription?.id || null;
    const enrollmentContentId = enrollment?.contentDescription?.id || null;
    return {
      active_interstitial: activeInterstitial,
      action: 'shown',
      user_id: userStore?.user?.id,
      ab_test_group: (userStore?.user?.id || 0) % 10, // last digit of user id for A/B grouping
      activity_id: activity?.id || null,
      activity_type: activity?.type || null,
      activity_isFree: !!activity?.isFree,
      activity_isClab: !!activity?.isClab,
      activity_isCybAssessment: !!activity?.isCybAssessment,
      activity_optional: !!activity?.optional,
      module_id: moduleId,
      course_id: isCollection ? itemId : enrollmentContentId,
      collection_id: isCollection ? enrollmentContentId : null,
    };
  }, [activeInterstitial, activity, activityId, enrollment, item, userStore?.user?.id, findModuleId]);

  useEffect(() => {
    function setMobileComponent() {
      setMobile(window.innerWidth < 1024);
    }
    if (activity) {
      fireTrackingEvents(activity, item, authStore.fireAttributionEvent);
      window.addEventListener('resize', throttle(setMobileComponent, 500, { leading: false, trailing: true }));
      initInterstitial();
      Userpilot.track(USERPILOT_EVENTS.IMMERSIVE_STARTED_ACTIVITY, userPilotMetadata);
    }
    return () => window.removeEventListener('resize', setMobileComponent);
  }, [activity]);

  useEffect(() => {
    // If the activity has changed, open the sidebar (if not on mobile and not an assessment)
    if (activityId && !activityIsCybAssessment) {
      setIsPrimarySidebarOpen(!isMobile);
    }
  }, [activityId, isMobile, activityIsCybAssessment]);

  // @Immersive: This was a hack, I'm not sure that it is relevant going forward. (IT WAS BUT WE STILL NEED IT FOR PREV/NEXT BUTTONS)
  useEffect(() => {
    if (activityIsComplete) {
      setCompletedActivityId(activityId);
      Userpilot.track(USERPILOT_EVENTS.IMMERSIVE_COMPLETED_ACTIVITY, userPilotMetadata);
    }
  }, [activityIsComplete, activityId]);

  useEffect(() => {
    if (!activeInterstitial) {
      return;
    }
    // Track when a user is shown an interstitial in immersive. label is the interstitial shown.
    trackSnowplowEvent({ category: 'ImmersiveInterstitials', action: 'shown', label: activeInterstitial });

    Userpilot.track(USERPILOT_EVENTS.IMMERSIVE_INTERSTITIAL, userPilotMetadata);
  }, [activeInterstitial]);

  const { title: enrollmentTitle } = enrollment;
  const { title, content_description_id: contentDescriptionId, contentDescription } = directParent;
  const { permalink, content_type: contentType, average_rating: averageRating, rating_count: ratingCount, has_certificate: hasCertificate } = contentDescription;
  const { nice_name: type, permalink_prefix: permalinkPrefix } = contentType;
  const { legacy, activityTitle, previousActivity, nextActivity, type: activityType } = activity;
  const desktopTabMenu = getDesktopTabMenu(menu, hasIncompleteItems);
  const sidebarMargin = isDoubleSidebar ? 'ml-[760px]' : 'ml-[500px]';
  const generalFrame = `w-auto transform transition-all duration-500 ease-in-out ${isPrimarySidebarOpen && !isMobile ? sidebarMargin : 'ml-0'}`;
  const onlyLegacyVideo = (activityType === 'Video Activity' || activityType === 'Cybrary Live Session') && legacy && authorized;

  const gotoNextActivity = () => {
    reset();
    navigate(getNextActivityUrl(nextActivity));
  };

  /**
   * Over-ride activity title on interstitials
   * @returns Active Interstitial or Activity title
   */
  const getActivityTitle = () => {
    if (activeInterstitial === 'upgrade') {
      return 'Upgrade to Cybrary Insider Pro';
    }
    return activityTitle;
  };

  const hideSecondary = true;
  // This is the old empty challenge logic, we want to remove the concept of an empty challenge
  // Just putting this here to save it in case we want to revert. isEmptyChallenge = !onlyLegacyVideo && !hasQuestions;
  const isEmptyChallenge = false;

  // We want to disable nav for Assessment types until an activity is completed.
  const disableNav = !completedActivityId && type === 'Assessment';

  const discourseSlug = directParent?.content?.meta?.discourseCategorySlug;

  return (
    <>
      <div key="immersive" className="overflow-hidden h-screen">
        <PrimaryNavigation
          activity={activity}
          outline={outline}
          type={type}
          contentDescriptionId={contentDescriptionId}
          progress={progress}
          title={title}
          permalink={permalink}
          permalinkPrefix={permalinkPrefix}
          averageRating={averageRating}
          ratingCount={ratingCount}
          userStore={userStore}
          commonStore={commonStore}
          canAccessActivity={canAccessActivity}
          toggleParentFeedbackDisplay={toggleParentFeedbackDisplay}
          isShareOpen={isShareOpen}
          toggleShareDisplay={toggleShareDisplay}
          isParentFeedbackOpen={isParentFeedbackOpen}
          togglePrimarySidebarDisplay={togglePrimarySidebarDisplay}
          desktopTabMenu={desktopTabMenu}
          onCompletePage={onCompletePage}
          hasIncompleteItems={hasIncompleteItems}
          navigate={navigate}
          activityTitle={getActivityTitle()}
          previous={previousActivity}
          next={nextActivity}
          completedActivityId={completedActivityId}
          isLast={!(nextActivity && nextActivity.url)}
          isItemLastActivity={isItemLastActivity}
          gotoCompletePage={gotoCompletePage}
          isPrimarySidebarOpen={isPrimarySidebarOpen}
          emptyChallenge={isEmptyChallenge}
          completeActivity={completeActivity}
          activeInterstitial={activeInterstitial}
          setActiveInterstitial={setActiveInterstitial}
          gotoStart={gotoFirstIncomplete}
          gotoNextActivity={gotoNextActivity}
          toggleSuccessBannerDisplay={() => setIsSuccessBannerOpen(!isSuccessBannerOpen)}
          isLastIncomplete={isLastIncomplete}
          setIsPrimarySidebarOpen={setIsPrimarySidebarOpen}
          isMobile={isMobile}
          disableSidebar={disableSidebar}
          setDisableSidebar={setDisableSidebar}
          runningClab={runningClab}
          toggleClabModal={toggleClabModal}
          disableNav={disableNav}
          completeAssessmentActivity={completeAssessmentActivity}
          discourseSlug={discourseSlug}
        />
        {!onCompletePage && !hideSecondary && (
          <SecondaryNavigation
            isMobile={isMobile}
            activity={activity}
            navigate={navigate}
            togglePrimarySidebarDisplay={togglePrimarySidebarDisplay}
            title={getActivityTitle()}
            previous={previousActivity}
            next={nextActivity}
            completedActivityId={completedActivityId}
            isLast={!(nextActivity && nextActivity.url)}
            isItemLastActivity={isItemLastActivity}
            gotoCompletePage={gotoCompletePage}
            isPrimarySidebarOpen={isPrimarySidebarOpen}
            emptyChallenge={isEmptyChallenge}
            completeActivity={completeActivity}
            activeInterstitial={activeInterstitial}
            setActiveInterstitial={setActiveInterstitial}
            setIsPrimarySidebarOpen={setIsPrimarySidebarOpen}
          />
        )}
        {!isMobile && (
          <div className={`transform transition-all duration-500 ease-in-out absolute z-50 top-16 ${isPrimarySidebarOpen ? `translate-x-0` : `-translate-x-full`}`}>
            <PrimarySidebarWrapper
              isPrimarySidebarOpen={isPrimarySidebarOpen}
              enrollmentTitle={enrollmentTitle}
              togglePrimarySidebarDisplay={togglePrimarySidebarDisplay}
              desktopTabMenu={desktopTabMenu}
              outline={outline}
              previous={previousActivity}
              next={nextActivity}
              canAccessActivity={canAccessActivity}
              activity={activity}
              hasIncompleteItems={hasIncompleteItems}
              gotoStart={gotoFirstIncomplete}
              gotoNextActivity={gotoNextActivity}
              gotoCompletePage={gotoCompletePage}
              completeActivity={completeActivity}
              completedActivityId={completedActivityId}
              isLastIncomplete={isLastIncomplete}
              toggleSuccessBannerDisplay={() => setIsSuccessBannerOpen(!isSuccessBannerOpen)}
              userStore={userStore}
              completeAssessmentActivity={completeAssessmentActivity}
              clabResourcesCount={clabResourcesCount}
              isDoubleSidebar={isDoubleSidebar}
              hasDoubleSidebar={hasDoubleSidebar}
              setIsDoubleSidebar={setIsDoubleSidebar}
              setHasDoubleSidebar={setHasDoubleSidebar}
            />
          </div>
        )}
        {/** General Frame */}
        <ImmersiveBody
          isMobile={isMobile}
          title={title}
          contentDescriptionId={contentDescriptionId}
          type={type}
          activityTitleComponent={<ActivityTitle activity={activity} title={getActivityTitle()} />}
          generalFrame={generalFrame}
          activity={activity}
          toggleSuccessBannerDisplay={() => setIsSuccessBannerOpen(!isSuccessBannerOpen)}
          gotoNextActivity={gotoNextActivity}
          gotoFirstIncomplete={gotoFirstIncomplete}
          authorized={authorized}
          completeActivity={completeActivity}
          userStore={userStore}
          isLastIncomplete={isLastIncomplete}
          enrollment={enrollment}
          isPrimarySidebarOpen={isPrimarySidebarOpen}
          isSuccessBannerOpen={isSuccessBannerOpen}
          gotoCompletePage={gotoCompletePage}
          navigate={navigate}
          completedActivityId={completedActivityId}
          onCompletePage={onCompletePage}
          hasIncompleteItems={hasIncompleteItems}
          isItemLastActivity={isItemLastActivity}
          onlyLegacyVideo={onlyLegacyVideo}
          emptyChallenge={isEmptyChallenge}
          activeInterstitial={activeInterstitial}
          setActiveInterstitial={setActiveInterstitial}
          hasCertificate={hasCertificate}
          hideTasks
          maxWidth={900}
          videoMaxWidth="5xl"
          setIsPrimarySidebarOpen={setIsPrimarySidebarOpen}
          setDisableSidebar={setDisableSidebar}
          completeAssessmentActivity={completeAssessmentActivity}
        />
      </div>
      <ClabExitModal showClabModal={showClabModal} toggleClabModal={toggleClabModal} navigate={navigate} />
    </>
  );
}

export default ImmersiveRenderer;
