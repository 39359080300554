import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import moment from 'moment';
import QueryString from 'query-string';
import Bugsnag from '@bugsnag/js';
import Header from '../../components/Header/Header';
import withRouter from '../../components/Router/withRouter';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Loading from '../../components/Loading/Loading';
import Segment from '../../components/Segment/Segment';
import DynamicForm from '../../components/DynamicForm/DynamicForm';
import StyledError from '../../components/Error/StyledError';
import Icon from '../../components/Icon/Icon';
import Container from '../../components/Container/Container';
import './admin.css';

function ChargebeeMessage({ licenseSource }) {
  if (!licenseSource || licenseSource !== 'chargebee') {
    return null;
  }
  return (
    <div className="flex gap-x-2 items-center p-4 text-sm font-semibold bg-yellow-100 rounded border border-black">
      <Icon name="exclamation-circle" className="shrink-0 w-8 h-8" />
      If you change the Team &quot;Package Type&quot;, &quot;Contract End Date&quot;, or &quot;Max Licenses&quot;, you MUST go into Chargebee and cancel the recurring Team
      subscription.
    </div>
  );
}

const ManageTeam = inject(
  'commonStore',
  'adminStore',
  'authStore'
)(
  observer(
    class ManageTeam extends Component {
      state = {
        view: null,
        team: null,
      };

      // Store risk info fields on the component since they are referenced in various places
      riskInfoFields = [
        'risk_info_value',
        'risk_info_maintenance_hours',
        'risk_info_value_at_risk',
        'risk_info_max_improvement_delta',
        'risk_info_max_improvement_hours',
        'risk_info_breach_likelihood',
        'risk_info_max_regression_delta',
        'risk_info_max_regression_hours',
      ];

      componentDidMount() {
        const newState = {
          ...this.state,
          view: this.props.match.params.id ? 'edit' : 'add',
          team: this.props.match.params.id ? this.props.match.params.id : null,
        };
        this.setState(newState);
        if (this.props.match.params.id) {
          this.props.adminStore.getTeamDetailsData(this.props.match.params.id);
        }
        // Confirm that we are allowed on the admin page
        this.props.adminStore.checkAccess(this.props.authStore);
        this.props.commonStore.hidePrimaryNav();
      }

      componentWillUnmount() {
        this.props.adminStore.setDefaultTeamDetailsData();
      }

      getBreadCrumbs() {
        const crumbs = [];
        crumbs.push({
          href: '/admin',
          label: 'Admin',
        });
        if (this.state.view === 'edit') {
          crumbs.push({
            href: '/admin/teams',
            label: 'Browse Teams',
          });
          crumbs.push({
            href: `/admin/view-team/${this.state.team}`,
            label: 'View Team',
          });
          crumbs.push({
            href: `/admin/edit-team/${this.state.team}`,
            label: 'Edit Team',
          });
        } else {
          crumbs.push({
            href: '/admin/teams',
            label: 'Browse Teams',
          });
          crumbs.push({
            href: '/admin/add-team',
            label: 'Add Team',
          });
        }
        return crumbs;
      }

      prepareSubmit = (data) => {
        if (data) {
          // If you are editing the team, and began with a package type but are saving without a package type ("none") show license warning
          if (
            this.state.view === 'edit' &&
            !!this.props.adminStore.teamDetailsData &&
            !!this.props.adminStore.teamDetailsData.data &&
            !!this.props.adminStore.teamDetailsData.data.package_types &&
            data.package_types === 'none'
          ) {
            this.props.commonStore.triggerConfirm({
              content:
                'When switching a team to Package Type "None", all user licenses are unset (upon saving). When changing the team back to a licensed package type, you will have to manually reassign licenses to users.',
              cancel: () => {
                this.props.commonStore.resetConfirmState();
              },
              continue: () => {
                this.props.commonStore.resetConfirmState();
                this.handleSubmit(data);
              },
            });
          } else {
            this.handleSubmit(data);
          }
        }
      };

      display422Error = (err) => {
        if (err.data && err.data.errors) {
          let errorMsg = null;
          const firstError = err.data.errors[Object.keys(err.data.errors)[0]];
          if (firstError[0].indexOf('domain has already been taken') > -1) {
            errorMsg = 'Sorry, that domain has already been taken by another team';
          } else if (firstError[0].indexOf('format is invalid') > -1) {
            errorMsg = 'Email domain format is not valid. Please try again';
          } else if (firstError[0].indexOf('This domain is not allowed') > -1) {
            errorMsg = 'This email domain is not allowed. Please try again';
          }
          this.props.commonStore.triggerToast('error', {
            errorCode: err.status,
            content: errorMsg,
          });
        } else {
          this.props.commonStore.triggerToast('error', {
            errorCode: err.status,
          });
        }
      };

      handleSubmit = (data) => {
        const submitData = { ...data };
        submitData.package_types = submitData.package_types.split('_');
        if (submitData.package_types.indexOf('none') > -1) {
          delete submitData.license_expires_at;
        }
        let emailDomains = [];
        if (submitData.domains && submitData.domains.length) {
          // Format domains to an array for submissions
          const domainsArr = submitData.domains.split(',');
          emailDomains = domainsArr.map((domain) => {
            if (!domain || !domain.trim().length) {
              return null;
            }
            return {
              domain: domain.trim(),
              enabled: true,
            };
          });
        }
        submitData.domains = emailDomains;

        // Transform the risk_info data for submission
        const riskInfo = {};
        this.riskInfoFields.forEach((fieldName) => {
          const riskInfoName = fieldName.replace('risk_info_', '');
          if (submitData[fieldName]) {
            riskInfo[riskInfoName] = submitData[fieldName];
          }
        });
        submitData.risk_info = riskInfo;

        this.props.adminStore
          .manageTeamData(submitData, this.state.team)
          .then((response) => {
            this.props.commonStore.triggerToast('success', {
              content: 'Team has been submitted. Redirecting you to the team view page for additional actions.',
            });
            setTimeout(() => {
              // Send the user to the team details page
              this.props.navigate(`/admin/view-team/${response.id}`);
            }, 3000);
          })
          .catch((e) => {
            Bugsnag.notify(e);
            if (!!e && e.response && (e.response.status === 403 || e.response.status === 422)) {
              if (e.response.status === 422) {
                this.display422Error(e.response);
              } else {
                this.props.commonStore.triggerToast('error', {
                  errorCode: e.response.status,
                });
              }
            } else {
              this.props.commonStore.triggerToast('error', {
                content: 'Something went wrong. Information not submitted. Please try again',
              });
            }
          });
      };

      // Transform array of domains into comma separated string
      formatDomainsField = (domains) => {
        if (!domains || !domains.length) {
          return '';
        }
        const validDomains = [];
        domains.forEach((domain) => {
          if (domain.enabled) {
            validDomains.push(domain.domain);
          }
        });
        return validDomains.length ? validDomains.join(',') : '';
      };

      addDefaultValues = (form, storeData) => {
        if (!storeData) return form; // There is no store data (user is adding a new team), so just return the form as-is
        const newForm = { ...form };
        Object.keys(newForm.fields).forEach((item) => {
          if (!!newForm.fields[item] && !!storeData[item] && storeData[item] !== newForm.fields[item].defaultValue) {
            newForm.fields[item].defaultValue = storeData[item];
          }
          if (item === 'package_types') {
            if (!storeData[item]) {
              newForm.fields[item].defaultValue = 'none';
            } else {
              newForm.fields[item].defaultValue = storeData[item].join('_');
            }
          } else if (item === 'type' && storeData.team_type && storeData.team_type.name) {
            newForm.fields[item].defaultValue = storeData.team_type.name;
          } else if (item === 'license_expires_at' && storeData[item]) {
            newForm.fields[item].defaultValue = moment(storeData[item]).format('YYYY-MM-DD HH:mm:ss');
          } else if (item === 'domains') {
            newForm.fields[item].defaultValue = this.formatDomainsField(storeData[item]);
          } else if (item.startsWith('risk_info_')) {
            const fieldName = item.replace('risk_info_', '');
            newForm.fields[item].defaultValue = storeData?.risk_info?.[fieldName] || newForm.fields[item].defaultValue || '';
          }
        });
        return newForm;
      };

      checkFieldLength = (data) => {
        return data && data.salesforce_id && data.salesforce_id.length === 18 ? null : 'This field must be 18 characters in length';
      };

      // Require any risk_info field if a single one of them is filled out.
      checkRiskInfo = (data) => {
        const riskInfoDataFieldsWithValues = this.riskInfoFields.filter((fieldName) => !!data?.[fieldName]);
        return riskInfoDataFieldsWithValues.length === 0 || riskInfoDataFieldsWithValues.length === this.riskInfoFields.length
          ? null
          : 'If any risk information is provided, all risk info fields become required.';
      };

      addTeamForm = (storeData) => {
        const queryParams = QueryString.parse(window.location.search);
        const teamForm = {
          order: [
            'name',
            'domains',
            'type',
            'salesforce_id',
            'package_types',
            'license_expires_at',
            'max_seats',
            ['risk_info_value', 'risk_info_maintenance_hours'],
            ['risk_info_value_at_risk', 'risk_info_max_improvement_delta', 'risk_info_max_improvement_hours'],
            ['risk_info_breach_likelihood', 'risk_info_max_regression_delta', 'risk_info_max_regression_hours'],
            'clab_access',
            'submit',
          ],
          fields: {
            name: {
              type: 'text',
              label: 'Team Name',
              defaultValue: '',
              validations: ['required'],
            },
            domains: {
              type: 'text',
              label: 'Email Domain(s)',
              defaultValue: '',
              description: 'Email domain(s) associated with this team (Do not include "@"). If including more than one domain, separate with a comma (e.g. cybrary.it,cybrary.com)',
            },
            type: {
              type: 'select',
              label: 'Team Type',
              defaultValue: '',
              validations: ['required'],
              options: [
                {
                  key: 'Internal',
                  text: 'Internal',
                  value: 'Internal',
                },
                {
                  key: 'Creator',
                  text: 'Creator',
                  value: 'Creator',
                },
                {
                  key: 'Demo',
                  text: 'Demo',
                  value: 'Demo',
                },
                {
                  key: 'Enterprise',
                  text: 'Enterprise',
                  value: 'Enterprise',
                },
              ],
            },
            salesforce_id: {
              type: 'text',
              label: 'Salesforce Account ID',
              defaultValue: '',
              validations: ['required', 'alphanumeric', this.checkFieldLength],
              conditions: (formState) => formState.type === 'Enterprise',
              description: 'Must be 18 characters in length',
            },
            package_types: {
              type: 'select',
              label: 'Package Type',
              defaultValue: 'team-full',
              validations: ['required'],
              options: [
                {
                  key: 'team-full',
                  text: 'Team Full',
                  value: 'team-full',
                },
                {
                  key: 'trial',
                  text: 'Team Trial',
                  value: 'team-trial',
                },
                {
                  key: 'none',
                  text: 'None',
                  value: 'none',
                },
              ],
            },
            license_expires_at: {
              type: 'datePicker',
              label: 'Contract End Date',
              dateFormat: 'YYYY-MM-DD HH:mm:ss',
              validations: ['required'],
              conditions: (formState) => formState.package_types !== 'none',
            },
            max_seats: {
              type: 'text',
              label: 'Max Licenses',
              defaultValue: '',
              validations: ['required'],
              conditions: (formState) => formState.package_types !== 'none',
            },
            risk_info_value: {
              type: 'number',
              label: 'Business Value',
              defaultValue: '',
              icon: 'dollar-sign',
              validations: ['nullable', 'number', this.checkRiskInfo],
            },
            risk_info_maintenance_hours: {
              type: 'number',
              label: 'Training Required for Maintenance',
              defaultValue: '',
              description: '(0% change in Breach Likelihood)',
              placeholder: 'hrs/learner/month',
              validations: ['nullable', 'number', this.checkRiskInfo],
            },
            risk_info_value_at_risk: {
              type: 'number',
              label: 'Percent of Value at Risk',
              defaultValue: '',
              validations: ['nullable', 'number', this.checkRiskInfo],
            },
            risk_info_max_improvement_delta: {
              type: 'number',
              label: 'Maximum Training Based Improvement',
              defaultValue: '',
              description: 'Percent',
              validations: ['nullable', 'number', this.checkRiskInfo],
            },
            risk_info_max_improvement_hours: {
              type: 'number',
              label: 'Learning Hours',
              defaultValue: '',
              description: 'Based on this many learning hours invested',
              validations: ['nullable', 'number', this.checkRiskInfo],
            },
            risk_info_breach_likelihood: {
              type: 'number',
              label: 'Likelihood of Breach',
              defaultValue: '',
              validations: ['nullable', 'number', this.checkRiskInfo],
            },
            risk_info_max_regression_delta: {
              type: 'number',
              label: 'Maximum Training Based Regression',
              defaultValue: '',
              description: 'Percent',
              validations: ['nullable', 'number', this.checkRiskInfo],
            },
            risk_info_max_regression_hours: {
              type: 'number',
              label: 'Learning Hours',
              defaultValue: '',
              description: 'Based on this many learning hours invested',
              validations: ['nullable', 'number', this.checkRiskInfo],
            },
            clab_access: {
              type: 'boolean',
              label: 'Assessments Beta',
              description: 'Toggle access for team admins to access the Cybrary Assessments beta',
            },
            submit: {
              type: 'button',
              color: 'green',
              icon: 'save outline',
              label: 'Save Team',
            },
          },
        };
        // For now, only add these options if query param exists
        if (queryParams.avatao) {
          teamForm.fields.package_types.options = [
            ...teamForm.fields.package_types.options,
            {
              key: 'team-full_avatao',
              text: 'Team Full with Avatao',
              value: 'team-full_avatao',
            },
            {
              key: 'trial_avatao',
              text: 'Team Trial with Avatao',
              value: 'team-trial_avatao',
            },
            {
              key: 'avatao',
              text: 'Avatao',
              value: 'avatao',
            },
          ];
        }
        return this.addDefaultValues(teamForm, storeData);
      };

      getForm = () => {
        if (this.props.adminStore.teamDetailsData.loading && this.state.view === 'edit') {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        if (this.props.adminStore.teamDetailsData.error && this.state.view === 'edit') {
          return (
            <Container>
              <StyledError error={this.props.adminStore.teamDetailsData.error} />
            </Container>
          );
        }
        return <DynamicForm form={this.addTeamForm(this.props.adminStore.teamDetailsData.data)} formName="teamForm" onSubmit={this.prepareSubmit} />;
      };

      render() {
        const { denyAccess, teamDetailsData } = this.props.adminStore;

        // If we should deny access, just get out now
        if (denyAccess) {
          return <Navigate to="/" />;
        }
        if (this.state.view === 'edit' && teamDetailsData.loading) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        return (
          <Container className="py-4 admin">
            <Segment className="border-none">
              <Breadcrumbs crumbs={this.getBreadCrumbs()} />
              <ChargebeeMessage licenseSource={teamDetailsData.data ? teamDetailsData.data.license_source : null} />
              <Header as="h1">Add/Edit Team</Header>
              {this.getForm()}
            </Segment>
          </Container>
        );
      }
    }
  )
);

export default withRouter(ManageTeam);
