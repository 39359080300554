import React from 'react';
import Container from '../../components/Container/Container';
import Header from '../../components/Header/Header';
import AddLink from '../../components/AddLink/AddLink';
import HeadersExampleSection from './ExampleComponents/HeadersExampleSection';
import WidgetContainerExampleSection from './ExampleComponents/WidgetContainerExampleSection';
import LockedContainerExampleSection from './ExampleComponents/LockedContainerExampleSection';
import ButtonsExampleSection from './ExampleComponents/ButtonsExampleSection';
import CardExampleSection from './ExampleComponents/CardExampleSection';
import LinksExampleSection from './ExampleComponents/LinksExampleSection';
import IconsExampleSection from './ExampleComponents/IconsExampleSection';
import ChipExampleSection from './ExampleComponents/ChipExampleSection';
import TableExampleSection from './ExampleComponents/TableExampleSection';
import ChartExampleSection from './ExampleComponents/ChartExampleSection';
import CountUpCardExampleSection from './ExampleComponents/CountUpCardExampleSection';
import ProgressExampleSection from './ExampleComponents/ProgressExampleSection';
import ListTableExampleSection from './ExampleComponents/ListTableExampleSection';

/**
 * Components page for example components
 * @returns {JSX.Element} - the components page
 */
function ComponentsPage() {
  return (
    <Container size="lg" className="flex flex-wrap mt-5 w-full">
      <div className="flex flex-col gap-4">
        <AddLink className="hover:text-black underline" to="/">
          &laquo; Home
        </AddLink>
        <Header as="h1">Example Components</Header>
      </div>
      {/** Headers */}
      <HeadersExampleSection />
      {/** WidgetContainers */}
      <WidgetContainerExampleSection />
      {/** LockedContainers */}
      <LockedContainerExampleSection />
      {/** Cards */}
      <CardExampleSection />
      <CountUpCardExampleSection />
      {/** Buttons */}
      <ButtonsExampleSection />
      {/** Links */}
      <LinksExampleSection />
      {/** Icons */}
      <IconsExampleSection />
      {/** Chips */}
      <ChipExampleSection />
      {/** Table */}
      <TableExampleSection />
      {/** List Table */}
      <ListTableExampleSection />
      {/** Chart */}
      <ChartExampleSection />
      {/** Progress Bar / Spinner */}
      <ProgressExampleSection />
    </Container>
  );
}

export default ComponentsPage;
