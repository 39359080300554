import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { debounce } from 'lodash';
import Cookies from 'js-cookie';
import withRouter from '../../components/Router/withRouter';
import Title from '../../components/Title/Title';
import CurriculaList from '../../components/Enterprise/CurriculaList';
import ContentUpdateBanner from '../../components/ContentUpdates/ContentUpdateBanner';
import AddLink from '../../components/AddLink/AddLink';
import Container from '../../components/Container/Container';
import ResultsSummary from '../../components/AdminTable/ResultsSummary';
import SearchIcon from '../../Icons/SearchIcon';
import FormatUtil from '../../utils/formatUtil';
import './enterprise.css';
import Dropdown from '../../components/Dropdown/Dropdown';

const SORT_OPTIONS = [
  { value: 'dateDesc', text: 'Date Created (Newest First)' },
  { value: 'dateAsc', text: 'Date Created (Oldest First)' },
  { value: 'nameAsc', text: 'Name (A-Z)' },
  { value: 'nameDesc', text: 'Name (Z-A)' },
];

function FilteredCurriculaList({ filterValue, userId, orgId, view, curricula }) {
  const [filtered, setFiltered] = useState([]);
  const [sortBy, setSortBy] = useState(Cookies.get('cyb_curriculaSortBy') || 'dateDesc');

  const handleSortChange = ({ value }) => {
    // Toss it into a cookie so we can remember it
    Cookies.set('cyb_curriculaSortBy', value);
    setSortBy(value);
  };

  // Initialize to the full list
  useEffect(() => {
    if (!!curricula && !!curricula[view]) {
      let sorted = [...curricula[view]];

      if (view === 'assessments') {
        sorted = sorted.filter((item) => item.in_team_catalog || item.status === 'Active');
      }

      // Apply sorting
      switch (sortBy) {
        case 'nameAsc':
          sorted.sort((a, b) => a.name.localeCompare(b.name));
          break;
        case 'nameDesc':
          sorted.sort((a, b) => b.name.localeCompare(a.name));
          break;
        case 'dateAsc':
          sorted.sort((a, b) => a.created_at.localeCompare(b.created_at));
          break;
        case 'dateDesc':
        default:
          sorted.sort((a, b) => b.created_at.localeCompare(a.created_at));
          break;
      }
      setFiltered(sorted);
    }
  }, [view, curricula[view], sortBy]);

  // Whenever filterValue changes, update the list
  useEffect(() => {
    if (curricula && curricula[view]) {
      const newFiltered = curricula[view].filter((item) => {
        // Just leave if we have no filter value, every item passes
        if (filterValue === '' || typeof filterValue === 'undefined') {
          return true;
        }
        const { name, description } = item || {};
        // First check the name
        if (name && name.toLowerCase().includes(filterValue.toLowerCase())) {
          return true;
        }
        // Next, check the description
        if (description && description.toLowerCase().includes(filterValue.toLowerCase())) {
          return true;
        }
        return false;
      });
      setFiltered(newFiltered);
    }
  }, [filterValue]);
  return (
    <>
      <div className="flex justify-between">
        <div>
          <ResultsSummary srOnly={!filterValue || !filterValue.length} numOfResults={filtered.length} />
        </div>
        <div>
          <label className="pb-0 mb-0" htmlFor="sort-by-selector">
            Sort By:
          </label>
          <Dropdown
            classes="md:w-72"
            name="Sort By"
            id="sort-by-selector"
            hideSelectedOptions={false}
            onChange={(value) => handleSortChange(value)}
            value={sortBy}
            options={SORT_OPTIONS}
          />
        </div>
      </div>
      <CurriculaList userId={userId} orgId={orgId} view={view} curricula={filtered} loading={curricula.loading} error={curricula.error} />
    </>
  );
}

const Curricula = inject(
  'userStore',
  'enterpriseStore',
  'commonStore',
  'authStore',
  'notificationsStore'
)(
  observer(
    class Curricula extends React.Component {
      state = {
        orgId: null,
        view: this.props.match.params.section || 'user',
        filterValue: '',
      };

      updateFilterValue = debounce((newValue) => {
        const newState = {
          ...this.state,
          filterValue: newValue,
        };
        this.setState(newState);
      }, 500);

      componentDidMount() {
        const isDemo = this.props.match.params.orgId === 'demo';
        if (isDemo) {
          this.props.userStore.enterDemo();
          this.props.enterpriseStore.enterDemo();
        } else {
          this.props.userStore.setPreferredTeamInit('', this.props.match.params.orgId, this.init);
          // Get user notifications (if not retrieved already)
          this.props.notificationsStore.getUserNotifications(this.props.userStore);
        }
        this.setNewPageTitle(this.state.view, isDemo);
        this.props.authStore.fireAttributionEvent();
      }

      componentDidUpdate(prevProps) {
        this.props.userStore.setPreferredTeamInit(prevProps.match.params.orgId, this.props.match.params.orgId, this.init);
        this.checkUpdateView();
      }

      componentWillUnmount() {
        this.props.enterpriseStore.reset();
        // reset demo on unmount
        if (this.props.match.params.orgId === 'demo') {
          this.props.userStore.exitDemo();
          this.props.enterpriseStore.exitDemo();
        }
      }

      init = (orgId) => {
        const { user, team } = this.props.userStore;
        const newState = {
          ...this.state,
          orgId,
        };
        this.setState(newState, () => {
          this.checkUpdateView();
        });
        this.props.enterpriseStore.loadCurricula(orgId, user.id, team.package_types);
      };

      checkUpdateView = () => {
        const { section, orgId } = this.props.match.params;
        const isDemo = orgId === 'demo';
        const newState = {
          ...this.state,
          view: section || 'user',
        };
        if (this.state.view !== newState.view) {
          this.setNewPageTitle(newState.view, isDemo);
          // Reset the filter value and the value in the ref to the actual element
          newState.filterValue = '';
          if (this.pathFilterElem) {
            this.pathFilterElem.value = '';
          }
          this.setState(newState);
        }
      };

      setNewPageTitle = (section, isDemo) => {
        const pageSection = section === 'user' ? 'my' : section;
        const formattedPageSection = pageSection ? FormatUtil.ucwords(pageSection) : '';
        return this.props.commonStore.setPageTitle(`${formattedPageSection} Paths${isDemo ? ' Demo' : ''} | Cybrary`);
      };

      getUpdatedBannerContent = () => {
        switch (this.state.view) {
          case 'cybrary':
            return 'We’ve improved one or more of Cybrary’s Paths! Click the blue banner to see what’s changed.';
          case 'team':
          default:
            return 'We’ve improved content items contained in your custom paths! Click "View Details" on the paths flagged with updates.';
        }
      };

      getHeading = () => {
        switch (this.state.view) {
          case 'team':
            return 'Team Paths';
          case 'assessments':
            return 'Assessment Paths';
          case 'cybrary':
            return 'Cybrary Paths';
          default:
            return 'My Paths';
        }
      };

      render() {
        const { enterpriseStore, userStore, notificationsStore } = this.props;
        const { view, filterValue } = this.state;
        const { curricula } = enterpriseStore;
        const { team } = userStore;
        const orgId = team?.id;
        const isDemo = orgId === 'demo';
        const { contentNotifications } = notificationsStore || {};
        const notifications = contentNotifications?.Paths[orgId] || 0;
        const canManageCurricula = team?.permissions?.canManageCurricula;
        const showFilter = curricula && curricula[view]?.length;

        return (
          <Container size="lg" className="curricula">
            <div className="pt-16 mb-4 sm:pb-4">
              <div className="items-center sm:flex">
                <Title title={this.getHeading()} omitPadding />
                {canManageCurricula && (
                  <div className="my-8 sm:flex-1 sm:my-0 sm:text-right">
                    <AddLink to={`/enterprise/${orgId}/path/create`}>
                      <button
                        className={`py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm ${
                          isDemo ? 'cursor-not-allowed' : ''
                        }`}
                        disabled={isDemo}
                      >
                        + Add Path
                      </button>
                    </AddLink>
                  </div>
                )}
              </div>
              {!!showFilter && (
                <div className="mt-4 max-w-lg">
                  <div className="flex py-2 px-4 bg-gray-200 rounded-full">
                    <div className="shrink">
                      <SearchIcon classes="w-6 h-6 text-gray-600" />
                    </div>
                    <div className="grow pl-4">
                      <input
                        type="text"
                        name="pathfilter"
                        ref={(elem) => {
                          this.pathFilterElem = elem;
                        }}
                        placeholder="Filter the list of paths"
                        className="text-gray-600 placeholder:text-gray-600 bg-gray-200 border-0 focus:border-0 outline-none focus:outline-none"
                        onChange={(e) => this.updateFilterValue(e.target.value)}
                        aria-label="Filter the list of paths."
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              )}
              {!!notifications && !!notifications[view] && (
                <div className="my-8 sm:mb-0">
                  <ContentUpdateBanner
                    page="Paths"
                    notificationId={`notification_${view}-path-update_${orgId}`}
                    content={this.getUpdatedBannerContent()}
                    cta="Ok, Got it"
                    notificationsCount={notifications[view]}
                  />
                </div>
              )}
            </div>
            <FilteredCurriculaList
              filterValue={filterValue}
              userId={this.props.userStore.user.id}
              orgId={orgId}
              view={view}
              curricula={curricula}
              loading={curricula.loading}
              error={curricula.error}
            />
          </Container>
        );
      }
    }
  )
);

export default withRouter(Curricula);
