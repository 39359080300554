import React from 'react';

import { observer, inject } from 'mobx-react';
import { when } from 'mobx';
import queryString from 'query-string';
import { Userpilot } from 'userpilot';
import withRouter from '../../components/Router/withRouter';
import AddLink from '../../components/AddLink/AddLink';
import OrganizationDashboardHeading from '../../components/Enterprise/OrganizationDashboardHeading';
import StatSectionContainer from '../../components/Analytics/StatSectionContainer';
import DateRangeFilter from '../../components/Enterprise/DateRangeFilter';
import Leaderboard from '../../components/Analytics/Leaderboard/Leaderboard';
import FeaturesSummary from '../../components/Analytics/FeaturesSummary';
import GroupSelect from '../../components/Enterprise/GroupSelect';
import FormatUtil from '../../utils/formatUtil';
import BetaLabel from '../../components/Label/BetaLabel';
import Container from '../../components/Container/Container';
import Sticky from '../../components/Sticky/Sticky';
import Card from '../../components/Card/Card';
import Label from '../../components/Label/Label';
import If from '../../components/If/If';

import './enterprise.css';
import '../../components/Analytics/analytics.css';
import StyleUtil from '../../utils/styleUtil';
import { USERPILOT_EVENTS } from '../../constants';
import OrganizationTrainingProgress from './OrganizationTrainingProgress';
import RiskImpact from './RiskImpact';
import OrganizationStatCards from './OrganizationStatCards';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';

const OrganizationDashboard = inject(
  'enterpriseStore',
  'commonStore',
  'userStore',
  'authStore',
  'adminStore'
)(
  observer(
    class OrganizationDashboard extends React.Component {
      state = {
        orgId: null,
        teamGroupId: null,
        view: 'team',
        groupSelection: null,
        tabularView: false,
      };

      componentDidMount() {
        const isDemo = this.props.match.params.orgId === 'demo';
        if (isDemo) {
          this.props.userStore.enterDemo();
          this.props.enterpriseStore.enterDemo();
          Userpilot.track(USERPILOT_EVENTS.VIEWED_DEMO_TEAM_DASHBOARD);
        } else {
          this.props.userStore.setPreferredTeamInit('', this.props.match.params.orgId, this.init);
          Userpilot.track(USERPILOT_EVENTS.VIEWED_TEAM_DASHBOARD);
        }
        this.props.commonStore.setPageTitle(`Team Dashboard${isDemo ? ' Demo' : ''} | Cybrary`);
        this.props.authStore.fireAttributionEvent();
      }

      componentDidUpdate(prevProps) {
        this.props.userStore.setPreferredTeamInit(prevProps.match.params.orgId, this.props.match.params.orgId, this.init);

        // If group ID in url changes (if using switcher on this page), get new data
        if (this.props.match.params.groupId !== prevProps.match.params.groupId) {
          this.init(this.props.userStore.team.id);
        }
      }

      componentWillUnmount() {
        this.props.enterpriseStore.setDefaultOrgDashboardData();

        // reset demo on unmount
        if (this.props.match.params.orgId === 'demo') {
          this.props.userStore.exitDemo();
          this.props.enterpriseStore.exitDemo();
        }
      }

      init = (orgId) => {
        const { team } = this.props.userStore;
        let groupScope = team.permissions && !team.permissions.canManageTeam && team.permissions.canViewReports !== 'all';
        let groupSelection = groupScope ? team.reportsTeamGroupId : null;
        const queryParams = queryString.parse(window.location.search);
        const groupQueryParam = queryParams.group;
        // If a group query param was provided, auto set to group scope and set that as group selection
        if (groupQueryParam) {
          groupScope = true;
          groupSelection = groupQueryParam * 1;
        }

        // This dashboard is shared between org and group views. teamGroupId is used mostly and will be either group ID or org ID depending on view
        // Org ID is saved in store too because some links may need to use org ID regardless of view (depending on permissions)
        const newState = {
          ...this.state,
          orgId,
          view: groupScope ? 'group' : 'team',
          groupSelection,
          // This dashboard is shared between org and group views. teamGroupId is used mostly and will be either group ID or org ID depending on view
          // Org ID is saved in store too because some links may need to use org ID regardless of view (depending on permissions)
          teamGroupId: groupSelection || orgId,
        };
        this.setState(newState, () => {
          this.getDashboardData();
        });
      };

      setGraphView = (e, { checked }) => {
        const newState = {
          ...this.state,
          tabularView: checked,
        };
        this.setState(newState, () => {
          this.getStatSections(true);
        });
      };

      getDashboardData = () => {
        const { team } = this.props.userStore;
        const freeTeam = !!team && (!team.package_types || !team.package_types.length);
        if (!freeTeam || !!this.props.adminStore.larping.teamId) {
          if (this.state.view !== 'group') {
            this.getFilterableContent();
          } else {
            this.props.enterpriseStore.getGroupMeta('orgDashboardData', this.state.teamGroupId);
            when(
              // once...
              () => !this.props.enterpriseStore.orgDashboardData.groupMeta.loading,
              // ... then
              () => this.getFilterableContent()
            );
          }
        }
      };

      getStatSections = (tabularViewChange) => {
        const statsConfig = this.props.enterpriseStore.orgDashboardData.config.stats;

        statsConfig.stat_cards.forEach((item) => {
          this.props.enterpriseStore.getDashboardMetrics('orgDashboardData', this.state.teamGroupId, null, item, null, this.state.tabularView, !!tabularViewChange);
        });
        statsConfig.half_stat_cards.forEach((item) => {
          this.props.enterpriseStore.getDashboardMetrics('orgDashboardData', this.state.teamGroupId, null, item, null, this.state.tabularView, !!tabularViewChange);
        });
        statsConfig.mixed_stat.forEach((item) => {
          this.props.enterpriseStore.getDashboardMetrics('orgDashboardData', this.state.teamGroupId, null, item, null, this.state.tabularView, !!tabularViewChange);
        });
      };

      getFilterableContent = () => {
        const leaderboardConfig = this.props.enterpriseStore.orgDashboardData.config.leaderboards;

        // Leaderboards
        leaderboardConfig.forEach((item) => {
          this.props.enterpriseStore.getDashboardLeaderboards('orgDashboardData', this.state.teamGroupId, item.key, item);
        });

        this.getStatSections();
      };

      getDateRangeFilter = (team) => {
        if (!team) {
          return null;
        }
        const isDemo = team.id === 'demo';
        const createdAt = team.data && team.data.parent_team ? team.data.parent_team.created_at : team.created_at;
        const showGroupCreationFilter = this.props.enterpriseStore.orgDashboardData && this.state.view === 'group';

        return (
          <DateRangeFilter
            dateChange={this.applyDateFilter}
            dateRange={this.props.enterpriseStore.orgDashboardData.filters.range}
            allTimeStart={createdAt}
            showGroupCreationFilter={showGroupCreationFilter}
            startDate={this.props.enterpriseStore.orgDashboardData.filters.startDate}
            endDate={this.props.enterpriseStore.orgDashboardData.filters.endDate}
            rangeSelectionClasses="w-full mb-6 md:mb-0 md:w-48 lg:w-52 md:mr-4"
            disabled={isDemo}
          />
        );
      };

      applyDateFilter = (startDate, endDate, value) => {
        if (startDate) {
          this.props.enterpriseStore.setDashboardDateFilter('orgDashboardData', 'startDate', startDate);
        }
        if (endDate) {
          this.props.enterpriseStore.setDashboardDateFilter('orgDashboardData', 'endDate', endDate);
        }
        this.props.enterpriseStore.setDashboardDateFilter('orgDashboardData', 'range', value || null);
        this.getFilterableContent();
      };

      getLeaderboards = (storeData) => {
        const { orgId } = this.props.match.params;
        // Check if loaded leaderboards are empty
        if (Object.keys(storeData.leaderboards).length === 0) {
          return null;
        }

        // Show one leaderboard per config entry
        return storeData.config.leaderboards.map((item) => {
          const leaderData = storeData.leaderboards[item.key];
          if (!leaderData.data || !leaderData.config) {
            return null;
          }
          const ignoredCols = [FormatUtil.getColIndex(leaderData.data.columns, 'groups')];
          return (
            <div className="mb-4" key={item.key}>
              <ErrorBoundary>
                <Leaderboard
                  loading={leaderData.loading}
                  error={leaderData.error}
                  data={leaderData.data}
                  heading={leaderData.config.heading}
                  filterValue={leaderData.queryParams.sortCol}
                  orgId={orgId}
                  filterChange={this.leaderboardFilterChange}
                  name={item.key}
                  href={`/enterprise/${orgId}${leaderData.config.href}`}
                  formatColumns={[
                    {
                      method: this.addLeaderboardLink,
                      colIdx: FormatUtil.getColIndex(leaderData.data, item.key === 'member' ? 'name' : 'content_description_title'),
                    },
                    {
                      method: this.formatScore,
                      colIdx: FormatUtil.getColIndex(leaderData.data, 'average_score'),
                    },
                    {
                      method: this.formatNumber,
                      colIdx: FormatUtil.getColIndex(leaderData.data, 'learning_hours'),
                    },
                    {
                      method: this.formatNumber,
                      colIdx: FormatUtil.getColIndex(leaderData.data, 'enrollments'),
                    },
                    {
                      method: this.formatNumber,
                      colIdx: FormatUtil.getColIndex(leaderData.data, 'total_xp'),
                    },
                  ]}
                  ignoredCols={ignoredCols}
                  filters={leaderData.config.filters.slice()}
                  headingTooltip={item.key === 'hands-on' ? true : null}
                />
              </ErrorBoundary>
            </div>
          );
        });
      };

      formatTitleWithType = (col, row, headings) => {
        const colArr = col.value.split(' ');
        const lastWord = colArr[colArr.length - 1];
        const tagsIdx = FormatUtil.getColIndex(headings, 'tags');
        const tags = tagsIdx !== -1 ? row[tagsIdx].value : null;
        if (row[FormatUtil.getColIndex(headings, 'type')]) {
          const type = row[FormatUtil.getColIndex(headings, 'type')].value;
          colArr.pop();
          return (
            <>
              {colArr.join(' ')}{' '}
              <span className="whitespace-nowrap">
                {lastWord}
                <span>
                  <Label className="ml-2" basic>
                    {type}
                  </Label>
                </span>
              </span>
              {tags && tags.indexOf('Beta') !== -1 ? <BetaLabel className="ml-2" /> : null}
            </>
          );
        }
        return col.value;
      };

      addLeaderboardLink = (col, row, headings, source) => {
        const { orgId } = this.props.match.params;
        let href = null;
        switch (source) {
          case 'member':
            href = `/enterprise/${orgId}/organization/member/${row[FormatUtil.getColIndex(headings, 'id')].value}/dashboard/`;
            break;
          case 'course':
            href = `/enterprise/${orgId}/reporting/report/content/?contentDescriptionId=${
              row[FormatUtil.getColIndex(headings, 'content_description_id')].value
            }&contentName=${encodeURIComponent(row[FormatUtil.getColIndex(headings, 'content_description_title')].value)}`;
            break;
          default:
            href = null;
        }
        // clear link if in demo
        if (orgId === 'demo') {
          href = null;
        }
        return (
          <React.Fragment key={col.value}>
            <AddLink className="hover:font-semibold text-black hover:text-black underline hover:underline" to={href}>
              {col.value}
            </AddLink>
          </React.Fragment>
        );
      };

      formatScore = (col) => {
        return col.value ? `${col.value}%` : '-';
      };

      formatNumber = (col) => {
        return col.value ? FormatUtil.formatNumbers(col.value) : 0;
      };

      leaderboardFilterChange = (leaderboardName, value, callback) => {
        this.props.enterpriseStore.setDashboardLeaderboardFilter('orgDashboardData', leaderboardName, value);
        this.props.enterpriseStore.getDashboardLeaderboards('orgDashboardData', this.state.teamGroupId, leaderboardName, null, callback);
      };

      // /**
      //  * Sets var in store for use in endpoint composition
      //  * @param value
      //  */
      applyGroupFilter = (groupId) => {
        const { team } = this.props.userStore;
        const groupSelection = groupId;
        const teamGroupId = groupId || this.state.orgId;
        const newState = {
          ...this.state,
          groupSelection,
          teamGroupId,
          view: !groupId ? 'team' : 'group',
        };
        this.setState(newState, () => {
          // If there's a group queryparam, lets remove it
          const queryParams = queryString.parse(window.location.search);
          if (queryParams.group) {
            delete queryParams.group;
            this.props.navigate({
              search: queryString.stringify(queryParams),
            });
          }
          this.getDashboardData();
          // If this is a group admin and the group filter was changed, set that to the preferred group for the user
          if (team && team.permissions && !team.permissions.canManageTeam && team.permissions.canViewReports !== 'all') {
            this.props.userStore.setPreferredGroup(this.state.orgId, groupId);
          }
        });
      };

      getPathActivityStatCards = (storeData) => {
        const { orgId } = this.props.match.params;
        const graphColorDefaults = StyleUtil.getGraphColorDefaults();
        return (
          <If condition={storeData?.stats?.curriculaActivity}>
            <Card className="p-0 my-0 stat-card horizontal-bar">
              <StatSectionContainer
                type="horizontal-bar"
                header="Paths Activity"
                orgId={orgId}
                filters={storeData.filters}
                xAxisLabel="Learning Hours"
                showLegend={false}
                loading={storeData?.stats?.curriculaActivity?.loading}
                error={storeData?.stats?.curriculaActivity?.error}
                displayData={storeData?.stats?.curriculaActivity?.data}
                emptyText="Add paths to start tracking performance."
                tooltip="The top paths used by your team based on learning hours."
                href={`/enterprise/${orgId}/reporting/report/curricula-overview`}
                primaryColors={graphColorDefaults.primaryColors}
                tabularView={this.state.tabularView && storeData.stats.curriculaActivity.tableView}
              />
            </Card>
          </If>
        );
      };

      getMemberActivityStatCard = (storeData) => {
        const { orgId } = this.props.match.params;
        const graphColorDefaults = StyleUtil.getGraphColorDefaults();
        return (
          <If condition={!!storeData?.stats?.memberActivity}>
            <Card className="p-0 my-0 stat-card horizontal-bar">
              <StatSectionContainer
                type="horizontal-bar"
                header="Trailing 90-day Member Activity"
                orgId={orgId}
                filters={storeData.filters}
                xAxisLabel="Members"
                showLegend={false}
                legendPosition="left"
                loading={storeData.stats.memberActivity.loading}
                error={storeData.stats.memberActivity.error}
                displayData={storeData.stats.memberActivity.data}
                emptyText="Add members to start tracking activity."
                tooltip={
                  'The activity status of your members based on recent logins. Active members have logged in within the last 30 days. ' +
                  'Passive members have a login between 31-90 days. Inactive members have had no login activity within the last 90 days.'
                }
                href={`/enterprise/${orgId}/reporting/report/status-per-member`}
                primaryColors={graphColorDefaults.primaryColors}
                tabularView={this.state.tabularView && storeData.stats.memberActivity.tableView}
              />
            </Card>
          </If>
        );
      };

      getHeaderStatCards = (storeData) => {
        return (
          <div className="grid grid-cols-1 gap-6 pb-12 mb-6 min-h-[285px] border-b-xs border-gray-400 md:grid-cols-2">
            {this.getMemberActivityStatCard(storeData)}
            {this.getPathActivityStatCards(storeData)}
          </div>
        );
      };

      getStatCards = (storeData) => {
        const { orgId } = this.props.match.params;
        const graphColorDefaults = StyleUtil.getGraphColorDefaults();
        const goalsGraphColorDefaults = StyleUtil.getGoalsGraphColorDefaults();
        const goalsTotal = this.props.enterpriseStore.getGoalsStatTotal(storeData.stats.goals.data);
        return (
          <div className="mb-8">
            <div className="grid grid-cols-1 gap-6 mb-6 sm:grid-cols-2 lg:grid-cols-4 stat-cards-group">
              <Card className="p-0 my-0 stat-card summary">
                <StatSectionContainer
                  type="summary"
                  linkAriaLabel="Total learning hours breakdown"
                  orgId={orgId}
                  filters={storeData.filters}
                  loading={storeData.stats.learningHoursSummary.loading}
                  error={storeData.stats.learningHoursSummary.error}
                  displayData={storeData.stats.learningHoursSummary.data}
                  emptyText={`There are no activities for this ${this.state.view} during this time period`}
                  tooltip="The total hours earned through Cybrary by watching videos and completing hands-on activities over time."
                  href={`/enterprise/${orgId}/reporting/report/learning-hours`}
                  tabularView={this.state.tabularView && storeData.stats.learningHoursSummary.tableView}
                />
              </Card>
              <Card className="p-0 my-0 stat-card summary">
                <StatSectionContainer
                  type="summary"
                  linkAriaLabel="Learning activity interactions breakdown"
                  orgId={orgId}
                  filters={storeData.filters}
                  loading={storeData.stats.learningActivityInteractions.loading}
                  error={storeData.stats.learningActivityInteractions.error}
                  displayData={storeData.stats.learningActivityInteractions.data}
                  emptyText={`There are no activities for this ${this.state.view} during this time period`}
                  tooltip="The total learning activities that a user has interacted with. Example interactions include enrolling
                  in a course, watching a video, and launching a lab."
                  href={`/enterprise/${orgId}/reporting/report/learning-activities`}
                  tabularView={this.state.tabularView && storeData.stats.learningActivityInteractions.tableView}
                />
              </Card>
              <Card className="p-0 my-0 stat-card pie">
                <StatSectionContainer
                  type="pie"
                  loading={storeData.stats.learningActivities.loading}
                  error={storeData.stats.learningActivities.error}
                  displayData={storeData.stats.learningActivities.data}
                  header="Learning Activities"
                  orgId={orgId}
                  filters={storeData.filters}
                  emptyText={`There are no activities for this ${this.state.view} during this time period`}
                  tooltip={
                    'The total learning activities completed filtered by the activity type. ' +
                    'Hands-on activities include all labs, assessments, and practice tests. Other activities consist of articles.'
                  }
                  href={`/enterprise/${orgId}/reporting/report/learning-activities`}
                  primaryColors={graphColorDefaults.primaryColors}
                  legendPosition="right"
                  tabularView={this.state.tabularView && storeData.stats.learningActivities.tableView}
                />
              </Card>
              <Card className="p-0 my-0 stat-card doughnut">
                <StatSectionContainer
                  type="doughnut"
                  header="Goals"
                  orgId={orgId}
                  filters={storeData.filters}
                  loading={storeData.stats.goals.loading}
                  error={storeData.stats.goals.error}
                  displayData={storeData.stats.goals.data}
                  emptyText={
                    <div>
                      You currently have no goals.{' '}
                      <AddLink className="text-cyb-pink-500 hover:text-black underline" to={`/enterprise/${orgId}/goals?action=add`}>
                        Create a new goal
                      </AddLink>{' '}
                      to start tracking performance
                    </div>
                  }
                  tooltip="The total goals filtered by their completion status."
                  href={`/enterprise/${orgId}/goals`}
                  reportLinkIcon="list"
                  primaryColors={goalsGraphColorDefaults}
                  legendPosition="left"
                  statMeta={goalsTotal && <span className="ml-2">Total: {goalsTotal}</span>}
                  tabularView={this.state.tabularView && storeData.stats.goals.tableView}
                />
              </Card>
            </div>
            <div className="mb-4 learning-hours">
              <Card className="p-0 my-0 stat-card mixed card">
                <StatSectionContainer
                  type="mixed"
                  header="Learning Hours"
                  mixedTypes={['bar', 'bar', 'line']}
                  filters={storeData.filters}
                  orgId={orgId}
                  interval={storeData.filters.interval}
                  loading={storeData.stats.learningHoursMixed.loading}
                  error={storeData.stats.learningHoursMixed.error}
                  displayData={storeData.stats.learningHoursMixed.data}
                  emptyText="Add members to start tracking activity."
                  tooltip={
                    'The total hours earned through Cybrary by watching videos and completing hands-on activities. Assigned hours includes content assigned as part of a Goal, ' +
                    'or Assignments created before April 1, 2021'
                  }
                  href={`/enterprise/${orgId}/reporting/report/learning-hours`}
                  yAxisLabel="Hours"
                  primaryColors={graphColorDefaults.primaryColors}
                  tabularView={this.state.tabularView && storeData.stats.learningHoursMixed.tableView}
                />
              </Card>
            </div>
          </div>
        );
      };

      render() {
        const { orgDashboardData: storeData } = this.props.enterpriseStore;
        const groupScope = this.state.view === 'group';
        const { team } = this.props.userStore;
        if (!team) {
          return null;
        }
        if (!!team && (!team.package_types || !team.package_types.length) && !this.props.adminStore.larping.teamId) {
          return <FeaturesSummary featureType="dashboard" />;
        }
        const filters = storeData?.filters ? { ...storeData.filters } : null;
        const orgId = this.state.teamGroupId;
        const isDemo = this.props.match.params.orgId === 'demo';

        return (
          <div className="organization">
            <div className="organization-dashboard dashboard">
              <Container>
                <OrganizationDashboardHeading
                  loading={groupScope ? storeData.groupMeta.loading : false}
                  error={groupScope ? storeData.groupMeta.error : false}
                  data={groupScope ? storeData.groupMeta.data : team}
                  groupScope={groupScope}
                  canManageAdmins={team && team.permissions && team.permissions.canManageAdmins}
                  team={team}
                  setGraphView={this.setGraphView}
                  graphView={this.state.tabularView}
                />
              </Container>
              <ErrorBoundary>
                <div className="filterable-content">
                  <Sticky stickyClasses="static lg:sticky lg:border-b lg:border-gray-400 z-210">
                    <Container className="z-210">
                      <div className="py-9 md:flex md:justify-end md:items-center filter-container">
                        <p className="mr-2 mb-0 lg:ml-0 lg:text-right">Filters:</p>
                        <span className="mr-4 w-56">
                          <GroupSelect
                            team={team}
                            handleGroupSelect={this.applyGroupFilter}
                            selectedGroup={this.state.groupSelection}
                            groupFilter={(group) => group.permissions.reports}
                            placeholder="All Groups"
                            defaultOption={
                              team && team.permissions && (team.permissions.canManageTeam || team.permissions.canViewReports === 'all')
                                ? {
                                    text: `All Groups`,
                                    value: null,
                                  }
                                : null
                            }
                          />
                        </span>
                        {this.getDateRangeFilter(!groupScope ? team : storeData.groupMeta)}
                      </div>
                    </Container>
                  </Sticky>
                  <Container>
                    <If condition={!!filters}>
                      <OrganizationStatCards filters={filters} orgId={orgId} isDemo={isDemo} />
                      <OrganizationTrainingProgress filters={filters} orgId={orgId} isDemo={isDemo} />
                      <RiskImpact filters={filters} orgId={orgId} isDemo={isDemo} />
                    </If>
                    {this.getLeaderboards(storeData)}
                  </Container>
                </div>
              </ErrorBoundary>
            </div>
          </div>
        );
      }
    }
  )
);

export default withRouter(OrganizationDashboard);
