import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function Certificate({ classes, id, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  const myId = `certificateicon${id || uuidv4()}`;

  return (
    <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <g clipPath={`url(#${myId})`}>
        <path
          d="M14.8813 6.16693L14.9913 4.18962C14.999 4.0569 14.9269 3.93225 14.8079 3.87286L13.0474 2.98261L12.1571 1.22203C12.0966 1.10415
            11.9727 1.03239 11.8404 1.03864L9.85966 1.14544L8.20915 0.0549977C8.09792 -0.0183326 7.95373 -0.0183326 7.84237 0.0549977L6.19186
            1.14544L4.21468 1.03538C4.08183 1.02757 3.95731 1.09973 3.89791 1.21877L3.00753 2.97935L1.24709 3.8696C1.12909 3.93004 1.05745 4.0539
            1.0637 4.18637L1.17363 6.16368L0.0833179 7.81419C0.00998779 7.92543 0.00998779 8.06961 0.0833179 8.18098L1.17037 9.83475L1.06031 11.8121C1.05263
            11.9448 1.12466 12.0694 1.2437 12.1288L3.00428 13.0191L3.89453 14.7797C3.95496 14.8977 4.07883 14.9693 4.21129 14.963L6.1886 14.853L7.83911
            15.9433C7.94969 16.019 8.09531 16.019 8.20589 15.9433L9.85641 14.853L11.8337 14.963C11.9664 14.9707 12.091 14.8986 12.1505 14.7797L13.0407
            13.0191L14.8013 12.1288C14.9192 12.0683 14.9909 11.9445 14.9847 11.8121L14.8746 9.83475L15.9649 8.18423C16.0383 8.073 16.0383 7.92868 15.9649
            7.81745L14.8813 6.16693ZM14.261 9.56136C14.2204 9.62101 14.2015 9.69278 14.2078 9.7648L14.311 11.6353L12.6472 12.4755C12.5838 12.5073 12.5324
            12.5589 12.5006 12.6223L11.6602 14.2861L9.78972 14.1829C9.71808 14.1802 9.64736 14.1987 9.58627 14.2361L8.02576 15.2665L6.46538 14.2361C6.4112
            14.1995 6.34737 14.1797 6.28199 14.1795H6.26532L4.39468 14.2829L3.55445 12.619C3.52267 12.5555 3.47122 12.5041 3.40766 12.4723L1.74047 11.6353L1.84389
            9.7648C1.8465 9.69317 1.82787 9.62244 1.79049 9.56136L0.760221 8.00084L1.79049 6.44033C1.83126 6.3808 1.85014 6.30891 1.84389 6.23701L1.74047
            4.36637L3.4044 3.52613C3.46783 3.49435 3.51928 3.44291 3.55106 3.37947L4.3913 1.71554L6.26193 1.81896C6.33344 1.82144 6.40416 1.80294 6.46538
            1.76556L8.02576 0.735289L9.58627 1.76556C9.64593 1.80633 9.71782 1.82521 9.78972 1.81896L11.6602 1.71554L12.5006 3.37947C12.5324 3.44291 12.5838
            3.49435 12.6472 3.52613L14.311 4.36637L14.2078 6.23701C14.2052 6.30852 14.2238 6.37924 14.261 6.44033L15.2914 8.00084L14.261 9.56136Z"
          fill="currentColor"
        />
        <path
          d="M14.6463 6.2242L14.753 4.30868C14.7605 4.18011 14.6905 4.05936 14.5753 4.00182L12.8688 3.13939L12.0059 1.43384C11.9472 1.31965 11.8272 1.25012 11.6989
            1.25618L9.7791 1.35964L8.17933 0.303279C8.07152 0.23224 7.93176 0.23224 7.82383 0.303279L6.22405 1.35964L4.30766 1.25302C4.17889 1.24545 4.0582 1.31536
            4.00063 1.43068L3.13762 3.13623L1.4313 3.99866C1.31692 4.05721 1.24749 4.17721 1.25355 4.30553L1.3601 6.22104L0.303307 7.81997C0.232231 7.92773 0.232231
            8.06741 0.303307 8.17529L1.35694 9.77738L1.25027 11.6929C1.24282 11.8215 1.31263 11.9422 1.42802 11.9998L3.13447 12.8622L3.99735 14.5677C4.05593 14.6821
            4.17599 14.7515 4.30438 14.7454L6.2209 14.6388L7.82067 15.695C7.92785 15.7683 8.06899 15.7683 8.17617 15.695L9.77595 14.6388L11.6925 14.7454C11.8211 14.7528
            11.9418 14.6829 11.9995 14.5677L12.8624 12.8622L14.5688 11.9998C14.6831 11.9411 14.7526 11.8212 14.7466 11.6929L14.6399 9.77738L15.6967 8.17845C15.7678 8.07069
            15.7678 7.93089 15.6967 7.82313L14.6463 6.2242ZM14.0452 9.51253C14.0058 9.57032 13.9875 9.63984 13.9935 9.70962L14.0936 11.5217L12.481 12.3356C12.4195 12.3664
            12.3696 12.4164 12.3388 12.4778L11.5243 14.0897L9.71131 13.9896C9.64187 13.9871 9.57332 14.005 9.51412 14.0412L8.00158 15.0394L6.48917 14.0412C6.43665 14.0057
            6.37479 13.9866 6.31142 13.9863H6.29526L4.48213 14.0865L3.66773 12.4746C3.63692 12.4131 3.58706 12.3633 3.52545 12.3325L1.90952 11.5217L2.00976 9.70962C2.01228
            9.64022 1.99423 9.57171 1.958 9.51253L0.9594 8.00079L1.958 6.48905C1.99751 6.43138 2.01582 6.36173 2.00976 6.29208L1.90952 4.47991L3.52229 3.66593C3.58377 3.63514
            3.63364 3.5853 3.66444 3.52385L4.47885 1.91193L6.29197 2.01211C6.36128 2.01451 6.42983 1.99659 6.48917 1.96038L8.00158 0.962308L9.51412 1.96038C9.57194 1.99987
            9.64162 2.01817 9.71131 2.01211L11.5243 1.91193L12.3388 3.52385C12.3696 3.5853 12.4195 3.63514 12.481 3.66593L14.0936 4.47991L13.9935 6.29208C13.991 6.36135
            14.0091 6.42987 14.0452 6.48905L15.0439 8.00079L14.0452 9.51253Z"
          fill="currentColor"
        />
        <path
          d="M8.02545 3.01953C5.27798 3.01953 3.04395 5.25356 3.04395 8.00104C3.04395 10.7486 5.27798 12.9827 8.02545 12.9827C10.7731 12.9827 13.0071 10.7486 13.0071
            8.00104C13.0071 5.25356 10.7731 3.01953 8.02545 3.01953ZM8.02545 12.3158C5.64476 12.3158 3.71082 10.3785 3.71082 8.00104C3.71082 5.6236 5.64476 3.68641 8.02545
            3.68641C10.4085 3.68641 12.3402 5.61813 12.3402 8.00104C12.3402 10.3839 10.4085 12.3158 8.02545 12.3158Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={myId}>
          <rect className={className} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
