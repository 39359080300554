import React from 'react';
import Alert from '../../Icons/Alert';
import Algolia from '../../Icons/Algolia';
import AmericanExpress from '../../Icons/AmericanExpress';
import ArrowNarrowUp from '../../Icons/ArrowNarrowUp';
import ArrowNarrowDown from '../../Icons/ArrowNarrowDown';
import ArrowRight from '../../Icons/ArrowRight';
import ArrowsUpDown from '../../Icons/ArrowsUpDown';
import Book from '../../Icons/Book';
import Bookmark from '../../Icons/Bookmark';
import Bullseye from '../../Icons/Bullseye';
import Calendar from '../../Icons/Calendar';
import Camera from '../../Icons/Camera';
import Certificate from '../../Icons/Certificate';
import Check from '../../Icons/Check';
import CheckmarkCircle from '../../Icons/CheckmarkCircle';
import ChevronDown from '../../Icons/ChevronDown';
import ChevronLeft from '../../Icons/ChevronLeft';
import ChevronRight from '../../Icons/ChevronRight';
import ChevronUp from '../../Icons/ChevronUp';
import ChevronDoubleLeft from '../../Icons/ChevronDoubleLeft';
import ChevronDoubleRight from '../../Icons/ChevronDoubleRight';
import ClipboardList from '../../Icons/ClipboardList';
import Clock from '../../Icons/Clock';
import CloseArrows from '../../Icons/CloseArrows';
import CloudLock from '../../Icons/CloudLock';
import Circle from '../../Icons/Circle';
import Community from '../../Icons/Community';
import CompletionCheck from '../../Icons/CompletionCheck';
import ContentAvailable from '../../Icons/ContentAvailable';
import Copy from '../../Icons/Copy';
import DinersClub from '../../Icons/DinersClub';
import Diamond from '../../Icons/Diamond';
import Discord from '../Navigation/Icons/DiscordIcon';
import Discover from '../../Icons/Discover';
import DotsHorizontal from '../../Icons/DotsHorizontal';
import DoubleChevronRight from '../../Icons/DoubleChevronRight';
import Download from '../../Icons/Download';
import Edit from '../../Icons/Edit';
import Elo from '../../Icons/Elo';
import Email from '../../Icons/Email';
import ExclamationCircle from '../../Icons/ExclamationCircle';
import ExclamationTriangle from '../../Icons/ExclamationTriangle';
import ExternalLink from '../../Icons/ExternalLink';
import Facebook from '../../Icons/Facebook';
import FaceFrown from '../../Icons/FaceFrown';
import Filter from '../../Icons/Filter';
import Flask from '../../Icons/Flask';
import GitHub from '../../Icons/GitHub';
import Globe from '../../Icons/Globe';
import Goal from '../../Icons/Goal';
import GoalFlag from '../../Icons/GoalFlag';
import GraduationCap from '../../Icons/GraduationCap';
import Grid from '../../Icons/Grid';
import Growth from '../../Icons/Growth';
import Handshake from '../../Icons/Handshake';
import HandWave from '../../Icons/HandWave';
import HelpIcon from '../../Icons/HelpIcon';
import Hacker from '../../Icons/Hacker';
import Hipercard from '../../Icons/Hipercard';
import Inbox from '../../Icons/Inbox';
import InformationCircle from '../../Icons/InformationCircle';
import Instagram from '../../Icons/Instagram';
import Keyboard from '../../Icons/Keyboard';
import License from '../../Icons/License';
import LineGraph from '../../Icons/LineGraph';
import Linkedin from '../../Icons/Linkedin';
import List from '../../Icons/List';
import Lock from '../../Icons/Lock';
import LogOut from '../../Icons/LogOut';
import Jcb from '../../Icons/Jcb';
import Maestro from '../../Icons/Maestro';
import Mastercard from '../../Icons/Mastercard';
import Mail from '../../Icons/Mail';
import Megaphone from '../../Icons/Megaphone';
import Mic from '../../Icons/Mic';
import Minus from '../../Icons/Minus';
import MinusCircle from '../../Icons/MinusCircle';
import Mountain from '../../Icons/Mountain';
import Newspaper from '../../Icons/Newspaper';
import Notebook from '../../Icons/Notebook';
import OnlineVideo from '../../Icons/OnlineVideo';
import PaperPlane from '../../Icons/PaperPlane';
import Path from '../../Icons/Path';
import Pause from '../../Icons/Pause';
import Pencil from '../../Icons/Pencil';
import PersonShield from '../../Icons/PersonShield';
import Play from '../../Icons/Play';
import Plus from '../../Icons/Plus';
import PlusCircle from '../../Icons/PlusCircle';
import QuestionCircle from '../../Icons/QuestionCircle';
import Refresh from '../../Icons/Refresh';
import Ribbon from '../../Icons/Ribbon';
import Ribbon2 from '../../Icons/Ribbon2';
import Road from '../../Icons/Road';
import Rocket from '../../Icons/Rocket';
import Rocket2 from '../../Icons/Rocket2';
import Search from '../../Icons/Search';
import SearchIcon from '../../Icons/SearchIcon';
import Selector from '../../Icons/Selector';
import Share from '../../Icons/Share';
import Shield from '../../Icons/Shield';
import Sitemap from '../../Icons/Sitemap';
import Spy from '../../Icons/Spy';
import Star from '../../Icons/Star';
import Sparkler from '../../Icons/Sparkler';
import Speedometer from '../../Icons/Speedometer';
import TeamsIcon from '../../Icons/TeamsIcon';
import Trash from '../../Icons/Trash';
import TrendingUp from '../../Icons/TrendingUp';
import TrendingDown from '../../Icons/TrendingDown';
import TrendingConstant from '../../Icons/TrendingConstant';
import Twitter from '../../Icons/Twitter';
import ThumbsDown from '../../Icons/ThumbsDown';
import ThumbsUp from '../../Icons/ThumbsUp';
import UnionPay from '../../Icons/UnionPay';
import Unlock from '../../Icons/Unlock';
import User from '../../Icons/User';
import UserGroup from '../../Icons/UserGroup';
import UserIcon from '../../Icons/UserIcon';
import UserMinus from '../../Icons/UserMinus';
import UserPlus from '../../Icons/UserPlus';
import UserWithStars from '../../Icons/UserWithStars';
import VisaCard from '../../Icons/VisaCard';
import Video from '../../Icons/Video';
import WorkingDev from '../../Icons/WorkingDev';
import Wrench from '../../Icons/Wrench';
import X from '../../Icons/X';
import XCircle from '../../Icons/XCircle';
import Trophy from '../../Icons/Trophy';
import LightningBolt from '../../Icons/LightningBolt';
import Fire from '../../Icons/Fire';
import Chat from '../../Icons/Chat';
import XP from '../../Icons/Xp';
import Expand from '../../Icons/Expand';
import Collapse from '../../Icons/Collapse';

function Icon({ name, active, className, accessibilityProps, solid }) {
  if (!name) {
    return null;
  }
  const iconMap = {
    alert: <Alert classes={className} accessibilityProps={accessibilityProps} />,
    algolia: <Algolia classes={className} accessibilityProps={accessibilityProps} />,
    'american-express': <AmericanExpress classes={className} accessibilityProps={accessibilityProps} />,
    'arrow-narrow-up': <ArrowNarrowUp classes={className} />,
    'arrow-narrow-down': <ArrowNarrowDown classes={className} />,
    'arrow-right': <ArrowRight classes={className} accessibilityProps={accessibilityProps} />,
    'arrows-up-down': <ArrowsUpDown classes={className} accessibilityProps={accessibilityProps} />,
    book: <Book classes={className} accessibilityProps={accessibilityProps} />,
    bookmark: <Bookmark active={active} classes={className} />,
    bullseye: <Bullseye classes={className} />,
    calendar: <Calendar classes={className} />,
    certificate: <Certificate classes={className} accessibilityProps={accessibilityProps} />,
    chat: <Chat classes={className} accessibilityProps={accessibilityProps} />,
    check: <Check active={active} classes={className} accessibilityProps={accessibilityProps} />,
    'checkmark-circle': <CheckmarkCircle classes={className} accessibilityProps={accessibilityProps} active={active} />,
    camera: <Camera classes={className} />,
    'chevron-down': <ChevronDown classes={className} accessibilityProps={accessibilityProps} />,
    'chevron-left': <ChevronLeft classes={className} />,
    'chevron-right': <ChevronRight classes={className} />,
    'chevron-up': <ChevronUp classes={className} accessibilityProps={accessibilityProps} />,
    'chevron-double-left': <ChevronDoubleLeft classes={className} accessibilityProps={accessibilityProps} />,
    'chevron-double-right': <ChevronDoubleRight classes={className} accessibilityProps={accessibilityProps} />,
    circle: <Circle classes={className} accessibilityProps={accessibilityProps} />,
    'clipboard-list': <ClipboardList classes={className} accessibilityProps={accessibilityProps} />,
    clock: <Clock classes={className} accessibilityProps={accessibilityProps} />,
    'cloud-lock': <CloudLock classes={className} accessibilityProps={accessibilityProps} />,
    'close-arrows': <CloseArrows classes={className} accessibilityProps={accessibilityProps} />,
    collapse: <Collapse classes={className} accessibilityProps={accessibilityProps} />,
    community: <Community classes={className} />,
    copy: <Copy classes={className} accessibilityProps={accessibilityProps} />,
    'completion-check': <CompletionCheck classes={className} accessibilityProps={accessibilityProps} />,
    'content-available': <ContentAvailable classes={className} accessibilityProps={accessibilityProps} />,
    diamond: <Diamond classes={className} accessibilityProps={accessibilityProps} />,
    'diners-club': <DinersClub classes={className} accessibilityProps={accessibilityProps} />,
    discord: <Discord classes={className} accessibilityProps={accessibilityProps} />,
    discover: <Discover classes={className} accessibilityProps={accessibilityProps} />,
    'dots-horizontal': <DotsHorizontal classes={className} />,
    'double-chevron-right': <DoubleChevronRight classes={className} accessibilityProps={accessibilityProps} />,
    download: <Download classes={className} accessibilityProps={accessibilityProps} />,
    edit: <Edit classes={className} />,
    elo: <Elo classes={className} accessibilityProps={accessibilityProps} />,
    'exclamation-circle': <ExclamationCircle active={active} classes={className} accessibilityProps={accessibilityProps} />,
    'exclamation-triangle': <ExclamationTriangle classes={className} accessibilityProps={accessibilityProps} />,
    expand: <Expand classes={className} accessibilityProps={accessibilityProps} />,
    'external-link': <ExternalLink classes={className} accessibilityProps={accessibilityProps} />,
    'face-frown': <FaceFrown classes={className} accessibilityProps={accessibilityProps} />,
    email: <Email classes={className} accessibilityProps={accessibilityProps} />,
    facebook: <Facebook classes={className} accessibilityProps={accessibilityProps} />,
    filter: <Filter active={active} classes={className} accessibilityProps={accessibilityProps} />,
    fire: <Fire classes={className} accessibilityProps={accessibilityProps} />,
    flask: <Flask classes={className} accessibilityProps={accessibilityProps} />,
    'git-hub': <GitHub classes={className} accessibilityProps={accessibilityProps} />,
    globe: <Globe classes={className} accessibilityProps={accessibilityProps} />,
    goal: <Goal classes={className} />,
    'goal-flag': <GoalFlag classes={className} />,
    'graduation-cap': <GraduationCap classes={className} accessibilityProps={accessibilityProps} />,
    grid: <Grid classes={className} accessibilityProps={accessibilityProps} />,
    growth: <Growth classes={className} accessibilityProps={accessibilityProps} />,
    hacker: <Hacker classes={className} accessibilityProps={accessibilityProps} />,
    handshake: <Handshake classes={className} accessibilityProps={accessibilityProps} />,
    'hand-wave': <HandWave classes={className} />,
    'help-icon': <HelpIcon active={active} classes={className} accessibilityProps={accessibilityProps} />,
    hipercard: <Hipercard classes={className} accessibilityProps={accessibilityProps} />,
    inbox: <Inbox classes={className} accessibilityProps={accessibilityProps} />,
    'information-circle': <InformationCircle classes={className} />,
    instagram: <Instagram classes={className} accessibilityProps={accessibilityProps} />,
    jcb: <Jcb classes={className} accessibilityProps={accessibilityProps} />,
    keyboard: <Keyboard classes={className} accessibilityProps={accessibilityProps} />,
    license: <License classes={className} accessibilityProps={accessibilityProps} />,
    'lightning-bolt': <LightningBolt classes={className} accessibilityProps={accessibilityProps} />,
    'line-graph': <LineGraph classes={className} accessibilityProps={accessibilityProps} />,
    linkedin: <Linkedin classes={className} accessibilityProps={accessibilityProps} />,
    list: <List classes={className} accessibilityProps={accessibilityProps} />,
    lock: <Lock classes={className} accessibilityProps={accessibilityProps} />,
    'log-out': <LogOut classes={className} accessibilityProps={accessibilityProps} />,
    maestro: <Maestro classes={className} accessibilityProps={accessibilityProps} />,
    mail: <Mail classes={className} />,
    mastercard: <Mastercard classes={className} accessibilityProps={accessibilityProps} />,
    megaphone: <Megaphone classes={className} accessibilityProps={accessibilityProps} solid={solid} />,
    mic: <Mic classes={className} accessibilityProps={accessibilityProps} />,
    minus: <Minus classes={className} />,
    'minus-circle': <MinusCircle active={active} classes={className} accessibilityProps={accessibilityProps} />,
    mountain: <Mountain classes={className} accessibilityProps={accessibilityProps} />,
    newspaper: <Newspaper classes={className} accessibilityProps={accessibilityProps} />,
    notebook: <Notebook classes={className} accessibilityProps={accessibilityProps} />,
    'online-video': <OnlineVideo classes={className} accessibilityProps={accessibilityProps} />,
    'paper-plane': <PaperPlane classes={className} accessibilityProps={accessibilityProps} />,
    path: <Path classes={className} accessibilityProps={accessibilityProps} />,
    pause: <Pause classes={className} accessibilityProps={accessibilityProps} />,
    pencil: <Pencil active={active} classes={className} accessibilityProps={accessibilityProps} />,
    'pencil-alt': <Edit classes={className} accessibilityProps={accessibilityProps} />,
    'person-shield': <PersonShield classes={className} accessibilityProps={accessibilityProps} />,
    play: <Play classes={className} accessibilityProps={accessibilityProps} />,
    plus: <Plus classes={className} accessibilityProps={accessibilityProps} />,
    'plus-circle': <PlusCircle active={active} classes={className} accessibilityProps={accessibilityProps} />,
    'question-circle': <QuestionCircle active={active} classes={className} accessibilityProps={accessibilityProps} />,
    refresh: <Refresh classes={className} accessibilityProps={accessibilityProps} />,
    ribbon: <Ribbon classes={className} accessibilityProps={accessibilityProps} />,
    'ribbon-2': <Ribbon2 classes={className} accessibilityProps={accessibilityProps} />,
    road: <Road classes={className} accessibilityProps={accessibilityProps} />,
    rocket: <Rocket classes={className} />,
    'rocket-2': <Rocket2 classes={className} accessibilityProps={accessibilityProps} />,
    search: <Search classes={className} accessibilityProps={accessibilityProps} />,
    'search-icon': <SearchIcon classes={className} accessibilityProps={accessibilityProps} />,
    selector: <Selector classes={className} />,
    share: <Share classes={className} />,
    shield: <Shield classes={className} />,
    sitemap: <Sitemap classes={className} accessibilityProps={accessibilityProps} />,
    spy: <Spy classes={className} accessibilityProps={accessibilityProps} />,
    star: <Star active={active} classes={className} />,
    sparkler: <Sparkler classes={className} />,
    speedometer: <Speedometer classes={className} accessibilityProps={accessibilityProps} />,
    'teams-icon': <TeamsIcon active={active} classes={className} accessibilityProps={accessibilityProps} />,
    'thumbs-down': <ThumbsDown active={active} classes={className} />,
    'thumbs-up': <ThumbsUp active={active} classes={className} />,
    trash: <Trash active={active} classes={className} accessibilityProps={accessibilityProps} />,
    'trending-up': <TrendingUp active={active} classes={className} accessibilityProps={accessibilityProps} />,
    'trending-down': <TrendingDown active={active} classes={className} accessibilityProps={accessibilityProps} />,
    'trending-constant': <TrendingConstant active={active} classes={className} accessibilityProps={accessibilityProps} />,
    trophy: <Trophy classes={className} accessibilityProps={accessibilityProps} />,
    twitter: <Twitter classes={className} accessibilityProps={accessibilityProps} />,
    unionpay: <UnionPay classes={className} accessibilityProps={accessibilityProps} />,
    unlock: <Unlock classes={className} />,
    user: <User classes={className} />,
    'user-group': <UserGroup classes={className} />,
    'user-icon': <UserIcon active={active} classes={className} accessibilityProps={accessibilityProps} />,
    'user-minus': <UserMinus classes={className} accessibilityProps={accessibilityProps} />,
    'user-plus': <UserPlus classes={className} accessibilityProps={accessibilityProps} />,
    'user-with-stars': <UserWithStars classes={className} accessibilityProps={accessibilityProps} />,
    visa: <VisaCard classes={className} accessibilityProps={accessibilityProps} />,
    video: <Video active={active} classes={className} />,
    'working-dev': <WorkingDev classes={className} accessibilityProps={accessibilityProps} />,
    wrench: <Wrench classes={className} accessibilityProps={accessibilityProps} />,
    x: <X active={active} classes={className} />,
    'x-circle': <XCircle active={active} classes={className} />,
    xp: <XP classes={className} />,
  };
  return iconMap[name] || null;
}

export default Icon;

export const ICON_NAMES = [
  'alert',
  'algolia',
  'american-express',
  'arrow-narrow-up',
  'arrow-narrow-down',
  'arrow-right',
  'arrows-up-down',
  'book',
  'bookmark',
  'bullseye',
  'calendar',
  'certificate',
  'chat',
  'check',
  'checkmark-circle',
  'camera',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'chevron-double-left',
  'chevron-double-right',
  'circle',
  'clipboard-list',
  'clock',
  'cloud-lock',
  'close-arrows',
  'collapse',
  'community',
  'copy',
  'completion-check',
  'content-available',
  'diamond',
  'diners-club',
  'discord',
  'discover',
  'dots-horizontal',
  'double-chevron-right',
  'download',
  'edit',
  'elo',
  'email',
  'exclamation-circle',
  'exclamation-triangle',
  'expand',
  'external-link',
  'facebook',
  'face-frown',
  'filter',
  'fire',
  'flask',
  'git-hub',
  'globe',
  'goal',
  'goal-flag',
  'graduation-cap',
  'grid',
  'growth',
  'hacker',
  'handshake',
  'hand-wave',
  'help-icon',
  'hipercard',
  'inbox',
  'information-circle',
  'instagram',
  'jcb',
  'keyboard',
  'license',
  'lightning-bolt',
  'line-graph',
  'linkedin',
  'list',
  'lock',
  'log-out',
  'maestro',
  'mail',
  'mastercard',
  'megaphone',
  'mic',
  'minus',
  'minus-circle',
  'mountain',
  'notebook',
  'newspaper',
  'online-video',
  'paper-plane',
  'path',
  'pause',
  'pencil',
  'pencil-alt',
  'person-shield',
  'play',
  'plus',
  'plus-circle',
  'question-circle',
  'refresh',
  'ribbon',
  'ribbon-2',
  'road',
  'rocket',
  'rocket-2',
  'search',
  'search-icon',
  'selector',
  'share',
  'shield',
  'sitemap',
  'speedometer',
  'spy',
  'star',
  'sparkler',
  'teams-icon',
  'thumbs-down',
  'thumbs-up',
  'trash',
  'trophy',
  'twitter',
  'unionpay',
  'unlock',
  'user',
  'user-group',
  'user-icon',
  'user-minus',
  'user-plus',
  'visa',
  'video',
  'working-dev',
  'wrench',
  'x',
  'x-circle',
  'xp',
];
