const vendorNameFilter = 'vendor.name';
const browseCertsAndVendors = [
  {
    id: 1,
    name: 'Cybrary',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/3WKjrt5O6AZZP2sPOsNP5V/8f04862cc87bb4b0d9b0bf9a5b5da263/Cybrary_Logo_2019-BlackCybrary.png?h=250',
    text: 'On Demand Video Courses and Assessments',
    filter: vendorNameFilter,
  },
  {
    id: 4,
    name: 'CyberVista',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/27hQ9MQFDpDk7ABFVAKInT/9b77f7a2375285aaee18df37cb55e4c3/cybervista_black_logo.png?h=250',
    text: 'IT & Cyber Security Certification Practice Tests',
    filter: vendorNameFilter,
    content_type_id: 14,
  },
  {
    id: 7,
    name: 'Skillable',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/3Ljt8DqJwauPXbEcqojjtI/0cd24b5a542becdd29e71c6e88d9d09a/Skillable-Logo.svg?h=250',
    text: 'Ready-built content across a variety of topics and technologies',
    filter: vendorNameFilter,
    content_type_id: 22,
  },
  {
    id: 8,
    name: 'Infosec Learning',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/4E1womIZLpaAyxyQelCf7g/d60bde8de10f49ebc7db82ec4efe1e2c/INFOSEC-final-logo-CMYK.png?h=250',
    text: 'Challenge-based cyber security hands-on labs',
    filter: vendorNameFilter,
    content_type_id: 34,
  },
  {
    id: 9,
    name: 'CompTIA',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/3Z8tDCAzvKBMFhUeoI4OFA/28e198f428f106ca1f43d5b4100d50d5/Comptia-logo.svg?h=250',
    text: 'Known for: CompTIA A+, Network+, Security+, CySA+, CASP',
    filter: 'groups_info',
    queryParam: 'certifications%7Ccomptia',
  },
  {
    id: 10,
    name: 'ISC2',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/7JtxG442xZCEPRVZnbQdPU/b45d9736e8708621fd41d0346dee1a47/ISC2.png?h=250',
    text: 'Known for: CISSP, CCSP, CSSLP',
    filter: 'groups_info',
    queryParam: 'certifications%7Cisc2',
  },
  {
    id: 11,
    name: 'ISACA',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/6bnTiWewbZNA0ijluTtxK/c1be714af5a656e0ac6c038599d709a7/ISACA_logo.png?h=250',
    text: 'Known for: CISM, CISA, CRISC',
    filter: 'groups_info',
    queryParam: 'certifications%7Cisaca',
  },
  {
    id: 12,
    name: 'EC-Council',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/3QZ5gWy2vuNTIo3f5vi74l/31464d4b813f16d41909cce27c6f51eb/browse-ec-council-logo.png?h=250',
    text: 'Known for: CEH (Certified Ethical Hacker)',
    filter: 'groups_info',
    queryParam: 'certifications%7Cec-council',
  },
  {
    id: 13,
    name: 'Cisco',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/IlFKghjTU0t2oUa6oD7mG/6c5a1d7a26147c89a9202277f62eed7a/Cisco_logo_blue_2016.svg?h=250',
    text: 'Known for: ICND1, ICND2, CyberOps',
    filter: 'groups_info',
    queryParam: 'certifications%7Ccisco',
  },
  {
    id: 14,
    name: 'Oracle',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/4ORUtUhElwRsR4meACOkU/70cd1c956ebd7050f2294cbf2e3dd31a/Oracle_logo.svg?h=250',
    text: 'Known for: Oracle Database',
    filter: 'groups_info',
    queryParam: 'certifications%7Coracle',
  },
  {
    id: 15,
    name: 'VMWare',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/5LjotxbcOl8gNKovoPFUbn/c0260a4264bf21628b26f4a7e881907b/browse-vmware-logo.png?h=250',
    text: 'Known for: VSphere',
    filter: 'groups_info',
    queryParam: 'certifications%7Cvmware',
  },
  {
    id: 16,
    name: 'Microsoft',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/19ufwN7ddeXmMDSYU4Nbwq/efd42fa68f20f5554a070180429bc46f/browse-microsoft-logo.png?h=250',
    text: 'Known for: MTA, Windows 10, Azure, Exchange Server, SQL Server',
    filter: 'groups_info',
    queryParam: 'certifications%7Cmicrosoft',
  },
  {
    id: 17,
    name: 'Axelos',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/3jZlMMlr5nURCm4KMGJMhn/58174fb50c631bde37266efb363adf73/browse-axelos-logo.png?h=250',
    text: 'Known for: ITIL',
    filter: 'groups_info',
    queryParam: 'certifications%7Citil',
  },
  {
    id: 18,
    name: 'PMI',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/GTWqAzLwBDoU6rBdyrwrr/a14c49fd0386dad69cef0071b8268054/browse-pmi-logo.png?h=250',
    text: 'Known for: CAPM, PMP (Project Management Professional)',
    filter: 'groups_info',
    queryParam: 'certifications%7Cpmi',
  },
  {
    id: 19,
    name: 'Logical Operations',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/3qcshGqH0Luj7Xgqvr2YgC/0b08f740ab17d962aef417823ad725f5/browse-logical-operations-logo.png?h=250',
    text: 'Known for: CyberSec First Responder',
    filter: 'groups_info',
    queryParam: 'certifications%7CCyberSec',
  },
  {
    id: 20,
    name: 'Azure',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/2idI4b1fMe8e1YilduD5R4/3bcb28707fa78c504fcf1e80ad390e51/Microsoft_Azure_Logo.svg?h=250',
    text: `Microsoft's cloud computing platform`,
    filter: 'groups_info',
    queryParam: 'certifications%7Cmicrosoft-azure',
  },
  {
    id: 21,
    name: 'AWS',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/2IUtLO7BTETEBaQjlX0aYD/7a8e806dd6efe515eefeccb535dfb12f/Amazon_Web_Services_Logo.svg?h=250',
    text: `Amazon's cloud platform`,
    filter: 'groups_info',
    queryParam: 'certifications%7Caws',
  },
  {
    id: 22,
    name: 'Kali Linux',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/7rjprOg5O138gkbOQaQm1s/fa3d8a4adc5ca59717b7d582ec8b73a5/browse-kali-logo.png?h=250',
    text: 'Linux distribution for penetration testing and security auditing',
    filter: 'q',
    queryParam: 'kali%20linux',
  },
  {
    id: 23,
    name: 'Mitre',
    imgPath: 'https://images.ctfassets.net/kvf8rpi09wgk/3RFPuDfcdS9Ol6eslNrkwD/4c5c3b3c435f0d681325e8b503687424/MITRE_Engenuity_logo_tertiary_RGB_purple.png?h=250',
    text: 'Known for: MITRE ATT&CK Defender (MAD)',
    filter: 'groups_info',
    queryParam: 'certifications%7Cmitre-engenuity',
  },
];

const vendors = [1, 2, 3, 4, 5, 6, 7, 8];
const certifications = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

export default class BrowseUtil {
  static getSectionDescription = (sectionNiceName) => {
    const description = {
      short: '',
      long: '',
    };
    /* 
     These are the filtered view descriptions of browse refined...
     i.e. The black "hero" banner found at /browse/refined?resultsView=grid&view=careerPath
     will read what is in the long description. And the Title & description below that will come from 
     the long description 
    */
    switch (sectionNiceName) {
      case 'Career Paths':
        description.short = 'Follow development plans tailored to in-demand cybersecurity roles';
        description.long = 'Follow a structured curriculum geared to help you develop the knowledge and skills you need for different cybersecurity roles';
        break;
      case 'Collections':
        description.short = 'Follow curated collections of thematically-aligned content';
        description.long = 'Follow curated collections of thematically-aligned content';
        break;
      case 'Virtual Labs':
        description.short = 'Get hands-on experience with the latest tools and technologies';
        description.long = 'Work through real-world scenarios and hone your cybersecurity skills with the latest tools and technologies in a secure virtual lab environment';
        break;
      case 'Courses':
        description.short = 'Learn job-ready skills from leading cybersecurity experts';
        description.long = 'Build your cybersecurity skills with courses developed and led by leading cybersecurity experts';
        break;
      case 'Skill Assessments':
        description.short = 'Identify your strengths and weaknesses to focus your learning';
        description.long = 'Gauge your knowledge, skills, and abilities with targeted assessments to identify areas for improvement and measure your growth';
        break;
      case 'Practice Tests':
        description.short = 'Make sure you’re ready to pass your certification exam';
        description.long = 'Build your confidence and ensure your readiness to pass a certification exam with industry-leading practice tests tied to key learning objectives';
        break;
      case 'New Releases':
        description.short = 'Take a look at new content we’ve recently added to the platform';
        break;
      case 'Coming Soon':
        description.short = 'Keep an eye out for this exciting content we’ll be adding soon';
        break;
      case 'Certification Bodies':
        description.short = 'Find the certification resources you need by certification body';
        break;
      case 'Learning Partners':
        description.short = 'Access content from top cybersecurity experts and leading organizations';
        break;
      default:
        return description;
    }
    return description;
  };

  static getBrowseCertsAndVendors = () => {
    return browseCertsAndVendors;
  };

  static getVendors = () => {
    return browseCertsAndVendors.filter((vendor) => vendors.includes(vendor.id));
  };

  static getCertifications = () => {
    return browseCertsAndVendors.filter((certification) => certifications.includes(certification.id));
  };

  // Omit certifications that come from groups_info array and check what we have stored in the browseCertAndVendors array
  static omitDynamicCertifications = (certs, localCert) => {
    const newCopy = [];

    if (localCert && localCert.length && certs && certs.length) {
      localCert.forEach((cert) => {
        certs.forEach((param) => {
          const dynamicCertQueryParam = param.toLowerCase().replace(' ', '-').replace('|', '%7C');
          if (cert.queryParam === dynamicCertQueryParam) {
            newCopy.push(cert);
          }
        });
      });
    }

    return newCopy;
  };

  static checkForCertifications = (groupsInfo) => {
    return groupsInfo ? groupsInfo.filter((cert) => /certifications\|/gim.test(cert)) : [];
  };
}
