import React from 'react';
import { Link } from 'react-router-dom';

const isExternal = (path) => {
  return /^http/.test(path);
};

const isApiCoursesLink = (path) => {
  return /^\/courses\/api/.test(path);
};

function AddLink(props) {
  if (!props.to || !props.to.length) {
    if (props.onClick) {
      return <button {...props}>{props.children}</button>;
    }
    return <div {...props}>{props.children}</div>;
  }

  if (isExternal(props.to)) {
    const newWindowSrText = props.target && props.target === '_blank' ? <span className="sr-only">This link opens a new window</span> : null;
    return (
      <a href={props.to} {...props}>
        {props.children} {newWindowSrText}
      </a>
    );
  }

  if (isApiCoursesLink(props.to)) {
    const path = props.to.replace('/courses/api', '');
    return (
      <a href={`${process.env.REACT_APP_COURSE_API_URL}${path}`} {...props}>
        {props.children}
      </a>
    );
  }

  return <Link {...props}>{props.children}</Link>;
}

export default AddLink;
