import React from 'react';
import ScrollIntoView from 'react-scroll-into-view';
import { getCN } from '../../utils/formatUtil';

// this component is currently designed for anchoring to a different part of the page
export default function ContentTabs({ tabs, activeTab, setActiveTab, wrapperClasses, position = 'left' }) {
  if (!tabs || !tabs.length) {
    return null;
  }
  const isActive = (tab) => {
    return tab.name === activeTab || (tab.id && tab.id === activeTab);
  };

  const activeTabClasses = 'border-b-2 border-cyb-pink-500 pb-3';
  // font weight is not inherited in <button> so we add manually
  const activeTabTextClasses = 'text-black font-semibold';
  const notActiveTabTextClasses = 'text-normal text-gray-600';
  const gridClass = ['grid-cols-1', 'grid-cols-2', 'grid-cols-3'];
  const positionClass = {
    left: 'flex',
    center: `grid ${gridClass[tabs.length - 1]}`,
  };

  const tabsWrapper = getCN(`
    lg:gap-8
    ${positionClass[position]}
    ${wrapperClasses}
    fixed lg:sticky bottom-0 left-0 flex justify-around lg:justify-start lg:border-b-xs border-gray-400  bg-white w-full z-10 lg:z-0
  `);

  return (
    <ul id="content-tabs" className={tabsWrapper}>
      {tabs.map((tab) => {
        const accessibilityProps = isActive(tab) ? { 'aria-current': 'page' } : {};
        return (
          <li key={tab.name} className={`${isActive(tab) ? activeTabClasses : ''} w-full lg:w-auto text-sm text-center py-4 mx-4 lg:mx-0`}>
            <ScrollIntoView selector={`${tab.scrollSelector}`} scrollOptions={{ block: 'start' }}>
              <button type="button" className={`${isActive(tab) ? activeTabTextClasses : notActiveTabTextClasses}`} onClick={() => setActiveTab(tab.name)} {...accessibilityProps}>
                {tab.name}
              </button>
            </ScrollIntoView>
          </li>
        );
      })}
    </ul>
  );
}
