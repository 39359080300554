import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import Agents from '../../agents/agents';
import Loading from '../../components/Loading/Loading';
import TeamHeader from '../../components/AdminTeamActivityLogs/TeamHeader';
import CybraryAdminActivityLog from '../../components/AdminTeamActivityLogs/CybraryAdminActivityLog';
import Container from '../../components/Container/Container';
import Divider from '../../components/Divider/Divider';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

function TeamActivityLog() {
  const params = useParams();
  const [teamData, setTeamData] = useState({});
  const [teamLoading, setTeamLoading] = useState({});

  const fetchTeamData = async () => {
    try {
      const response = await Agents.admin.getTeamData(params.id);
      setTeamData(response);
      setTeamLoading(false);
    } catch (e) {
      Bugsnag.notify(e);
    }
  };

  useEffect(() => {
    // Fetch team data
    fetchTeamData();
  }, []);

  const breadcrumbs = [
    { label: 'Browse Teams', href: '/admin/teams' },
    { label: 'View Team', href: `/admin/view-team/${params.id}` },
  ];

  if (teamLoading) {
    return (
      <div className="py-8">
        <Loading message="Loading..." />
      </div>
    );
  }

  return (
    <Container size="xl" className="py-12">
      <Breadcrumbs crumbs={breadcrumbs} />
      <TeamHeader data={teamData} />
      <Divider />
      <CybraryAdminActivityLog team={teamData} />
    </Container>
  );
}

export default TeamActivityLog;
