import { v4 as uuidv4 } from 'uuid';

/*
  Nav items here are available only for NON-enterprise users 
  based on specific display logic located inside of each object
*/
export const TEAMS_DEMO_NAV = {
  label: 'Teams Demo',
  icon: 'teams',
  href: '/enterprise/demo/organization/welcome',
  display: () => false, // Disabled for now... pass the `isNonEnterpriseUser` function in '../utils/navUtil' to enable
  startsWith: true,
  id: uuidv4(),
  children: [
    {
      label: 'Welcome',
      href: '/enterprise/demo/organization/welcome',
      id: uuidv4(),
    },
    {
      label: 'Dashboard',
      href: '/enterprise/demo/organization/dashboard',
      id: uuidv4(),
    },
    {
      label: 'People',
      href: '/enterprise/demo/organization/members',
      id: uuidv4(),
      children: [
        {
          label: 'Members',
          href: '/enterprise/demo/organization/members',
          id: uuidv4(),
        },
        {
          label: 'Groups',
          href: '/enterprise/demo/organization/groups',
          disabled: true,
          startsWith: true,
          id: uuidv4(),
        },
      ],
    },
  ],
  group: 'main',
};
