import React from 'react';
import { observer } from 'mobx-react';
import Header from '../Header/Header';
import DropdownFilter from '../Dropdown/DropdownFilter';
import NestedContentTables from '../AdminTable/NestedContentTables';
import FormatUtil from '../../utils/formatUtil';
import CatalogUtil from '../../utils/catalogUtil';
import Loading from '../Loading/Loading';
import Segment from '../Segment/Segment';
import StyledError from '../Error/StyledError';
import AddLink from '../AddLink/AddLink';
import ReportHeadingTooltip from './ReportHeadingTooltip';
import Container from '../Container/Container';
import { HELP_DESK_LINKS } from '../../constants';

export default observer(
  class ContentActivityTable extends React.Component {
    tableSort = (heading) => {
      const { filterParams } = this.props;
      if (heading) {
        if (heading === filterParams.sortCol) {
          filterParams.sortDirection = filterParams.sortDirection === 'desc' ? 'asc' : 'desc';
        } else {
          filterParams.sortCol = heading;
          filterParams.sortDirection = 'desc';
        }
        filterParams.activePg = 1;
        this.props.getActivityTableData(filterParams, this.props.filterValue);
      }
    };

    filterChange = ({ value }) => {
      const { filterParams } = this.props;
      filterParams.sortDirection = 'asc';
      filterParams.sortCol = 'content_description_title';
      filterParams.activePg = 1;
      filterParams.recordsPerPage = 20;
      this.props.getActivityTableData(filterParams, value);
    };

    checkIsCompleteAssessment = (contentType, progress) => {
      const modalityType = FormatUtil.formatContentType(contentType);
      return ['Assessment', 'Practice Test'].indexOf(modalityType) > -1 && 1 * progress === 100;
    };

    checkExpandTable = (row, headings, parentContentType) => {
      const progressIdx = FormatUtil.getColIndex(headings, 'progress');
      const progress = progressIdx > -1 ? row[progressIdx].value : null;
      const typeIdx = FormatUtil.getColIndex(headings, 'content_description_type');
      const contentType = typeIdx > -1 ? row[typeIdx].value : null;
      const isCompleteAssessment = this.checkIsCompleteAssessment(contentType, progress);
      if (
        ((contentType === 'Course Module' || contentType === 'Course' || CatalogUtil.checkIfCourseType(contentType) || contentType === 'Career Path') &&
          this.props.expandFunction) ||
        // We only want to show the score breakdown/expansion when we're inside of a collection
        ((parentContentType === 'Career Path' || parentContentType === 'Curriculum') && isCompleteAssessment && this.props.getAllScores)
      ) {
        return true;
      }
      return false;
    };

    showRowExpansion = (row, headings, parentContentType) => {
      if (this.checkExpandTable(row, headings, parentContentType)) {
        const progressIdx = FormatUtil.getColIndex(headings, 'progress');
        const progress = progressIdx ? row[progressIdx].value : null;
        const typeIdx = FormatUtil.getColIndex(headings, 'content_description_type');
        const contentType = typeIdx ? row[typeIdx].value : null;
        const isCompleteAssessment = this.checkIsCompleteAssessment(contentType, progress);
        const isParentCollection = parentContentType === 'Career Path' || parentContentType === 'Curriculum';
        const positionIdx = FormatUtil.getColIndex(headings, 'position'); // Get's the parent level idx when getting expanded children rows.
        const positionValue = row[positionIdx] ? row[positionIdx].value : null;
        // We only want to show the score breakdown/expansion when inside a collection
        if (isParentCollection && isCompleteAssessment) {
          const tableProps = {
            getMethod: (data) => this.props.getAllScores(data.content_description_id),
          };
          return this.getScoreBreakdownTable(contentType, tableProps, row, headings);
        }
        const tableProps = {
          getMethod: this.props.expandFunction,
          omitHeadings: true,
        };
        return this.getNestedTable(null, row, headings, tableProps, positionValue);
      }
      return null;
    };

    getScoreBreakdownTable = (contentType, tableProps, row, headings) => {
      const contentDescriptionIdIdx = FormatUtil.getColIndex(headings, 'content_description_id');
      const contentDescriptionId = contentDescriptionIdIdx > -1 ? row[contentDescriptionIdIdx].value : null;
      return (
        <NestedContentTables
          {...tableProps}
          tableKey={this.props.tableKey}
          displayCheckBox={false}
          contentDescriptionId={contentDescriptionId}
          getWorkroleIcons={this.props.getWorkroleIcons}
          formatDate={this.props.formatDate}
          parentContentType={contentType}
        />
      );
    };

    pagChangePg = (pg) => {
      const { filterParams } = this.props;
      filterParams.activePg = pg;
      this.props.getActivityTableData(filterParams, this.props.filterValue);
    };

    getNestedTable = (contentId, row, headings, tableProps, positionValue) => {
      const contentTypeIdx = FormatUtil.getColIndex(headings, 'content_description_type');
      const contentType = !!contentTypeIdx && contentTypeIdx > -1 ? row[contentTypeIdx].value : null;
      const contentDescriptionId =
        contentId ||
        (!!row && row[FormatUtil.getColIndex(headings, 'content_description_id')] && row[FormatUtil.getColIndex(headings, 'content_description_id')].value
          ? row[FormatUtil.getColIndex(headings, 'content_description_id')].value
          : null);

      const enrollmentId =
        !!row && row[FormatUtil.getColIndex(headings, 'id')] && row[FormatUtil.getColIndex(headings, 'id')].value ? row[FormatUtil.getColIndex(headings, 'id')].value : null;
      return (
        <NestedContentTables
          {...tableProps}
          enrollmentId={enrollmentId}
          tableKey={this.props.tableKey}
          displayCheckBox={false}
          enrollment={this.props.enrollment}
          contentDescriptionId={contentDescriptionId}
          fillEmptyCols="-"
          showSummary={this.props.showSummary}
          checkCerts={this.props.checkCerts}
          parentContentType={contentType}
          getWorkroleIcons={this.props.getWorkroleIcons}
          formatDate={this.props.formatDate}
          parentIdx={positionValue}
          learningHoursCalc={this.props.learningHoursCalc}
          progressFormat={this.props.progressFormat}
          titleTagFormat={this.props.titleTagFormat}
          clickToExpand={{
            condition: (tableRow, columns) => this.checkExpandTable(tableRow, columns, contentType),
            method: (tableRow, columns) => this.showRowExpansion(tableRow, columns, contentType),
          }}
        />
      );
    };

    getActivityTable = () => {
      const loading = this.props.loading || this.props.certData.loading;
      if (loading) {
        return (
          <Container>
            <Loading message="Loading..." />
          </Container>
        );
      }
      if (this.props.error) {
        return (
          <Container>
            <StyledError error={this.props.error} />
          </Container>
        );
      }
      const noResultsMsg = this.props.noResultsMsg ? this.props.noResultsMsg : 'There are no activities to show';
      const tableProps = {
        data: this.props.data,
        headerClickFunc: !this.props.omitSort ? this.tableSort : null,
        sortCol: this.props.filterParams.sortCol,
        sortDirection: this.props.filterParams.sortDirection,
        omitHeadings: false,
        noResultsMsg,
        pagNumPgs: this.props.data.pagPagesCount,
        pagChangePg: this.pagChangePg,
        pagActivePg: this.props.filterParams.activePg,
        showUnenrollIcon: this.props.showUnenrollIcon,
      };

      return this.getNestedTable(null, null, null, tableProps);
    };

    render() {
      return (
        <Segment className="px-0 border-none member-dashboard-learning" style={{ border: 'none', paddingLeft: 0, paddingRight: 0 }}>
          <div className="grid grid-cols-2">
            <div>
              {this.props.heading && (
                <Header as="h2" className="float-left mr-2 mb-6 text-2xl font-black">
                  {this.props.heading}
                </Header>
              )}
              {this.props.headingTooltip && <ReportHeadingTooltip />}
            </div>
            <div>
              {!this.props.hideFilters && (
                <div className="flex gap-x-4 justify-end items-center w-full">
                  <span>Filters:</span>
                  <DropdownFilter
                    placeholder={this.props.filterPlaceholder}
                    options={this.props.filterOptions}
                    value={this.props.filterValue}
                    onChange={this.filterChange}
                    classes="filter-dropdown w-48 text-left"
                  />
                </div>
              )}
            </div>
          </div>
          {this.props.showCourseProgressLink && (
            <div className="progress-message">
              <AddLink target="_blank" to={HELP_DESK_LINKS.VENDOR_REPORTING_PROGRESS}>
                When will my progress update?
              </AddLink>
            </div>
          )}
          <div className="learning-container">{this.getActivityTable()}</div>
        </Segment>
      );
    }
  }
);
