/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect, useContext, createContext } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { initializeApp, getApp } from 'firebase/app';
import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {
  ProviderId,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  isSignInWithEmailLink,
  signInWithEmailLink,
  GoogleAuthProvider,
  signInWithRedirect,
  FacebookAuthProvider,
  OAuthProvider,
  SAMLAuthProvider,
  applyActionCode,
  signInWithCustomToken,
  getRedirectResult,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import queryString from 'query-string';
import { PUBLIC_PAGES, CHARGEBEE_ANNUAL_TEAMS_PLANS, DEFAULT_COOKIE_SETTINGS } from '../constants';
import agents from '../agents/agents';
import AuthUtil from '../utils/authUtil';
import { trackSnowplowEvent } from '../utils/snowplowUtil';

const FIREBASE_APP_NAME_KEY = 'firebase-app-name';
const FIREBASE_DEFAULT_APP = '[DEFAULT]';
const FIREBASE_CUSTOM_DOMAIN_APP = '[CUSTOM_DOMAIN]';
const FIREBASE_DOMAIN_MAP = {
  [process.env.REACT_APP_FIREBASE_DOMAIN]: FIREBASE_DEFAULT_APP,
  [process.env.REACT_APP_FIREBASE_DOMAIN_CUSTOM]: FIREBASE_CUSTOM_DOMAIN_APP,
};

// Init vars to prevent collision & dup efforts in firebase listener
let isCheckingCustomToken = false;
let isSettingFirebaseUser = false;
let isSigningOut = false;

// 401/403 retry tracking vars
let retry401 = false;
/** let retry403 = false; */

const configs = {};
configs[FIREBASE_DEFAULT_APP] = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
};

configs[FIREBASE_CUSTOM_DOMAIN_APP] = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN_CUSTOM,
};

const firebaseDefaultApp = initializeApp(configs[FIREBASE_DEFAULT_APP]);
const firebaseCustomApp = initializeApp(configs[FIREBASE_CUSTOM_DOMAIN_APP], FIREBASE_CUSTOM_DOMAIN_APP);

const getFirebaseAuth = () => {
  const name = sessionStorage.getItem(FIREBASE_APP_NAME_KEY) || FIREBASE_DEFAULT_APP;
  const app = getApp(name);
  return getAuth(app);
};

const setProviderDomain = ({ provider_domain }) => {
  sessionStorage.setItem(FIREBASE_APP_NAME_KEY, FIREBASE_DOMAIN_MAP[provider_domain] || FIREBASE_DEFAULT_APP);
};

const resetProviderDomain = () => {
  sessionStorage.removeItem(FIREBASE_APP_NAME_KEY);
};

export const authContext = createContext();

const usesSamlProvider = (jwt) => {
  if (!jwt) {
    return false;
  }
  const decoded = jwtDecode(jwt);
  const isSamlProvider = decoded?.firebase?.sign_in_provider.indexOf('saml') === 0;
  const isCustomSamlProvider = decoded?.firebase?.sign_in_provider === 'custom' && decoded?.custom?.sign_in_provider?.startsWith('saml');
  return isSamlProvider || isCustomSamlProvider;
};

const getSsoHandling = async (jwt) => {
  const decoded = jwtDecode(jwt);
  const isSSOProviderUsed = usesSamlProvider(jwt);
  const ssoHandling = {};
  // Check if user is SSO and does NOT have a UID
  if (isSSOProviderUsed && !decoded.uid) {
    const accountExists = await agents.authGoogle.accountExists(jwt);
    if (accountExists.error) {
      ssoHandling.error = accountExists.error;
    }
    ssoHandling.canLinkAccount = accountExists.exists === false;
  }
  return ssoHandling;
};

const getProvider = (providerId) => {
  let provider = null;
  switch (providerId) {
    case ProviderId.GOOGLE:
      provider = new GoogleAuthProvider();
      break;
    case ProviderId.FACEBOOK:
      provider = new FacebookAuthProvider();
      provider.addScope('email');
      break;
    case 'microsoft.com':
      provider = new OAuthProvider('microsoft.com');
      break;
    case 'apple.com':
      provider = new OAuthProvider('apple.com');
      break;
    case 'linkedin.com':
      provider = new OAuthProvider('linkedin.com');
      break;
    default:
      provider = new SAMLAuthProvider(providerId);
  }
  return provider;
};

const handleProviderLogin = (provider) => {
  const authProvider = getProvider(provider.name);
  setProviderDomain(provider);
  const auth = getFirebaseAuth();
  sessionStorage.setItem('signInWithRedirect', true);
  return signInWithRedirect(auth, authProvider);
};

const handleRememberMe = (remember, email) => {
  if (remember) {
    Cookies.set('loginRememberMeEmail', email, {
      ...DEFAULT_COOKIE_SETTINGS,
      expires: 365,
    }); // Set expiration a year from now
  } else {
    // Delete cookie
    Cookies.remove('loginRememberMeEmail');
  }
};

// Set the userStore data so components using that data work as they always have
// To be stripped out when ready to refactor majority of app to use functional comps and this context rather than store
const setUserStoreData = (userStore, data, loadUserPreferences = false) => {
  if (data) {
    // Set License/Permissions
    const permissions = userStore.transformLicensesToPermissions(data.userLicenses);
    userStore.setPermissions(permissions);
    userStore.setLicenses(data.userLicenses);
    // Set User data
    userStore.setUser(data.userMeta);
    userStore.setUserMetaCookie(data.userMeta);
    userStore.setAvatarUrl(data.userMeta.avatar_url);

    // Set bookmarks and user preferences (level and category)
    if (loadUserPreferences) {
      Promise.all([userStore.getCategoryAndLevelPreferences(), userStore.getBookmarks()]);
    }

    // set CIP subscription status in local storage
    if (data.newSubscription) {
      const subscriptionStatus = JSON.stringify(data.newSubscription);
      window.localStorage.setItem('newSubscription', subscriptionStatus);
    }

    // Set team data
    userStore.setUserTeams(data.userTeams);
    if (userStore.isEnterprise) {
      userStore.setPreferredTeam();
      userStore.setPreferredGroups();
    }
  }
  return null;
};

const handle401Error = (error, signout) => {
  Bugsnag.leaveBreadcrumb(`401 Error - ${error.config.headers.Authorization}`);
  if (!retry401) {
    retry401 = true;
    return axios.request(error.config).then((response) => {
      Bugsnag.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = `Successfully retried a 401 (Request received false 401 - ${error.config.url}`;
      });
      retry401 = false;
      return response;
    }); // NO catch -- Should go through this same interceptor and log user out if 401 or return other errors
  }
  // Otherwise, logout
  Bugsnag.notify(error, (event) => {
    // eslint-disable-next-line no-param-reassign
    event.context = `Retry 401 failed - ${error.config.url}`;
  });
  signout();
  return null;
};

/** const handle403Error = (error) => {
  if (!retry403) {
    retry403 = true;
    return axios.request(error.config).then((response) => {
      if (response && response.data && response.data.error) {
        retry403 = false;
        return Promise.reject(error);
      }
      Bugsnag.notify(new Error(`Successfully retried a 403 (Request received false 403 - ${error.config.url}`));
      retry403 = false;
      return response;
    });
  }
  // retry403 was set, so we were already retrying once.
  // If here, assumed the retry failed. Return error response and reset var for next potential 403
  retry403 = false;
  return null;
}; */

const setupErrorInterceptors = (authStore, signout) => {
  axios.interceptors.response.use(
    (response) => response && response.data,
    (error) => {
      if (error.response) {
        // If 401 or 403, retry once
        // If this is a public page (e.g Login, verification-code, etc.) do not retry & redirect the user to /login on 401 - Should be handled via component level messaging
        const isPublicPage = PUBLIC_PAGES.includes(window.location.pathname);
        if (error.response.status === 401 && !isPublicPage && !AuthUtil.isDevEnvironment()) {
          return handle401Error(error, signout);
        }
        /** if (error.response.status === 403) {
          return handle403Error(error);
        } */
      }
      return Promise.reject(error);
    }
  );
};

const getRedirectUrl = (location, userStore) => {
  // NOTE: There is a redirect to TOS page in PrivateRoute.js too. That is because we want the user to accept TOS before they can do anything
  // So anywhere the user tries to navigate will be intercepted to TOS until completed
  const { team, isNewUser } = userStore;

  // Failed new CIP subscription redirect
  // if the user has come from www/upgrade/checkout and processing their payment has failed on first login
  // send the user to app/upgrade/checkout and display that error
  const newSubscription = userStore.fetchSubscriptionStatus();
  const subscriptionStatus = newSubscription && newSubscription.status;
  const subscriptionPlanId = newSubscription && newSubscription.planId;
  const hasSubscriptionError = subscriptionStatus === 'fail';
  if (hasSubscriptionError) {
    return CHARGEBEE_ANNUAL_TEAMS_PLANS.includes(subscriptionPlanId) ? '/upgrade/teams-checkout' : '/upgrade/checkout';
  }

  // If there's a param explicitly telling where to redirect to, lets send them there
  const queryParams = queryString.parse(location.search);
  if (queryParams.redirect_to) {
    return queryParams.redirect_to;
  }

  // If new team user with a new subscription (assumed they purchased a team) - Send them to their members page
  if (team && newSubscription) {
    return `/enterprise/${team.id}/organization/members?newSubscription=1`;
  }

  // If the user signed up after viewing a course page, let's send them to the app version of that page
  const lastCourseViewed = Cookies.get('lastCourseViewed');
  if (lastCourseViewed && isNewUser) {
    Cookies.remove('lastCourseViewed', { path: '/', domain: '.cybrary.it' });
    return `${lastCourseViewed}/`;
  }

  return '/';
};

const handleSocialError = (e) => {
  Bugsnag.notify(e);

  trackSnowplowEvent({
    category: 'Oauth Authentication',
    action: 'failure',
    label: 'Failed to authenticate with provider',
    property: e,
  });

  // When user tries to login via social using an email already set on an account with UN/PW
  if (e.code === 'auth/account-exists-with-different-credential') {
    const queryParams = queryString.parse(window.location.search);
    queryParams.status = 'error';
    queryParams.action = e.code;
    window.location.href = `${window.location.pathname}?${queryString.stringify(queryParams)}`;
  }
};

const handleSignoutRedirect = (redirectTo) => {
  if (redirectTo) {
    window.location.href = redirectTo;
  } else {
    const currentPath = window.location.pathname + window.location.search;
    const redirectUrl = currentPath.length > 1 ? encodeURIComponent(window.location.pathname + window.location.search) : null;
    let redirectUri = `${process.env.REACT_APP_LOGIN_PATH}${redirectUrl ? `?redirect_to=${redirectUrl}` : ''}`;
    if (/logout/.test(window.location.pathname)) {
      redirectUri = process.env.REACT_APP_LOGIN_PATH;
    }
    window.location.href = AuthUtil.isDevEnvironment() ? '/local-login' : redirectUri;
  }
};

// The actual logic to provide the hook, think of this as a react component that doesn't render anything, just provides an object
// with functionality for other components to leverage
function useProvideAuth(userStore, authStore) {
  const [authReady, setAuthReady] = useState(false);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ssoError, setSsoError] = useState(null);
  const navigate = useNavigate();

  const resetUser = () => {
    setUser(null);
    setUserData(null);
    userStore.resetUser();
  };

  Promise.all([setPersistence(getAuth(firebaseCustomApp), browserLocalPersistence), setPersistence(getAuth(firebaseDefaultApp), browserLocalPersistence)]).then(() => {
    setAuthReady(true);
  });

  // Setup any functions that we want to export (for now, just signout)
  const signout = (skipRedirect, redirectTo) => {
    // If we're already signing out, don't repeat. Prevents private route logout from jumping in and interferring if any special handling occurring
    if (isSigningOut) {
      return new Promise((resolve) => {
        resolve();
      });
    }
    isSigningOut = true;
    const auth = getFirebaseAuth();
    return signOut(auth).then(() => {
      // Destroy the jwt
      authStore.clearSession();
      resetUser();
      resetProviderDomain();
      // Handle redirect
      if (!skipRedirect) {
        handleSignoutRedirect(redirectTo);
        isSigningOut = false;
      }
    });
  };

  // Verify Register Code
  const submitRegisterCode = (data) => agents.authGoogle.verifyCode(data);

  // Handle the request for Reset password
  const submitResetPassword = (data) => agents.authGoogle.passwordReset(data);

  // Handle the request to get a provider for the email address
  const getProviderForEmail = (email) => agents.authGoogle.getIdp({ email });

  // Handle the request for forgot password
  const submitForgotPassword = (data) => agents.authGoogle.passwordResetEmail(data);

  // Handle the request for Reset password
  const handleActioncode = (oob) => {
    const auth = getFirebaseAuth();
    return applyActionCode(auth, oob);
  };

  // Handle the request for sending user the magic login link
  const sendMagicLink = (email) => {
    const payload = {
      email,
      actionSettings: {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: window.location.origin, // https://app.cybrary.it, https://app.blackwaterbay.cybrary.it, http://localhost:3000
        // This must be true.
        handleCodeInApp: true,
      },
    };
    return agents.authGoogle.sendMagicEmail(payload).then(() => {
      // Save the email locally so you don't need to ask the user for it again if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
    });
  };

  const checkIsSignInWithEmailLink = (href) => {
    const auth = getFirebaseAuth();
    return isSignInWithEmailLink(auth, href);
  };

  const loginInWithEmailLink = (email, href) => {
    const auth = getFirebaseAuth();
    return signInWithEmailLink(auth, email, href);
  };

  // Sign in to google auth with email and password
  const signInWithPassword = (email, password, remember) => {
    handleRememberMe(remember, email);
    const auth = getFirebaseAuth();
    return signInWithEmailAndPassword(auth, email, password);
  };

  /* Refresh user data in userStore and auth using fresh data from existing store methods */
  const refreshUser = async (callback) => {
    await userStore.loadUserMeta();
    await userStore.loadUserTeams();

    const storeTeamData = { ...userStore.userTeams };
    const storeUserData = { ...userStore.user };
    const newUserData = { ...userData };
    newUserData.userMeta = storeUserData;
    newUserData.userTeams = storeTeamData;

    // Set store data. Most is already set, but this ensures preferred team/group is set as well (in case user just joined team)
    setUserStoreData(userStore, newUserData);
    setUserData(newUserData);
    if (callback) {
      callback();
    }
  };

  const loginWithCustomToken = (token) => {
    const auth = getFirebaseAuth();
    return signInWithCustomToken(auth, token);
  };

  const loginWithGoogleToken = async (googleToken) => {
    // If we're not already checking the custom token, let's do so
    if (isCheckingCustomToken) {
      try {
        await loginWithCustomToken(googleToken);
      } catch (e) {
        Bugsnag.notify(e);
      }
      isCheckingCustomToken = false;
    }
    return null;
  };

  // Subscribe to firebase auth changes on mount!
  useEffect(() => {
    if (!authReady) {
      return () => {};
    }

    const auth = getFirebaseAuth();
    // Check if we have a custom token that needs to be logged in with first
    // onAuthStateChanged only triggered on sign-in or sign-out
    const cleanupListener = onAuthStateChanged(auth, async (firebaseUser) => {
      if (sessionStorage.getItem('signInWithRedirect')) {
        try {
          const result = await getRedirectResult(auth);

          if (result) {
            trackSnowplowEvent({
              category: 'Oauth Authentication',
              action: result.operationType,
              label: `Provider: ${result.providerId}`,
              property: result.user,
            });
          }
        } catch (e) {
          handleSocialError(e);
        }
        sessionStorage.removeItem('signInWithRedirect');
      }
      const tokenName = 'google-token';
      // If we have a custom google token, and we haven't already checked custom token, lets login with that first
      if (sessionStorage.getItem(tokenName)) {
        isCheckingCustomToken = true;
        // Save token to var before deleting
        const googleToken = sessionStorage.getItem(tokenName);
        sessionStorage.removeItem(tokenName);
        await loginWithGoogleToken(googleToken);
      }
      if (firebaseUser && !isSettingFirebaseUser) {
        isSettingFirebaseUser = true;
        // For now, let's store the jwt
        let jwt = await firebaseUser.getIdToken();

        /** if (firebaseUser.providerData?.providerId === 'password' && !firebaseUser.emailVerified)
         * Redirect to register code interstitial (with message) - Will be added After A/B test when registration with code is only flow
         */

        /* SSO Handling */
        // Check if SSO login, and if so, if there's any server errors or the user can link their account
        const ssoHandling = await getSsoHandling(jwt);
        // If there's an error from server, set error to state to show on page, logout of firebase
        if (ssoHandling.error) {
          setSsoError(ssoHandling.error);
          signout(true);
          setLoading(false);
          return;
        }
        // If the user is SSO and can link account, send them to the link account page
        if (jwt && !window.sessionStorage.getItem('skipLinkAccount') && ssoHandling.canLinkAccount) {
          // STORE THE JWT TEMPORARILY IN LOCAL STORAGE AND GRAB IT ON THE LINK ACCOUNT PAGE ON MOUNT
          window.localStorage.setItem('temporaryJwt', jwt);
          navigate('/link-account');
          setLoading(false);
          return;
        }
        /* End SSO Handling */

        // Get follow up user meta (userMeta and userTeams) - This is the 'callback' route
        let freshUserData = null;
        try {
          freshUserData = await agents.authGoogle.login(jwt);
        } catch (e) {
          Bugsnag.notify(e);
          // Show generic error message and reset submitting state if 'callback' route fails
          signout(false, '/login/?status=error&action=default');
          return;
        }
        const shouldRefreshJWT = freshUserData && (freshUserData.refreshJwt || freshUserData.refreshUser);
        if (shouldRefreshJWT) {
          jwt = await firebaseUser.getIdToken(true);
        }
        // Check if jwt at this point is missing UID. If so, send to bugsnag and log user out
        const decodedJwt = jwtDecode(jwt);
        if (!decodedJwt || !decodedJwt.uid) {
          signout();
        }
        authStore.setToken(jwt);

        // Set the user
        setUserData(freshUserData);

        const queryParams = queryString.parse(window.location.search);
        const loadUserPreferences = !queryParams.refreshUser;

        // Set all of the data in the userStore so it works across the app as it always had
        setUserStoreData(userStore, freshUserData, loadUserPreferences);
        setUser(firebaseUser);
        authStore.setFirebaseUser(firebaseUser);
        // Tell app we are loaded -- Pause for loading facts to have a chance to display
        setTimeout(() => {
          setLoading(false);
          isSettingFirebaseUser = false;
        }, 1000);
        Bugsnag.setUser(decodedJwt.uid);

        // If this is /login or /email-login, redirect as needed
        // Note: Using window.location because location is stored as the original location on init, not where the user is if redirecting during login flow
        // Eg. User comes in through magic email to /acctmgmt then gets redirected to /email-login. location = /acctmgmt
        if (/^\/((email-)?login|verification-code|saml-result)\/?$/g.test(window.location.pathname)) {
          const redirect = getRedirectUrl(window.location, userStore);
          navigate(redirect, { replace: true });
        }
      } else if (!isCheckingCustomToken && !isSettingFirebaseUser) {
        setLoading(false);
        // Destroy the jwt
        window.localStorage.removeItem('jwt');
        resetUser();
      }
    });

    setupErrorInterceptors(authStore, signout);

    return () => cleanupListener();
  }, [authReady]);

  // For more examples, see https://usehooks.com/useAuth/

  return {
    userData,
    user,
    signout,
    getProviderForEmail,
    signInWithPassword,
    submitForgotPassword,
    submitResetPassword,
    sendMagicLink,
    checkIsSignInWithEmailLink,
    loginInWithEmailLink,
    refreshUser,
    loading,
    handleActioncode,
    loginWithCustomToken,
    handleProviderLogin,
    submitRegisterCode,
    ssoError,
  };
}

// Auth provider component (this is boilerplate for context providers)
export function ProvideAuth({ userStore, authStore, children }) {
  const auth = useProvideAuth(userStore, authStore);
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Define our hook for child components to use to get access to the auth object, as well as re-render when it changes!
export const useAuth = () => {
  return useContext(authContext);
};
