import React from 'react';
import { observer, inject } from 'mobx-react';
import './admin.css';
import Icon from '../Icon/Icon';

export default inject(
  'userStore',
  'adminStore'
)(
  observer(({ userStore, adminStore }) => {
    const { team } = userStore;
    const { larping, setDefaultLarp } = adminStore;

    const isValidTeamId = (teamId) => Object.keys(userStore.userTeams).indexOf(teamId) > -1;

    if (larping?.teamId && team && isValidTeamId(larping.teamId)) {
      return (
        <div className="admin-larp">
          <div className="text-center larp-controls-container">
            <p className="text-base">
              LARPing: <span className="font-semibold text-yellow-200">{team.name}</span>
            </p>
            <button className="flex gap-x-2 items-center mx-auto" onClick={() => setDefaultLarp()}>
              <Icon name="x" className="w-4 h-4" /> Back to Teams
            </button>
          </div>
          <div className="larp-icon-container">
            <Icon name="spy" className="w-16 h-16 text-blue-600 fill-current" />
          </div>
        </div>
      );
    }
    return null;
  })
);
