import React from 'react';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { useOnboardingContext } from '../../../providers/OnboardingProvider';
import OnboardingStep from './OnboardingStep';
import { COUNTRIES, SHARED_ROLES, SHARED_DEPARTMENTS } from '../../../constants';

function DemoFormStep() {
  const { setStep, setNavigationLocation, isSubmitting, savedFormFields } = useOnboardingContext();

  const { firstName = '', lastName = '', company = '', department = '', business_email = '', country = '', phone = '', title = '' } = savedFormFields;
  const DEMO_FORM_FIELDS = {
    order: [['firstName', 'lastName'], ['company', 'email'], ['country', 'phone'], ['role', 'department'], 'teamSize', 'interest', 'howDidYouHear', 'submit'],
    fields: {
      firstName: {
        id: 'onboarding-demo-first-name',
        type: 'text',
        defaultValue: firstName,
        label: 'First Name',
        validations: ['required'],
      },
      lastName: {
        id: 'onboarding-demo-last-name',
        type: 'text',
        defaultValue: lastName,
        label: 'Last Name',
        validations: ['required'],
      },
      company: {
        id: 'onboarding-demo-company',
        type: 'text',
        defaultValue: company,
        label: 'Company',
        validations: ['required'],
      },
      email: {
        id: 'onboarding-demo-email',
        type: 'email',
        defaultValue: business_email,
        label: 'Email',
        placeholder: 'name@example.com',
        validations: ['required'],
      },

      country: {
        id: 'onboarding-demo-country',
        type: 'select',
        label: 'Country',
        placeholder: 'Choose Country',
        defaultValue: country,
        options: COUNTRIES,
        validations: ['required'],
      },
      phone: {
        id: 'onboarding-demo-phone',
        type: 'phone',
        defaultValue: phone,
        label: 'Phone Number',
        validations: ['required'],
      },

      role: {
        id: 'onboarding-demo-role',
        type: 'select',
        label: 'I am a(n)...',
        defaultValue: title,
        placeholder: 'Choose Role',
        options: SHARED_ROLES,
        validations: ['required'],
      },

      department: {
        id: 'onboarding-demo-department',
        type: 'select',
        label: 'Department',
        placeholder: 'Choose Department',
        defaultValue: department,
        options: SHARED_DEPARTMENTS,
        validations: ['required'],
      },
      teamSize: {
        id: 'onboarding-demo-teamSize',
        type: 'select',
        label: 'Team Size',
        placeholder: 'Select Team Size',
        options: [
          {
            text: '5 - 9',
            value: '5 - 9',
          },
          {
            text: '10 - 49',
            value: '10 - 49',
          },
          {
            text: '50 - 99',
            value: '50 - 99',
          },
          {
            text: '100 - 499',
            value: '100 - 499',
          },
          {
            text: '500+',
            value: '500+',
          },
        ],
        validations: ['required'],
      },
      interest: {
        id: 'onboarding-demo-interest',
        type: 'textarea',
        label: 'What interests you most about our Teams Product?',
        maxLength: 1000,
      },
      howDidYouHear: {
        id: 'onboarding-demo-how-did-you-hear',
        type: 'text',
        label: 'How did you hear about Cybrary?',
      },
      submit: {
        type: 'button',
        color: 'pink',
        label: 'Submit',
        className: 'mx-auto',
        loading: isSubmitting,
      },
    },
  };

  const handleSubmit = () => {
    /** we fire a form submission via snowplow so no api request needed here */
    setNavigationLocation('/enterprise/demo/organization/welcome');
    setStep('navigate');
  };
  const stepDescription = `See the platform 96% of Fortune 1000 companies use to empower employees 
  with cybersecurity skills to effectively defend their organizations. Complete the form below to schedule a demo of 
  Cybrary for Teams and our sales team will contact you shortly.`;
  return (
    <OnboardingStep title="Get a Demo of Cybrary for Teams">
      <OnboardingStep.Description description={stepDescription} className="font-normal md:text-sm" />
      <div className="p-12 m-auto max-w-[75%] bg-white rounded-[24px] border-1 border-gray-600">
        <DynamicForm form={DEMO_FORM_FIELDS} onSubmit={handleSubmit} />
      </div>
    </OnboardingStep>
  );
}
export default DemoFormStep;
