import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Agents from '../agents/agents';
import BugsnagUtil from '../utils/bugsnagUtil';
import ActionUtil from '../utils/actionsUtil';
import AuthUtil from '../utils/authUtil';
import { isPaidAdmin } from '../utils/navUtil';
import useSandboxCookie from '../hooks/cookies/useSandboxCookie';
import DEMO_LEARNER_BASELINE_GOALS from '../pages/Measure/Demo/Reports/baselining-learner-goals.json';
import DEMO_LEARNER_BASELINE_GOAL from '../pages/Measure/Demo/Reports/baselining-learner-goal.json';
import DEMO_TEAM_BASELINE_GOALS from '../pages/Measure/Demo/Reports/baselining-team-goals.json';
import DEMO_TEAM_BASELINE_GOAL from '../pages/Measure/Demo/Reports/baselining-team-goal.json';
import DEMO_TEAM_PRE_ASSESSMENT_GOAL from '../pages/Measure/Demo/Reports/baselining-team-pre-assessment-goal.json';
import DEMO_TEAM_REMEDIATION_GOAL from '../pages/Measure/Demo/Reports/baselining-team-remediation-goal.json';
import DEMO_TEAM_POST_ASSESSMENT_GOAL from '../pages/Measure/Demo/Reports/baselining-team-post-assessment-goal.json';
import { CONTENT_TYPES } from '../constants';

const CybMeasureContext = createContext();

/**
 * Cybrary Measure Provider
 * @param {*} children - React children
 * @param {*} userStore - Mobx user store
 * @returns <CybMeasureProvider.Provider />
 */
function CybMeasureProvider({ children, userStore }) {
  const { isMeasureSandboxEnabled } = useSandboxCookie();

  const location = useLocation();

  const sandboxEnvKey = useMemo(() => {
    if (AuthUtil.isDevEnvironment()) {
      return 'DEV';
    }

    if (AuthUtil.isBWBEnvironment()) {
      return 'BWB';
    }

    return 'PROD';
  }, []);

  const userOrgId = useMemo(() => userStore?.teamData?.id, [userStore?.teamData?.id]);

  const isTeamLearner = useMemo(() => userStore?.isTeamMember, [userStore?.isTeamMember]);

  const isTeamAdmin = useMemo(() => isPaidAdmin(userStore), [userStore?.teamData?.role]);

  // context mode, either role (assessment path) || topic (assessment course)
  const [contextMode, setContextMode] = useState('role');
  // index of topics (assessment courses) indexed by `orgId`
  const [topics, setTopics] = useState({});
  // loading state
  const [isLoadingTopics, setIsLoadingTopics] = useState(false);
  // error state
  const [topicsError, setTopicsError] = useState(null);

  /**
   * Team learner (auth user) state definitions
   */
  // index of top-level data of assigned baseline goals for learner (auth user), indexed by `orgId`
  const [learnerBaselineGoals, setLearnerBaselineGoals] = useState({});
  // index of detailed data of assigned baseline goals for learner (auth user), indexed by `orgId`_`roleId`
  const [learnerBaselineGoalDetails, setLearnerBaselineGoalDetails] = useState({});
  // -- Loading states
  const [isLoadingBaselineGoals, setIsLoadingBaselineGoals] = useState(false);
  const [isLoadingBaselineGoalDetails, setIsLoadingBaselineGoalDetails] = useState(false);
  // -- Error states
  const [learnerBaselineGoalsError, setLearnerBaselineGoalsError] = useState(null);
  const [learnerBaselineGoalDetailsError, setLearnerBaselineGoalDetailsError] = useState(null);

  /**
   * Team admin (auth user) state definitions
   */
  // index of top-level data for assigned team baseline goals of admin (auth user), indexed by `orgId`_`roleId`
  const [teamBaselineGoals, setTeamBaselineGoals] = useState({});
  // index of detailed data for assigned baseline goals of admin (auth user), indexed by `orgId`_`goalId`
  const [teamBaselineGoalDetails, setTeamBaselineGoalDetails] = useState({});
  // index of detailed data for pre-assessment baseline goals, indexed by `orgId`_`goalId`
  const [preAssessmentGoalDetails, setPreAssessmentGoalDetails] = useState({});
  // index of detailed data for remediation baseline goals, indexed by `orgId`_`goalId`
  const [remediationGoalDetails, setRemediationGoalDetails] = useState({});
  // index of detailed data for post-assessment baseline goals, indexed by `orgId`_`goalId`
  const [postAssessmentGoalDetails, setPostAssessmentGoalDetails] = useState({});
  // -- ** Loading states
  // ---- index of loading states when fetching `teamBaselineGoals` data, indexed by `orgId`_`roleId`
  const [isLoadingTeamBaselineGoals, setIsLoadingTeamBaselineGoals] = useState({});
  // ---- index of loading states when fetching `teamBaselineGoalDetails` data
  const [isLoadingTeamBaselineGoalDetails, setIsLoadingTeamBaselineGoalDetails] = useState(false);
  // ---- index of loading states when fetching `preAssessmentGoalDetails` data
  const [isLoadingPreAssessmentGoalDetails, setIsLoadingPreAssessmentGoalDetails] = useState(false);
  // ---- index of loading states when fetching `remediationGoalDetails` data
  const [isLoadingRemediationGoalDetails, setIsLoadingRemediationGoalDetails] = useState(false);
  // ---- index of loading states when fetching `postAssessmentGoalDetails` data
  const [isLoadingPostAssessmentGoalDetails, setIsLoadingPostAssessmentGoalDetails] = useState(false);
  // ---- loading state when creating a baseline goal
  const [isProcessingGoalCreation, setIsProcessingGoalCreation] = useState(false);
  // ---- loading state when deleting a baseline goal
  const [isProcessingGoalDeletion, setIsProcessingGoalDeletion] = useState(false);
  // ---- loading state when updating a baseline goal
  const [isProcessingGoalUpdate, setIsProcessingGoalUpdate] = useState(false);
  // -- ** Error states
  // ---- index of error states when fetching `teamBaselineGoals` data, indexed by `orgId`_`roleId`
  const [teamBaselineGoalsError, setTeamBaselineGoalsError] = useState({});
  // ---- index of error states when fetching `teamBaselineGoalDetails` data
  const [teamBaselineGoalDetailsError, setTeamBaselineGoalDetailsError] = useState(null);
  // ---- index of error states when fetching `preAssessmentGoalDetails` data
  const [preAssessmentGoalDetailsError, setPreAssessmentGoalDetailsError] = useState(null);
  // ---- index of error states when fetching `remediationGoalDetails` data
  const [remediationGoalDetailsError, setRemediationGoalDetailsError] = useState(null);
  // ---- index of error states when fetching `postAssessmentGoalDetails` data
  const [postAssessmentGoalDetailsError, setPostAssessmentGoalDetailsError] = useState(null);

  /**
   * get or fetch topics (assessment courses) by `orgId` and set `topics`
   * @param {number} orgId - Org ID
   */
  const getTopics = useCallback(
    async (orgId) => {
      try {
        let teamTopics = topics[orgId];

        if (!teamTopics) {
          setIsLoadingTopics(true);
          setTopicsError(null);

          const query = `?content_type_id=${CONTENT_TYPES.CYBRARY_ASSESSMENT_COURSE}&recordsPerPage=100`;

          const resp = await Agents.catalog.listContent(query);

          teamTopics = resp.data;

          setTopics((prevState) => ({
            ...prevState,
            [orgId]: teamTopics,
          }));
        }

        return teamTopics;
      } catch (getTopicsError) {
        setTopicsError(getTopicsError);
        BugsnagUtil.notify(getTopicsError);
      } finally {
        setIsLoadingTopics(false);
      }

      return null;
    },
    [topics]
  );

  /**
   * get or fetch top-level data of assigned learner baseline goals by `orgId` and set `learnerBaselineGoals`
   * @param {number} orgId - Org ID
   */
  const getLearnerBaselineGoals = useCallback(
    async (orgId) => {
      try {
        let goals = learnerBaselineGoals[orgId];

        if (!goals) {
          if (isMeasureSandboxEnabled) {
            setIsLoadingBaselineGoals(true);

            await ActionUtil.sleep(1000);

            setLearnerBaselineGoals((prevState) => ({
              ...prevState,
              [orgId]: DEMO_LEARNER_BASELINE_GOALS[sandboxEnvKey],
            }));
          } else {
            setIsLoadingBaselineGoals(true);
            setLearnerBaselineGoalsError(null);

            const resp = await Agents.measure.getLeanerBaselineGoals(orgId);

            goals = resp;

            setLearnerBaselineGoals((prevState) => ({
              ...prevState,
              [orgId]: goals,
            }));
          }
        }

        return goals;
      } catch (getLearnerBaselineGoalsError) {
        setLearnerBaselineGoalsError(getLearnerBaselineGoalsError);
        BugsnagUtil.notify(getLearnerBaselineGoalsError);
      } finally {
        setIsLoadingBaselineGoals(false);
      }

      return null;
    },
    [learnerBaselineGoals, isMeasureSandboxEnabled]
  );

  /**
   * get or fetch detail data of assigned learner baseline goals by `orgId`, `contentDescriptionId` and update `learnerBaselineGoalDetails`.
   * The `goalId` is passed because that's how we request the goal from the API & the `contentDescriptionId` is passed so we can efficiently build an index
   * Of the goals including the contentDescriptionId as this is what the role dropdown selector uses as its value and so we also don't have to do an uneccessary lookup
   * In the goal data itself for the correlating role (assessment path) or topic (assessment course)
   * @param {number} orgId - Org ID
   * @param {number} goalId - Goal ID
   * @param {number} contentDescriptionId - Content Description ID; The content description id of the role (assessment path) or topic (assessment course) related to the goal
   * @param {boolean} noCache - Whether cache should be ignored
   */
  const getLearnerBaselineGoalDetails = useCallback(
    async (orgId, goalId, contentDescriptionId, noCache = false) => {
      try {
        const key = `${orgId}_${contentDescriptionId}`;

        let goal = noCache ? null : learnerBaselineGoalDetails[key];

        if (!goal) {
          if (isMeasureSandboxEnabled) {
            setIsLoadingBaselineGoalDetails(true);

            await ActionUtil.sleep(1000);

            setLearnerBaselineGoalDetails((prevState) => ({
              ...prevState,
              [key]: DEMO_LEARNER_BASELINE_GOAL[String(goalId)],
            }));
          } else {
            setIsLoadingBaselineGoalDetails(true);
            setLearnerBaselineGoalDetailsError(null);

            const resp = await Agents.measure.getLeanerBaselineGoal(goalId);

            goal = resp;

            setLearnerBaselineGoalDetails((prevState) => ({
              ...prevState,
              [key]: goal,
            }));
          }
        }

        return goal;
      } catch (getLearnerBaselineGoalDetailsError) {
        setLearnerBaselineGoalDetailsError(getLearnerBaselineGoalDetailsError);
        BugsnagUtil.notify(getLearnerBaselineGoalDetailsError);
      } finally {
        setIsLoadingBaselineGoalDetails(false);
      }

      return null;
    },
    [learnerBaselineGoalDetails, isMeasureSandboxEnabled]
  );

  /**
   * get or fetchtop-level data of assigned team baseline goals by `orgId`, `contentDescriptionId` and update `teamBaselineGoals`.
   * The `contentDescriptionId` is passed because that's how we request the goal from the API and so we can efficiently build an index
   * Of the goals including the contentDescriptionId as this is what the role dropdown selector uses as its value and so we also don't have to do an uneccessary lookup
   * In the goal data itself for the correlating role (assessment path) or topic (assessment course)
   * @param {number} orgId - Org ID
   * @param {number} contentDescriptionId - Content Description ID; The content description id of the role (assessment path) or topic (assessment course) related to the goal
   * @param {boolean?} refetch - Whether call should force refetch of cached team baseline goals
   */
  const getTeamBaselineGoals = useCallback(
    async (orgId, contentDescriptionId, refetch) => {
      const key = `${orgId}_${contentDescriptionId}`;

      try {
        let goals = refetch ? null : teamBaselineGoals[key];

        if (!goals) {
          if (isMeasureSandboxEnabled) {
            setIsLoadingTeamBaselineGoals((prevState) => ({
              ...prevState,
              [key]: true,
            }));

            await ActionUtil.sleep(1000);

            setTeamBaselineGoals((prevState) => ({
              ...prevState,
              [key]: DEMO_TEAM_BASELINE_GOALS[sandboxEnvKey][contentDescriptionId],
            }));
          } else {
            setIsLoadingTeamBaselineGoals((prevState) => ({
              ...prevState,
              [key]: true,
            }));
            setTeamBaselineGoalsError((prevState) => ({
              ...prevState,
              [key]: null,
            }));

            const resp = await Agents.measure.getTeamBaselineGoals(orgId, contentDescriptionId);

            goals = resp;

            setTeamBaselineGoals((prevState) => ({
              ...prevState,
              [key]: goals,
            }));
          }
        }

        return goals;
      } catch (getTeamBaselineGoalDetailsError) {
        setTeamBaselineGoalsError((prevState) => ({
          ...prevState,
          [key]: getTeamBaselineGoalDetailsError,
        }));
        BugsnagUtil.notify(getTeamBaselineGoalDetailsError);
      } finally {
        setIsLoadingTeamBaselineGoals((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }

      return null;
    },
    [teamBaselineGoals, isMeasureSandboxEnabled]
  );

  /**
   * get or fetch detail data of assigned team baseline goals by `orgId`, `goalId` and update `teamBaselineGoalDetails`.
   * The `goalId` is passed because that's how we request the goal from the API and so we can efficiently build an index of the goals
   * @param {number} orgId - Org ID
   * @param {number} goalId - Goal ID
   * @param {boolean} noCache - Whether cache should be ignored
   */
  const getTeamBaselineGoalDetails = useCallback(
    async (orgId, goalId, noCache = false) => {
      try {
        const key = `${orgId}_${goalId}`;

        let goal = noCache ? null : teamBaselineGoalDetails[key];

        if (!goal) {
          if (isMeasureSandboxEnabled) {
            setIsLoadingTeamBaselineGoalDetails(true);

            await ActionUtil.sleep(500);

            setTeamBaselineGoalDetails((prevState) => ({
              ...prevState,
              [key]: DEMO_TEAM_BASELINE_GOAL[sandboxEnvKey][String(goalId)],
            }));
          } else {
            setIsLoadingTeamBaselineGoalDetails(true);
            setTeamBaselineGoalDetailsError(null);

            const resp = await Agents.measure.getTeamBaselineGoal(orgId, goalId);

            goal = resp;

            setTeamBaselineGoalDetails((prevState) => ({
              ...prevState,
              [key]: goal,
            }));
          }
        }

        return goal;
      } catch (getTeamBaselineGoalDetailsError) {
        setTeamBaselineGoalDetailsError(getTeamBaselineGoalDetailsError);
        BugsnagUtil.notify(getTeamBaselineGoalDetailsError);
      } finally {
        setIsLoadingTeamBaselineGoalDetails(false);
      }

      return null;
    },
    [teamBaselineGoalDetails, isMeasureSandboxEnabled]
  );

  /**
   * get or fetch detail data of baseline goal > pre-assessment goal by `orgId`, `goalId` and update `preAssessmentGoalDetails`.
   * The `goalId` is passed because that's how we request the goal from the API and so we can efficiently build an index of the goals
   * @param {number} orgId - Org ID
   * @param {number} goalId - Goal ID
   */
  const getPreAssessmentGoalProgress = useCallback(
    async (orgId, goalId, query = '') => {
      try {
        const key = `${orgId}_${goalId}`;

        let preAssessmentGoal = preAssessmentGoalDetails[key];

        if (!preAssessmentGoal) {
          if (isMeasureSandboxEnabled) {
            setIsLoadingPreAssessmentGoalDetails(true);

            await ActionUtil.sleep(1500);

            setPreAssessmentGoalDetails((prevState) => ({
              ...prevState,
              [key]: DEMO_TEAM_PRE_ASSESSMENT_GOAL[String(goalId)],
            }));
          } else {
            setIsLoadingPreAssessmentGoalDetails(true);
            setPreAssessmentGoalDetailsError(null);

            const resp = await Agents.measure.getPreAssessmentGoalProgress(orgId, goalId, query);

            preAssessmentGoal = resp;

            setPreAssessmentGoalDetails((prevState) => ({
              ...prevState,
              [key]: preAssessmentGoal,
            }));
          }
        }

        return preAssessmentGoal;
      } catch (getPreAssessmentGoalDetailsError) {
        setPreAssessmentGoalDetailsError(getPreAssessmentGoalDetailsError);
        BugsnagUtil.notify(getPreAssessmentGoalDetailsError);
      } finally {
        setIsLoadingPreAssessmentGoalDetails(false);
      }

      return null;
    },
    [preAssessmentGoalDetails, isMeasureSandboxEnabled]
  );

  /**
   * get or fetch detail data of baseline goal > remediation goal by `orgId`, `goalId` and update `remediationGoalDetails`.
   * The `goalId` is passed because that's how we request the goal from the API and so we can efficiently build an index of the goals
   * @param {number} orgId - Org ID
   * @param {number} goalId - Goal ID
   */
  const getRemediationGoalProgress = useCallback(
    async (orgId, goalId, query = '') => {
      try {
        const key = `${orgId}_${goalId}`;

        let remediationGoal = remediationGoalDetails[key];

        if (!remediationGoal) {
          if (isMeasureSandboxEnabled) {
            setIsLoadingRemediationGoalDetails(true);

            await ActionUtil.sleep(1500);

            setRemediationGoalDetails((prevState) => ({
              ...prevState,
              [key]: DEMO_TEAM_REMEDIATION_GOAL[String(goalId)],
            }));
          } else {
            setIsLoadingRemediationGoalDetails(true);
            setRemediationGoalDetailsError(null);

            const resp = await Agents.measure.getRemediationGoalProgress(orgId, goalId, query);

            remediationGoal = resp;

            setRemediationGoalDetails((prevState) => ({
              ...prevState,
              [key]: remediationGoal,
            }));
          }
        }

        return remediationGoal;
      } catch (getRemediationGoalDetailsError) {
        setRemediationGoalDetailsError(getRemediationGoalDetailsError);
        BugsnagUtil.notify(getRemediationGoalDetailsError);
      } finally {
        setIsLoadingRemediationGoalDetails(false);
      }

      return null;
    },
    [remediationGoalDetails, isMeasureSandboxEnabled]
  );

  /**
   * get or fetch detail data of baseline goal > post-assessment goal by `orgId`, `goalId` and update `postAssessmentGoalDetails`.
   * The `goalId` is passed because that's how we request the goal from the API and so we can efficiently build an index of the goals
   * @param {number} orgId - Org ID
   * @param {number} goalId - Goal ID
   */
  const getPostAssessmentGoalProgress = useCallback(
    async (orgId, goalId, query = '') => {
      try {
        const key = `${orgId}_${goalId}`;

        let postAssessmentGoal = postAssessmentGoalDetails[key];

        if (!postAssessmentGoal) {
          if (isMeasureSandboxEnabled) {
            setIsLoadingPostAssessmentGoalDetails(true);

            await ActionUtil.sleep(1500);

            setPostAssessmentGoalDetails((prevState) => ({
              ...prevState,
              [key]: DEMO_TEAM_POST_ASSESSMENT_GOAL[String(goalId)],
            }));
          } else {
            setIsLoadingPostAssessmentGoalDetails(true);
            setPostAssessmentGoalDetailsError(null);

            const resp = await Agents.measure.getPostAssessmentGoalProgress(orgId, goalId, query);

            postAssessmentGoal = resp;

            setPostAssessmentGoalDetails((prevState) => ({
              ...prevState,
              [key]: postAssessmentGoal,
            }));
          }
        }

        return postAssessmentGoal;
      } catch (getPostAssessmentGoalDetailsError) {
        setPreAssessmentGoalDetailsError(getPostAssessmentGoalDetailsError);
        BugsnagUtil.notify(getPostAssessmentGoalDetailsError);
      } finally {
        setIsLoadingPostAssessmentGoalDetails(false);
      }

      return null;
    },
    [postAssessmentGoalDetails, isMeasureSandboxEnabled]
  );

  /**
   * create a baselining goal.
   * @param {number} orgId - Org ID
   * @param {number} contentDescriptionId - Content Description ID
   * @param {Object} payload - baseline goal object
   * @param {Function} callback - callback function
   */
  const createBaselineGoal = useCallback(
    async (orgId, contentDescriptionId, payload, callback) => {
      try {
        setIsProcessingGoalCreation(true);

        await Agents.measure.createBaselineGoal(orgId, payload);

        if (callback) {
          callback();
        }

        getTeamBaselineGoals(orgId, contentDescriptionId, true);
      } catch (createBaselineGoalError) {
        BugsnagUtil.notify(createBaselineGoalError);
      } finally {
        setIsProcessingGoalCreation(false);
      }

      return null;
    },
    [getTeamBaselineGoals]
  );

  /**
   * update a baselining goal.
   * @param {number} orgId - Org ID
   * @param {number} contentDescriptionId - Content Description ID
   * @param {number} goalId - Goal ID
   * @param {Object} payload - baseline goal object
   * @param {Function} callback - callback function
   */
  const updateBaselineGoal = useCallback(
    async (orgId, contentDescriptionId, goalId, payload, callback) => {
      try {
        setIsProcessingGoalUpdate(true);

        await Agents.measure.updateBaselineGoal(orgId, goalId, payload);

        if (callback) {
          callback();
        }

        getTeamBaselineGoals(orgId, contentDescriptionId, true);
      } catch (updateBaselineGoalError) {
        BugsnagUtil.notify(updateBaselineGoalError);
      } finally {
        setIsProcessingGoalUpdate(false);
      }

      return null;
    },
    [getTeamBaselineGoals]
  );

  /**
   * delete a baselining goal.
   * @param {number} orgId - Org ID
   * @param {number} contentDescriptionId - Content Description ID
   * @param {number} goalId - Goal ID
   */
  const deleteBaselineGoal = useCallback(
    async (orgId, contentDescriptionId, goalId) => {
      try {
        setIsProcessingGoalDeletion(true);

        await Agents.measure.deleteBaselineGoal(orgId, goalId);

        getTeamBaselineGoals(orgId, contentDescriptionId, true);
      } catch (deleteBaselineGoalError) {
        BugsnagUtil.notify(deleteBaselineGoalError);
      } finally {
        setIsProcessingGoalDeletion(false);
      }

      return null;
    },
    [getTeamBaselineGoals]
  );

  const value = useMemo(
    () => ({
      // State
      contextMode,
      topics,
      isLoadingTopics,
      topicsError,
      learnerBaselineGoals,
      learnerBaselineGoalDetails,
      teamBaselineGoals,
      teamBaselineGoalDetails,
      preAssessmentGoalDetails,
      remediationGoalDetails,
      postAssessmentGoalDetails,
      // - Loading State
      isLoadingBaselineGoals,
      isLoadingBaselineGoalDetails,
      isLoadingTeamBaselineGoals,
      isLoadingTeamBaselineGoalDetails,
      isLoadingPreAssessmentGoalDetails,
      isLoadingRemediationGoalDetails,
      isLoadingPostAssessmentGoalDetails,
      isProcessingGoalCreation,
      isProcessingGoalDeletion,
      isProcessingGoalUpdate,
      // - Error State
      learnerBaselineGoalsError,
      learnerBaselineGoalDetailsError,
      teamBaselineGoalsError,
      teamBaselineGoalDetailsError,
      preAssessmentGoalDetailsError,
      remediationGoalDetailsError,
      postAssessmentGoalDetailsError,
      // Computed
      isTeamLearner,
      isTeamAdmin,
      // Actions'
      setContextMode,
      getTopics,
      getLearnerBaselineGoals,
      getLearnerBaselineGoalDetails,
      getTeamBaselineGoals,
      getTeamBaselineGoalDetails,
      getPreAssessmentGoalProgress,
      getRemediationGoalProgress,
      getPostAssessmentGoalProgress,
      createBaselineGoal,
      updateBaselineGoal,
      deleteBaselineGoal,
    }),
    [
      contextMode,
      topics,
      isLoadingTopics,
      topicsError,
      learnerBaselineGoals,
      learnerBaselineGoalDetails,
      teamBaselineGoals,
      teamBaselineGoalDetails,
      preAssessmentGoalDetails,
      remediationGoalDetails,
      postAssessmentGoalDetails,
      isLoadingBaselineGoals,
      isLoadingBaselineGoalDetails,
      isLoadingTeamBaselineGoals,
      isLoadingTeamBaselineGoalDetails,
      isLoadingPreAssessmentGoalDetails,
      isLoadingRemediationGoalDetails,
      isLoadingPostAssessmentGoalDetails,
      isProcessingGoalCreation,
      isProcessingGoalDeletion,
      isProcessingGoalUpdate,
      learnerBaselineGoalsError,
      learnerBaselineGoalDetailsError,
      teamBaselineGoalsError,
      teamBaselineGoalDetailsError,
      preAssessmentGoalDetailsError,
      remediationGoalDetailsError,
      postAssessmentGoalDetailsError,
      isTeamLearner,
      isTeamAdmin,
      setContextMode,
      getTopics,
      getLearnerBaselineGoals,
      getLearnerBaselineGoalDetails,
      getTeamBaselineGoals,
      getTeamBaselineGoalDetails,
      getPreAssessmentGoalProgress,
      getRemediationGoalProgress,
      getPostAssessmentGoalProgress,
      createBaselineGoal,
      updateBaselineGoal,
      deleteBaselineGoal,
    ]
  );

  useEffect(() => {
    if (contextMode === 'topic' && !topics[userOrgId] && !isLoadingTopics && !topicsError) {
      getTopics(userOrgId);
    }
  }, [userOrgId, contextMode, topics, isLoadingTopics, topicsError]);

  useEffect(() => {
    // invoke preliminary fetch (or whenever selected team changes) for team learner (auth user) baseline goals
    if (isTeamLearner && location?.pathname.includes('/measure/') && userOrgId && !learnerBaselineGoals[userOrgId]) {
      getLearnerBaselineGoals(userOrgId);
    }
  }, [userOrgId, location?.pathname, learnerBaselineGoals, isTeamLearner]);

  return <CybMeasureContext.Provider value={value}>{children}</CybMeasureContext.Provider>;
}

export const useCybMeasure = () => useContext(CybMeasureContext);

export default CybMeasureProvider;
