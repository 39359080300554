import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';

import withRouter from '../../components/Router/withRouter';
import Title from '../../components/Title/Title';
import Container from '../../components/Container/Container';
import './enterprise.css';
import ManagerInviteForm from './ManagerInviteForm';
import OrgOwnersList from './OrgOwnersList';
import { DEFAULT_COOKIE_SETTINGS } from '../../constants';

const RequestAddOnLicense = inject(
  'authStore',
  'commonStore',
  'userStore',
  'enterpriseStore'
)(
  observer(function RequestAddOnLicense({ authStore, commonStore, userStore, enterpriseStore, match }) {
    const title = 'Request Cybrary Select';
    const cookieKey = 'requestAddOnLicenseContactedAdmins';
    const { orgId } = match.params;
    const [message, setMessage] = useState('');
    const [contactedAdmins, setContactedAdmins] = useState([]); // array of admin id's who we've already contacted

    if (!userStore?.activeTeams?.includes(`${orgId}`)) {
      return <Navigate replace to="/" />;
    }
    function init() {
      if (cookieKey) {
        const contactedAdminsCookie = Cookies.get(cookieKey)?.split(',') || [];
        setContactedAdmins(contactedAdminsCookie);
      }
      authStore.fireAttributionEvent();
      commonStore.setPageTitle(`${title} | Cybrary`);
      userStore.setPreferredTeamInit('', orgId);
      enterpriseStore.getUserListData(orgId, { role: 'org-owner' });
    }

    useEffect(() => {
      init();
      return () => {
        enterpriseStore.setDefaultUserListData();
        enterpriseStore.setDefaultTeamInviteUrl();
      };
    }, []);

    useEffect(() => {
      userStore.setPreferredTeamInit('', orgId);
      enterpriseStore.getUserListData(orgId, { role: 'org-owner' });
    }, [orgId]);

    function sendAddOnLicenseRequest(ownerId, name) {
      if (!ownerId) {
        return null;
      }

      return enterpriseStore
        .requestAddOnLicense(orgId, ownerId)
        .then(() => {
          const content = `Thank you! Your team admin ${name} has been notified of your interest in Cybrary Select content.`;
          commonStore.triggerToast('success', { content });
          const updatedContactedAdmins = [...contactedAdmins, `${ownerId}`];
          setContactedAdmins(updatedContactedAdmins);
          setMessage(content);
          if (cookieKey) {
            const COOKIE_PROPS = {
              ...DEFAULT_COOKIE_SETTINGS,
              path: '/',
              expires: 1,
            };
            Cookies.set(cookieKey, updatedContactedAdmins.join(','), COOKIE_PROPS);
          }
        })
        .catch(({ response }) => {
          const content = response?.data?.message || `Something went wrong. Unable to request Cybrary Select from ${name} at this time.`;
          setMessage(content);
          commonStore.triggerToast('error', { content });
        });
    }

    return (
      <Container size="md" className="request-license">
        <Title title={title} />
        <p className="text-sm text-gray-600">Org Owners can grant and revoke Cybrary Select access. Please select an Org Owner and they will be notified of your request.</p>
        <OrgOwnersList requestLicense={(ownerId, name) => sendAddOnLicenseRequest(ownerId, name)} contactedAdmins={contactedAdmins} />
        {message ? <p className="text-sm">{message}</p> : <ManagerInviteForm orgId={orgId} />}
      </Container>
    );
  })
);

export default withRouter(RequestAddOnLicense);
