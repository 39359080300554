import React from 'react';
import CountUp from 'react-countup';

function ProgressBarDisplayLabel({ isAggregateView, goalProgressPercentage, goalProgressDisplay, goalProgressSuffix, wrapperClasses, progressClasses, percentageClasses }) {
  return (
    <div className={`inline-block ${wrapperClasses || ''}`}>
      <span className={progressClasses}>
        {isAggregateView ? 'Avg: ' : ''}
        <CountUp end={goalProgressDisplay} preserveValue duration={2} />
        {goalProgressSuffix}
      </span>
      {goalProgressPercentage ? (
        <span className={percentageClasses}>
          (<CountUp end={1 * goalProgressPercentage} preserveValue suffix="%" duration={2} />)
        </span>
      ) : null}
    </div>
  );
}

export default ProgressBarDisplayLabel;
