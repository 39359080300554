import React from 'react';
import Table from '../../Table/Table';
import Loading from '../../Loading/Loading';
import StyledError from '../../Error/StyledError';
import AddLink from '../../AddLink/AddLink';
import NoResultsMessage from '../../NoResultsMessage/NoResultsMessage';
import Container from '../../Container/Container';
import LeaderboardTableBody from './LeaderboardTableBody';
import '../analytics.css';

function LeaderboardTable({ data = {}, formatColumns, loading, error, href, orgId, filterValue, heading, ignoredCols = [], ariaLive, setPlacementRef, name }) {
  if (loading) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }
  if (error) {
    return (
      <Container>
        <StyledError error={error} />
      </Container>
    );
  }
  const { columns, tableData, totalRecords } = data;
  const isDemo = orgId === 'demo';
  const ariaLabelHeader = heading?.toLowerCase();
  let filteredColIdx = null;
  if (!totalRecords) {
    return <NoResultsMessage message="There are no activities during this time period" />;
  }
  return (
    <>
      <div className="mt-4 mb-8 responsive-table-container">
        <p className="sr-only" aria-live={ariaLive}>
          Showing top {totalRecords < 10 ? totalRecords : 10} records based on {filterValue.split('_').join(' ')}
        </p>
        <Table className="responsive-table" style={{ borderRadius: '2px' }}>
          <Table.Header>
            <Table.Row className="print:text-sm">
              <Table.HeaderCell className="print:hidden" scope="col">
                {/* Intentionally blank for placement column unless screen reader available */}
                <span className="sr-only">Placement</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="print:hidden" scope="col">
                {/* Intentionally blank for trophy column unless screen reader available */}
                <span className="sr-only">Placement Trophy</span>
              </Table.HeaderCell>
              {columns.map((col, idx) => {
                // Explicitly looking for false because not existing is valid
                if (col.showCol !== false && !ignoredCols.includes(idx)) {
                  if (col.key === filterValue) {
                    filteredColIdx = idx;
                  }
                  return (
                    <Table.HeaderCell scope="col" className={idx === filteredColIdx ? 'highlight' : null} key={col.display}>
                      {col.display}
                    </Table.HeaderCell>
                  );
                }
                ignoredCols.push(idx);
                return null;
              })}
            </Table.Row>
          </Table.Header>
          <LeaderboardTableBody
            setPlacementRef={setPlacementRef}
            tableData={tableData}
            formatColumns={formatColumns}
            ignoredCols={ignoredCols}
            filteredColIdx={filteredColIdx}
            data={data}
            name={name}
          />
        </Table>
      </div>
      {href && (
        <AddLink
          className={`print:hidden text-cyb-pink-500 hover:text-black underline ${isDemo ? 'cursor-not-allowed' : ''}`}
          to={isDemo ? null : href}
          aria-label={`View more ${ariaLabelHeader}`}
        >
          View More
        </AddLink>
      )}
    </>
  );
}
export default LeaderboardTable;
