import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import Bugsnag from '@bugsnag/js';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import withRouter from '../../components/Router/withRouter';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import CancellationOffer from './CancellationOffer';
import CancellationSteps from './CancellationSteps';
import CancellationSurvey from './CancellationSurvey';
import CancellationThanks from './CancellationThanks';
import CancellationConfirm from './CancellationConfirm';
import GaUtil from '../../utils/gaUtil';
import agents from '../../agents/agents';
import Message from '../../components/Message/Message';
import { PADDLE_DISCOUNT_ID_CIP_ANNUAL, PADDLE_DISCOUNT_ID_CIP_MONTHLY, PADDLE_PRICE_ID_CIP_ANNUAL } from '../../constants';
import CancellationUtil from '../../utils/cancellationUtil';
import { SupportLink } from '../Paddle/PaddleToasts';

const DEFAULT_STEPS = [
  { text: 'Start', icon: null, disabled: true, onClick: null },
  { text: 'Instructions', icon: null, disabled: true, onClick: null },
  { text: 'Survey', icon: null, disabled: true, onClick: null },
  { text: 'Cancellation', icon: null, disabled: true, onClick: null },
];

// Handles swapping between different steps based on the current path we're on
// Sets page key, step index, and steps array based on the current path
const getCurrentStepData = ({ pathname, subscriptionId, plan, gateway, navigate }) => {
  const updatedSteps = [...DEFAULT_STEPS];
  const currentStepData = {
    key: 'offer',
    stepNumber: 0,
    updatedSteps,
  };
  switch (true) {
    case pathname.startsWith('/cybrary-insider-pro/steps'):
      currentStepData.stepNumber = 1; // index of the current step
      currentStepData.key = 'steps'; // page key to show. ex: offer, steps, survey, thanks, confirm
      updatedSteps[0].icon = 'check'; // update the icon for the step once its done
      // update the onClick for the step to get back here
      updatedSteps[0].onClick = () => navigate(`/cybrary-insider-pro/cancel?subscriptionId=${subscriptionId}&plan=${plan}&gateway=${gateway}`);
      updatedSteps[0].disabled = false; // enable the step
      updatedSteps[1].disabled = false; // enable the step
      break;
    case pathname.startsWith('/cybrary-insider-pro/survey'):
      currentStepData.stepNumber = 2; // index of the current step
      currentStepData.key = 'survey'; // page key to show. ex: offer, steps, survey, thanks, confirm
      updatedSteps[0].icon = 'check';
      // update the onClick for the step to get back here
      updatedSteps[0].onClick = () => navigate(`/cybrary-insider-pro/cancel?subscriptionId=${subscriptionId}&plan=${plan}&gateway=${gateway}`);
      updatedSteps[0].disabled = false;
      updatedSteps[1].icon = 'check';
      updatedSteps[1].onClick = () => navigate(`/cybrary-insider-pro/steps?subscriptionId=${subscriptionId}&plan=${plan}&gateway=${gateway}`);
      updatedSteps[1].disabled = false;
      break;
    case pathname.startsWith('/cybrary-insider-pro/confirm'):
      currentStepData.stepNumber = 3; // index of the current step
      currentStepData.key = 'confirm'; // page key to show. ex: offer, steps, survey, thanks, confirm
      updatedSteps[0].icon = 'check';
      updatedSteps[1].icon = 'check';
      updatedSteps[2].icon = 'check';
      updatedSteps[3].icon = 'check';
      updatedSteps[3].disabled = false;
      break;
    case pathname.startsWith('/cybrary-insider-pro/thanks'):
      currentStepData.stepNumber = 1; // index of the current step
      currentStepData.key = 'thanks'; // page key to show. ex: offer, steps, survey, thanks, confirm
      // This is where we successfully recovered the subscription
      // Replace steps with the new successful recovery steps
      currentStepData.updatedSteps = [
        { text: 'Start', icon: 'check', disabled: true },
        { text: 'Thank You', icon: 'check' },
      ];
      break;
    default:
      updatedSteps[0].disabled = false;
      updatedSteps[1].disabled = true;
      break;
  }
  return currentStepData;
};

// Fetch the subscription and estimate from chargebee
const handleChargebeeInit = async (targetSubscription, planRegion = 'US') => {
  const targetEstimate = await CancellationUtil.chargebee.fetchEstimate(targetSubscription, planRegion);
  if (!targetSubscription || !targetEstimate) {
    // Looks like something went wrong...
    throw new Error('Something went wrong fetching chargebee currentSubscription or previewSubscription');
  }
  return CancellationUtil.chargebee.getOffer(targetSubscription, targetEstimate);
};

// Fetch the subscription and estimate from paddle
const handlePaddleOfferInit = async (targetSubscription) => {
  // If we're missing targetSubscription, bail. We cannot continue.
  if (!targetSubscription) {
    throw new Error('Something went wrong fetching the targetSubscription');
  }
  const { price_id, id: subscription_id } = targetSubscription;

  // get current subscrition details to compare against
  const currentSubscription = await agents.paddle.getSubscription(subscription_id);
  // If we're missing currentSubscription, bail. We cannot continue.

  if (!currentSubscription) {
    throw new Error('Something went wrong fetching currentSubscription');
  }

  const isCIPAnnual = price_id === PADDLE_PRICE_ID_CIP_ANNUAL;
  const updatePreviewParams = {
    subscription_id,
    // get the matching discount_id for the current CIP plan
    discount_id: isCIPAnnual ? PADDLE_DISCOUNT_ID_CIP_ANNUAL : PADDLE_DISCOUNT_ID_CIP_MONTHLY,
    // include the items for the subscription update. THIS IS REQUIRED. REMOVING ITEMS HERE REMOVES THEM FROM THE SUBSCRIPTION.
    items: [{ price_id, quantity: 1 }],
  };

  // get an estimate of their new subscription if we were to apply a disocunt to it.
  const previewSubscription = await agents.paddle.getSubscriptionUpdatePreview(updatePreviewParams);

  // If we're missing previewSubscription, bail. We cannot continue.
  if (!previewSubscription) {
    throw new Error('Something went wrong fetching previewSubscription');
  }

  // compare the two and determine the best offer for the user
  return CancellationUtil.paddle.getOffer(currentSubscription, previewSubscription);
};

const showErrorToast = ({ commonStore, toastError, message }) => {
  const { status } = toastError?.response || {};
  let header = 'Error';
  if (status) {
    header += ` ${status}`;
  }
  commonStore.triggerToast(
    'error',
    {
      icon: 'face-frown',
      header,
      content: (
        <>
          <p>Sorry, we are having some trouble processing your request right now:</p>
          <p className="font-black">{message || toastError?.message || 'Unknown'}</p>
          <SupportLink />
        </>
      ),
    },
    15000
  );
};

/**
 * Cancellation page for CIP
 */
const CIPCancellation = inject(
  'userStore',
  'commonStore',
  'authStore'
)(
  observer(({ userStore, commonStore, authStore }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { search, pathname } = location;
    const queryParams = queryString.parse(search || '');
    const { hasCalendly, subscriptionId, plan, gateway } = queryParams;

    const [step, setStep] = useState(0); // index of the current step
    const [steps, setSteps] = useState([...DEFAULT_STEPS]); // array of steps to show in the stepper
    const [pageKey, setPageKey] = useState('offer'); // page key to show. ex: offer, steps, survey, thanks, confirm
    const [formattedOffer, setFormattedOffer] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const updateView = () => {
      const { key, stepNumber, updatedSteps } = getCurrentStepData({ pathname, subscriptionId, plan, gateway, navigate });
      setStep(stepNumber); // index of the current step
      setPageKey(key); // page key to show. ex: offer, steps, survey, thanks, confirm
      setSteps(updatedSteps);
    };

    // Update the view when the pathname changes, this means we advanced to the next step
    useEffect(() => {
      // Fire attribution event when we land on the page
      authStore.fireAttributionEvent();
      updateView();
    }, [pathname]);

    /**
     * Fetch the subscription and estimate from the gateway
     * @returns {Promise<void>}
     */
    const performOfferInit = async () => {
      const targetSubscription = await CancellationUtil.fetchTargetSubscription(subscriptionId);
      const planRegion = authStore?.getPlanRegion() || 'US';
      // fetch the subscription and estimate based on gateway
      switch (gateway) {
        case 'paddle':
          return handlePaddleOfferInit(targetSubscription);
        case 'chargebee':
          return handleChargebeeInit(targetSubscription, planRegion);
        default:
          // Invalid gateway, send them back to the membership page
          showErrorToast({ commonStore, message: 'Invalid gateway' });
          navigate('/settings/membership?badGateway=1');
          return null;
      }
    };
    const init = async () => {
      try {
        // If we're missing any of these three, bail. We cannot continue.
        if (!subscriptionId || !plan || !gateway) {
          showErrorToast({ commonStore, message: 'Bad Link' });
          navigate('/settings/membership?badLink=1');
          return;
        }
        setIsLoading(true);
        updateView();
        const offer = await performOfferInit();
        // set the offer
        setFormattedOffer(offer);
      } catch (err) {
        Bugsnag.notify(err);
        setError(err);
        showErrorToast({ commonStore, toastError: err });
        navigate('/settings/membership?error=1');
      } finally {
        setIsLoading(false);
      }
    };

    // on mount fetch all subscriptions and set current subscription based on query params
    useEffect(() => {
      commonStore.setPageTitle(`CIP Cancellation | Cybrary`);

      // reset all react state on unmount
      return () => {
        setStep(0);
        setPageKey('offer');
        setSteps([...DEFAULT_STEPS]);
        setFormattedOffer({});
        setIsLoading(true);
        setError(false);
      };
    }, []);

    // whenever the subscription id or plan changes, restart the retention process
    useEffect(() => {
      init();
    }, [subscriptionId, plan, gateway]);

    /**
     * Cancel subscription based on gateway
     */
    const performCancelationByGateway = async () => {
      // cancel subscription based on gateway
      switch (gateway) {
        case 'chargebee':
          await agents.auth.cancelSubscription({ subscriptionId });
          break;
        case 'paddle':
          await agents.paddle.cancelSubscription({ subscription_id: subscriptionId });
          break;
        default:
          throw new Error('Invalid gateway');
      }
    };

    /**
     * Update subscription based on gateway
     */
    const performUpdateByGateway = async () => {
      const { applyCoupon, couponId, items } = formattedOffer;
      // update subscription based on gateway
      const coupons = applyCoupon ? [couponId] : [];
      const discount_id = applyCoupon ? couponId : null;
      switch (gateway) {
        case 'chargebee':
          // update subscription with coupon for new discounted rate
          await agents.auth.applyCoupon({
            subscriptionId,
            coupons,
          });
          break;
        case 'paddle':
          // update subscription with discount for new discounted rate
          await agents.paddle.updateSubscription({
            subscription_id: subscriptionId,
            discount_id,
            items,
          });
          break;
        default:
          throw new Error('Invalid gateway');
      }
    };

    /**
     * handle the cancellation process
     * redirect to /cybrary-insider-pro/confirm upon success
     */
    const cancelSubscription = async (data) => {
      setIsLoading(true);
      const talkWithTeamParam = data?.talkWithTeam ? '&hasCalendly=1' : '';
      const redirectLink = `/cybrary-insider-pro/confirm?subscriptionId=${subscriptionId}&gateway=${gateway}&plan=${plan}${talkWithTeamParam}`;
      try {
        performCancelationByGateway();
        // fire GA event on successful cancellation
        GaUtil.fireEvent('Survey', 'CIP Cancellation', 'Cancellation Survey Submitted');
        GaUtil.fireEvent('Cancellation Confirmation', 'CIP Cancellation', 'Cancelled');
        // redirect to confirmation page
        navigate(redirectLink);
        // show success toast
        commonStore.triggerToast('success', {
          content: 'Your subscription has successfully been cancelled.',
        });
        // reload user-meta
        userStore.loadUserMeta();
      } catch (err) {
        Bugsnag.notify(err);
        setError(err);
        showErrorToast({ commonStore, toastError: err });
      } finally {
        setIsLoading(false);
      }
      return null;
    };

    /**
     * Applys a coupon to the user's current subscription to reduce the price and prevent churn
     */
    const updateSubscription = async () => {
      setIsLoading(true);
      try {
        performUpdateByGateway();
        // fire GA event on successful coupon application
        GaUtil.fireEvent('Promotion Confirmation', 'CIP Cancellation', 'Coupon');
        commonStore.triggerToast('success', {
          content: 'Thank you. Your subscription has been updated.',
        });
      } catch (err) {
        Bugsnag.notify(err);
        setError(err);
        showErrorToast({ commonStore, toastError: err });
      } finally {
        setIsLoading(false);
      }
    };

    // if the user isn't a cip, redirect them back to the membership page
    if (!userStore.isCip) {
      return <Navigate replace to="/settings/membership?error=isTeams" />;
    }

    // switch statement to render the correct page based on the pageKey
    function CancellationPage() {
      switch (pageKey) {
        case 'offer':
          return (
            <CancellationOffer
              subscriptionId={subscriptionId}
              plan={plan}
              gateway={gateway}
              formattedOffer={formattedOffer}
              loading={isLoading}
              updateSubscription={updateSubscription}
            />
          );
        case 'steps':
          return <CancellationSteps subscriptionId={subscriptionId} plan={plan} gateway={gateway} />;
        case 'survey':
          return <CancellationSurvey cancelSubscription={(data) => cancelSubscription(data)} />;
        case 'thanks':
          return <CancellationThanks formattedOffer={formattedOffer} error={error} loading={isLoading} />;
        case 'confirm':
          return <CancellationConfirm hasCalendly={hasCalendly} gateway={gateway} />;
        default:
          return null;
      }
    }

    return (
      <div className="my-0 mx-auto max-w-[45rem] cancellation-page">
        <div className="mt-16 progress-stepper-container">
          <ProgressStepper steps={steps} activeStep={step} error={error} activeColor={error ? '#ed1e44' : '#0db350'} completeColor="#0db350" />
        </div>
        {!!error && <Message status="error" className="mt-16" msgBody={error.message} msgHeader={`Error ${error.status || ''}`} isDismissible onDismiss={() => setError(null)} />}
        <CancellationPage />
      </div>
    );
  })
);

export default withRouter(CIPCancellation);
