import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { debounce } from 'lodash';
import Bugsnag from '@bugsnag/js';
import FormatUtil from '../../utils/formatUtil';
import Title from '../Title/Title';
import Icon from '../Icon/Icon';
import TeamHierarchyGroups from './TeamHierarchyGroups';
import CreateGroup from './CreateGroup';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import ContentOverlay from '../ContentOverlay/ContentOverlay';
import GroupMembers from './GroupMembers';
import SearchInput from '../Search/SearchInput';
import ResultsSummary from '../AdminTable/ResultsSummary';
import Container from '../Container/Container';
import Modal from '../Modal/Modal';
import Divider from '../Divider/Divider';
import Agents from '../../agents/agents';
import AdminBulkImport from '../AdminBulkImport/AdminBulkImport';

const TeamHierarchy = inject(
  'commonStore',
  'userStore',
  'enterpriseStore',
  'teamHierarchyStore'
)(
  observer(({ team, teamHierarchyStore, userStore, commonStore, enterpriseStore }) => {
    const [createGroupModalOpen, setCreateGroupModalOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [teamInfoSlider, setTeamInfoSlider] = useState({
      open: false,
      parentId: null,
      groupId: null,
      groupName: null,
      parentName: null,
      isSubGroup: false,
      canManageGroup: null,
    });
    const { permissions } = userStore.team;

    /**
     * Filter out groups that do not contain the search query entered, or do not contain children with the search query entered
     */
    const handleFiltering = (data, val) => {
      const dataCopy = [...data];
      if (!val || !val.length) {
        return dataCopy;
      }
      // Loop through the current groups
      const retData = [];
      dataCopy.forEach((group, idx) => {
        if (group.name.toLowerCase().indexOf(val.toLowerCase()) > -1) {
          // This top level group has a match! Let's keep it
          retData.push(group);
        } else if (group.children && group.children.length) {
          // Cycle through children to see if there's any matches further down
          // For now just goes one layer down. Will need to abstract & call recursively if we add more layers
          for (let i = 0; i < group.children.length; i++) {
            if (group.children[i].name.toLowerCase().indexOf(val.toLowerCase()) > -1) {
              dataCopy[idx].defaultOpen = true; // If match was found in sub-group, we want the parent group's collapse state to be open
              retData.push(group);
              break;
            }
          }
        }
      });
      return retData;
    };

    const searchGroups = debounce((val) => {
      setSearchValue(val);
    }, 500);

    const refreshData = (preserveState) => {
      teamHierarchyStore.getTeamHierarchy(team, true, !!preserveState);
    };

    useEffect(() => {
      refreshData(); // Fetch fresh data to pick up on anything that could've updated for the user (added to groups, perm changes, etc.)
    }, [team.id]);

    /**
     * Callback to refresh data and close modal after creating group
     */
    const createGroupCallback = () => {
      setCreateGroupModalOpen(false);
      refreshData();
    };

    /**
     * Create a new group inline (when user types directly into row)
     */
    const newGroupInline = (parentId, groupName) => {
      enterpriseStore
        .addNewGroup(parentId, { name: groupName })
        .then(() => {
          commonStore.triggerToast('success', {
            content: 'New group has been added',
          });
          refreshData(true);
        })
        .catch((error) => {
          commonStore.triggerToast('error', {
            errorCode: error.response && error.response.status ? error.response.status : null,
          });
        });
    };

    /**
     * Delete Group and refresh data
     */
    const deleteGroup = (parentId, groupId) => {
      enterpriseStore
        .deleteGroup(parentId, groupId)
        .then(() => {
          commonStore.triggerToast('success', {
            content: 'Group has been deleted',
          });
          refreshData(true);
        })
        .catch((e) => {
          commonStore.triggerToast('error', {
            errorCode: (e && e.response && e.response.status) || null,
          });
        });
    };

    /**
     * Trigger confirm box prior to deleting group. Message varies depending on if a top-level group or sub-group
     */
    const deleteGroupConfirm = (parentId, groupId, isSubgroup, groupName, parentName) => {
      const confirmContent = (
        <div>
          <p>
            Are you sure you want to delete <span className="font-bold">{groupName}</span>? All sub-groups will also be deleted. This action can not be undone.
          </p>
          {isSubgroup ? (
            <p>
              All members of <span className="font-bold">{groupName}</span> will remain on <span className="font-bold">{parentName}</span>.
            </p>
          ) : null}
        </div>
      );
      commonStore.triggerConfirm({
        content: confirmContent,
        cancel: () => commonStore.resetConfirmState(),
        continue: () => {
          commonStore.resetConfirmState();
          deleteGroup(parentId, groupId);
        },
      });
    };

    /**
     * Toggle the show/hide of content overlay
     */
    const toggleTeamInfoSlider = (parentId, groupId, groupName, parentName, isSubgroup, canManageGroup) => {
      if (!parentId && !groupId) {
        // We are shutting the slider. Let's refresh data in case something changed (new admin, users removed, etc.)
        refreshData();
      } else {
        // We are opening the slider, lets set this to our preferred group
        userStore.setPreferredGroup(team.id, groupId);
      }
      setTeamInfoSlider({
        open: !teamInfoSlider.open,
        parentId: parentId || null,
        groupId: groupId || null,
        groupName: groupName || null,
        parentName: parentName || null,
        isSubgroup: !!isSubgroup,
        canManageGroup,
      });
    };

    /**
     * Display the actual hierarchy component when ready
     */
    const renderTeamHierarchy = () => {
      if (teamHierarchyStore.isLoading(team.id)) {
        return (
          <Container>
            <Loading message="Loading..." />
          </Container>
        );
      }
      const error = teamHierarchyStore.getError(team.id);
      if (error) {
        return (
          <Container>
            <StyledError error={error} />
          </Container>
        );
      }
      if (!teamHierarchyStore.trees[team.id] || !teamHierarchyStore.trees[team.id].data) {
        return null;
      }
      // Filter data down based on search input
      const filteredData = handleFiltering(teamHierarchyStore.trees[team.id].data, searchValue);
      // Return list of direct children groups at team level, which will recursively call self for nested subgroups
      return (
        <>
          {!!searchValue && (
            <div className="mb-4">
              <ResultsSummary numOfResults={filteredData.length} className="ml-4" />
            </div>
          )}
          <div className="overflow-x-auto hierarchy-container">
            <div style={{ marginTop: 0, minWidth: '62rem' }}>
              <TeamHierarchyGroups
                data={filteredData}
                refreshData={refreshData}
                parentId={team.id}
                newGroupInline={newGroupInline}
                deleteGroup={deleteGroupConfirm}
                getTeamInfo={toggleTeamInfoSlider}
                handleColSort={teamHierarchyStore.setHierarchySorting}
                getSortMap={teamHierarchyStore.getSortOrders}
                searchVal={searchValue}
                canManageTeam={permissions && permissions.canManageTeam}
                canManageGroup={permissions && permissions.canManageTeam}
              />
            </div>
          </div>
        </>
      );
    };

    /**
     * Trigger confirm box prior to changing user role. Message varies depending on if a top-level group or sub-group
     */
    const getRoleConfirm = (role, changeRole) => {
      const confirmContent = (
        <div>
          <p key="main-line">
            Are you sure you want to make these individuals a {FormatUtil.convertRoleName(role, 'group')} of{' '}
            <span key="group-name" className="font-bold">
              {teamInfoSlider.groupName}
            </span>
            ?
          </p>
          {teamInfoSlider.isSubgroup ? (
            <p key="sub-line">
              This will not change their permissions for{' '}
              <span key="parent-name" className="font-bold">
                {teamInfoSlider.parentName}
              </span>
              .
            </p>
          ) : (
            <p key="sub-line">They will also have {FormatUtil.convertRoleName(role, 'group')} permissions for all sub-groups.</p>
          )}
        </div>
      );
      commonStore.triggerConfirm({
        content: confirmContent,
        cancel: () => commonStore.resetConfirmState(),
        continue: () => {
          changeRole();
        },
      });
    };

    /**
     * Trigger confirm box prior to removing user. Message varies depending on if a top-level group or sub-group
     */
    const getRemoveConfirm = (removeMember) => {
      const confirmContent = (
        <div>
          <p key="main-line">
            Are you sure you want to remove these members from{' '}
            <span key="group-name" className="font-bold">
              {teamInfoSlider.groupName}
            </span>
            ?
          </p>
          {teamInfoSlider.isSubgroup ? (
            <p key="sub-line">
              They will still remain members of{' '}
              <span key="parent-name" className="font-bold">
                {teamInfoSlider.parentName}
              </span>
              .
            </p>
          ) : (
            <p key="sub-line">They will also be removed from all sub-groups.</p>
          )}
        </div>
      );
      commonStore.triggerConfirm({
        content: confirmContent,
        cancel: () => commonStore.resetConfirmState(),
        continue: () => {
          removeMember();
        },
      });
    };

    const getGroupsExportCSV = async () => {
      try {
        const teamHierarchy = await Agents.admin.getTeamHierarchy(team.id, '?format=csv');
        FormatUtil.downloadCSV(teamHierarchy, `${FormatUtil.lowerCaseHyphenText(team.name)}_groups.csv`);
      } catch (e) {
        Bugsnag.notify(e);
        commonStore.triggerToast('error', { content: 'Something went wrong. Unable to retrieve team groups at this time.' });
      }
    };

    return (
      <Container className="team-hierarchies">
        <div className="items-center pt-16 mb-12 border-b-xs border-gray-400 md:flex md:justify-between md:pb-12">
          <Title title="Groups" omitPadding />
          <div className="my-8 md:my-0">
            {permissions && permissions.canManageTeam ? (
              <Modal
                open={createGroupModalOpen}
                paddingBottom="pb-0"
                toggle={() => setCreateGroupModalOpen(false)}
                position="center"
                trigger={
                  <button
                    className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm"
                    onClick={() => setCreateGroupModalOpen(true)}
                  >
                    + Add Group
                  </button>
                }
                ariaLabel="Add Group Modal"
                sizeClasses="sm:w-2/3 2xl:w-1/2"
              >
                <div className="p-4">
                  <h2 className="mb-4 text-2xl font-black">Add Group</h2>
                  <div className="mb-8 max-w-md">
                    <h3 className="text-lg font-bold text-black">Create New Group</h3>
                    <p className="text-xs">Add a new empty group.</p>
                    <CreateGroup teamId={team.id} view="add" callback={createGroupCallback} />
                  </div>
                  <Divider horizontal className="mb-6">
                    <p>OR</p>
                  </Divider>
                  <h3 className="text-lg font-bold text-black">Bulk Group Creation</h3>
                  <p className="mb-6 text-xs">Download and complete the CSV template to create many groups at once.</p>
                  <AdminBulkImport
                    teamId={team.id}
                    importTypeDisplay="import" // For UI display purposes
                    formConfig={{
                      formName: 'bulkGroupImport',
                      submitText: 'Create Groups',
                      instructions: (
                        <p className="-mt-4 mb-6 text-xs">
                          Upload the completed CSV template and click “Create Groups” to create new groups.
                          <br />
                          To view existing groups, download the{' '}
                          <button className="text-cyb-pink-500 hover:underline" onClick={() => getGroupsExportCSV()}>
                            Current Groups Report <Icon name="download" className="inline-block w-4 h-4" />
                          </button>
                          .
                        </p>
                      ),
                    }}
                    importConfig={{
                      templateFileName: 'cybrary_group_import',
                      routesObject: 'enterprise',
                      importType: 'group', // For classification of import type in routes
                      dataKey: 'import', // Post data key that CSV will be included in
                      confirmText: 'Are you sure you want to create these groups? The Bulk Group Creation function will create multiple groups at once.',
                      logDescription: 'Review and download past Bulk Creation CSVs.',
                    }}
                  />
                </div>
              </Modal>
            ) : null}
          </div>
        </div>
        <div className="mb-4">
          <SearchInput width="sm:w-96" placeholder="Search Groups" onChange={(e) => searchGroups(e.target.value)} />
        </div>
        {renderTeamHierarchy()}
        <ContentOverlay open={teamInfoSlider.open} dismiss={toggleTeamInfoSlider} ariaLabelledBy="group-title">
          <GroupMembers
            orgId={team.id} // Prop needed for dashboard link
            parentId={teamInfoSlider.parentId}
            groupId={teamInfoSlider.groupId}
            customRoleConfirmHandler={getRoleConfirm}
            customRemoveConfirmHandler={getRemoveConfirm}
            parentGroupName={teamInfoSlider.isSubgroup && teamInfoSlider.parentName ? teamInfoSlider.parentName : null}
            viewOnly={!teamInfoSlider.canManageGroup}
          />
        </ContentOverlay>
      </Container>
    );
  })
);

export default TeamHierarchy;
