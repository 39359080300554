import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import Bugsnag from '@bugsnag/js';

import AdminAddMembers from './AdminAddMembers';
import AdminBulkImport from '../AdminBulkImport/AdminBulkImport';
import Collapsible from '../Collapsible/Collapsible';
import Icon from '../Icon/Icon';
import Divider from '../Divider/Divider';
import Agents from '../../agents/agents';
import FormatUtil from '../../utils/formatUtil';

function SectionWrapper({ heading, openByDefault, children, description }) {
  const [open, setOpen] = useState(!!openByDefault);
  return (
    <Collapsible
      onOpening={() => setOpen(true)}
      onClosing={() => setOpen(false)}
      open={open}
      trigger={
        <>
          <div className="flex gap-x-2 items-center">
            <h3 className="text-lg font-bold">{heading}</h3>
            <Icon name="chevron-down" className={`text-black w-4 h-4 ${open ? 'transform rotate-180' : ''}`} />
          </div>
          {description && <p className="text-xs">{description}</p>}
        </>
      }
    >
      <div className="mt-4">{children}</div>
    </Collapsible>
  );
}
const AdminTeamOnboardingTools = inject('commonStore')(
  observer(({ commonStore, team, teamName, addMember }) => {
    const getGroupsExportCSV = async () => {
      try {
        const teamHierarchy = await Agents.admin.getTeamHierarchy(team, '?format=csv');
        FormatUtil.downloadCSV(teamHierarchy, `${FormatUtil.lowerCaseHyphenText(teamName)}_groups.csv`);
      } catch (e) {
        Bugsnag.notify(e);
        commonStore.triggerToast('error', { content: 'Something went wrong. Unable to retrieve team groups at this time.' });
      }
    };

    return (
      <div>
        <SectionWrapper heading="Search For Users" openByDefault>
          <AdminAddMembers team={team} addFunc={addMember} />
        </SectionWrapper>
        <Divider marginTop="mt-8" marginBottom="mb-8" />
        <SectionWrapper heading="Bulk User Invite" description="Download and complete the CSV template to invite many users at once.">
          <AdminBulkImport
            teamId={team}
            importTypeDisplay="invite" // For UI display purposes
            formConfig={{
              formName: 'bulkUserInvite',
              submitText: 'Bulk Invite',
              instructions: (
                <p className="-mt-4 mb-6 text-xs">
                  Upload the completed CSV template and click “Bulk Invite” to send many team invitations.
                  <br />
                  To view the Group IDs, download the{' '}
                  <button className="text-cyb-pink-500 hover:underline" onClick={() => getGroupsExportCSV()}>
                    Current Groups Report <Icon name="download" className="inline-block w-4 h-4" />
                  </button>
                  .
                </p>
              ),
            }}
            importConfig={{
              templateFileName: 'cybrary_user_invite',
              routesObject: 'admin',
              importType: 'team-invite', // For classification of import type in routes
              dataKey: 'import', // Post data key that CSV will be included in
              confirmText: 'Are you sure you want to invite these users? The Bulk Invite function will send Teams invitations to every email in the CSV template.',
              logDescription: 'Review and download past Bulk Invite CSVs.',
            }}
          />
        </SectionWrapper>
        <Divider marginTop="mt-8" marginBottom="mb-8" />
        <SectionWrapper heading="Bulk Group Creation" description="Download and complete the CSV template to create many groups at once.">
          <AdminBulkImport
            teamId={team}
            importTypeDisplay="import" // For UI display purposes
            formConfig={{
              formName: 'bulkGroupImport',
              submitText: 'Create Groups',
              instructions: (
                <p className="-mt-4 mb-6 text-xs">
                  Upload the completed CSV template and click “Create Groups” to create new groups.
                  <br />
                  To view existing groups, download the{' '}
                  <button className="text-cyb-pink-500 hover:underline" onClick={() => getGroupsExportCSV()}>
                    Current Groups Report <Icon name="download" className="inline-block w-4 h-4" />
                  </button>
                  .
                </p>
              ),
            }}
            importConfig={{
              templateFileName: 'cybrary_group_import',
              routesObject: 'admin',
              importType: 'group', // For classification of import type in routes
              dataKey: 'import', // Post data key that CSV will be included in
              confirmText: 'Are you sure you want to create these groups? The Bulk Group Creation function will create multiple groups at once.',
              logDescription: 'Review and download past Bulk Creation CSVs.',
            }}
          />
        </SectionWrapper>
      </div>
    );
  })
);

export default AdminTeamOnboardingTools;
