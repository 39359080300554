import React from 'react';
import { inject, observer } from 'mobx-react';
import Icon from '../Icon/Icon';
import Divider from '../Divider/Divider';
import SelectTiles from '../SelectTiles/SelectTiles';
import AddLink from '../AddLink/AddLink';
import './content-preferences.css';

function SelectionBreadcrumbs({ crumbs, handleClick }) {
  if (!crumbs || !crumbs.length) {
    return null;
  }
  const crumbsLength = crumbs.length;
  return (
    <div className="breadcrumbs">
      {crumbs.map((crumb, idx) => {
        const isActiveCrumb = idx + 1 === crumbsLength;
        return (
          <span key={crumb}>
            {idx > 0 && <Icon name="chevron-left" className="inline-block mx-2 w-4 h-4" />}
            {!isActiveCrumb ? (
              <button className="text-cyb-pink-500 cursor-pointer" onClick={() => handleClick()}>
                {crumb}
              </button>
            ) : (
              <span>{crumb}</span>
            )}
          </span>
        );
      })}
    </div>
  );
}

function ContentPreferencesCrossLinking({ scope, teams }) {
  if (scope === 'team') {
    return (
      <div className="text-gray-600">
        Looking for your personal content preferences? You can find it in your{' '}
        <AddLink className="text-cyb-pink-500 hover:text-black underline" to="/settings/account?content-preferences=1">
          Account Settings
        </AddLink>
        .
      </div>
    );
  }
  // User scope, linking to teams -- Only if you are an admin of a team
  const adminTeams = teams && Object.keys(teams).length ? Object.keys(teams).filter((teamId) => teams[teamId].permissions && teams[teamId].permissions.canManageTeam) : null;
  if (adminTeams && adminTeams.length) {
    if (adminTeams.length === 1) {
      return (
        <div>
          Looking for content preferences for your team? You can find it in your{' '}
          <AddLink className="text-cyb-pink-500 hover:text-black underline" to={`/enterprise/${adminTeams[0]}/settings`}>
            Team Settings
          </AddLink>
          .
        </div>
      );
    }
    // If admin of more than one team, list them out
    return (
      <div className="cross-linking-container">
        Looking for content preferences for your team? You can find it in your Team Settings.
        <ul>
          {adminTeams.map((teamId) => {
            return (
              <li key={teamId}>
                <AddLink className="text-cyb-pink-500 hover:text-black underline" to={`/enterprise/${teamId}/settings`}>
                  {teams[teamId].name}
                </AddLink>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  return null;
}

const ContentPreferences = inject(
  'catalogStore',
  'userStore',
  'enterpriseStore'
)(
  observer(
    class ContentPreferences extends React.Component {
      state = {
        scope: 'user',
        certificationGroup: null,
        topicGroup: null,
      };

      componentDidMount() {
        const newState = {
          ...this.state,
        };
        this.props.catalogStore.getSkillAndCertOptions();
        if (this.props.teamId) {
          newState.scope = 'team';
          this.setState(newState);
        }
        this.props[this.props.teamId ? 'enterpriseStore' : 'userStore'].getContentPreferences(this.props.teamId);
      }

      componentWillUnmount() {
        this.props[this.state.scope === 'team' ? 'enterpriseStore' : 'userStore'].setDefaultContentPreferences();
      }

      focusOnTermHeaders = (term) => {
        if (term === 'topic') {
          this.skillsRef.focus();
        } else if (term === 'certification') {
          this.certificationRef.focus();
        }
      };

      handleSelection = (selection, term) => {
        const { skillAndCertOptions, findTermObjById } = this.props.catalogStore;
        const { addContentPreference } = this.props[this.state.scope === 'team' ? 'enterpriseStore' : 'userStore'];
        const newState = {
          ...this.state,
        };
        // Determine if this is a group, or an actual term selection
        if (!this.state[`${term}Group`] && skillAndCertOptions[`${term}Groups`] && skillAndCertOptions[`${term}Groups`].indexOf(selection.value) > -1) {
          newState[`${term}Group`] = selection.value;
        } else {
          const fullSelection = findTermObjById(selection.value);
          // Save display (name) as value - Uses term name rather than ID for values
          if (fullSelection) {
            addContentPreference(term, fullSelection, this.props.teamId);
          }
        }
        this.setState(newState);
        this.focusOnTermHeaders(term);
      };

      removeSelection = (selection, term) => {
        const { findTermObjById } = this.props.catalogStore;
        const { deleteContentPreference } = this.props[this.state.scope === 'team' ? 'enterpriseStore' : 'userStore'];
        const fullSelection = findTermObjById(selection.value);
        // Uses term name rather than ID for values
        if (fullSelection) {
          deleteContentPreference(term, fullSelection, this.props.teamId);
        }
        this.focusOnTermHeaders(term);
      };

      getTermSelectOptions = (term) => {
        const { skillAndCertOptions } = this.props.catalogStore;
        const { contentPreferences } = this.props[this.state.scope === 'team' ? 'enterpriseStore' : 'userStore'];
        const groupSelected = this.state[`${term}Group`];
        if (!skillAndCertOptions.data) {
          return [];
        }
        // If a group was selected, need to show terms in that group
        if (groupSelected) {
          const selectedValues = contentPreferences[term].map((item) => {
            return item.id;
          });
          const selectOptions = [];
          // Get options for the specified term and omit those already selected
          skillAndCertOptions.data.forEach((item) => {
            if (item.category.toLowerCase() === term && item.group === groupSelected && selectedValues.indexOf(item.id) === -1) {
              selectOptions.push({
                display: item.name,
                value: item.id,
              });
            }
          });
          return selectOptions;
        }
        // Otherwise, show groups
        return skillAndCertOptions[`${term}Groups`].map((item) => {
          // Groups don't have ID's so just use name as value as well
          return {
            display: item,
            value: item,
          };
        });
      };

      getBreadcrumbs = (term) => {
        const groupSelected = this.state[`${term}Group`];
        const crumbs = [];
        if (groupSelected) {
          crumbs.push(term === 'certification' ? 'Certification Body' : 'Domain Areas');
          crumbs.push(groupSelected);
        }
        return crumbs;
      };

      handleCrumbClick = (term) => {
        // Right now, there's only two levels, so clicking a crumb just resets/removes the group selection
        const newState = {
          ...this.state,
        };
        newState[`${term}Group`] = null;
        this.setState(newState);
      };

      getSelectedValues = (term) => {
        const { contentPreferences } = this.props[this.state.scope === 'team' ? 'enterpriseStore' : 'userStore'];
        const selections = contentPreferences[term];
        if (!selections || !selections.length) {
          return [];
        }
        return selections.map((item) => {
          return {
            display: `${item.group} | ${item.name}`,
            value: item.id,
          };
        });
      };

      render() {
        const { skillAndCertOptions } = this.props.catalogStore;
        const { contentPreferences } = this.props[this.state.scope === 'team' ? 'enterpriseStore' : 'userStore'];
        const { scope, certificationGroup, topicGroup } = this.state;
        return (
          <div className="content-preferences">
            <div>
              {scope === 'team' ? (
                <p className="mb-4 text-gray-600">
                  Help us better understand your desired areas of focus so we can incorporate your input into future content development. Which of the following skill areas are
                  important to your team?
                </p>
              ) : null}
              <ContentPreferencesCrossLinking scope={scope} teams={this.props.userStore.userTeams} />
            </div>
            <Divider />
            <div>
              <h3
                className="pb-4 text-xl font-bold"
                ref={(ref) => {
                  this.skillsRef = ref;
                }}
                tabIndex="-1"
              >
                Skills
              </h3>
              <p className="mb-4 text-gray-600">Which skills are the most important for you? Please select all that apply.</p>
              <SelectionBreadcrumbs crumbs={this.getBreadcrumbs('topic')} handleClick={() => this.handleCrumbClick('topic')} />
              <SelectTiles
                selectedOptions={this.getSelectedValues('topic')}
                handleSelection={(selection) => this.handleSelection(selection, 'topic')}
                removeSelection={(selection) => this.removeSelection(selection, 'topic')}
                data={this.getTermSelectOptions('topic')}
                loading={skillAndCertOptions.loading || contentPreferences.loading}
                error={skillAndCertOptions.error || contentPreferences.error}
                emptyMessage="All skills are selected"
                displayLength={20}
                omitAddIcon={!topicGroup}
              />
            </div>
            <Divider />
            <div>
              <h3
                className="pb-4 text-xl font-bold"
                ref={(ref) => {
                  this.certificationRef = ref;
                }}
                tabIndex="-1"
              >
                Certifications
              </h3>
              <SelectionBreadcrumbs crumbs={this.getBreadcrumbs('certification')} handleClick={() => this.handleCrumbClick('certification')} />
              <p className="mb-4 text-gray-600">Which certifications are the most important for you? Please select all that apply.</p>
              <SelectTiles
                selectedOptions={this.getSelectedValues('certification')}
                handleSelection={(selection) => this.handleSelection(selection, 'certification')}
                removeSelection={(selection) => this.removeSelection(selection, 'certification')}
                data={this.getTermSelectOptions('certification')}
                loading={skillAndCertOptions.loading || contentPreferences.loading}
                error={skillAndCertOptions.error || contentPreferences.error}
                emptyMessage="All certifications are selected"
                displayLength={20}
                omitAddIcon={!certificationGroup}
              />
            </div>
          </div>
        );
      }
    }
  )
);

export default ContentPreferences;
