import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import Icon from '../../components/Icon/Icon';
import Header from '../../components/Header/Header';
import Segment from '../../components/Segment/Segment';
import { useAuth } from '../../contexts/UseAuth';
import Container from '../../components/Container/Container';
import agents from '../../agents/agents';
/**
 * Intermediary logout page.
 */
const Logout = inject('authStore')(
  observer(({ authStore }) => {
    const auth = useAuth();
    useEffect(() => {
      authStore.fireAttributionEvent().then(async () => {
        // Logout through the backend first
        await agents.authGoogle.logout();

        // Then logout through the client
        auth.signout();
      });
    }, []);

    return (
      <Container>
        <Segment className="py-24 bg-gray-200">
          <Header className="text-center">
            <Icon name="log-out" className="inline-block mr-2 w-14 h-14 text-black" />
            Logout In Process...
          </Header>
        </Segment>
      </Container>
    );
  })
);

export default Logout;
