import React from 'react';
import { debounce, flatten } from 'lodash';
import queryString from 'query-string';
import { inject, observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';
import withRouter from '../Router/withRouter';
import SearchUtil from '../../utils/searchUtil';
import PermalinkUtil from '../../utils/permalinkUtil';
import SearchInput from '../Search/SearchInput';
import DropdownContainer from './DropdownContainer';
import If from '../If/If';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import AddLink from '../AddLink/AddLink';

const CONTENT_GROUPS = [['Course', 'MicroCourse'], '"Career Path"', '"Certification Prep"', '"Virtual Lab"', 'Assessment', '"Practice Test"', '"Challenge"'];

function Label({ children, classes }) {
  const classNames = twMerge('inline-block px-2 py-1 rounded-md border font-normal', classes || '');

  return (
    <div className={classNames} style={{ fontSize: '0.625rem' }}>
      {children}
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
function SearchResultsList({ hits, title, blurMenu, navigate, queryID, userId, checkEnrollmentProgress, toggleSearchBar, query }) {
  return (
    <div className="">
      <h4 className="px-8 mb-2 text-lg">
        <span className="font-bold">{title}</span>
      </h4>
      {hits && hits.length ? (
        <div className="">
          {hits.map((item, idx) => {
            const positionIdx = idx + 1;
            const queryParams = `?queryID=${queryID}&objectID=${item.objectID}`;
            const permalink = PermalinkUtil.formatInApp(item.permalink + queryParams, item.content_type.nice_name);
            const progress = checkEnrollmentProgress(item.objectID);
            return (
              <button
                className="flex flex-row justify-between items-center py-2 px-8 w-full text-sm hover:font-bold text-left text-gray-600 hover:text-black hover:bg-gray-50 border border-transparent hover:border-y-slate-200 cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  blurMenu();
                  SearchUtil.searchInsightEvent(userId, queryID, item.objectID, positionIdx);
                  // Fire Snowplow event for search result click
                  trackSnowplowEvent({
                    category: 'NavigationBarSearch',
                    action: `clickedSearchLink`,
                    label: query, // The users current search (e.g. "python")
                    value: item.id, // The ID of the content item clicked (e.g. "12345")
                    property: `Clicked link to content in nav bar search dropdown`, // Description of the action
                  });
                  if (toggleSearchBar) {
                    toggleSearchBar(false);
                  }
                  navigate(permalink);
                }}
                key={permalink}
              >
                <div className="flex flex-auto pr-5">{item.title}</div>
                <div className="flex flex-row items-center">
                  <If condition={progress !== null}>
                    <Label classes="whitespace-nowrap mr-1">{progress}% Complete</Label>
                  </If>
                  <Label classes="whitespace-nowrap">{item.content_group}</Label>
                </div>
              </button>
            );
          })}
        </div>
      ) : (
        <p className="pl-8 text-gray-600">No results were found.</p>
      )}
    </div>
  );
}

const SearchCatalog = inject(
  'userStore',
  'enrollmentStore'
)(
  observer(
    class SearchCatalog extends React.Component {
      state = {
        query: '',
        open: false,
        dropdownStyle: {},
        tagsInfo: 'Popular',
        hits: [],
        queryID: '',
        queryIDs: [],
      };

      debouncedSearch = debounce(() => {
        this.performSearch();
      }, 150);

      showDropdown = () => {
        const desktopInputLocation = document.getElementById('sitewide-search').getBoundingClientRect();
        const mobileInputLocation = document.getElementById('mobile-sitewide-search').getBoundingClientRect();
        const inputLocation = desktopInputLocation.width ? desktopInputLocation : mobileInputLocation;
        const top = inputLocation.top + inputLocation.height - 10;
        const maxHeightBuffer = top + 25;
        const minWidth = 300;
        const width = inputLocation.width > minWidth ? inputLocation.width : minWidth;
        this.performSearch();

        const newState = {
          ...this.state,
          open: true,
          dropdownStyle: {
            ...this.state.dropdownStyle,
            width: `${width}px`,
            maxWidth: `${width}px`,
            maxHeight: `calc(100vh - ${maxHeightBuffer}px)`,
          },
        };
        this.setState(newState);
        this.props?.setOpen?.(true);
      };

      closeDropdown = () => {
        const newState = {
          ...this.state,
          open: false,
          query: '',
        };
        this.setState(newState);
        this.props?.setOpen?.(false);
      };

      handleFocus = () => {
        this.showDropdown();
      };

      handleBlur = () => {
        setTimeout(() => {
          this.closeDropdown();
        }, 150);
      };

      handleChange = (e) => {
        const newState = { ...this.state, query: e.target.value };
        this.setState(newState, () => {
          this.debouncedSearch();
        });
      };

      getDestination = (view) => {
        let destination = `/browse/refined`;
        const params = {};
        if (this.state.query) {
          params.q = this.state.query;
        }
        if (view) {
          params.view = view;
        }
        if (params.view || params.q) {
          destination += `?${queryString.stringify(params)}`;
        }
        return destination;
      };

      redirectToDestination = (destination) => {
        const newState = {
          ...this.state,
          query: '',
          open: false,
        };
        this.setState(newState);
        this.props?.setOpen?.(false);
        PermalinkUtil.redirectToDestination(destination, this.props.navigate);
      };

      buttonPress = (e, type) => {
        e.preventDefault();
        const destination = this.getDestination(type);
        // fire snowplow event when user clicks the "View all" button for a specific content type
        trackSnowplowEvent({
          category: 'NavigationBarSearch',
          action: `clickedViewAllLink|${type}`,
          label: this.state.query, // The users current search (e.g. "python")
          property: `Clicked "View All" link in nav bar search dropdown for the ${type} content type`, // Description of the action
        });
        this.redirectToDestination(destination);
      };

      handleSearchKeyPress = (e) => {
        // Check to see if we pressed enter key
        const key = e.which || e.keyCode;
        if (key === 13) {
          const destination = this.getDestination();
          // fire Snowplow event when user searches using the nav bar search box
          trackSnowplowEvent({
            category: 'NavigationBarSearch',
            action: 'search',
            label: this.state.query, // What the user searched for (e.g. "python")
            property: 'Searched using nav bar search box', // Description of the action
          });
          // Clear the input
          e.target.value = '';
          e.target.blur();
          // Redirect to the destination
          this.redirectToDestination(destination);
        }
      };

      performSearch = (equalize = false) => {
        const hitsPerPage = 10;
        const index = process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX;
        const userId = this.props.userStore?.user?.id;
        let options = [];

        const filters = {
          display_in_catalog: true,
          tags_info: this.state.tagsInfo,
        };

        const { query } = this.state;

        if (query) {
          delete filters.tags_info;
        }

        if (equalize) {
          const content_group_filters = CONTENT_GROUPS.map((group) => ({
            ...filters,
            content_group: group,
          }));

          options = options.concat(
            content_group_filters.map((obj) => ({
              hitsPerPage,
              filters: SearchUtil.filtersFromObject(obj),
            }))
          );
        } else {
          options.push({
            hitsPerPage,
            filters: SearchUtil.filtersFromObject({ ...filters, content_group: flatten(CONTENT_GROUPS) }),
          });
        }

        const promises = options.map((option) => SearchUtil.fetchFromAlgolia(index, option, query, { userId }));

        Promise.all(promises).then((results) => {
          let hits = [];

          if (equalize) {
            results.forEach((result) => {
              hits = hits.concat(result.hits.slice(0, 2));
            });
          } else {
            hits = hits.concat(results[0].hits);
          }

          const newState = {
            ...this.state,
            hits,
            queryID: results[0].queryID,
            queryIDs: results.map((result) => result.queryID),
          };

          this.setState(newState);
        });
      };

      checkEnrollmentProgress = (id) => {
        return this.props.enrollmentStore.getEnrollmentProgressForContentDescriptionId(id);
      };

      render() {
        const { query, hits, queryID, dropdownStyle, open } = this.state;

        const title = query ? 'Results' : 'Popular Content';
        const userId = this.props.userStore?.user?.id;

        return (
          <div className="relative">
            <div className="relative" style={{ zIndex: '801' }}>
              <SearchInput
                width="w-full"
                inputClasses={this.props.inputClasses}
                handleFocus={this.handleFocus}
                onChange={this.handleChange}
                onKeyPress={this.handleSearchKeyPress}
                visible={open}
                onClose={() => this.closeDropdown()}
                value={this.state.query}
                isSearchCatalog
              />
            </div>
            <DropdownContainer
              ariaLabel="Close search results"
              classes="left-0 mt-1 overflow-y-scroll py-8"
              width="25rem"
              customStyles={{ ...dropdownStyle }}
              visible={open}
              onClose={() => this.handleBlur()}
            >
              <SearchResultsList
                title={title}
                hits={hits}
                queryID={queryID}
                navigate={this.props.navigate}
                userId={userId}
                blurMenu={() => this.handleBlur()}
                checkEnrollmentProgress={this.checkEnrollmentProgress}
                toggleSearchBar={this.props.toggleSearchBar}
                query={query}
              />
              <AddLink
                onClick={() => this.redirectToDestination(this.getDestination())}
                className="flex justify-center items-center py-3 px-6 mx-auto mt-5 w-1/2 text-sm font-bold text-black hover:text-black bg-gray-200 hover:bg-cyb-gray-500 rounded-full"
              >
                <span>Explore All</span>
              </AddLink>
            </DropdownContainer>
          </div>
        );
      }
    }
  )
);

export default withRouter(SearchCatalog);
