import React from 'react';
import { useLocation } from 'react-router-dom';
import AddLink from '../AddLink/AddLink';

function UpgradeLogo() {
  const location = useLocation() || window.location;
  const isUpgradePage = /upgrade\/(teams-)?checkout/g.test(location.pathname);
  const isUpdatePage = /upgrade\/update/g.test(location.pathname);
  if (!isUpgradePage && !isUpdatePage) {
    return null;
  }
  return (
    <div className="flex justify-between items-center w-full bg-white">
      <div className="pb-4 mt-6 mr-4 mb-0 ml-8">
        <AddLink to="/" aria-label="Home" className="block">
          <img
            className="inline-block ml-0"
            src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg"
            alt="Cybrary"
            width="120"
            height="31"
          />
        </AddLink>
      </div>
    </div>
  );
}

export default UpgradeLogo;
