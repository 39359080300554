import React, { useMemo } from 'react';
import { useContinueLearningContext } from '../../providers/ContinueLearningProvider';
import FormatUtil from '../../utils/formatUtil';
import AddLink from '../AddLink/AddLink';
import ProgressBar from '../ProgressBar/ProgressBar';
import { ContinueLearningButton, StartLearningButton } from './ContinueLearningButtons';
import ContinueLearningFeatures from './ContinueLearningFeatures';

/**
 * Single content row display for in progress content and bookmarked content.
 * Can display either 1) an enrollment or 2) bookmarked content data object.
 * @param {Object} enrollment - Enrollment object to show inside the row
 * @param {Object} bookmarkedContentData - Bookmarked content to show inside the row
 * @param {Boolean} isBookmark - Whether or not this is a bookmarked content row
 * @returns <ContinueLearningContentRow />
 */
export default function ContinueLearningContentRow({ enrollment, bookmarkedContentData, isBookmark }) {
  // If this is a bookmarked content row, try to find a matching enrollment from the context
  let bookmarkEnrollment = {};
  if (isBookmark) {
    const { enrollments: incompleteEnrollments } = useContinueLearningContext();
    bookmarkEnrollment = incompleteEnrollments.find((incompleteEnrollment) => incompleteEnrollment?.content_description_id === bookmarkedContentData?.id);
  }
  const { transformedData } = enrollment || bookmarkEnrollment || {};
  const enrollmentId = enrollment?.id || bookmarkEnrollment?.id;
  const { currentContentItem, secondsCompleted, secondsTotal, activitiesCompleted, activitiesTotal } = transformedData || {};
  // set data that is only available from the course enrollment data
  const learningSecondsCompleted = secondsCompleted || 0;
  const learningSecondsTotal = secondsTotal || bookmarkedContentData?.duration_seconds; // default to algolia duration

  const completedPercent = useMemo(() => {
    if (!activitiesCompleted || !activitiesTotal) return 0;
    return Math.floor((activitiesCompleted / activitiesTotal) * 100);
  }, [activitiesCompleted, activitiesTotal]);

  const remainingTime = useMemo(() => {
    const remainingSeconds = learningSecondsTotal - learningSecondsCompleted || learningSecondsTotal;
    return FormatUtil.formatTime(remainingSeconds, 'hma')?.trim() || 'Unknown time';
  }, [learningSecondsCompleted, learningSecondsTotal]);

  // set variable data with priority to the course enrollment data, falling back to algolia data if no enrollments found
  const thumbnailUrl = currentContentItem?.thumbnail_url || bookmarkedContentData?.thumbnail_url;
  const title = transformedData?.title || currentContentItem?.title || bookmarkedContentData?.title;
  const permalink = currentContentItem?.permalink || bookmarkedContentData?.permalink;
  const type = currentContentItem?.content_type?.nice_name || bookmarkedContentData?.content_type?.nice_name;
  const totalEnrollments = enrollment?.total_enrollments || bookmarkedContentData?.total_enrollments || bookmarkedContentData?.bookmarks_count;
  const difficulty = currentContentItem?.level?.name || bookmarkedContentData?.level?.name;
  const xp = currentContentItem?.experience_points_total || bookmarkedContentData?.experience_points_total;
  // link as dep into immersive as we can.
  const linkTo = `/immersive/${enrollmentId}`;
  return (
    <div className="flex flex-col p-2 px-4 mb-2 w-full bg-white border-b-2 border-slate-200">
      {/** Header row */}
      <div className="justify-between items-center mb-2 text-center sm:flex">
        <AddLink to={linkTo} className="shrink-0">
          <img src={`${thumbnailUrl}`} alt="Course thumbnail" className="m-auto w-52 sm:w-18 sm:h-10" />
        </AddLink>
        <div className="grow py-2 text-lg font-semibold sm:py-0 sm:px-4">
          <AddLink to={`/browse/${permalink}`}>{title}</AddLink>
        </div>
        {/** If we're enrolled, show continue button. Otherwise, show options to start */}
        {enrollmentId ? (
          <ContinueLearningButton to={linkTo} className="w-full rounded sm:w-auto" />
        ) : (
          <StartLearningButton permalink={permalink} className="w-full rounded sm:w-auto" />
        )}
      </div>
      {/** Progress & Stats */}
      <div className="justify-between items-center sm:flex sm:flex-row-reverse">
        <ContinueLearningFeatures type={type} learningSecondsTotal={learningSecondsTotal} totalEnrollments={totalEnrollments} difficulty={difficulty} xp={xp} />
        <p className="text-2xs sm:flex sm:space-x-1">
          {completedPercent}% complete ({remainingTime} remaining)
        </p>
      </div>
      <ProgressBar progress={completedPercent || 0} />
    </div>
  );
}
