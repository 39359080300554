import React from 'react';

const Tiles = ({ tiles, addFilter }) => {
  return tiles.map((tile) => {
    const queryParam = tile.queryParam ? decodeURIComponent(tile.queryParam) : tile.name;
    return (
      <button type="button" key={tile.name} onClick={() => addFilter(tile.filter, queryParam)} className="inline-block w-full">
        <div className="py-4 bg-white rounded-sm border-xs border-gray-400">
          <div className="flex justify-between items-center h-20">
            <div className="px-[5%] w-[35%]">
              <img src={tile.imgPath} alt={tile.name} className="object-contain w-full max-w-[9rem] max-h-[3rem]" />
            </div>
            <div className="px-[5%] w-[65%]">
              <span className="sr-only">Filter by {tile.name}</span>
              <p className="m-0 text-sm text-gray-600">{tile.text}</p>
            </div>
          </div>
        </div>
      </button>
    );
  });
};

export default function TilesContainer({ tiles, addFilter }) {
  const copyOfTiles = [...tiles];
  const half = Math.ceil(copyOfTiles.length / 2);
  const firstHalf = copyOfTiles.splice(0, half);
  const secondHalf = copyOfTiles.splice(-half);
  return (
    <div className="grid gap-x-8 md:grid-cols-2">
      <div className="flex flex-col gap-y-8">
        <Tiles tiles={firstHalf} addFilter={addFilter} />
      </div>
      <div className="flex flex-col gap-y-8">
        <Tiles tiles={secondHalf} addFilter={addFilter} />
      </div>
    </div>
  );
}
