import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * Card component for displaying content in a card format.
 * @param {string} className - additional classes to be added to the card
 * @param {string} id - id to be added to the card
 * @param {boolean} inlineDisplay - boolean to display the card inline (default false)
 * @param {boolean} inlineContent - boolean to display the card content inline (default false)
 * @param {boolean} stackDisplay - boolean to display the card content in a stack (default false)
 * @param {JSX.Element} children - the content to be displayed in the card
 * @returns {JSX.Element} - the card component
 */
function Card({ className = '', id, inlineDisplay = false, inlineContent = false, stackDisplay = false, children }) {
  const classes = useMemo(() => {
    let classesToUse = 'p-4 relative rounded-sm border rounded-sm z-0 bg-white border-gray-400';
    // If inlineDisplay, remove margin from the last child. If not, remove margin from the first and last child.
    classesToUse += inlineDisplay ? ' last:mr-0' : ' last:mb-0 first:mt-0';
    // If inlineContent, use flex. If not, use flex-col.
    classesToUse += inlineContent ? ' flex gap-2 items-center' : ' flex-col gap-2';
    // If stackDisplay, remove margin and bottom border from all but last card
    classesToUse += stackDisplay ? ' my-0 border-b-0 last:border-b' : ' my-2';
    // Marge the classes with the className prop.
    return twMerge(classesToUse, className);
  }, [className, inlineDisplay, inlineContent, stackDisplay]);

  return (
    <div id={id || ''} className={classes}>
      {children}
    </div>
  );
}

/**
 * Card Section component for displaying content in a card format.
 * For use within the Card component.
 * Useful for creating columns of content within a card, or grouping components into a container to grow a section.
 * @param {string} className - additional classes to be added to the card section
 * @param {JSX.Element} children - the content to be displayed in the card section
 * @param {boolean} inlineContent - boolean to display the card section content inline (default false)
 * @param {boolean} grow - boolean to allow the card section to grow to fill the space (default false)
 * @returns
 */
function CardSection({ className, children, inlineContent = false, grow = false }) {
  const classes = useMemo(() => {
    let classesToUse = 'flex gap-2';
    // If inlineContent, use flex. If not, use flex-col.
    classesToUse += inlineContent ? ' items-center' : ' flex-col';
    // If grow, allow this section to grow to fill the space.
    classesToUse += grow ? ' grow' : '';
    // Marge the classes with the className prop.
    return twMerge(classesToUse, className);
  }, [inlineContent, className]);

  return <div className={classes}>{children}</div>;
}

Card.Section = CardSection;
export default Card;
