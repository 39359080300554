import { v4 as uuidv4 } from 'uuid';
import {
  canAccessClab,
  canManageAdmins,
  canManageAssignments,
  canManageTeam,
  canManageTeamOrGroups,
  canViewAnalytics,
  canViewPaths,
  canViewTeamGoals,
  isEnterpriseAdmin,
  isEnterpriseUser,
  teamHrefReplace,
  teamPeopleHrefReplace,
} from '../utils/navUtil';

/*
  Nav items here are available only for Enterprise users 
  based on specific display logic located inside of each object
*/
export const TEAMS_NAV = {
  label: 'Teams',
  icon: 'teams',
  href: '/enterprise/:orgId',
  display: isEnterpriseAdmin,
  hrefReplace: teamHrefReplace,
  startsWith: true,
  id: uuidv4(),
  children: [
    {
      teams: true,
      id: uuidv4(),
    },
    {
      divider: true,
      id: uuidv4(),
    },
    {
      label: 'Dashboard',
      href: '/enterprise/:orgId/organization/dashboard',
      display: isEnterpriseUser,
      hrefReplace: teamHrefReplace,
      id: uuidv4(),
    },
    {
      label: 'People',
      href: '/enterprise/:orgId/organization/members',
      display: canManageTeamOrGroups,
      hrefReplace: teamPeopleHrefReplace,
      id: uuidv4(),
      children: [
        {
          label: 'Members',
          href: '/enterprise/:orgId/organization/members',
          display: canManageTeam,
          hrefReplace: teamHrefReplace,
          id: uuidv4(),
        },
        {
          label: 'Groups',
          href: '/enterprise/:orgId/organization/groups',
          display: canManageTeamOrGroups,
          hrefReplace: teamHrefReplace,
          startsWith: true,
          id: uuidv4(),
        },
      ],
    },
    {
      label: 'Paths',
      href: '/enterprise/:orgId/paths',
      display: canViewPaths,
      hrefReplace: teamHrefReplace,
      startsWith: true,
      id: uuidv4(),
      children: [
        {
          label: 'My Paths',
          href: '/enterprise/:orgId/paths',
          display: canViewPaths,
          hrefReplace: teamHrefReplace,
          id: uuidv4(),
        },
        {
          label: 'Assessment Paths',
          href: '/enterprise/:orgId/paths/assessments',
          display: canViewPaths,
          hrefReplace: teamHrefReplace,
          startsWith: true,
          id: uuidv4(),
        },
        {
          label: 'Assessment Path',
          href: '/enterprise/:orgId/paths/assessments/:id',
          display: () => false,
          hrefReplace: teamHrefReplace,
          startsWith: true,
          id: uuidv4(),
        },
        {
          label: 'Team Paths',
          href: '/enterprise/:orgId/paths/team',
          display: canViewPaths,
          hrefReplace: teamHrefReplace,
          startsWith: true,
          id: uuidv4(),
        },
        {
          label: 'Cybrary Paths',
          href: '/enterprise/:orgId/paths/cybrary',
          display: canViewPaths,
          hrefReplace: teamHrefReplace,
          startsWith: true,
          id: uuidv4(),
        },
      ],
    },
    {
      label: 'Assignments',
      href: '/enterprise/:orgId/assignments',
      display: canManageAssignments,
      hrefReplace: teamHrefReplace,
      id: uuidv4(),
      children: [
        {
          label: 'In Progress',
          href: '/enterprise/:orgId/assignments',
          display: canManageAssignments,
          hrefReplace: teamHrefReplace,
          id: uuidv4(),
        },
        {
          label: 'Completed',
          href: '/enterprise/:orgId/assignments/past',
          display: canManageAssignments,
          hrefReplace: teamHrefReplace,
          id: uuidv4(),
        },
      ],
    },
    {
      label: 'Goals',
      href: '/enterprise/:orgId/goals',
      display: canViewTeamGoals,
      hrefReplace: teamHrefReplace,
      id: uuidv4(),
      startsWith: true,
      children: [
        {
          label: 'Active',
          href: '/enterprise/:orgId/goals',
          display: canViewTeamGoals,
          hrefReplace: teamHrefReplace,
          id: uuidv4(),
        },
        {
          label: 'Upcoming',
          href: '/enterprise/:orgId/goals/upcoming',
          display: canViewTeamGoals,
          hrefReplace: teamHrefReplace,
          id: uuidv4(),
        },
        {
          label: 'History',
          href: '/enterprise/:orgId/goals/completed',
          display: canViewTeamGoals,
          hrefReplace: teamHrefReplace,
          id: uuidv4(),
        },
      ],
    },
    {
      label: 'Analytics',
      href: '/enterprise/:orgId/reporting',
      display: canViewAnalytics,
      hrefReplace: teamHrefReplace,
      id: uuidv4(),
      startsWith: true,
      children: [
        {
          label: 'User Analytics',
          href: '/enterprise/:orgId/reporting',
          display: canViewAnalytics,
          hrefReplace: teamHrefReplace,
          id: uuidv4(),
        },
        {
          label: 'Content Analytics',
          href: '/enterprise/:orgId/reporting/content',
          display: canViewAnalytics,
          hrefReplace: teamHrefReplace,
          id: uuidv4(),
        },
        {
          label: 'Tools',
          href: '/enterprise/:orgId/reporting/tools',
          display: canViewAnalytics,
          hrefReplace: teamHrefReplace,
          id: uuidv4(),
        },
      ],
    },
    {
      label: 'Settings',
      href: '/enterprise/:orgId/settings',
      display: canManageTeam,
      hrefReplace: teamHrefReplace,
      id: uuidv4(),
    },
    {
      label: 'Audit Log',
      href: '/enterprise/:orgId/logs',
      display: canManageAdmins,
      hrefReplace: teamHrefReplace,
      id: uuidv4(),
    },
  ],
  group: 'main',
};
export const CLAB_BETA_NAV = {
  label: 'Assess (BETA)',
  icon: 'terminal',
  href: '/assessments',
  display: canAccessClab,
  id: uuidv4(),
};
