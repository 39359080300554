import React from 'react';
import Icon from '../Icon/Icon';

const buttonClass = 'flex justify-center items-center cursor-pointer w-8 h-8 hover:bg-gray-200 hover:text-cyb-pink-500';
const linkClass = 'border-r last:border-0 border-gray-300';
function PaginationWrapper({
  pathname,
  getOnClick,
  activePage,
  totalPages,
  getFirstPageBatch,
  getFirstPageAndEllipsis,
  getLastPageBatch,
  getMiddlePagesBatch,
  getLastPageAndEllipsis,
  isZeroIndexed,
}) {
  const shim = isZeroIndexed ? 1 : 0;
  const firstIndex = 1 - shim;
  const ret = [];
  const goToFirstClick = getOnClick(pathname, firstIndex, activePage, shim);
  const goToPrevClick = getOnClick(pathname, activePage - 1, activePage, shim);
  const goToNextClick = getOnClick(pathname, activePage + 1, activePage, shim);
  const goToLastClick = getOnClick(pathname, totalPages, activePage, shim);

  if (activePage > firstIndex) {
    ret.push(
      <li className={linkClass} key="double-prev">
        <a className={buttonClass} rel="prev" {...goToFirstClick} aria-label="First PAge">
          <Icon name="chevron-double-left" className="w-2 h-2" />
        </a>
      </li>
    );

    // Previous button - Always shown, not always active
    ret.push(
      <li className={`hidden md:flex ${linkClass}`} key="prev">
        <a className={buttonClass} rel="prev" {...goToPrevClick} aria-label="Previous Page">
          <Icon name="chevron-left" className="w-2 h-2" />
        </a>
      </li>
    );
  }

  // If there are 5 or less pages, we just show those pages - nothing fancy
  if (totalPages <= 5 - shim) {
    ret.push(...getFirstPageBatch(5, pathname, totalPages, activePage, shim));
  } else {
    // If we are within 3 pages from the beginning, we show those 5 pages
    if (activePage < 3 - shim) {
      ret.push(...getFirstPageBatch(3, pathname, totalPages, activePage, shim));
    }
    // If we are more than 3 pages in and there are more than 5 pages, show page 1 and ellipsis
    ret.push(...getFirstPageAndEllipsis(pathname, totalPages, activePage, shim));

    // If we are within 3 pages from the end, we show those last 5 pages (unless we have less than 5 pages total - Then already handled where we show first 5 pages)
    ret.push(...getLastPageBatch(pathname, totalPages, activePage, shim));

    // If not near the beginning or end, show the active page with the sibling pages
    ret.push(...getMiddlePagesBatch(pathname, totalPages, activePage, shim));

    // if we are more than 3 pages in but not more than 3 pages from the end, show the last page
    ret.push(...getLastPageAndEllipsis(pathname, totalPages, activePage, shim));
  }

  if (activePage !== totalPages) {
    // Next button - Always shown
    ret.push(
      <li className={`hidden md:flex ${linkClass}`} key="next">
        <a className={buttonClass} rel="next" {...goToNextClick} aria-label="Next Page">
          <Icon name="chevron-right" className="w-2 h-2" />
        </a>
      </li>
    );

    ret.push(
      <li className={linkClass} key="double-next">
        <a className={buttonClass} rel="next" {...goToLastClick} aria-label="Last Page">
          <Icon name="chevron-double-right" className="w-2 h-2" />
        </a>
      </li>
    );
  }
  return <ul className="inline-flex overflow-hidden text-xs text-center bg-white rounded-sm border border-gray-300">{ret}</ul>;
}

class Pagination extends React.Component {
  getFirstPageBatch = (count, pathname, totalPages, activePage, shim) => {
    const ret = [];
    for (let i = 1 - shim; i <= count - shim; i++) {
      if (i >= 1 - shim && i <= totalPages) {
        const onClick = this.getOnClick(pathname, i, activePage, shim);
        const isActive = i === activePage;
        const ariaLabelText = isActive ? `Current Page, Page ${i + shim}` : `Goto Page ${i + shim}`;
        const activeLinkClass = isActive ? 'bg-gray-300 ' : '';
        const ariaCurrentProp = isActive ? { 'aria-current': 'true' } : {};
        ret.push(
          <li className={linkClass} key={i + shim}>
            <a className={`text-cyb-black hover:text-cyb-black ${buttonClass} ${activeLinkClass}`} {...onClick} aria-label={ariaLabelText} {...ariaCurrentProp}>
              {i + shim}
            </a>
          </li>
        );
      }
    }
    return ret;
  };

  getLastPageBatch = (pathname, totalPages, activePage, shim) => {
    const ret = [];
    if (totalPages > 3 && activePage > totalPages - 2) {
      for (let i = totalPages - 2; i <= totalPages; i++) {
        if (i <= totalPages && i >= 1) {
          const onClick = this.getOnClick(pathname, i, activePage, shim);
          const isActive = i === activePage;
          const ariaCurrentProp = isActive ? { 'aria-current': 'true' } : {};
          const activeLinkClass = isActive ? 'bg-gray-300 ' : '';
          const ariaLabelText = isActive ? `Current Page, Page ${i + shim}` : `Goto Page ${i + shim}`;
          ret.push(
            <li className={linkClass} key={i + shim}>
              <a className={`text-cyb-black hover:text-cyb-black ${buttonClass} ${activeLinkClass}`} {...onClick} aria-label={ariaLabelText} {...ariaCurrentProp}>
                {i + shim}
              </a>
            </li>
          );
        }
      }
    }
    return ret;
  };

  getMiddlePagesBatch = (pathname, totalPages, activePage, shim) => {
    const ret = [];
    if (activePage >= 3 - shim && activePage <= totalPages - 2) {
      // Show the siblings of the current page (can be expanded to pass in sibling value as prop, if needed)
      for (let i = activePage - 1; i <= activePage + 1; i++) {
        const isActive = i === activePage;
        const onClick = this.getOnClick(pathname, i, activePage, shim);
        const activeLinkClass = isActive ? 'bg-gray-300 ' : '';
        const ariaLabelText = isActive ? `Current Page, Page ${i + shim}` : `Goto Page ${i + shim}`;
        const ariaCurrentProp = isActive ? { 'aria-current': 'true' } : {};
        ret.push(
          <li className={linkClass} key={i + shim}>
            <a className={`text-cyb-black hover:text-cyb-black ${buttonClass} ${activeLinkClass}`} {...onClick} aria-label={ariaLabelText} {...ariaCurrentProp}>
              {i + shim}
            </a>
          </li>
        );
      }
    }
    return ret;
  };

  getFirstPageAndEllipsis = (pathname, totalPages, activePage, shim) => {
    const ret = [];
    if (totalPages > 3 && activePage >= 3 - shim) {
      const onClick = this.getOnClick(pathname, 1 - shim, activePage, shim);
      ret.push(
        <li className={linkClass} key="first-page">
          <a className={`text-cyb-black hover:text-cyb-black ${buttonClass}`} {...onClick} aria-label="Goto Page 1">
            1
          </a>
        </li>
      );
      ret.push(
        <li className={linkClass} key="beg-ellipses">
          <div className={buttonClass}>
            <Icon className="w-2 h-2" name="dots-horizontal" />
          </div>
        </li>
      );
    }
    return ret;
  };

  getLastPageAndEllipsis = (pathname, totalPages, activePage, shim) => {
    const ret = [];
    if (totalPages > 3 && activePage <= totalPages - 2) {
      const onClick = this.getOnClick(pathname, totalPages, activePage, shim);
      ret.push(
        <li className={linkClass} key="end-ellipses">
          <div className={buttonClass}>
            <Icon name="dots-horizontal" className="w-2 h-2 text-center" />
          </div>
        </li>
      );
      ret.push(
        <li className={linkClass} key="last-page">
          <a className={`text-cyb-black hover:text-cyb-black text-xs ${buttonClass}`} {...onClick} aria-label={`Goto Page ${totalPages + shim}`}>
            {totalPages + shim}
          </a>
        </li>
      );
    }
    return ret;
  };

  getPageHref = (pathname, pageIdx, activePage, shim) => {
    if (pageIdx === 1 - shim) {
      return pathname;
    }
    if (pageIdx === activePage) {
      return null;
    }
    return `${pathname}${pageIdx}/`;
  };

  getOnClick = (pathname, pageIdx, activePage, shim) => {
    if (pageIdx === activePage) {
      return null;
    }
    return this.props.onPageChange
      ? {
          onClick: (e) => {
            e.preventDefault();
            this.props.onPageChange(pageIdx);
          },
        }
      : { href: this.getPageHref(pathname, pageIdx, activePage, shim) };
  };

  render() {
    const { pathname, activePage, totalPages, siblingRange, isZeroIndexed } = this.props;
    if (totalPages <= 1) {
      return null;
    }
    /** @important this is b/c when parsing query params, page=`${pageNumber}` can be a string so we force it to type number */
    const activePageAsNumber = Number(activePage);
    return (
      <nav aria-label="Pagination Navigation">
        <PaginationWrapper
          pathname={pathname}
          activePage={activePageAsNumber}
          totalPages={totalPages}
          siblingRange={siblingRange}
          getFirstPageBatch={this.getFirstPageBatch}
          getFirstPageAndEllipsis={this.getFirstPageAndEllipsis}
          getLastPageBatch={this.getLastPageBatch}
          getMiddlePagesBatch={this.getMiddlePagesBatch}
          getLastPageAndEllipsis={this.getLastPageAndEllipsis}
          getOnClick={this.getOnClick}
          isZeroIndexed={isZeroIndexed}
        />
      </nav>
    );
  }
}

export default Pagination;
