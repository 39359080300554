import React from 'react';
import OnboardingStep from './OnboardingStep';
import { useOnboardingContext } from '../../../providers/OnboardingProvider';
import SwitchToManager from './OnboardingStepsSwitchToManager';

/** Step shown at the start of the onboarding flow */
function CIPExperienceStep() {
  const { setCipExperience, setStep } = useOnboardingContext();
  const handleStep = (value) => {
    setCipExperience(value);
    setStep('cipForm');
  };

  return (
    <OnboardingStep title="How much IT experience do you have?">
      <div className="flex flex-col justify-center items-center sm:flex-row">
        <OnboardingStep.Button title="I'm brand new" icon="person-shield" onClick={() => handleStep("I'm brand new")} className="justify-center w-64 h-64" />
        <OnboardingStep.Button
          title="I've got some IT experience"
          icon="working-dev"
          onClick={() => handleStep("I've got some IT experience")}
          className="justify-center w-64 h-64"
        />
      </div>
      <SwitchToManager />
    </OnboardingStep>
  );
}

export default CIPExperienceStep;
