import React, { useEffect, useMemo } from 'react';
import Carousel from '../Carousel/Carousel';
import AddLink from '../AddLink/AddLink';
import { useProfileContext } from '../../providers/ProfileProvider';
import Loading from '../Loading/Loading';
import Badge from '../Profile/ProfileTabs/BadgesTab/Badge';
import If from '../If/If';
import { useBadgeModal } from '../../providers/BadgeModalProvider';
import Header from '../Header/Header';

// Sort function for badges - Newest first
const recentlyCreatedBadgesFirstSort = (a, b) => {
  const aDate = new Date(a.created_at);
  const bDate = new Date(b.created_at);
  return bDate - aDate;
};

// Sort function for badges - Recently earned first
const recentlyEarnedBadgesFirstSort = (a, b) => {
  const aDate = new Date(a.completed_at);
  const bDate = new Date(b.completed_at);
  return bDate - aDate;
};

// Filter function for recently earned badges - earned within the last 30 days
const recentlyEarnedBadgesFilter = (badge) => {
  if (!badge.completed_at) return false;
  const badgeDate = new Date(badge.completed_at);
  const today = new Date();
  const thirtyDaysAgo = new Date(today.setDate(today.getDate() - 30));
  return badgeDate > thirtyDaysAgo;
};

// Filter function for in progress badges
const inProgressFilter = (badge) => !badge.completed_at && badge.progress > 0;

// Active and Inactive classes for tab headers
const ACTIVE_CLASSES = ' border-cyb-pink-500 font-semibold text-black';
const INACTIVE_CLASSES = ' border-slate-200 cursor-pointer';

// Tabs to display in the continue learning block
const TABS = [
  {
    key: 'inProgress',
    tabTitle: 'In Progress',
  },
  {
    key: 'recentlyEarned',
    tabTitle: 'Recently Earned',
  },
  {
    key: 'new',
    tabTitle: 'Recently Added',
  },
];

/**
 * Use customHeader to put title, tabs, carousel actions, and carousel navigation all in one row
 * @param {string} activeTab the active tab to apply sort/filter rules for
 * @param {function} setActiveTab the function to set the active tab
 * @returns <BadgeWidgetHeader /> the header for the badges widget
 */
function BadgeWidgetHeader({ activeTab, setActiveTab }) {
  return (
    <div className="block items-center align-middle lg:flex">
      <Header as="h2" className="pr-4 mb-4">
        Badges
      </Header>
      <nav className="flex grow max-w-md">
        {TABS.map(({ key, tabTitle }) => (
          <button
            key={key}
            aria-pressed={activeTab === key}
            aria-label={`View ${tabTitle} content`}
            className={`inline-block text-sm px-1 w-full mb-4 border-b-2 pb-2 ${activeTab === key ? ACTIVE_CLASSES : INACTIVE_CLASSES}`}
            onClick={() => setActiveTab(key)}
          >
            {tabTitle}
          </button>
        ))}
      </nav>
    </div>
  );
}

/**
 * Actions for the badges widget
 * @returns <BadgeWidgetActions /> the actions for the badges widget
 */
function BadgeWidgetActions() {
  return (
    <AddLink className="inline-block text-sm text-cyb-pink-500 hover:text-black underline whitespace-nowrap cursor-pointer" to="/profile?tab=badges">
      View all
    </AddLink>
  );
}

/**
 * Badges widget for Dashboard
 * @returns <BadgesWidget /> the badges widget
 */
function BadgesWidget() {
  const { isLoading, badges } = useProfileContext();
  const { open } = useBadgeModal();
  const [activeTab, setActiveTab] = React.useState(TABS[0].key);
  // Try to put the user on their most relevant tab:
  // 1. If they have in progress badges, show them first
  // 2. If they have recently earned badges, show them second
  // 3. If there are new badges, show them last
  const [initTab, setInitTab] = React.useState(true);
  useEffect(() => {
    if (initTab && badges.length) {
      if (badges.some(inProgressFilter)) {
        setActiveTab('inProgress');
      } else if (badges.some(recentlyEarnedBadgesFilter)) {
        setActiveTab('recentlyEarned');
      } else {
        setActiveTab('new');
      }
      setInitTab(false);
    }
  }, [badges, initTab]);

  // Filter / Sort badges based on active tab
  const displayBadges = useMemo(() => {
    switch (activeTab) {
      case 'new':
        return badges.sort(recentlyCreatedBadgesFirstSort);
      case 'recentlyEarned':
        return badges.filter(recentlyEarnedBadgesFilter).sort(recentlyEarnedBadgesFirstSort);
      case 'inProgress':
        return badges.filter(inProgressFilter);
      default:
        return badges;
    }
  }, [badges, activeTab]);

  // Empty state text
  const noItemsJsx = useMemo(() => {
    let text;
    switch (activeTab) {
      case 'new':
        text = 'There are no new badges at this time. Check back later!';
        break;
      case 'recentlyEarned':
        text = 'You have no recently earned badges.';
        break;
      case 'inProgress':
        text = 'You have no in progress badges yet.';
        break;
      default:
        text = 'No badges here yet';
        break;
    }
    return <div className="flex justify-center items-center h-48 text-lg text-gray-600">{text}</div>;
  }, [activeTab]);
  return (
    <div className="w-full h-full min-h-[255px]">
      {/** Loading State */}
      <If condition={isLoading}>
        <BadgeWidgetHeader activeTab={activeTab} setActiveTab={setActiveTab} />
        <Loading message="Loading badges..." wrapperClassName="w-full h-4/5" />
      </If>
      {/** Default State */}
      <If condition={!isLoading}>
        <Carousel
          id="new-badges-carousel"
          className="flex flex-col w-full max-w-[675px] min-h-[255px]"
          items={displayBadges.map((badge) => (
            <Badge key={badge.id} badge={badge} className="w-48 rounded-md" onClick={() => open(badge)} wrapperClassName="bg-white" showProgress noDescription alwaysUnlocked />
          ))}
          customHeaderJsx={<BadgeWidgetHeader activeTab={activeTab} setActiveTab={setActiveTab} />}
          actions={<BadgeWidgetActions />}
          noItemsJsx={noItemsJsx}
        />
      </If>
    </div>
  );
}

export default BadgesWidget;
